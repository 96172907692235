import { useState } from 'react';

export const useFileUpload = ({
    acceptedTypes,
    maxSizeConfig = {
        image: 5 * 1024 * 1024, // 5MB for images
        video: 100 * 1024 * 1024 // 100MB for videos
    },
    onFileSelect,
}) => {
    const [error, setError] = useState('');

    const handleFile = (file) => {
        if (!file) {
            setError('No file selected');
            return;
        }

        // Determine file type category
        const isImage = file.type.startsWith('image/');
        const isVideo = file.type.startsWith('video/');
        
        // Get applicable max size
        const maxSize = isImage ? maxSizeConfig.image : 
                       isVideo ? maxSizeConfig.video : 
                       Math.max(maxSizeConfig.image, maxSizeConfig.video);

        // Validate file type
        if (acceptedTypes !== '*' && !file.type.match(new RegExp(acceptedTypes.split(',').join('|')))) {
            setError('File type not accepted');
            return;
        }

        // Validate file size
        if (file.size > maxSize) {
            const sizeInMB = Math.floor(maxSize / 1024 / 1024);
            setError(`File size exceeds ${sizeInMB}MB limit for ${isImage ? 'images' : 'videos'}`);
            return;
        }

        setError('');
        onFileSelect?.(file);
    };

    return { error, handleFile };
};