export const dateDifference = (targetDate) => {
    // Convert both dates to midnight UTC to ensure consistent comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const target = new Date(targetDate);
    target.setHours(0, 0, 0, 0);
    
    // Calculate the time difference in milliseconds
    const timeDiff = target.getTime() - today.getTime();
    
    // Convert to days and round down
    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    
    // Return 0 if the date has passed or is today
    return Math.max(0, daysRemaining);
};