import { apiClient } from '../../../services/api/config';
import { ENDPOINTS } from '../../../services/api/endpoints';

export const authenticationApi = {
    login: async(credentials) => {
        const response = await apiClient.post(ENDPOINTS.AUTHENTICATION.LOGIN, credentials);
        return response.data;
    },

    // logout: async () => {
    //     const response = await apiClient.post(ENDPOINTS.AUTHENTICATION.LOGOUT);
    //     return response.data;
    // }
};