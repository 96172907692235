import { useQueries, useQuery } from '@tanstack/react-query';
import { userApi } from '../services/userApi';
import { groupApi } from '../../group/services/groupApi';
import { eventApi } from '../../event/services/eventApi';
import { collectionApi } from '../../collection/services/collectionApi';
import { personApi } from '../../person/services/personApi';
import { useAuthenticationStore } from '../../authentication/store/authenticationStore';
import { useUserStore } from '../store/userStore';
import { useGroupStore } from '../../group/store/groupStore';
import { useEventStore } from '../../event/store/eventStore';
import { useCollectionStore } from '../../collection/store/collectionStore';
import { usePersonStore } from '../../person/store/personStore';
import { mediaApi } from '../../media/services/mediaApi';

export const useUserDetails = () => {
    const isAuthenticated = useAuthenticationStore(state => state.isAuthenticated);
    const { setUser } = useUserStore();
    const { setGroups } = useGroupStore();
    const { setEvents } = useEventStore();
    const { setCollections } = useCollectionStore();
    const { setPeople, setPerson } = usePersonStore();

    const userQuery = useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            try {
                const userId = localStorage.getItem('user_id');
                const [userData, profileImage] = await Promise.all([
                    userApi.getUserDetails(),
                    userApi.getUserProfileImage(userId)
                        .catch(() => null)
                ]);
                return {
                    ...userData,
                    profile_image: profileImage
                };
            } catch (err) {
                console.error('Error in userQuery:', err);
                throw err;
            }
        },
        enabled: isAuthenticated && !!localStorage.getItem('user_id'),
        staleTime: 300000,
        cacheTime: 3600000,
        onError: (err) => console.error('Query error:', err),
        onSuccess: (data) => {
            console.log('Query success data:', data);
            if (!data) throw new Error('No user data');
            setUser(data);
            console.log('User set in store');
        }
    });

    const queries = useQueries({
        queries: [
            {
                queryKey: ['person', userQuery.data?.person_id],
                queryFn: async () => {
                    if (!userQuery.data?.person_id) {
                        setPeople([]);
                        return null;
                    }
                    const personData = await personApi.getPersonDetails(userQuery.data.person_id);
                    setPerson(personData.data);
                    return personData;
                },
                enabled: !!userQuery.data?.person_id,
                staleTime: 300000,
                cacheTime: 3600000
            },
            {
                queryKey: ['groups'],
                queryFn: async () => {
                    if (!userQuery.data?.groups?.length) {
                        setGroups([]);
                        return [];
                    }

                    const groupDetails = await Promise.all(
                        userQuery.data.groups.map(async group => {
                            const [groupData, profileImage] = await Promise.all([
                                groupApi.getGroupDetails(group.group_id),
                                groupApi.getGroupProfileImage(group.group_id)
                                    .catch(() => null)
                            ]);

                            return {
                                ...groupData,
                                profile_image: profileImage
                            };
                        })
                    );

                    setGroups(groupDetails);
                    return groupDetails;
                },
                enabled: !!userQuery.data,
                staleTime: 300000,
                cacheTime: 3600000
            },
            {
                queryKey: ['events'],
                queryFn: async () => {
                    if (!userQuery.data?.events?.length) {
                        setEvents([]);
                        return [];
                    }

                    const eventDetails = await Promise.all(
                        userQuery.data.events.map(async event => {
                            const [eventData, profileImage] = await Promise.all([
                                eventApi.getEventDetails(event.event_id),
                                eventApi.getEventProfileImage(event.event_id)
                                    .catch(() => null)
                            ]);

                            return {
                                ...eventData,
                                profile_image: profileImage
                            };
                        })
                    );

                    setEvents(eventDetails);
                    return eventDetails;
                },
                enabled: !!userQuery.data,
                staleTime: 300000,
                cacheTime: 3600000
            },
            {
                queryKey: ['collections'],
                queryFn: async () => {
                    if (!userQuery.data?.collections?.length) {
                        setCollections([]);
                        return [];
                    }

                    const collectionDetails = await Promise.all(
                        userQuery.data.collections.map(async collection => {
                            const [collectionData, profileImage] = await Promise.all([
                                collectionApi.getCollectionDetails(collection.collection_id),
                                collectionApi.getCollectionProfileImage(collection.collection_id)
                                    .catch(() => null)
                            ]);

                            let thumbnail = null;
                            if (collectionData.media?.length) {
                                const mediaWithThumbnails = collectionData.media.filter(
                                    item => item.media_thumbnail_blob_path
                                );
                                if (mediaWithThumbnails.length) {
                                    const randomMedia = mediaWithThumbnails[
                                        Math.floor(Math.random() * mediaWithThumbnails.length)
                                    ];
                                    try {
                                        thumbnail = await mediaApi.getMediaThumbnailImage(
                                            randomMedia.media_id
                                        );
                                    } catch (error) {
                                        console.error('Error downloading thumbnail:', error);
                                    }
                                }
                            }

                            return {
                                ...collectionData,
                                profile_image: profileImage,
                                thumbnail
                            };
                        })
                    );

                    setCollections(collectionDetails);
                    return collectionDetails;
                },
                enabled: !!userQuery.data,
                staleTime: 300000,
                cacheTime: 3600000
            },
            {
                queryKey: ['groupPeople'],
                queryFn: async () => {
                    if (!userQuery.data?.groups?.length) {
                        return [];
                    }

                    const groups = await Promise.all(
                        userQuery.data.groups.map(group => groupApi.getGroupDetails(group.group_id))
                    );

                    const userIds = new Set();
                    groups.forEach(group => {
                        if (!group.users) return;
                        group.users.forEach(user => userIds.add(user.user_id));
                    });

                    const userDetails = await Promise.all(
                        Array.from(userIds).map(async userId => {
                            const [userData, profileImage] = await Promise.all([
                                userApi.getUserDetailsByUserId(userId),
                                userApi.getUserProfileImage(userId)
                                    .catch(() => null)
                            ]);

                            return {
                                ...userData,
                                profile_image: profileImage
                            };
                        })
                    );

                    const userMap = userDetails.reduce((acc, user) => {
                        acc[user.user_id] = user;
                        return acc;
                    }, {});

                    const personIds = userDetails
                        .filter(user => user?.person_id)
                        .map(user => user.person_id);

                    if (personIds.length === 0) return [];

                    const peopleData = await Promise.all(
                        personIds.map(async id => {
                            const personData = await personApi.getPersonDetails(id);
                            const user = userMap[personData.data.user_id];

                            return {
                                ...personData,
                                data: {
                                    ...personData.data,
                                    user: {
                                        ...personData.data.user,
                                        profile_image: user?.profile_image
                                    }
                                }
                            };
                        })
                    );

                    setPeople(peopleData);
                    return peopleData;
                },
                enabled: !!userQuery.data,
                staleTime: 300000,
                cacheTime: 3600000
            }
        ]
    });

    const isLoading = [userQuery, ...queries].some(query => query.isLoading);
    const isFetching = [userQuery, ...queries].some(query => query.isFetching);
    const isError = [userQuery, ...queries].some(query => query.isError);
    const isSuccess = [userQuery, ...queries].every(query => query.isSuccess || query.isIdle);

    const isDataReady = isSuccess && !isLoading && !isFetching;

    return {
        userData: userQuery.data,
        groups: queries[1].data || [],
        events: queries[2].data || [],
        collections: queries[3].data || [],
        people: queries[4].data || [],
        isLoading,
        isFetching,
        isDataReady,
        error: isError ? [userQuery, ...queries].find(query => query.error)?.error : null,
        refetchUser: userQuery.refetch
    };
};