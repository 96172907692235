import React from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';

export const PublicMediaFileItem = ({ 
    mediaItem, 
    onDelete,
    showError = false,
    errorMessage = '',
    isUploading = false,
    thumbnailUrl
}) => {    
    // Check if this is an HEIC file that was successfully uploaded but has no thumbnail
    const isHeicWithoutThumbnail = mediaItem.isHeic && mediaItem.thumbnailUnavailable;
    const isProcessing = !thumbnailUrl && !isUploading && !isHeicWithoutThumbnail;
    
    if (showError) {
        return (
            <Box 
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box 
                    sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        zIndex: 2
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={() => onDelete(mediaItem)}
                        sx={{
                            backgroundColor: 'white',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                backgroundColor: '#f5f5f5'
                            }
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
                <Typography sx={{
                    fontSize: '14px',
                    color: '#666',
                    textAlign: 'center'
                }}>
                    {errorMessage || 'Upload Error'}
                </Typography>
                <Typography sx={{
                    fontSize: '12px',
                    color: '#999',
                    marginTop: '4px',
                    textAlign: 'center'
                }}>
                    {mediaItem.media_name}
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px',
                width: '100%', // Make width responsive
                position: 'relative'
            }}
        >
            <Box 
                sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    zIndex: 2
                }}
            >
                <IconButton
                    size="small"
                    onClick={() => onDelete(mediaItem)}
                    sx={{
                        backgroundColor: 'white',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                        border: '1px solid #E6E8E9',
                        padding: {
                            xs: '2px', // Smaller on mobile
                            sm: '4px'  // Normal on larger screens
                        },
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        }
                    }}
                >
                    <CloseIcon 
                        sx={{
                            fontSize: {
                                xs: '0.8rem', // Smaller on mobile
                                sm: 'small'   // Normal on larger screens
                            }
                        }} 
                    />
                </IconButton>
            </Box>

            <Box
                sx={{
                    marginTop: '5px',
                    marginLeft: {
                        xs: '5px',  // Less margin on mobile
                        sm: '10px'  // Normal margin on larger screens
                    },
                    height: {
                        xs: '150px', // Smaller height on mobile
                        sm: '180px', // Medium height on tablet
                        md: '200px'  // Full height on desktop
                    },
                    width: '100%',   // Full width of parent container
                    maxWidth: {
                        xs: '100%',  // Full width on mobile
                        sm: '207px'  // Original width on larger screens
                    },
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: '#f0f0f0',
                    transition: 'all 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                {/* Show uploading state */}
                {isUploading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '10px'
                        }}
                    >
                        <CircularProgress 
                            size={
                                {
                                    xs: 30, // Smaller on mobile
                                    sm: 40  // Normal on larger screens
                                }
                            }
                            sx={{ 
                                color: '#0357D7',
                                marginBottom: '10px'
                            }} 
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                color: '#000',
                                fontSize: {
                                    xs: '14px', // Smaller on mobile
                                    sm: '16px'  // Normal on larger screens
                                },
                                fontWeight: '500',
                                lineHeight: '150%'
                            }}
                        >
                            Uploading...
                        </Typography>
                    </Box>
                )}

                {/* Show processing state */}
                {isProcessing && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '10px'
                        }}
                    >
                        <CircularProgress 
                            size={
                                {
                                    xs: 30, // Smaller on mobile
                                    sm: 40  // Normal on larger screens
                                }
                            }
                            sx={{ 
                                color: '#0357D7',
                                marginBottom: '10px'
                            }} 
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                color: '#000',
                                fontSize: {
                                    xs: '14px', // Smaller on mobile
                                    sm: '16px'  // Normal on larger screens
                                },
                                fontWeight: '500',
                                lineHeight: '150%'
                            }}
                        >
                            Processing...
                        </Typography>
                    </Box>
                )}

                {/* Special handling for HEIC files without thumbnails */}
                {isHeicWithoutThumbnail && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: '10px',
                            padding: '16px'
                        }}
                    >
                        <ImageIcon 
                            sx={{ 
                                fontSize: {
                                    xs: 30, // Smaller on mobile
                                    sm: 40  // Normal on larger screens
                                },
                                color: '#0357D7',
                                marginBottom: '10px'
                            }} 
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                color: '#000',
                                fontSize: {
                                    xs: '14px', // Smaller on mobile
                                    sm: '16px'  // Normal on larger screens
                                },
                                fontWeight: '500',
                                lineHeight: '150%',
                                textAlign: 'center'
                            }}
                        >
                            {mediaItem.media_name}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                color: '#666',
                                fontSize: {
                                    xs: '12px', // Smaller on mobile
                                    sm: '13px'  // Normal on larger screens
                                },
                                fontWeight: '400',
                                lineHeight: '150%',
                                textAlign: 'center',
                                mt: 1
                            }}
                        >
                            HEIC Image (Uploaded)
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};