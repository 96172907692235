import { useMutation } from '@tanstack/react-query';
import { collectionApi } from '../services/collectionApi';
import { mediaApi } from '../../media/services/mediaApi';
import { isEmpty } from '../../../utils/Utils';

export const useCollection = () => {
    const createCollectionMutation = useMutation({
        mutationFn: async (collectionFormData) => {
            console.log('Creating collection with data:', collectionFormData);
            // Step 1: Create the collection
            const requestPayload = {
                group_id: collectionFormData.groupId,
                user_id: collectionFormData.userId,
                collection_name: collectionFormData.name,
                collection_description: collectionFormData.description,
                collection_location: collectionFormData.location,
                collection_start_date: collectionFormData.startDate,
                collection_end_date: collectionFormData.endDate
            };

            const response = await collectionApi.createCollection(requestPayload);
            
            // collectionApi.createCollection() already returns response.data,
            // so we don't need to access .data again
            const collectionId = response;
            console.log('Collection created successfully, ID:', collectionId);
            
            // Step 2: If there's a thumbnail, upload it
            if (collectionFormData.thumbnailFile && !isEmpty(collectionId)) {
                console.log('Uploading collection thumbnail image for collection ID:', collectionId);
                try {
                    const thumbnailResponse = await mediaApi.updateEntityThumbnailImage(
                        collectionId, 
                        'collection',
                        collectionFormData.thumbnailFile
                    );
                    console.log('Collection thumbnail uploaded successfully:', thumbnailResponse);
                } catch (thumbnailError) {
                    console.error('Failed to upload collection thumbnail:', thumbnailError);
                    // Note: We don't throw here to avoid failing the entire operation
                }
            } else {
                console.log('No thumbnail to upload or missing collection ID');
                if (!collectionFormData.thumbnailFile) {
                    console.log('No thumbnail file provided');
                }
                if (isEmpty(collectionId)) {
                    console.log('Collection ID is empty:', collectionId);
                }
            }
            
            // Return the full result
            return {
                collectionId: collectionId,
                formData: collectionFormData
            };
        },
        onSuccess: (result) => {
            console.log(`Successfully created collection: ${result.collectionId} for group ${result.formData.groupId}`);
        },
        onError: (error, variables) => {
            console.error(`Failed to create collection for group ${variables.groupId}:`, error);
        }
    });

    return {
        createCollection: createCollectionMutation.mutate,
        createCollectionAsync: createCollectionMutation.mutateAsync,
        isCreateCollectionLoading: createCollectionMutation.isPending,
        errorCreateCollection: createCollectionMutation.error
    };
};