import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useAuthenticationStore } from '../features/authentication/store/authenticationStore';
import NavigationLayout from '../features/navigation/components/navigation-layout/NavigationLayout';
import HomePage from '../features/home/HomePage';
import LandingPage from '../features/landing/LandingPage';
import CollectionViewPage from '../features/collection/CollectionViewPage';
import LoginComponent from '../features/landing/components/login/LoginComponent';
import SignupComponent from '../features/landing/components/signup/SignupComponent';
import ResetPasswordComponent from '../features/landing/components/reset-password/ResetPasswordComponent';
import { ShareableLinkMediaUploadPage } from '../features/public/shareable-link-media-upload/ShareableLinkMediaUploadPage';

const AuthGuard = ({ children }) => {
    const isAuthenticated = useAuthenticationStore(state => state.isAuthenticated);
    return isAuthenticated ? children : <Navigate to="/auth/login" replace />;
};

const PublicGuard = ({ children }) => {
    const isAuthenticated = useAuthenticationStore(state => state.isAuthenticated);
    return isAuthenticated ? <Navigate to="/home" replace /> : children;
};

const routes = [
    {
        path: '/',
        element: <Navigate to="/auth/login" replace />
    },
    {
        path: 'public/upload/:token',
        element: <ShareableLinkMediaUploadPage />
    },
    {
        path: 'auth',
        element: (
            <PublicGuard>
                <LandingPage />
            </PublicGuard>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="login" replace />
            },
            {
                path: 'login',
                element: <LoginComponent />
            },
            {
                path: 'signup',
                element: <SignupComponent />
            },
            {
                path: 'reset-password',
                element: <ResetPasswordComponent />
            }
        ]
    },
    {
        path: '/',
        element: (
            <AuthGuard>
                <NavigationLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '*',
                element: <HomePage />
            },
            {
                path: 'collection/:collectionId',
                element: <CollectionViewPage />
            }
        ]
    }
];

const router = createBrowserRouter(routes);

export const AppRouter = () => <RouterProvider router={router} />;