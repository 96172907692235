import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { TEXT } from './constants';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';

export const PublicNavigationLayout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ 
            width: '100%', 
            marginTop: {
                xs: '15px', // Mobile
                sm: '20px', // Tablet
                md: '25px'  // Desktop
            },
            height: {
                xs: '80px', // Mobile
                sm: '90px', // Tablet
                md: '100px' // Desktop
            }
        }}>
            <Box component="nav" sx={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: '1 0 0',
                marginTop: {
                    xs: '16px', // Mobile
                    sm: '24px', // Tablet
                    md: '32px'  // Desktop
                },
                marginLeft: {
                    xs: '16px', // Mobile
                    sm: '60px', // Tablet
                    md: '120px', // Small Desktop
                    lg: '210px'  // Large Desktop
                },
                marginRight: {
                    xs: '16px', // Mobile
                    sm: '60px', // Tablet
                    md: '120px', // Small Desktop
                    lg: '210px'  // Large Desktop
                },
                marginBottom: {
                    xs: '16px', // Mobile
                    sm: '24px', // Tablet
                    md: '32px'  // Desktop
                }
            }}>
                <Box sx={{ 
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center'
                }}>
                    <Box sx={{ 
                        fontSize: {
                            xs: '20px', // Mobile
                            sm: '22px', // Tablet
                            md: '24px'  // Desktop
                        },
                        fontWeight: '700',
                        color: '#0357D7',
                        letterSpacing: '-1px',
                        marginRight: '22px',
                        lineHeight: 'normal',
                        fontFamily: 'Wanted Sans'
                    }}>
                        {TEXT.GROUPSHACK}
                    </Box>
                </Box>
                <Box sx={{ 
                    display: 'flex',
                    gap: {
                        xs: '12px', // Mobile
                        sm: '16px'  // Tablet and Desktop
                    },
                    alignItems: 'center'
                }}>
                    <NavLink
                        to="/auth/login"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography
                            sx={{
                               color: '#000',
                               fontFamily: 'Wanted Sans',
                               fontSize: {
                                   xs: '14px', // Mobile
                                   sm: '16px'  // Tablet and Desktop
                               },
                               fontStyle: 'normal',
                               fontWeight: '500',
                               lineHeight: '24px' 
                            }}
                        >
                            {TEXT.LOGIN}
                        </Typography>
                    </NavLink>
                    <NavLink
                        to="/auth/signup"
                        style={{ textDecoration: 'none' }}
                    >
                        <Typography
                            sx={{
                               color: '#000',
                               fontFamily: 'Wanted Sans',
                               fontSize: {
                                   xs: '14px', // Mobile
                                   sm: '16px'  // Tablet and Desktop
                               },
                               fontStyle: 'normal',
                               fontWeight: '500',
                               lineHeight: '24px' 
                            }}
                        >
                            {TEXT.SIGNUP}
                        </Typography>
                    </NavLink>
                </Box>
            </Box>
            <Box component="main">
                <Outlet />
            </Box>
        </Box>
    );
};
