import { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { TEXT } from './constants';
import { Box, CircularProgress, Typography } from '@mui/material';
import { PublicNavigationLayout } from '../../navigation/components/public-navigation-layout/PublicNavigationLayout';
import { PublicMediaUpload } from './components/public-media-upload/PublicMediaUpload';
import { useShareableData } from './hooks/useShareableData';
import { useShareableLinkStore } from './store/shareableLinkStore';
import { ExpiredShareableLink } from './components/expired-shareable-link/ExpiredShareableLink';

export const ShareableLinkMediaUploadPage = () => {
    const { token } = useParams();
    const { fetchShareableDataAsync, isLoading: hookIsLoading, error: hookError, data } = useShareableData();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { shareableToken, setShareableToken } = useShareableLinkStore();
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 2;

    useEffect(() => {
        const initializeShareableLink = async () => {
            if (!token) {
                console.error('No token provided in URL');
                setError(new Error('No token provided'));
                setIsLoading(false);
                return;
            }
            
            try {
                console.log('Fetching shareable data for token:', token);
                const result = await fetchShareableDataAsync(token);
                
                // IMPORTANT: Verify we have a valid result before proceeding
                if (result && result.token) {
                    console.log('Setting token in store manually:', result);
                    
                    // First set in localStorage directly to ensure it's saved
                    localStorage.setItem('shareable_token', result.token);
                    
                    // Then update the store
                    setShareableToken(result);
                    
                    // Delay state update to ensure store is updated first
                    setTimeout(() => {
                        setIsLoading(false);
                        setError(null); // Clear any previous errors
                    }, 100);
                } else {
                    console.error('API returned successful response but without token:', result);
                    throw new Error('Invalid API response - missing token');
                }
            } catch (error) {
                console.error('Error initializing shareable link:', error);
                
                // Check if we have a token in localStorage that we can use
                const storedToken = localStorage.getItem('shareable_token');
                if (storedToken) {
                    console.log('Found token in localStorage, attempting to use it');
                    setShareableToken({ token: storedToken });
                    setIsLoading(false);
                    // Don't set error since we have a token
                } else {
                    setError(error);
                    setIsLoading(false);
                    
                    // Attempt retry if under max retry count
                    if (retryCount < MAX_RETRIES) {
                        console.log(`Retry attempt ${retryCount + 1} of ${MAX_RETRIES}`);
                        setTimeout(() => {
                            setRetryCount(prev => prev + 1);
                            setIsLoading(true);
                            setError(null);
                        }, 1000); // Wait a second before retrying
                    }
                }
            }
        };

        // Clear loading state after a timeout to prevent infinite loading
        const timeoutId = setTimeout(() => {
            if (isLoading) {
                console.warn('Loading timeout reached, forcing state update');
                setIsLoading(false);
                
                // Check if we have a token in localStorage before setting error
                const storedToken = localStorage.getItem('shareable_token');
                if (!storedToken) {
                    setError(new Error('Loading timeout reached'));
                }
            }
        }, 15000); // 15 seconds timeout

        if (isLoading) {
            initializeShareableLink();
        }
        
        return () => clearTimeout(timeoutId);
    }, [token, fetchShareableDataAsync, setShareableToken, isLoading, retryCount]);

    // Update local state when hook state or data changes
    useEffect(() => {
        if (hookError) {
            console.log('Hook error detected:', hookError);
            
            // Check if we have a token before setting error
            const storedToken = localStorage.getItem('shareable_token');
            const hasTokenInStore = !!(shareableToken && shareableToken.token);
            
            if (!storedToken && !hasTokenInStore) {
                setError(hookError);
            }
        }
        
        if (data && data.token) {
            console.log('Data received from hook:', data);
            setError(null); // Clear error if we received valid data
        }
    }, [hookError, data, shareableToken]);
    
    // Log information about the current state - this must be outside conditional rendering
    useEffect(() => {
        console.log('Component state:', {
            isLoading,
            hasError: !!error,
            hasToken: !!(shareableToken && shareableToken.token),
            tokenData: shareableToken
        });
    }, [isLoading, error, shareableToken]);

    // Verify we have the token in multiple places for debugging - MOVED BEFORE ANY CONDITIONAL RETURNS
    useEffect(() => {
        if (!isLoading) {
            console.log('ShareableLink not loading, token verification:', {
                storeToken: shareableToken?.token ? shareableToken.token.substring(0, 10) + '...' : 'Missing',
                localStorageToken: localStorage.getItem('shareable_token') ? 
                    localStorage.getItem('shareable_token').substring(0, 10) + '...' : 'Missing',
                hasError: !!error
            });
        }
    }, [isLoading, shareableToken, error]);

    // Handle loading state
    if (isLoading) {
        return (
            <Box>
                <PublicNavigationLayout />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '55vh',
                        gap: 2,
                        padding: {
                            xs: '0 16px', // Add padding on mobile
                            sm: 0         // No padding on larger screens
                        }
                    }}
                >
                    <CircularProgress />
                    <Typography
                        sx={{
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: 'Wanted Sans',
                            fontSize: {
                                xs: '18px', // Mobile
                                sm: '20px'  // Tablet and Desktop
                            },
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '150%'
                        }}
                    >
                        { TEXT.VERIFYING_LINK }
                    </Typography>
                </Box>
            </Box>
        );
    }

    // CRITICAL FIX: Check for token existence in both localStorage and store before showing error message
    const hasTokenInLocalStorage = !!localStorage.getItem('shareable_token');
    const hasTokenInStore = !!(shareableToken && shareableToken.token);
    
    // Only show expired link if there's an error AND no token available anywhere
    if (!isLoading && error && !hasTokenInLocalStorage && !hasTokenInStore) {
        return (
            <Box>
                <PublicNavigationLayout />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '60vh',
                        gap: 2,
                        padding: {
                            xs: '16px', // Smaller padding on mobile
                            sm: '24px', // Medium padding on tablet
                            md: '3'     // Original padding on desktop
                        }
                    }}
                >
                    <ExpiredShareableLink />
                </Box>
            </Box>
        );
    }

    // Handle successful state
    return (
        <Box>
            <PublicNavigationLayout />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: {
                            xs: '8px 0', // Add some vertical padding on mobile
                            sm: 0        // No padding on larger screens
                        }
                    }}
                >
                <PublicMediaUpload />
            </Box>
        </Box>
    );
};