import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { 
    Box, 
    Button, 
    ButtonGroup, 
    Typography, 
    styled,
    Pagination,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Snackbar,
    Alert
} from '@mui/material';
import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { useCollectionStore } from '../../store/collectionStore';
import { MediaFileItem } from '../../../media/components/media-file-item/MediaFileItem';
import { TEXT } from './constants';
import { useMedia } from '../../../media/hooks/useMedia';
import PillSwitch from '../../../common/pill-switch/PillSwitch';

// Apply global styles to hide scrollbars
const NoScrollBox = styled(Box)({
    '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
        display: 'none',
    },
    'scrollbarWidth': 'none', /* Firefox */
    'msOverflowStyle': 'none', /* IE and Edge - fixed kebab-case */
    overflowX: 'hidden',
    overflowY: 'hidden',
});

const NavButton = styled(Button)(({ theme, selected }) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Wanted Sans',
    fontSize: '20px',
    fontWeight: selected ? '700' : '400',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    textTransform: 'none',
    lineHeight: 'normal',
    minWidth: 'auto',
    padding: '8px 16px',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Wanted Sans',
    fontSize: '15px',
    fontWeight: '400',
    padding: '16px 20px',
    borderBottom: '1px solid #F5F5F5',
    color: '#505050',
}));

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Wanted Sans',
    fontSize: '15px',
    fontWeight: '500',
    padding: '16px 20px',
    backgroundColor: '#FAFBFD',
    borderBottom: '1px solid #F0F0F0',
    color: '#606060',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#F8F9FC',
    },
}));

// Create a styled TableContainer with no scrollbars
const NoScrollTableContainer = styled(TableContainer)({
    maxHeight: 'none',
    overflowY: 'visible', 
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
        width: 0,
        height: 0,
        display: 'none',
    },
    'scrollbarWidth': 'none', /* Firefox */
    'msOverflowStyle': 'none', /* IE and Edge - fixed kebab-case */
});

const CollectionFileView = ({ 
    selectedMedia, 
    onMediaSelect, 
    onMediaDoubleClick, 
    onThumbnailUpdate,
    showMediaViewer = false,
    externalThumbnailData = {},
    externalSelectedItems = {},
    onViewModeChange,
    onSelectedItemsChange,
}) => {
    const { collectionId } = useParams();
    const collections = useCollectionStore(state => state.collections);
    const { 
        getMediaThumbnail,
        updateThumbnailImage,
        isUpdateThumbnailImageLoading
    } = useMedia();
    
    // Local state for component
    const [localThumbnailData, setLocalThumbnailData] = useState({});
    const [selectedView, setSelectedView] = useState(TEXT.ALL);
    const [currentPage, setCurrentPage] = useState(1);
    const [thumbnailLoadingStatus, setThumbnailLoadingStatus] = useState({});
    const [isPageReady, setIsPageReady] = useState(false);
    const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'filter'
    const [sortField, setSortField] = useState('fileName');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedItems, setSelectedItems] = useState({});
    const [downloadSuccess, setDownloadSuccess] = useState(false);
    const [downloadCount, setDownloadCount] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    // Track if we've attempted to set a collection thumbnail
    const [thumbnailSetAttempted, setThumbnailSetAttempted] = useState(false);
    
    // Reference for tracking loading timeout
    const loadingTimeoutRef = useRef(null);
    const transitionTimeoutRef = useRef(null);
    const processedItemsRef = useRef('');

    // Fixed settings
    const ITEMS_PER_PAGE = 16;

    // Combine local and external thumbnail data
    const combinedThumbnailData = useMemo(() => {
        return { ...externalThumbnailData, ...localThumbnailData };
    }, [externalThumbnailData, localThumbnailData]);

    // Get current collection data
    const currentCollection = useMemo(() => 
        collections.find(collection => collection.collection_id === collectionId),
        [collections, collectionId]
    );

    // Check if the collection has a thumbnail
    const hasThumbnail = useMemo(() => {
        return currentCollection && 
               currentCollection.thumbnail && 
               currentCollection.thumbnail.thumbnail_blob_path;
    }, [currentCollection]);

    // Filter media items based on selected view (All, Photos, Videos)
    const filterMediaItems = useCallback((items) => {
        if (!items) return [];
        if (selectedView === TEXT.ALL) return items;
        if (selectedView === TEXT.PHOTOS) {
            return items.filter(item => item.media_type.startsWith('image/'));
        }
        if (selectedView === TEXT.VIDEOS) {
            return items.filter(item => item.media_type.startsWith('video/'));
        }
        return items;
    }, [selectedView]);

    // Sort media items based on selected field and direction
    const sortMediaItems = useCallback((items) => {
        if (!items || !items.length) return [];
        
        return [...items].sort((a, b) => {
            let valueA, valueB;
            
            // Determine values to compare based on sort field
            switch (sortField) {
                case 'fileName':
                    valueA = a.media_name || '';
                    valueB = b.media_name || '';
                    break;
                case 'fileSize':
                    valueA = a.size_in_bytes || 0;
                    valueB = b.size_in_bytes || 0;
                    break;
                case 'uploadedBy':
                    valueA = a.uploaded_by || '';
                    valueB = b.uploaded_by || '';
                    break;
                case 'dimension':
                    valueA = (a.width || 0) * (a.height || 0);
                    valueB = (b.width || 0) * (b.height || 0);
                    break;
                default:
                    valueA = a.media_name || '';
                    valueB = b.media_name || '';
            }
            
            // Compare based on direction
            if (sortDirection === 'asc') {
                return typeof valueA === 'string' 
                    ? valueA.localeCompare(valueB) 
                    : valueA - valueB;
            } else {
                return typeof valueA === 'string' 
                    ? valueB.localeCompare(valueA) 
                    : valueB - valueA;
            }
        });
    }, [sortField, sortDirection]);

    // Apply filtering and sorting to media items
    const filteredMedia = useMemo(() => 
        sortMediaItems(filterMediaItems(currentCollection?.media)),
        [filterMediaItems, sortMediaItems, currentCollection?.media]
    );

    // Calculate total pages
    const totalPages = useMemo(() => 
        Math.ceil(filteredMedia.length / ITEMS_PER_PAGE),
        [filteredMedia.length]
    );

    // Get items for current page
    const currentPageItems = useMemo(() => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredMedia.length);
        return filteredMedia.slice(startIndex, endIndex);
    }, [currentPage, filteredMedia]);

    // Count selected items
    const selectedItemCount = useMemo(() => {
        return Object.values(selectedItems).filter(Boolean).length;
    }, [selectedItems]);

    // Determine if we should show the empty state (no items to display)
    const showEmptyState = useMemo(() => 
        filteredMedia.length === 0,
        [filteredMedia]
    );

    // Check if all current page items are selected
    const isAllSelected = useMemo(() => {
        if (!currentPageItems.length) return false;
        return currentPageItems.every(item => selectedItems[item.media_id]);
    }, [currentPageItems, selectedItems]);

    // Set collection thumbnail if needed
    useEffect(() => {
        const setCollectionThumbnail = async () => {
            // Only proceed if collection has no thumbnail but has media items
            if (!hasThumbnail && 
                currentCollection?.media?.length > 0 && 
                !thumbnailSetAttempted && 
                updateThumbnailImage) {
                
                // Mark that we've attempted to set a thumbnail
                setThumbnailSetAttempted(true);
                
                // Find the first image media item
                const imageMedia = currentCollection.media.find(item => 
                    item.media_type && item.media_type.startsWith('image/')
                );
                
                if (imageMedia) {
                    try {
                        console.log('Setting collection thumbnail from first image media:', imageMedia.media_id);
                        
                        // Get the thumbnail for the media item
                        const thumbnailResult = await getMediaThumbnail(imageMedia.media_id);
                        
                        if (thumbnailResult.success && thumbnailResult.thumbnailUrl) {
                            // Convert base64 string to blob
                            const fetchResponse = await fetch(thumbnailResult.thumbnailUrl);
                            const thumbnailBlob = await fetchResponse.blob();
                            
                            // Create a File object from the blob
                            const thumbnailFile = new File(
                                [thumbnailBlob], 
                                `${imageMedia.media_name || 'thumbnail'}.jpg`, 
                                { type: imageMedia.media_type }
                            );
                            
                            // Update the collection thumbnail
                            await updateThumbnailImage({
                                entityId: collectionId,
                                entityType: 'collection',
                                entityThumbnailFile: thumbnailFile
                            });
                            
                            console.log('Collection thumbnail set successfully');
                        } else {
                            console.warn('Failed to get thumbnail for media item:', imageMedia.media_id);
                        }
                    } catch (error) {
                        console.error('Failed to set collection thumbnail:', error);
                    }
                }
            }
        };
        
        setCollectionThumbnail();
    }, [
        hasThumbnail, 
        currentCollection, 
        thumbnailSetAttempted, 
        updateThumbnailImage, 
        getMediaThumbnail,
        collectionId
    ]);

    // Sync external selected items with internal state
    useEffect(() => {
        // Only update if the external selection state is different from the internal state
        // and if there are actual external selections provided (not empty on initial render)
        if (
            Object.keys(externalSelectedItems).length > 0 || 
            (Object.keys(externalSelectedItems).length === 0 && Object.keys(selectedItems).length > 0)
        ) {
            setSelectedItems(externalSelectedItems);
        }
    }, [externalSelectedItems]);

    // Send initial thumbnails to parent component on mount
    useEffect(() => {
        if (Object.keys(localThumbnailData).length > 0 && onThumbnailUpdate) {
            onThumbnailUpdate(localThumbnailData);
        }
    }, []);

    // Thumbnail load handler
    const handleThumbnailLoad = useCallback((mediaId, success, thumbnailUrl) => {
        if (!mediaId) return;
    
        // Use setTimeout to break circular update cycles
        setTimeout(() => {
            // Mark thumbnail as processed (success or failure)
            setThumbnailLoadingStatus(prev => {
                if (prev[mediaId] === true) return prev;
                return { ...prev, [mediaId]: true };
            });
            
            // Only update if we got a valid URL and don't already have it
            if (success && thumbnailUrl && !combinedThumbnailData[mediaId]) {
                // Update local state
                setLocalThumbnailData(prev => {
                    const newData = { ...prev, [mediaId]: thumbnailUrl };
                    
                    // Notify parent about new thumbnail using setTimeout to break cycles
                    if (onThumbnailUpdate && typeof onThumbnailUpdate === 'function') {
                        setTimeout(() => {
                            onThumbnailUpdate({ [mediaId]: thumbnailUrl });
                        }, 0);
                    }
                    
                    return newData;
                });
            }
        }, 0);
    }, [combinedThumbnailData, onThumbnailUpdate]);

    // Select first item when filtered media changes or none is selected
    useEffect(() => {
        if (filteredMedia?.length > 0 && onMediaSelect) {
          const currentMediaInFiltered = selectedMedia && 
            filteredMedia.some(item => item.media_id === selectedMedia.media_id);
          
          if (!selectedMedia || !currentMediaInFiltered) {
            requestAnimationFrame(() => {
              onMediaSelect(filteredMedia[0]);
            });
          }
        }
    }, [filteredMedia, selectedMedia, onMediaSelect]);

    // Reset selection when filters change
    useEffect(() => {
        if (selectedMedia && filteredMedia?.length > 0 && onMediaSelect) {
          // Check if current selection exists in filtered results
          const isCurrentSelectionValid = filteredMedia.some(
            item => item.media_id === selectedMedia.media_id
          );
          
          // If current selection is not valid, select the first item in filtered results
          if (!isCurrentSelectionValid) {
            requestAnimationFrame(() => {
              onMediaSelect(filteredMedia[0]);
            });
          }
        }
    }, [selectedView, sortField, sortDirection, filteredMedia, selectedMedia, onMediaSelect]);

    // Keyboard navigation effect - only active when MediaViewer is closed
    useEffect(() => {
        const handleKeyDown = (event) => {
            // Only handle keyboard navigation when MediaViewer is NOT open
            if (showMediaViewer) return;
            
            // Handle keyboard navigation for selected media item
            if (selectedMedia && filteredMedia?.length > 0) {
                const currentIndex = filteredMedia.findIndex(
                    item => item.media_id === selectedMedia.media_id
                );
                
                if (currentIndex !== -1) {
                    let newIndex;
                    const ITEMS_PER_ROW = 4; // Number of items in each row of the grid view
                    
                    // Navigate with arrow keys
                    switch (event.key) {
                        case 'ArrowLeft':
                            // Move to previous item, loop to end if at beginning
                            newIndex = currentIndex === 0 ? filteredMedia.length - 1 : currentIndex - 1;
                            onMediaSelect(filteredMedia[newIndex]);
                            event.preventDefault();
                            break;
                            
                        case 'ArrowRight':
                            // Move to next item, loop to beginning if at end
                            newIndex = currentIndex === filteredMedia.length - 1 ? 0 : currentIndex + 1;
                            onMediaSelect(filteredMedia[newIndex]);
                            event.preventDefault();
                            break;
                            
                        case 'ArrowUp':
                            // Move up a row (subtract ITEMS_PER_ROW)
                            newIndex = currentIndex - ITEMS_PER_ROW;
                            // If we would go above the first row, wrap to the last row at the same column
                            if (newIndex < 0) {
                                const rowsCount = Math.ceil(filteredMedia.length / ITEMS_PER_ROW);
                                const column = currentIndex % ITEMS_PER_ROW;
                                // Calculate the same column position in the last row
                                newIndex = ((rowsCount - 1) * ITEMS_PER_ROW) + column;
                                // If this position doesn't exist (last row might not be full), use the last item
                                if (newIndex >= filteredMedia.length) {
                                    newIndex = filteredMedia.length - 1;
                                }
                            }
                            onMediaSelect(filteredMedia[newIndex]);
                            event.preventDefault();
                            break;
                            
                        case 'ArrowDown':
                            // Move down a row (add ITEMS_PER_ROW)
                            newIndex = currentIndex + ITEMS_PER_ROW;
                            // If we would go below the last row, wrap to the first row at the same column
                            if (newIndex >= filteredMedia.length) {
                                const column = currentIndex % ITEMS_PER_ROW;
                                newIndex = column;
                                // If this position doesn't exist, use the last item in the first row
                                if (newIndex >= filteredMedia.length) {
                                    newIndex = filteredMedia.length - 1;
                                }
                            }
                            onMediaSelect(filteredMedia[newIndex]);
                            event.preventDefault();
                            break;
                    }
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedMedia, filteredMedia, onMediaSelect, showMediaViewer]);

    // Handle page readiness and loading indicators
    useEffect(() => {
        // Clear any existing timeout
        if (loadingTimeoutRef.current) {
            clearTimeout(loadingTimeoutRef.current);
            loadingTimeoutRef.current = null;
        }
        
        // Set page to not ready state
        setIsPageReady(false);
        
        // Generate a key to identify current items
        const itemsKey = currentPageItems?.map(item => item.media_id).join(',') || '';
        
        // Skip if this exact set of items was already processed
        if (processedItemsRef.current === itemsKey && itemsKey !== '') {
            setIsPageReady(true);
            return;
        }
        
        // Store the current items key
        processedItemsRef.current = itemsKey;
        
        // Set a short timeout to allow thumbnails to load
        loadingTimeoutRef.current = setTimeout(() => {
            setIsPageReady(true);
        }, 300);
        
        // Cleanup function
        return () => {
            if (loadingTimeoutRef.current) {
                clearTimeout(loadingTimeoutRef.current);
                loadingTimeoutRef.current = null;
            }
        };
    }, [currentPageItems]);

    // Clear transition timeouts on unmount
    useEffect(() => {
        return () => {
            if (transitionTimeoutRef.current) {
                clearTimeout(transitionTimeoutRef.current);
                transitionTimeoutRef.current = null;
            }
        };
    }, []);

    // Handler for view type change (All/Photos/Videos)
    const handleViewChange = useCallback((view) => {
        if (view === selectedView) return;
        
        // Trigger transition state to hide scrollbars
        setIsTransitioning(true);
        
        // Clear any existing transition timeout
        if (transitionTimeoutRef.current) {
            clearTimeout(transitionTimeoutRef.current);
        }
        
        // Set a short timeout before changing view to ensure container is hidden
        transitionTimeoutRef.current = setTimeout(() => {
            setSelectedView(view);
            
            // End transition after a short delay to ensure content has updated
            setTimeout(() => {
                setIsTransitioning(false);
            }, 300);
        }, 10);
    }, [selectedView]);

    // Handler for pagination
    const handlePageChange = useCallback((event, newPage) => {
        // Prevent unnecessary updates
        if (newPage === currentPage) return;
        
        // Ensure the new page is within valid range
        const validatedPage = Math.min(Math.max(1, newPage), totalPages);
        
        // Trigger transition state to hide scrollbars
        setIsTransitioning(true);
        
        // Reset page state explicitly
        setIsPageReady(false);
        
        // Set the page with a slight delay to ensure state updates
        requestAnimationFrame(() => {
            setCurrentPage(validatedPage);
            
            // End transition after content has updated
            setTimeout(() => {
                setIsTransitioning(false);
            }, 300);
        });
    }, [currentPage, totalPages]);

    // Handler for view mode change (grid/filter)
    const handleViewModeChange = useCallback((isFilterView) => {
        const newViewMode = isFilterView ? 'filter' : 'grid';
        
        // Trigger transition state to hide scrollbars
        setIsTransitioning(true);
        
        setViewMode(newViewMode);
        
        // Notify parent of view mode change
        if (onViewModeChange) {
            onViewModeChange(newViewMode);
        }
        
        // End transition after content has updated
        setTimeout(() => {
            setIsTransitioning(false);
        }, 300);
    }, [onViewModeChange]);

    // Handler for table header sort
    const handleHeaderClick = useCallback((field) => {
        // Trigger transition state to hide scrollbars
        setIsTransitioning(true);
        
        if (sortField === field) {
            // Toggle sort direction if clicking the same field
            setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
        } else {
            // Set new sort field and default to ascending
            setSortField(field);
            setSortDirection('asc');
        }
        
        // End transition after content has updated
        setTimeout(() => {
            setIsTransitioning(false);
        }, 300);
    }, [sortField]);

    // Handler for selecting an item (checkbox)
    const handleItemSelect = useCallback((mediaId) => {
        setSelectedItems(prev => {
            const newSelectedItems = {
                ...prev,
                [mediaId]: !prev[mediaId]
            };
            
            // Notify parent of selection change
            if (onSelectedItemsChange) {
                onSelectedItemsChange(newSelectedItems);
            }
            
            return newSelectedItems;
        });
    }, [onSelectedItemsChange]);

    // Handler for select all checkbox
    const handleSelectAll = useCallback((event) => {
        let newSelectedItems = {};
        
        if (event.target.checked) {
            // Select all items on current page
            currentPageItems.forEach(item => {
                newSelectedItems[item.media_id] = true;
            });
        }
        
        setSelectedItems(newSelectedItems);
        
        // Notify parent of selection change
        if (onSelectedItemsChange) {
            onSelectedItemsChange(newSelectedItems);
        }
    }, [currentPageItems, onSelectedItemsChange]);

    // Format dimensions for display
    const formatDimensions = useCallback((mediaItem) => {
        if (!mediaItem.width || !mediaItem.height) return 'Unknown';
        return `${mediaItem.width} x ${mediaItem.height}`;
    }, []);

    // Handle closing the download success notification
    const handleCloseSnackbar = useCallback(() => {
        setDownloadSuccess(false);
    }, []);
    
    return (
        <NoScrollBox
            sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingTop: '32px',
                paddingBottom: '10px',
                marginBottom: showEmptyState ? '150px' : 0,
                flexDirection: 'column',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
                background: '#FFF',
                position: 'relative',
                overflowX: 'hidden',
                overflowY: 'hidden',
            }}
        >
            {/* Success Notification */}
            <Snackbar
                open={downloadSuccess}
                autoHideDuration={10000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity="success" 
                    sx={{ 
                        width: '100%',
                        fontFamily: 'Wanted Sans'
                    }}
                >
                    {`${downloadCount} ${downloadCount === 1 ? 'file' : 'files'} downloaded successfully`}
                </Alert>
            </Snackbar>

            {/* Thumbnail Setting Indicator */}
            {isUpdateThumbnailImageLoading && (
                <Snackbar
                    open={true}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert 
                        severity="info" 
                        sx={{ 
                            width: '100%',
                            fontFamily: 'Wanted Sans'
                        }}
                    >
                        Setting collection thumbnail...
                    </Alert>
                </Snackbar>
            )}

            {/* Top row with navigation buttons and view controls */}
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '32px'
                }}
            >
                {/* Left side: ALL/Photos/Videos buttons */}
                <ButtonGroup
                    variant='text'
                    sx={{
                        '& .MuiButtonGroup-grouped:not(:last-of-type)': {
                            border: 'none'
                        },
                        position: 'relative',
                        zIndex: 1
                    }}
                >
                    {[TEXT.ALL, TEXT.PHOTOS, TEXT.VIDEOS].map((item) => (
                        <NavButton
                            key={item}
                            onClick={() => handleViewChange(item)}
                            selected={selectedView === item}
                            disableRipple
                        >
                            {item}
                        </NavButton>
                    ))}
                </ButtonGroup>

                {/* Right side: View controls */}
                <Box sx={{ display: 'flex', gap: '19px', alignItems: 'center' }}>
                    <PillSwitch 
                        onChange={handleViewModeChange}
                        size='small'
                        leftIcon={GridViewIcon}
                        rightIcon={TableRowsIcon}
                        defaultChecked={viewMode === 'filter'}
                    />
                </Box>
            </Box>

            {/* Empty state */}
            {showEmptyState && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        right: 0,
                        transform: 'translateY(-50%)',
                        zIndex: 5,
                        pointerEvents: 'none'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: 'Wanted Sans',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '150%'
                        }}
                    >
                        {TEXT.NO_ITEMS_TO_DISPLAY}
                    </Typography>
                </Box>
            )}

            {/* Loading indicator */}
            {!isPageReady && !showEmptyState && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 5
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            {/* Main content area - Only rendered if not in empty state */}
            {!showEmptyState && (
                <NoScrollBox
                    sx={{
                        flex: 1,
                        position: 'relative',
                        minHeight: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        visibility: isTransitioning ? 'hidden' : 'visible',
                        opacity: isPageReady ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out',
                    }}
                >
                    {/* Conditional rendering based on view mode */}
                    {viewMode === 'grid' ? (
                        // Grid View - UPDATED FOR RESPONSIVENESS
                        <NoScrollBox
                            sx={{
                                display: 'grid',
                                // Explicit breakpoints to ensure 4 items per row on desktop
                                gridTemplateColumns: {
                                    xs: '1fr',                  // 1 column on mobile
                                    sm: 'repeat(2, 1fr)',       // 2 columns on small screens
                                    md: 'repeat(3, 1fr)',       // 3 columns on medium screens
                                    lg: 'repeat(4, 1fr)'        // 4 columns on large screens
                                },
                                gap: '20px',
                                flex: 1,
                                width: '100%',
                                paddingRight: '4px',
                                boxSizing: 'border-box',
                                overflowX: 'hidden'
                            }}
                        >
                            {currentPageItems.map((mediaItem) => {
                                // Generate a unique key for each item
                                const uniqueKey = `file-${mediaItem.media_id}-${mediaItem.size_in_bytes || Date.now()}`;
                                
                                return (
                                    <MediaFileItem 
                                        key={uniqueKey}
                                        mediaItem={mediaItem}
                                        isSelected={selectedMedia?.media_id === mediaItem.media_id}
                                        onClick={onMediaSelect}
                                        onDoubleClick={onMediaDoubleClick}
                                        onThumbnailLoad={handleThumbnailLoad}
                                        thumbnailUrl={combinedThumbnailData[mediaItem.media_id]}
                                    />
                                );
                            })}
                        </NoScrollBox>
                    ) : (
                        // Filter View (Table/List View)
                        <NoScrollTableContainer>
                            <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                                <TableHead>
                                    <TableRow>
                                        <HeaderTableCell padding="checkbox" sx={{ width: '50px' }}>
                                            <Checkbox
                                                indeterminate={currentPageItems.some(item => selectedItems[item.media_id]) && !isAllSelected}
                                                checked={isAllSelected}
                                                onChange={handleSelectAll}
                                                size="small"
                                                sx={{
                                                    color: '#BDBDBD',
                                                    '&.Mui-checked': {
                                                        color: '#4B8FE2',
                                                    },
                                                    '&.MuiCheckbox-indeterminate': {
                                                        color: '#4B8FE2',
                                                    }
                                                }}
                                            />
                                        </HeaderTableCell>
                                        <HeaderTableCell sx={{ width: '120px' }}>Image Preview</HeaderTableCell>
                                        <HeaderTableCell 
                                            onClick={() => handleHeaderClick('fileName')}
                                            sx={{ width: '30%' }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                File Name
                                                {sortField === 'fileName' && (
                                                    sortDirection === 'asc' ? 
                                                    <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, opacity: 0.5 }} /> : 
                                                    <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, opacity: 0.5 }} />
                                                )}
                                            </Box>
                                        </HeaderTableCell>
                                        <HeaderTableCell 
                                            onClick={() => handleHeaderClick('fileSize')}
                                            sx={{ width: '100px' }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                File Size
                                                {sortField === 'fileSize' && (
                                                    sortDirection === 'asc' ? 
                                                    <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, opacity: 0.7 }} /> : 
                                                    <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, opacity: 0.7 }} />
                                                )}
                                            </Box>
                                        </HeaderTableCell>
                                        <HeaderTableCell 
                                            onClick={() => handleHeaderClick('uploadedBy')}
                                            sx={{ width: '20%' }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                Uploaded by
                                                {sortField === 'uploadedBy' && (
                                                    sortDirection === 'asc' ? 
                                                    <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, opacity: 0.7 }} /> : 
                                                    <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, opacity: 0.7 }} />
                                                )}
                                            </Box>
                                        </HeaderTableCell>
                                        <HeaderTableCell sx={{ width: '15%' }}>Dimension</HeaderTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentPageItems.map((mediaItem) => (
                                        <TableRow 
                                            key={mediaItem.media_id}
                                            hover
                                            selected={selectedItems[mediaItem.media_id]}
                                            onClick={() => onMediaSelect(mediaItem)}
                                            onDoubleClick={() => onMediaDoubleClick(mediaItem)}
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&.MuiTableRow-hover:hover': {
                                                    backgroundColor: '#FAFCFF',
                                                },
                                                '&.Mui-selected': {
                                                    backgroundColor: '#F8FBFF',
                                                },
                                                '&.Mui-selected:hover': {
                                                    backgroundColor: '#F5F9FF',
                                                },
                                                transition: 'background-color 0.3s ease'
                                            }}
                                        >
                                            {/* Checkbox cell */}
                                            <StyledTableCell padding="checkbox" sx={{ width: '50px' }}>
                                                <Checkbox
                                                    checked={!!selectedItems[mediaItem.media_id]}
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        handleItemSelect(mediaItem.media_id);
                                                    }}
                                                    size="small"
                                                    onClick={(e) => e.stopPropagation()}
                                                    sx={{
                                                        color: '#BDBDBD',
                                                        '&.Mui-checked': {
                                                            color: '#4B8FE2',
                                                        }
                                                    }}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ width: '120px' }}>
                                                <Box 
                                                    sx={{ 
                                                        width: '90px', 
                                                        height: '65px',
                                                        borderRadius: '8px',
                                                        overflow: 'hidden',
                                                        border: '1px solid #F8F8F8',
                                                        backgroundImage: combinedThumbnailData[mediaItem.media_id] ? `url(${combinedThumbnailData[mediaItem.media_id]})` : 'none',
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        backgroundColor: !combinedThumbnailData[mediaItem.media_id] ? '#fcfcfc' : 'transparent',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    {!combinedThumbnailData[mediaItem.media_id] && thumbnailLoadingStatus[mediaItem.media_id] && 
                                                    (mediaItem.media_type?.startsWith('image/') ? 
                                                        '🖼️' : mediaItem.media_type?.startsWith('video/') ? 
                                                        '🎬' : '📄')}
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ width: '20%' }}>{mediaItem.media_name || 'Untitled'}</StyledTableCell>
                                            <StyledTableCell sx={{ width: '100px' }}>{`${(mediaItem.size_in_bytes / (1024 * 1024)).toFixed(1)} mb`}</StyledTableCell>
                                            <StyledTableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                    <Box 
                                                        sx={{ 
                                                            width: '28px', 
                                                            height: '28px', 
                                                            borderRadius: '50%', 
                                                            backgroundColor: mediaItem.uploaded_by ? '#EFF5FC' : '#F9F9F9',
                                                            color: mediaItem.uploaded_by ? '#4B8FE2' : '#A0A0A0',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            fontFamily: 'Wanted Sans',
                                                            fontSize: '13px',
                                                            fontWeight: '500'
                                                        }}
                                                    >
                                                        {mediaItem.uploaded_by ? mediaItem.uploaded_by.charAt(0).toUpperCase() : 'A'}
                                                    </Box>
                                                    {mediaItem.uploaded_by || 'Anonymous'}
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ width: '15%' }}>{formatDimensions(mediaItem)}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </NoScrollTableContainer>
                    )}
                </NoScrollBox>
            )}

            {/* Pagination - Only shown if there are pages and not in empty state */}
            {totalPages > 1 && !showEmptyState && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '20px'
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                        sx={{
                            '& .MuiPagination-ul': {
                                justifyContent: 'center',
                                gap: '4px',
                                margin: '0 auto',
                                flexWrap: 'nowrap',
                                overflowX: 'auto',
                                maxWidth: '100%'
                            },
                            '& .MuiPaginationItem-root': {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px',
                                fontWeight: '400',
                                minWidth: '40px',
                                height: '40px'
                            },
                            '& .Mui-selected': {
                                fontWeight: '600',
                            },
                            '& .MuiPaginationItem-ellipsis': {
                                margin: '0 4px'
                            },
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            padding: '0 16px'
                        }}
                    />
                </Box>
            )}
        </NoScrollBox>
    );
};

export default CollectionFileView;