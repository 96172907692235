import { Box, Typography } from '@mui/material';
import { TEXT } from './constants';
import { useNavigate } from 'react-router-dom';
import { dateConvert } from '../../../../utils/DateConverter';
import { getMediaTypeCounts } from '../../../../utils/GetMediaTypeCounts';
import { useEffect, useState } from 'react';
import { collectionApi } from '../../services/collectionApi';

export const CollectionListItem = ({ collection }) => {
    const navigate = useNavigate();
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const collectionId = collection.collection_id;
    const collectionName = collection.collection_name;
    const collectionDate = dateConvert(collection.collection_created_on_date);
    const mediaTypeCounts = getMediaTypeCounts(collection.media);
    const numImages = mediaTypeCounts?.image || 0;
    const numVideos = mediaTypeCounts?.video || 0;
    const numImagesText = `${numImages == 1 ? TEXT.IMAGE : TEXT.IMAGES}`;
    const numVideosText = `${numVideos == 1 ? TEXT.VIDEO : TEXT.VIDEOS}`;
    const numImagesAndVideosInCollection = `
        ${numImages} ${numImagesText}, ${numVideos} ${numVideosText}
    `;

    // Fetch the collection thumbnail when the component mounts
    useEffect(() => {
        const fetchThumbnail = async () => {
            try {
                // Check different ways the thumbnail might be accessed in the collection object
                if (collection.thumbnail && typeof collection.thumbnail === 'string') {
                    // Direct string URL
                    setThumbnailUrl(collection.thumbnail);
                } else if (collection.thumbnail?.thumbnail_blob_path) {
                    // Nested object with blob path
                    setThumbnailUrl(collection.thumbnail.thumbnail_blob_path);
                } else {
                    // Fetch the thumbnail using the API if not already in the collection object
                    const thumbnailData = await collectionApi.getCollectionProfileImage(collectionId);
                    if (thumbnailData) {
                        setThumbnailUrl(thumbnailData);
                    }
                }
            } catch (error) {
                console.error('Error fetching collection thumbnail:', error);
            }
        };

        fetchThumbnail();
    }, [collection, collectionId]);

    const handleClick = () => {
        navigate(`/collection/${collectionId}`);
    };

    return (
        <Box
            onClick={handleClick}
            sx={{
                width: 'calc(33.333% - 21.333px)',
                height: 'auto',
                marginBottom: '32px',
                cursor: 'pointer',
                '&:hover': {
                    opacity: 0.9
                }
            }}
        >
        <Box
            sx={{
                width: '100%',
                height: '200px',
                borderRadius: '10px',
                backgroundColor: '#E6E8E9',
                overflow: 'hidden',
                position: 'relative'
            }}
        >
            {thumbnailUrl && (
                <img
                    src={thumbnailUrl}
                    alt={collectionName || 'Collection thumbnail'}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                />
            )}
        </Box>
            <Typography
                sx={{
                    marginTop: '8px',
                    alignSelf: 'stretch',
                    color: '#000',
                    fontFamily: 'Wanted Sans',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal'
                }}
            >
                { collectionName }
            </Typography>
            <Typography
                sx={{
                    marginTop: '6px',
                    alignSelf: 'stretch',
                    color: '#565656',
                    fontFamily: 'Wanted Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '16px',
                    letterSpacing: '-1px'
                }}
            >
                { collectionDate }
            </Typography>
            <Typography
                sx={{
                    marginTop: '5px',
                    alignSelf: 'stretch',
                    color: '#565656',
                    fontFamily: 'Wanted Sans',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '16px',
                    letterSpacing: '-1px'
                }}
            >
                { numImagesAndVideosInCollection }
            </Typography>
        </Box>
    );
};