import React, { useState, useEffect } from 'react';
import { TYPES, EVENT_TYPES } from '../../../../types/types';
import { Box, Typography } from '@mui/material';
import { FeedItem } from '../../../common/feed-item/FeedItem';
import { useUserFeedActivities } from '../../../user/hooks/useUserFeedActivities';
import { useUserStore } from '../../store/userStore';
import { useGroupStore } from '../../../group/store/groupStore';
import { useCollectionStore } from '../../../collection/store/collectionStore';
import { useEventStore } from '../../../event/store/eventStore';
import { usePersonStore } from '../../../person/store/personStore';
import { timeSince } from '../../../../utils/DateConverter';

// Group Icons
import Diversity3Icon from '@mui/icons-material/Diversity3';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import SleddingIcon from '@mui/icons-material/Sledding';
// Collection Icons
import FiberNewIcon from '@mui/icons-material/FiberNew';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';

// Event Icons
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';

const LOGO_HEIGHT = 32;
const LOGO_WIDTH = 32;

export const UserTimelineFeed = () => {
    const { fetchUserFeedActivities, isLoading, error } = useUserFeedActivities();
    const { userFeedActivites } = useUserStore();
    const groups = useGroupStore(state => state.groups);
    const collections = useCollectionStore(state => state.collections);
    const events = useEventStore(state => state.events);
    const people = usePersonStore(state => state.people);

    const [sortedFeedItems, setSortedFeedItems] = useState([]);

    const assembleFeedItemData = () => {
        if (!Array.isArray(userFeedActivites.activities)) {
            return [];
        }
    
        if (userFeedActivites.length === 0) {
            return [];
        }
    
        const sortedActivities = [...userFeedActivites.activities].sort((a, b) => b.ts - a.ts);

        var timelineFeedData = [];

        sortedActivities.forEach((activity) => {
            const currentGroup = groups.find(group => group.group_id === activity.group_id);
            const groupName = currentGroup ? currentGroup.group_name : 'Unknown Group';

            const currentPerson = people.find(p => p.data.user_id === activity.user_id);
            const personName = currentPerson
            ? `${currentPerson.data.first_name} ${currentPerson.data.last_name}`
            : 'Unknown User';

            var timelineFeedObject = {
                icon: null,
                description: null,
                lastUpdated: null
            };

            if (activity.entity_type == TYPES.GROUP) {
                if (activity.activity_type == EVENT_TYPES.GROUP_CREATED) {
                    timelineFeedObject.icon = <Diversity3Icon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT}} />;
                    timelineFeedObject.description = `${personName} created the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.GROUP_DELETED) {
                    timelineFeedObject.icon = <NoAccountsIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} deleted the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.GROUP_UPDATED) {
                    timelineFeedObject.icon = <Diversity2Icon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT}} />;
                    timelineFeedObject.description = `${personName} updated the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.USER_ADDED_TO_GROUP) {
                    timelineFeedObject.icon = <SkateboardingIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT}} />;
                    timelineFeedObject.description = `${personName} joined the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.USER_REMOVED_FROM_GROUP) {
                    timelineFeedObject.icon = <SleddingIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT, transform: 'scaleX(-1)' }} />;
                    timelineFeedObject.description = `${personName} left the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
            }
            else if (activity.entity_type == TYPES.COLLECTION) {
                const currentCollection = collections.find(collection => collection.collection_id == activity.entity_id);
                const collectionName = currentCollection ? currentCollection.collection_name : 'Unknown Collection';

                if (activity.activity_type == EVENT_TYPES.COLLECTION_CREATED) {
                    timelineFeedObject.logo = <FiberNewIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} added a collection ${collectionName} to the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.COLLECTION_DELETED) {
                    timelineFeedObject.logo = <DeleteIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} removed the collection ${collectionName} from the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.COLLECTION_UPDATED) {
                    timelineFeedObject.logo = <UploadIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} updated the collection ${collectionName} in the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
            }
            else if (activity.entity_type == TYPES.EVENT) {
                const currentEvent = events.find(event => event.event_id == activity.entity_id);
                const eventName = currentEvent ? currentEvent.event_name : 'Unknown Event';

                if (activity.activity_type == EVENT_TYPES.EVENT_CREATED) {
                    timelineFeedObject.logo = <LocalActivityIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} created an event ${eventName} in the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.EVENT_DELETED) {
                    timelineFeedObject.logo = <DeleteIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} deleted the event ${eventName} in the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.EVENT_UPDATED) {
                    timelineFeedObject.logo = <EditCalendarIcon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} updated the event ${eventName} in the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.USER_ADDED_TO_EVENT) {
                    timelineFeedObject.logo = <PersonAddAlt1Icon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} is attending the event ${eventName} in the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
                else if (activity.activity_type == EVENT_TYPES.USER_REMOVED_FROM_EVENT) {
                    timelineFeedObject.logo = <PersonRemoveAlt1Icon sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} />;
                    timelineFeedObject.description = `${personName} is not attending the event ${eventName} in the ${groupName} group`;
                    timelineFeedObject.lastUpdate = timeSince(activity.ts);
                }
            }
            else if (activity.entity_type == TYPES.USER) {
                console.log(`User activity in ${groupName}`);
            }

            timelineFeedData.push(timelineFeedObject);
        });

        return timelineFeedData;
    };

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        if (userId) {
            fetchUserFeedActivities({ userId });
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(userFeedActivites.activities)) {
            const sorted = assembleFeedItemData();
            setSortedFeedItems(sorted);
        }
    }, [userFeedActivites, groups]); 

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}
        >
            {sortedFeedItems.map((feedItem, index) => (
                <FeedItem
                    key={index}
                    logo={feedItem.icon || feedItem.logo}
                    description={feedItem.description}
                    lastUpdated={feedItem.lastUpdate}
                />
            ))}
        </Box>
    );
};