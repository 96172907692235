import { useMutation } from "@tanstack/react-query";
import { eventApi } from "../services/eventApi";
import { useMedia } from "../../media/hooks/useMedia";
import { isEmpty } from "../../../utils/Utils";

export const useEvent = () => {
    const { 
        updateThumbnailImage, 
        isUpdateThumbnailImageLoading, 
        updateThumbnailImageError 
    } = useMedia();

    const addUserToEventMutation = useMutation({
        mutationFn: async({ eventId, userId }) => {
            return await eventApi.joinEvent(eventId, userId);
        },
        onSuccess: () => {
            console.log('Successfully joined event');
        },
        onError: (error) => {
            console.error('Error joining event:', error);
        }
    });

    const createEventMutation = useMutation({
        mutationFn: async(eventFormData) => {
            const requestPayload = {
                group_id: eventFormData.groupId,
                user_id: eventFormData.userId,
                event_name: eventFormData.name,
                event_location: eventFormData.location,
                event_description: eventFormData.description,
                event_date: eventFormData.date
            };

            const response = await eventApi.createEvent(requestPayload);
            const eventId = response.data;
            console.log('Event created successfully, ID:', eventId);

            // Use the media service for thumbnail upload
            if (eventFormData.thumbnailFile && !isEmpty(eventId)) {
                console.log('Uploading event thumbnail image for event ID:', eventId);
                try {
                    const thumbnailResponse = await updateThumbnailImage({
                        entityId: eventId,
                        entityType: 'event',
                        entityThumbnailFile: eventFormData.thumbnailFile
                    });
                    console.log('Event thumbnail uploaded successfully:', thumbnailResponse);
                } catch (thumbnailError) {
                    console.error('Failed to upload event thumbnail:', thumbnailError);
                    // We don't throw here to avoid failing the entire operation
                }
            } else {
                console.log('No thumbnail to upload or missing event ID');
            }

            return {
                eventId: eventId,
                formData: eventFormData
            };
        },
        onSuccess: (result) => {
            console.log(`Successfully created event: ${result.formData.name}`);
        },
        onError: (error, variables) => {
            console.error(`Failed to create event: ${variables.name}`, error);
        }
    });

    return {
        createEvent: createEventMutation.mutate,
        isCreateEventLoading: createEventMutation.isPending || isUpdateThumbnailImageLoading,
        errorCreateEvent: createEventMutation.error || updateThumbnailImageError,
        
        // Export the user join function
        addUserToEvent: addUserToEventMutation.mutate,
        isAddingUserToEvent: addUserToEventMutation.isPending,
        errorAddingUserToEvent: addUserToEventMutation.error
    };
};