import { Box, Typography } from '@mui/material';
import { CollectionListItem } from '../../../collection/components/collection-list-item/CollectionListItem';

const GroupCollectionList = ({ year, collections }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: 'auto',
                marginBottom: '32px',
                padding: '32px',
                flexDirection: 'column',
                alignItems: 'flexStart',
                gap: '32px',
                alignSelf: 'stretch',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
            }}
        >
            <Typography
                sx={{
                    color: '#000',
                    fontFamily: 'Wanted Sans',
                    fontSize: '32px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    letterSpacing: '-1'
                }}
            >
                { year }
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    gap: '32px',
                    alignSelf: 'stretch',
                    flexWrap: 'wrap'
                }}
            >
                { collections.map((collection) => (
                    <CollectionListItem
                        key={collection.collection_id}
                        collection={collection}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default GroupCollectionList; 