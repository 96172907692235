export const TEXT = {
    MY_COLLECTIONS: 'My Collections',
    TOTAL_MEDIA: 'total media:',
    IMAGES: 'images',
    VIDEOS: 'videos',
    LATEST: 'Latest',
    OLDEST: 'Oldest',
    SORT_BY: 'Sort by',
    GO_BACK: 'go back',
    CREATED: 'created:',
    DURATION: 'duration:',
    MEDIA: 'media:',
    UPLOAD_IMAGE: '+ upload image',
    UPLOAD_VIDEO: '+ upload video',
    SHARE_UPLOAD_LINK: 'share upload link'
};