import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Typography, TextField, Switch, FormControlLabel, Snackbar, Alert } from '@mui/material';
import { TEXT } from './constants';
import { useGroup } from '../../hooks/useGroup';

export const GroupForm = ({ onCancel }) => {
    // State for form fields
    const userId = localStorage.getItem('user_id');
    const navigate = useNavigate();

    // Get group management functions from the hook
    const { 
        createGroup, 
        addUserToGroups, 
        isCreateGroupLoading, 
        errorCreateGroup 
    } = useGroup();

    // Toast notification state
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastSeverity, setToastSeverity] = useState('success');

    const [groupData, setGroupData] = useState({
        userId: userId,
        name: '',
        description: '',
        url: '',
        openInvitation: false,
        isPrivate: true,
        thumbnailUrl: null,
        thumbnailFile: null
    });

    // Add state for validation errors
    const [errors, setErrors] = useState({
        name: false,
        url: false
    });

    const handleOpenInvitationChange = (event) => {
        setGroupData(prev => ({
            ...prev,
            openInvitation: event.target.checked
        }));
    };
    
    const handlePrivacyChange = (event) => {
        setGroupData(prev => ({
            ...prev,
            isPrivate: event.target.checked
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setGroupData(prev => ({
            ...prev,
            [name]: value
        }));
        
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: false
            }));
        }
    };

    const handleThumbnailUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Basic file validation
            if (!file.type.startsWith('image/')) {
                showToast('Please select an image file', 'error');
                return;
            }
            
            // Check file size (limit to 5MB for example)
            if (file.size > 5 * 1024 * 1024) {
                showToast('File size should not exceed 5MB', 'error');
                return;
            }
            
            const reader = new FileReader();
            reader.onload = (e) => {
                setGroupData(prev => ({
                    ...prev,
                    thumbnailUrl: e.target.result,
                    thumbnailFile: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveThumbnail = () => {
        setGroupData(prev => ({
            ...prev,
            thumbnailUrl: null,
            thumbnailFile: null
        }));
    };

    const validateForm = () => {
        const newErrors = {
            name: !groupData.name.trim(),
            url: !groupData.url.trim()
        };
        
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleToastClose = () => {
        setToastOpen(false);
    };

    const showToast = (message, severity = 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setToastOpen(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (validateForm()) {
            try {
                if (isCreateGroupLoading) {
                    return;
                }
                
                createGroup(groupData, {
                    onSuccess: (result) => {
                        const { groupId } = result;
                        
                        if (groupId) {
                            addUserToGroups({
                                groupIds: [groupId],
                                userId: userId
                            }, {
                                onSuccess: () => {
                                    console.log(`User ${userId} added to group ${groupId}`);
                                },
                                onError: (error) => {
                                    console.error('Error adding user to group:', error);
                                }
                            });
                        }
                        
                        showToast("Group created successfully!");
                        
                        setTimeout(() => {
                            navigate('/groups', { 
                                state: { 
                                    toast: { 
                                        message: "Group created successfully!", 
                                        severity: "success" 
                                    } 
                                } 
                            });
                        }, 1500);
                    },
                    onError: (error) => {
                        console.error('Error creating group:', error);
                        showToast('Failed to create group. Please try again.', 'error');
                    }
                });
            } catch (error) {
                console.error('Error in form submission:', error);
                showToast('An unexpected error occurred. Please try again.', 'error');
            }
        } else {
            showToast('Please fill in all required fields', 'error');
        }
    };

    return (
        <Box
            sx={{
                height: '100%'
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Wanted Sans',
                    fontSize: '32px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal'
                }}
            >
                {TEXT.CREATE_GROUP}
            </Typography>
            
            {/* Logo Upload Section */}
            <Box
                sx={{
                    marginTop: '32px',
                    display: 'flex',
                    padding: '32px',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    gap: '16px',
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        color: '#000'
                    }}
                >
                    {TEXT.UPLOAD_THUMBNAIL}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch'
                    }}
                >
                    <Avatar 
                        src={groupData.thumbnailUrl || "/path/to/default/image.jpg"}
                        sx={{
                            width: 128,
                            height: 128
                        }}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="logo-upload"
                        onChange={handleThumbnailUpload}
                    />
                    <label htmlFor="logo-upload">
                        <Button
                            component="span"
                            sx={{
                                marginLeft: '16px',
                                display: 'flex',
                                padding: '8px 22px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '4px',
                                border: '1px solid #0357D7',
                                background: '#0357D7'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#FFF',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '26px',
                                    textTransform: 'none'
                                }}
                            >
                                {TEXT.UPLOAD_IMAGE_BUTTON_TEXT}
                            </Typography>
                        </Button>
                    </label>
                    {groupData.thumbnailUrl && (
                        <Button
                            onClick={handleRemoveThumbnail}
                            sx={{
                                marginLeft: '8px',
                                padding: '8px 22px',
                                borderRadius: '4px',
                                border: '1px solid #0357D7'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#0357D7',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '26px',
                                    textTransform: 'none'
                                }}
                            >
                                {TEXT.REMOVE_BUTTON_TEXT}
                            </Typography>
                        </Button>
                    )}
                </Box>
            </Box>

            {/* Group Information Form */}
            <Box
                component='form'
                onSubmit={handleSubmit}
                sx={{
                    marginTop: '16px',
                    padding: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.GROUP_INFORMATION}
                </Typography>
                
                <TextField
                    id='group_name'
                    name='name'
                    label={TEXT.GROUP_NAME}
                    value={groupData.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name ? "* Field is required" : ""}
                    sx={{ marginTop: '32px', width: '100%' }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    id='group_description'
                    name='description'
                    label={TEXT.GROUP_DESCRIPTION}
                    value={groupData.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    sx={{ marginTop: '16px', width: '100%' }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />
                
                <TextField
                    id='group_url'
                    name='url'
                    label="Group URL"
                    value={groupData.url}
                    onChange={handleInputChange}
                    error={errors.url}
                    helperText={errors.url ? "* Field is required" : ""}
                    sx={{ marginTop: '16px', width: '100%' }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        marginTop: '32px'
                    }}
                >
                    { TEXT.GROUP_PRIVACY_SETTINGS }
                </Typography>
                
                <Box sx={{
                        marginTop: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        alignSelf: 'stretch'
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={groupData.openInvitation}
                                onChange={handleOpenInvitationChange}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#0357D7'
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: '#0357D7'
                                    }
                                }}
                            />
                        }
                        label={
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '16px',
                                        fontWeight: '700'
                                    }}
                                >
                                    {TEXT.OPEN_INVITATION}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '15px'
                                    }}
                                >
                                    {TEXT.ALLOW_ANYONE_TO_JOIN_GROUP}
                                </Typography>
                            </Box>
                        }
                    />
                    
                    <FormControlLabel
                        control={
                            <Switch
                                checked={groupData.isPrivate}
                                onChange={handlePrivacyChange}
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#0357D7'
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: '#0357D7'
                                    }
                                }}
                            />
                        }
                        label={
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '16px',
                                        fontWeight: '700'
                                    }}
                                >
                                    { TEXT.PRIVATE_GROUP }
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '15px'
                                    }}
                                >
                                    { TEXT.GROUP_WILL_NOT_APPEAR_IN_SEARCH_RESULTS }
                                </Typography>
                            </Box>
                        }
                    />
                </Box>

                <Box
                    sx={{
                        marginTop: '24px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '8px',
                        width: '100%'
                    }}
                >
                    <Button
                        onClick={onCancel}
                        sx={{
                            padding: '6px 16px',
                            borderRadius: '4px',
                            border: '1px solid #0357D7'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#0357D7',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            {TEXT.GO_BACK_BUTTON_TEXT}
                        </Typography>
                    </Button>
                    <Button
                        type="submit"
                        disabled={isCreateGroupLoading}
                        sx={{
                            padding: '6px 16px',
                            borderRadius: '4px',
                            border: '1px solid #0357D7',
                            background: '#0357D7',
                            '&:disabled': {
                                opacity: 0.7,
                                backgroundColor: '#ccc',
                                borderColor: '#ccc'
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#FFF',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            {isCreateGroupLoading ? 'Creating...' : TEXT.CREATE_GROUP_BUTTON_TEXT}
                        </Typography>
                    </Button>
                </Box>
            </Box>
            
            {/* Toast notification */}
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleToastClose} 
                    severity={toastSeverity} 
                    sx={{ width: '100%', whiteSpace: 'pre-line' }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};