import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TEXT } from './constants';
import { Avatar, Card, CardContent, Button } from '@mui/material';
import styles from './styles.module.css';
import { usePersonStore } from '../../../person/store/personStore';

const UserProfileCard = ({ user }) => {
    const navigate = useNavigate();

    const { person } = usePersonStore();
    
    const userName = user?.user_name;
    const firstName = person?.first_name;
    const lastName = person?.last_name;
    const userProfileImage = user?.profile_image;

    const handleEditProfile = () => {
        navigate('/profile/edit');
    };

    return (
        <Card
            elevation={0}
            sx={{
                display: 'flex',
                height: 'auto',
                width: '100%',
                padding: '16px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
                alignSelf: 'stretch',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
                boxSizing: 'border-box',
                maxWidth: '320px' // Adjusted to 320px
            }}
        >
            <CardContent
                className={styles['card-content']}
                sx={{
                    padding: '0 !important', // Override MUI padding
                    width: '100%',
                    '&:last-child': {
                        paddingBottom: '0'
                    }
                }}
            >
                <div className={styles['image-container']}>
                    <Avatar 
                        src={userProfileImage || "/path/to/default/avatar.jpg"}
                        sx={{
                            width: 120,
                            height: 120,
                            '& img': {
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%'
                            }
                        }}
                    />
                </div>
                <div className={styles['user-info-container']}>
                    <div className={styles['name-container']}>
                        {`${firstName} ${lastName}`}
                    </div>
                    <div className={styles['username-container']}>
                        @ {userName}
                    </div>
                </div>
            </CardContent>
            <Button
                variant="contained"
                onClick={handleEditProfile}
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '36px',
                    borderRadius: '4px',
                    border: '1px solid #0357D7',
                    bgcolor: '#0357D7',
                    '&:hover': {
                        bgcolor: '#0357D7'
                    },
                    color: '#FFF',
                    fontFamily: 'Wanted Sans',
                    fontSize: '12.5px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textTransform: 'none',
                    marginTop: '8px'
                }}
            >
                {TEXT.EDIT_PROFILE}
            </Button>
        </Card>
    );
};

export default UserProfileCard;