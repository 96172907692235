import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GroupView from "./components/group-view/GroupView";
import GroupList from "./components/group-list/GroupList";
import { useGroupStore } from './store/groupStore';

const GroupPage = ({ groupId, onCreateGroup }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { groups } = useGroupStore();
    
    useEffect(() => {
        // If groupId is invalid, redirect to groups list
        if (groupId && !groups.find(group => group.group_id === groupId)) {
            navigate('/groups');
        }
    }, [groupId, groups, navigate]);

    const handleCreateGroup = () => {
        // Use the HomePage's handleCreateGroup if provided
        if (onCreateGroup) {
            // Navigate to the create form page first
            navigate('/groups/new');
            // The actual group creation will happen in HomePage's handleCreateGroup
            // when the form is submitted
        } else {
            // Fallback to just navigation if the function wasn't passed
            navigate('/groups/new');
        }
    };

    const handleCreateGroupCollection = (selectedGroupId) => {
        navigate(`/groups/${selectedGroupId}/collections/new`);
    };

    const handleViewGroup = (selectedGroupId) => {
        navigate(`/groups/${selectedGroupId}`);
    };

    const renderContent = () => {
        // New group form is handled by HomePage routing
        if (location.pathname === '/groups/new') {
            return null;
        }
        
        // Group detail view
        if (groupId) {
            return (
                <GroupView 
                    key={groupId} 
                    groupId={groupId} 
                    onCreateClick={() => handleCreateGroupCollection(groupId)}
                />
            );
        }

        // Groups list view
        return (
            <GroupList 
                groups={groups} 
                onCreateClick={handleCreateGroup}
                onGroupClick={handleViewGroup}
            />
        );
    };

    return (
        <div>
            {renderContent()}
        </div>
    );
};

export default GroupPage;