export const TEXT = {
    EDIT_PROFILE: 'Edit Profile',
    PROFILE_PHOTO: 'Profile Photo',
    UPLOAD_IMAGE_BUTTON_TEXT: 'Upload Image',
    REMOVE_BUTTON_TEXT: 'Remove',
    PERSONAL_INFORMATION: 'Personal Information',
    USER_NAME: 'User Name',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    BIRTHDAY: 'Birthday',
    PHONE_NUMBER: 'Phone Number',
    EMAIL_ADDRESS: 'Email Address',
    NOTIFICATION_PREFERENCES: 'Notification Preferences',
    NOTIFICATION_PREFERNCES_INFO_TEXT: 'Be notified with new alerts on upload activity and updates',
    ACTIVITY_NOTIFICATION: 'Activity Notification',
    ACTIVITY_NOTIFICATION_INFO_TEXT: 'Get alerts when members of your group upload or make changes',
    GROUP_NOTIFICATION: 'Group Notification',
    GROUP_NOTIFICATION_INFO_TEXT: 'Receive updates about groups, new members, new collections, and new events',
    MARKETING_NOTIFICATION: 'Marketing Notification',
    MARKETING_NOTIFICATION_INFO_TEXT: 'Stay informed about application updates',
    CHANGE_PASSWORD: 'Change Password',
    CURRENT_PASSWORD: 'Current Password',
    NEW_PASSWORD: 'New Password',
    REPEAT_NEW_PASSWORD: 'Repeat New Password',
    GO_BACK_BUTTON_TEXT: 'go back',
    SAVE_CHANGES_BUTTON_TEXT: 'save changes'
};