import { useState, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { TEXT } from './constants';
import { CollectionList } from './components/collection-list/CollectionList';
import { useCollectionStore } from './store/collectionStore';
import { getMediaTypeCounts } from '../../utils/GetMediaTypeCounts';

const CollectionPage = () => {
    const [sortBy, setSortBy] = useState('latest');
    const { collections } = useCollectionStore();
    const [totalCounts, setTotalCounts] = useState({ images: 0, videos: 0 });

    // Calculate total media counts when collections change
    useEffect(() => {
        // Initialize counters
        let totalImages = 0;
        let totalVideos = 0;

        // Process each collection using the same method as CollectionListItem
        collections.forEach(collection => {
            const mediaTypeCounts = getMediaTypeCounts(collection.media);
            totalImages += mediaTypeCounts?.image || 0;
            totalVideos += mediaTypeCounts?.video || 0;
        });

        // Update state with the calculated counts
        setTotalCounts({ 
            images: totalImages, 
            videos: totalVideos 
        });
    }, [collections]);

    const totalImagesAndVideosText = `${totalCounts.images} ${TEXT.IMAGES}, ${totalCounts.videos} ${TEXT.VIDEOS}`;

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    padding: '32px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '8px',
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        fontFamily: 'Wanted Sans',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    { TEXT.MY_COLLECTIONS }
                </Typography>
                <Box
                    sx={{
                        alignSelf: 'stretch',
                        display: 'flex',
                        gap: '4px',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#565656',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px'
                        }}
                    >
                        { TEXT.TOTAL_MEDIA }
                    </Typography>
                    <Typography
                        sx={{
                           color: '#565656',
                           fontFamily: 'Wanted Sans',
                           fontSize: '14px',
                           fontStyle: 'normal',
                           fontWeight: '700',
                           lineHeight: '16px' 
                        }}
                    >
                        { totalImagesAndVideosText }
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    gap: '8px',
                    alignSelf: 'stretch'
                }}
            >
                <FormControl
                    elevation={0}
                    size="medium"
                    variant="outlined"
                    sx={{
                        width: '210px',
                        borderRadius: '1px solid #C4C4C4',
                        backgroundColor: '#FFFFFF'
                    }} 
                >
                    <InputLabel
                        id="sort-label"
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '24px',
                            letterSpacing: '0.15px'
                        }}
                    >
                        { TEXT.SORT_BY }
                    </InputLabel>
                    <Select
                        labelId="sort-label"
                        value={sortBy}
                        label="Sort by"
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '24px',
                            letterSpacing: '.15px'
                        }}
                        onChange={handleSortChange}
                    >
                        <MenuItem
                            value="latest"
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '24px',
                                letterSpacing: '.15px'
                            }}
                        >
                            { TEXT.LATEST }
                        </MenuItem>
                        <MenuItem
                            value="oldest"
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '24px',
                                letterSpacing: '.15px'
                            }}
                        >
                            { TEXT.OLDEST }
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <CollectionList sortBy={sortBy} />
            <Box
                sx={{
                    marginTop: 'calc(6% + 100px)',
                    bottom: '0'
                }}
            />
        </Box>
    )
}

export default CollectionPage;