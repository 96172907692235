import { useMutation } from "@tanstack/react-query";
import { eventApi } from "../services/eventApi";
import { useEventStore } from "../store/eventStore";

export const useEventProfileImage = () => {
    const { events, setEvents } = useEventStore();

    const fetchProfileImagesMutation = useMutation({
        mutationFn: async (eventIds) => {
            if (Array.isArray(eventIds) && typeof eventIds[0] === 'object') {
                eventIds = eventIds.map(event => event.event_id);
            }
            
            if (!Array.isArray(eventIds)) {
                eventIds = [eventIds];
            }
            
            const responses = await Promise.all(
                eventIds.map(async (id) => {
                    try {
                        const imageData = await eventApi.getEventProfileImage(id);
                        return { event_id: id, profile_image: imageData };
                    } catch (error) {
                        console.error(`Error fetching profile image for event ${id}:`, error);
                        return { event_id: id, profile_image: null };
                    }
                })
            );
            
            return responses;
        },
        onSuccess: (imageData) => {
            const updatedEvents = events.map(event => {
                const eventImage = imageData.find(
                    img => img.event_id === event.event_id
                );
                return {
                    ...event,
                    profile_image: eventImage?.profile_image || null
                };
            });
            
            setEvents(updatedEvents);
        }
    });

    return {
        fetchEventProfileImages: fetchProfileImagesMutation.mutate,
        isLoading: fetchProfileImagesMutation.isPending,
        error: fetchProfileImagesMutation.error
    };
};