import { Avatar, Box, Typography, Skeleton } from '@mui/material';
import { ChevronLeft, ChevronRight, ArrowForward } from '@mui/icons-material';
import { useState, useRef, useEffect } from 'react';
import { useMedia } from '../../../media/hooks/useMedia';
import { TEXT } from './constants';
import { useNavigate } from 'react-router-dom';
import { collectionApi } from '../../services/collectionApi';

export const CollectionCarouselPreview = ({
    collectionId,
    groupName,
    collectionName,
    collectionThumbnailImage,
    numImages = 0,
    numVideos = 0,
    mediaItems = [],
    totalCount = 0,
    collection
}) => {
    const navigate = useNavigate();
    const { getMediaThumbnail } = useMedia();
    
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [dragOffset, setDragOffset] = useState(0);
    const [thumbnailData, setThumbnailData] = useState({});
    const [loadingThumbnails, setLoadingThumbnails] = useState({});
    const [resolvedThumbnailUrl, setResolvedThumbnailUrl] = useState(null);
    const [isCollectionThumbnailLoading, setIsCollectionThumbnailLoading] = useState(true);
    
    // Refs to prevent infinite loops
    const loadedThumbnailsRef = useRef({});
    const attemptedLoadRef = useRef({});

    const containerRef = useRef(null);
    const animationRef = useRef(null);
    const velocityRef = useRef(0);
    const lastTimeRef = useRef(null);
    const lastDragXRef = useRef(null);

    const displayedItems = mediaItems;
    const itemsPerView = 4;
    const hasMoreItems = totalCount > mediaItems.length;
    const totalItems = hasMoreItems ? displayedItems.length + 1 : displayedItems.length;
    const empty = (totalItems === 0);
    const itemWidth = 247;
    const itemGap = 20;
    const visibleWidth = (itemWidth * itemsPerView) + (itemGap * (itemsPerView - 1));
    const totalWidth = (itemWidth * totalItems) + (itemGap * (totalItems - 1));
    const maxIndex = Math.max(0, Math.ceil((totalWidth - visibleWidth) / (itemWidth + itemGap)));
    
    const navigationDisabled = totalItems <= itemsPerView;
    const numImagesAndVideosText = `${numImages} ${TEXT.IMAGES}, ${numVideos} ${TEXT.VIDEOS}`;

    // Resolve and set the collection thumbnail URL
    useEffect(() => {
        const resolveThumbnail = async () => {
            setIsCollectionThumbnailLoading(true); // Start loading
            try {
                // Check if direct thumbnail URL is provided
                if (collectionThumbnailImage) {
                    setResolvedThumbnailUrl(collectionThumbnailImage);
                    setIsCollectionThumbnailLoading(false);
                    return;
                }

                // Check if collection object is available and has thumbnail info
                if (collection) {
                    // Try different possible thumbnail paths in the collection object
                    if (collection.thumbnail && typeof collection.thumbnail === 'string') {
                        setResolvedThumbnailUrl(collection.thumbnail);
                        setIsCollectionThumbnailLoading(false);
                        return;
                    }
                    
                    if (collection.thumbnail?.thumbnail_blob_path) {
                        setResolvedThumbnailUrl(collection.thumbnail.thumbnail_blob_path);
                        setIsCollectionThumbnailLoading(false);
                        return;
                    }
                }

                // If no thumbnail is found, fetch it using the API
                if (collectionId) {
                    const thumbnailData = await collectionApi.getCollectionProfileImage(collectionId);
                    if (thumbnailData) {
                        setResolvedThumbnailUrl(thumbnailData);
                    }
                }
            } catch (error) {
                console.error('Error resolving collection thumbnail:', error);
            } finally {
                setIsCollectionThumbnailLoading(false); // End loading regardless of outcome
            }
        };

        resolveThumbnail();
    }, [collectionId, collectionThumbnailImage, collection]);

    // Initialize loading state for new media items
    useEffect(() => {
        if (displayedItems.length === 0) return;

        // Create an initial loading state for new items only
        const initialLoadingState = {};
        let hasNewItems = false;

        displayedItems.forEach(item => {
            const mediaId = item.media_id;
            // Only set loading=true for items we haven't attempted to load yet
            if (!loadedThumbnailsRef.current[mediaId] && 
                !attemptedLoadRef.current[mediaId] && 
                !thumbnailData[mediaId]) {
                
                initialLoadingState[mediaId] = true;
                attemptedLoadRef.current[mediaId] = true;
                hasNewItems = true;
            }
        });

        // Only update state if we have new items
        if (hasNewItems) {
            setLoadingThumbnails(prev => ({
                ...prev,
                ...initialLoadingState
            }));
        }
    }, [displayedItems]);

    // Load thumbnails for items marked as loading
    useEffect(() => {
        const loadPendingThumbnails = async () => {
            // Find items that are marked as loading
            const itemsToLoad = displayedItems.filter(item => 
                loadingThumbnails[item.media_id] === true && 
                !loadedThumbnailsRef.current[item.media_id]
            );
            
            if (itemsToLoad.length === 0) return;
            
            const newThumbnailData = {};
            const newLoadingState = {};
            
            // Process each item that needs loading
            for (const item of itemsToLoad) {
                const mediaId = item.media_id;
                
                try {
                    // Mark this as attempted to prevent duplicates
                    loadedThumbnailsRef.current[mediaId] = true;
                    
                    const result = await getMediaThumbnail(mediaId);
                    
                    if (result.success && result.thumbnailUrl) {
                        newThumbnailData[mediaId] = result.thumbnailUrl;
                    }
                    
                    // Mark as no longer loading
                    newLoadingState[mediaId] = false;
                    
                } catch (error) {
                    console.error(`Error loading thumbnail for media ${mediaId}:`, error);
                    newLoadingState[mediaId] = false;
                }
            }
            
            // Update thumbnail data if we got new thumbnails
            if (Object.keys(newThumbnailData).length > 0) {
                setThumbnailData(prev => ({
                    ...prev,
                    ...newThumbnailData
                }));
            }
            
            // Update loading states
            if (Object.keys(newLoadingState).length > 0) {
                setLoadingThumbnails(prev => ({
                    ...prev,
                    ...newLoadingState
                }));
            }
        };
        
        loadPendingThumbnails();
    }, [loadingThumbnails, displayedItems, getMediaThumbnail]);

    // Clean up animation on unmount
    useEffect(() => {
        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);

    const getCurrentPosition = () => {
        return currentIndex * (itemWidth + itemGap) + dragOffset;
    };

    const animateToPosition = (targetPosition) => {
        const startPosition = getCurrentPosition();
        const startTime = performance.now();
        const duration = 300;

        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            const easeOut = 1 - Math.pow(1 - progress, 2);
            const currentPosition = startPosition + (targetPosition - startPosition) * easeOut;
            
            const newIndex = Math.round(currentPosition / (itemWidth + itemGap));
            setCurrentIndex(Math.max(0, Math.min(newIndex, maxIndex)));
            setDragOffset(currentPosition - (newIndex * (itemWidth + itemGap)));

            if (progress < 1) {
                animationRef.current = requestAnimationFrame(animate);
            } else {
                setDragOffset(0);
                setCurrentIndex(Math.round(targetPosition / (itemWidth + itemGap)));
            }
        };

        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        animationRef.current = requestAnimationFrame(animate);
    };

    const handleNext = () => {
        const nextIndex = Math.min(currentIndex + 1, maxIndex);
        animateToPosition(nextIndex * (itemWidth + itemGap));
    };

    const handlePrev = () => {
        const prevIndex = Math.max(currentIndex - 1, 0);
        animateToPosition(prevIndex * (itemWidth + itemGap));
    };

    const handleMouseDown = (e) => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        setIsDragging(true);
        setStartX(e.pageX);
        lastTimeRef.current = performance.now();
        lastDragXRef.current = e.pageX;
        velocityRef.current = 0;
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();

        const currentX = e.pageX;
        const deltaX = startX - currentX;
        
        const currentTime = performance.now();
        const timeDelta = currentTime - lastTimeRef.current;
        const dragDelta = lastDragXRef.current - currentX;
        
        if (timeDelta > 0) {
            const newVelocity = dragDelta / timeDelta;
            velocityRef.current = velocityRef.current * 0.7 + newVelocity * 0.3;
        }
        
        lastTimeRef.current = currentTime;
        lastDragXRef.current = currentX;

        const newPosition = getCurrentPosition() + deltaX;
        const maxPosition = maxIndex * (itemWidth + itemGap);
        
        // Add elastic resistance when dragging past the ends
        let clampedPosition;
        if (newPosition < 0) {
            clampedPosition = newPosition * 0.5;
        } else if (newPosition > maxPosition) {
            clampedPosition = maxPosition + (newPosition - maxPosition) * 0.5;
        } else {
            clampedPosition = newPosition;
        }
        
        const newIndex = Math.floor(clampedPosition / (itemWidth + itemGap));
        const newOffset = clampedPosition - (newIndex * (itemWidth + itemGap));
        
        setCurrentIndex(newIndex);
        setDragOffset(newOffset);
        setStartX(currentX);
    };

    const handleMouseUp = () => {
        if (!isDragging) return;
        setIsDragging(false);

        const velocity = velocityRef.current;
        const momentum = velocity * 120;
        
        let targetPosition = getCurrentPosition() + momentum;
        
        if (targetPosition < 0) targetPosition = 0;
        const maxPosition = maxIndex * (itemWidth + itemGap);
        if (targetPosition > maxPosition) targetPosition = maxPosition;
        
        const nearestIndex = Math.round(targetPosition / (itemWidth + itemGap));
        const clampedIndex = Math.max(0, Math.min(nearestIndex, maxIndex));
        
        animateToPosition(clampedIndex * (itemWidth + itemGap));
    };

    const handleMouseLeave = () => {
        if (isDragging) {
            handleMouseUp();
        }
    };

    const viewAllClicked = () => {
        navigate(`/collection/${collectionId}`);
    };

    // Render custom carousel item with proper thumbnail rendering
    const renderCarouselItem = (mediaItem) => {
        const mediaId = mediaItem.media_id;
        const thumbnailUrl = thumbnailData[mediaId];
        const isLoading = loadingThumbnails[mediaId] === true;
        
        return (
            <Box
                key={mediaId}
                sx={{
                    width: `${itemWidth}px`,
                    height: '200px',
                    borderRadius: '10px',
                    border: '1px solid #F8F8F8',
                    overflow: 'hidden',
                    position: 'relative',
                    flexShrink: 0,
                    cursor: 'pointer'
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {isLoading ? (
                    // Skeleton loading effect
                    <Skeleton 
                        variant="rectangular" 
                        animation="wave"
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                            borderRadius: '8px',
                            transform: 'scale(1)', // Fix for animation issues
                        }}
                    />
                ) : (
                    // Actual thumbnail
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : 'none',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundColor: !thumbnailUrl ? '#fcfcfc' : 'transparent',
                        }}
                    />
                )}
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                padding: empty ? '34px' : '34px 34px 20px 34px',
                alignItems: 'flex-start',
                flexDirection: 'column',
                borderRadius: '16px',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                background: '#FFF',
                transition: 'all 0.3s ease',
                '&:hover': {
                    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
                }
            }}
        >
            <Box
                onClick={viewAllClicked}
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    marginBottom: empty ? 0 : '24px',
                    transition: 'opacity 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.85,
                    }
                }}
            >
                {isCollectionThumbnailLoading ? (
                    // Skeleton for collection thumbnail
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        sx={{
                            width: 52,
                            height: 52,
                            borderRadius: '14px',
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                            transform: 'scale(1)', // Fix for animation issues
                        }}
                    />
                ) : (
                    <Avatar 
                        src={resolvedThumbnailUrl || "/path/to/default/image.jpg"}
                        sx={{
                            width: 52,
                            height: 52,
                            borderRadius: '14px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.06)',
                            '& img': {
                                objectFit: 'cover',
                            }
                        }}
                    />
                )}
                <Box sx={{ marginLeft: '16px' }}>
                    {isCollectionThumbnailLoading ? (
                        // Skeletons for text content
                        <>
                            <Skeleton animation="wave" width="60%" height={26} sx={{ transform: 'scale(1)' }} />
                            <Skeleton animation="wave" width="40%" height={18} sx={{ mt: 0.5, transform: 'scale(1)' }} />
                            <Skeleton animation="wave" width="30%" height={16} sx={{ mt: 0.5, transform: 'scale(1)' }} />
                        </>
                    ) : (
                        // Actual text content
                        <>
                            <Typography
                                sx={{
                                    color: '#1D1D1F',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '22px',
                                    fontWeight: 600,
                                    letterSpacing: '-0.01em',
                                    lineHeight: 1.2,
                                }}
                            >
                                {collectionName}            
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#424245',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontWeight: 500,
                                    marginTop: '2px',
                                }}
                            >
                                {groupName}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#86868B',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    marginTop: '4px',
                                }}
                            >
                                {numImagesAndVideosText}
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>

            {!empty && (
                <Box 
                    ref={containerRef}
                    sx={{
                        position: 'relative',
                        width: '100%',
                        overflow: 'hidden',
                        cursor: isDragging ? 'grabbing' : 'grab',
                        borderRadius: '12px',
                        WebkitOverflowScrolling: 'touch'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                    onTouchStart={(e) => {
                        if (animationRef.current) {
                            cancelAnimationFrame(animationRef.current);
                        }
                        setIsDragging(true);
                        setStartX(e.touches[0].pageX);
                        lastTimeRef.current = performance.now();
                        lastDragXRef.current = e.touches[0].pageX;
                        velocityRef.current = 0;
                    }}
                    onTouchMove={(e) => {
                        if (!isDragging) return;
                        
                        const currentX = e.touches[0].pageX;
                        const deltaX = startX - currentX;
                        
                        const currentTime = performance.now();
                        const timeDelta = currentTime - lastTimeRef.current;
                        const dragDelta = lastDragXRef.current - currentX;
                        
                        if (timeDelta > 0) {
                            const newVelocity = dragDelta / timeDelta;
                            velocityRef.current = velocityRef.current * 0.7 + newVelocity * 0.3;
                        }
                        
                        lastTimeRef.current = currentTime;
                        lastDragXRef.current = currentX;

                        const newPosition = getCurrentPosition() + deltaX;
                        const maxPosition = maxIndex * (itemWidth + itemGap);
                        
                        let clampedPosition;
                        if (newPosition < 0) {
                            clampedPosition = newPosition * 0.5;
                        } else if (newPosition > maxPosition) {
                            clampedPosition = maxPosition + (newPosition - maxPosition) * 0.5;
                        } else {
                            clampedPosition = newPosition;
                        }
                        
                        const newIndex = Math.floor(clampedPosition / (itemWidth + itemGap));
                        const newOffset = clampedPosition - (newIndex * (itemWidth + itemGap));
                        
                        setCurrentIndex(newIndex);
                        setDragOffset(newOffset);
                        setStartX(currentX);
                    }}
                    onTouchEnd={() => handleMouseUp()}
                    onTouchCancel={() => handleMouseUp()}
                >
                    {!navigationDisabled && currentIndex > 0 && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                bottom: 0,
                                width: '20%',
                                zIndex: 1,
                                '&:hover .left-nav': {
                                    opacity: 1
                                }
                            }}
                        >
                            <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePrev();
                                }}
                                className="left-nav"
                                sx={{
                                    position: 'absolute',
                                    left: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'rgba(0, 0, 0, 0.3)',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    opacity: 0,
                                    transition: 'opacity 0.2s ease-in-out',
                                    '&:hover': {
                                        background: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }}
                            >
                                <ChevronLeft sx={{ color: '#FFF' }} />
                            </Box>
                        </Box>
                    )}

                    {!navigationDisabled && currentIndex < maxIndex && (
                        <Box
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                bottom: 0,
                                width: '20%',
                                zIndex: 1,
                                '&:hover .right-nav': {
                                    opacity: 1
                                }
                            }}
                        >
                            <Box
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleNext();
                                }}
                                className="right-nav"
                                sx={{
                                    position: 'absolute',
                                    right: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'rgba(0, 0, 0, 0.3)',
                                    cursor: 'pointer',
                                    borderRadius: '50%',
                                    opacity: 0,
                                    transition: 'opacity 0.2s ease-in-out',
                                    '&:hover': {
                                        background: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }}
                            >
                                <ChevronRight sx={{ color: '#FFF' }} />
                            </Box>
                        </Box>
                    )}
                    <Box 
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            transform: `translateX(-${getCurrentPosition()}px)`,
                            willChange: 'transform',
                            width: 'fit-content',
                            transition: isDragging ? 'none' : 'transform 0.3s ease-out',
                            gap: `${itemGap}px`,
                            userSelect: 'none'
                        }}
                    >
                        {displayedItems.map((mediaItem) => (
                            renderCarouselItem(mediaItem)
                        ))}
                        
                        {hasMoreItems && (
                            <Box
                                sx={{
                                    flex: `0 0 ${itemWidth}px`,
                                    width: `${itemWidth}px`,
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '8px',
                                    cursor: 'pointer',
                                    borderRadius: '14px',
                                    background: 'rgba(250, 250, 250, 0.95)',
                                    backdropFilter: 'blur(8px)',
                                    transition: 'all 0.3s ease',
                                    flexShrink: 0,
                                    '&:hover': {
                                        transform: 'scale(1.02)',
                                        background: 'rgba(245, 245, 245, 0.98)',
                                    }
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewAllClicked();
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '17px',
                                        fontWeight: 600,
                                        color: '#0357D7',
                                        letterSpacing: '-0.01em',
                                    }}
                                >
                                    {TEXT.VIEW_ALL}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '15px',
                                        fontWeight: 400,
                                        color: '#666',
                                    }}
                                >
                                    {totalCount - mediaItems.length} more items
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            {/* "View All" button with extra spacing */}
            {!empty && (
                <Box
                    onClick={(e) => {
                        e.stopPropagation();
                        viewAllClicked();
                    }}
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginTop: '20px',
                        padding: '10px 0',
                        cursor: 'pointer'
                    }}
                >
                    <Box 
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            padding: '5px 4px',
                            borderRadius: '20px',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.04)',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#1D1D1F',
                                fontFamily: 'Wanted Sans',
                                fontSize: '15px',
                                fontWeight: 500,
                                letterSpacing: '-0.01em',
                            }}
                        >
                            {TEXT.VIEW_ALL_LOWERCASE}
                        </Typography>
                        <ArrowForward 
                            sx={{
                                color: '#1D1D1F',
                                width: '16px',
                                height: '16px',
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};