// Function to prefix all endpoints with /api
const prefixEndpoints = (endpoints) => {
    const prefixed = {};
    
    const ensurePath = (path) => {
        if (path === null || path === undefined) {
            console.warn('Endpoint path is null or undefined');
            return '/';
        }
        
        const stringPath = String(path);
        return stringPath.startsWith('/') ? stringPath : `/${stringPath}`;
    };
    
    // In production, DON'T add /api prefix because the baseURL already has it
    const shouldAddApiPrefix = process.env.NODE_ENV !== 'production';
    const apiPrefix = shouldAddApiPrefix ? '/api' : '';
    
    // Helper function to process endpoints recursively
    const processEndpoint = (endpoint) => {
        if (typeof endpoint === 'function') {
            return (...args) => {
                const path = endpoint(...args);
                return `${apiPrefix}${ensurePath(path)}`;
            };
        } else if (typeof endpoint === 'object' && endpoint !== null) {
            // Handle nested objects
            const processed = {};
            for (const key in endpoint) {
                processed[key] = processEndpoint(endpoint[key]);
            }
            return processed;
        } else {
            // String or other primitive value
            return `${apiPrefix}${ensurePath(endpoint)}`;
        }
    };
    
    // Process all categories
    for (const category in endpoints) {
        prefixed[category] = {};
        for (const key in endpoints[category]) {
            prefixed[category][key] = processEndpoint(endpoints[category][key]);
        }
    }
    
    return prefixed;
};

// Original endpoints without /api prefix
const ORIGINAL_ENDPOINTS = {
    AUTHENTICATION: {
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout'
    },
    ACCOUNT: {
        CREATE_ACCOUNT: '/account',
        RETRIEVE_ACCOUNT_DETAILS: '/account/view'
    },
    ACTIVITY: {
        RETRIEVE_USER_FEED_ACTIVITIES: '/activity/user',
        RETRIEVE_GROUP_FEED_ACTIVITIES: '/activity/group',
        RETRIEVE_EVENT_FEED_ACTIVITIES: '/activity/event'
    },
    USER: {
        RETRIEVE_USER_DETAILS: '/user/view',
        UPDATE_USER: '/user'
    },
    PERSON: {
        RETRIEVE_PERSON_DETAILS: '/person/view',
        UPDATE_PERSON: '/person'
    },
    EVENT: {
        CREATE_EVENT: '/event',
        RETRIEVE_EVENT_DETAILS: '/event/view',
        USER_ADDED_TO_EVENT: '/event/user-added'
    },
    GROUP: {
        CREATE_GROUP: '/group',
        RETRIEVE_ALL_GROUPS: '/group',
        RETRIEVE_GROUP_DETAILS: '/group/view',
        RETRIEVE_GROUP_BY_URL: '/group/url',
        USER_ADDED_TO_GROUP: '/group/user-added',
        USER_REMOVED_FROM_GROUP: '/group/user-removed'
    },
    COLLECTION: {
        CREATE_COLLECTION: '/collection',
        RETRIEVE_COLLECTION_DETAILS: '/collection/view'
    },
    MEDIA: {
        INITIALIZE_MEDIA_UPLOAD: '/media/init',
        UPLOAD_MEDIA_CHUNK: '/media/chunk',
        UPLOAD_MEDIA_STATUS: '/media/status',
        DOWNLOAD_THUMBNAIL: '/media/thumbnail',
        DOWNLOAD_THUMBNAIL_PUBLIC: 'media/public/thumbnail',
        DOWNLOAD_STANDARD: '/media/standard',
        DOWNLOAD_ORIGINAL: '/media/file',
        DELETE_FILE: '/media/delete',
        UPLOAD_THUMBNAIL_IMAGE: '/media/entity-thumbnail',
        RETRIEVE_THUMBNAIL_IMAGE: '/media/entity-thumbnail',
        DELETE_PROFILE_THUMBNAIL_IMAGE: '/media/remove-entity-thumbnail'
    },
    PUBLIC: {
        SHAREABLE_LINK: {
            CREATE_SHAREABLE_MEDIA_UPLOAD_LINK: '/public/shareable-link',
            GET_SHAREABLE_LINK_TOKEN: '/public/shareable-link/token',
            UPLOAD_TO_SHAREABLE_LINK: '/media/shareable-link/upload'
        }
    }
};

// Export the prefixed version
export const ENDPOINTS = prefixEndpoints(ORIGINAL_ENDPOINTS);
