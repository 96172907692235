export const dateConvert = (dateString) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const [year, month, day] = dateString.split('-');
    const monthName = months[parseInt(month) - 1];

    // Pad the day with leading zero if needed
    const paddedDay = day.padStart(2, '0');

    return `${monthName} ${paddedDay}, ${year}`;
};

export const timeSince = (epochTime) => {
    const now = Date.now();
    const timestamp = epochTime * 1000; // Convert epoch seconds to milliseconds
    const diffInSeconds = Math.floor((now - timestamp) / 1000);

    // Less than 1 hour
    if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    }

    // Less than 24 hours
    if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    }

    // More than 24 hours
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
};