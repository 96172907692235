import { Box, IconButton, Typography, CircularProgress } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useMedia } from '../../hooks/useMedia';
import { useMediaStore } from '../../store/mediaStore';
import { useEffect, useState } from 'react';

export const MediaViewer = ({ selectedMedia, onClose, onNavigate, collection }) => {
    const { media } = useMediaStore(); // Remove setMedia to avoid storage issues
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [standardImageUrl, setStandardImageUrl] = useState(null);
    
    const mediaItem = media.find(item => item.media_id === selectedMedia?.media_id);
    
    const isImage = selectedMedia?.media_type?.startsWith('image/');
    const isVideo = selectedMedia?.media_type?.startsWith('video/');
    
    const { 
        downloadStandardFile, 
        isStandardDownloading, 
        standardDownloadError 
    } = useMedia();
    
    useEffect(() => {
        let isMounted = true;

        const loadMedia = async () => {
            if (!selectedMedia?.media_id) {
                setIsLoading(false);
                return;
            }

            // If we already have the URL in component state, don't reload
            if (standardImageUrl) {
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                const response = await downloadStandardFile(selectedMedia.media_id);
                
                if (!response?.standardSizeUrl) {
                    throw new Error('No standardSizeUrl in response');
                }

                if (isMounted) {
                    // Store in component state instead of global store
                    setStandardImageUrl(response.standardSizeUrl);
                }
            } catch (err) {
                if (isMounted) {
                    console.error('Error loading media:', err);
                    setError(err);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        setIsLoading(true);
        setError(null);
        loadMedia();

        return () => {
            isMounted = false;
            // Clear the URL when unmounting to free memory
            URL.revokeObjectURL(standardImageUrl);
        };
    }, [selectedMedia?.media_id, standardImageUrl, downloadStandardFile]);

    // Reset the standard image URL when selected media changes
    useEffect(() => {
        setStandardImageUrl(null);
    }, [selectedMedia?.media_id]);

    const getCurrentMediaIndex = () => {
        return collection.findIndex(media => media.media_id === selectedMedia?.media_id);
    };

    const handlePrevious = () => {
        const currentIndex = getCurrentMediaIndex();
        if (currentIndex > 0) {
            onNavigate(collection[currentIndex - 1]);
        }
    };

    const handleNext = () => {
        const currentIndex = getCurrentMediaIndex();
        if (currentIndex < collection.length - 1) {
            onNavigate(collection[currentIndex + 1]);
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'ArrowLeft') {
                handlePrevious();
            } else if (event.key === 'ArrowRight') {
                handleNext();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [selectedMedia, collection]);

    const currentIndex = getCurrentMediaIndex();
    const showLeftArrow = currentIndex > 0;
    const showRightArrow = currentIndex < collection.length - 1;

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    if (!selectedMedia) {
        return null;
    }
    
    return (
        <Box
            onClick={handleBackdropClick}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                backgroundColor: 'rgba(0, 0, 0, .25)'
            }}
        >
            {/* Navigation Columns */}
            {showLeftArrow && (
                <Box
                    onClick={handlePrevious}
                    sx={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        width: '120px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'fixed',
                            left: '50px',
                            color: 'white',
                            fontSize: '4rem'
                        }}
                    >
                        <ChevronLeft
                            sx={{
                                width: 60,
                                height: 60
                            }}
                        />
                    </IconButton>
                </Box>
            )}

            <Box
                sx={{
                    maxWidth: '90%',
                    maxHeight: '80vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    mx: '120px'
                }}
            >
                {/* Loading State */}
                {(isLoading || isStandardDownloading) && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <CircularProgress sx={{ color: 'white' }} />
                    </Box>
                )}

                {/* Error State */}
                {(error || standardDownloadError) && (
                    <Typography sx={{ color: 'error.main' }}>
                        Failed to load media: {(error || standardDownloadError).message}
                    </Typography>
                )}

                {/* Media Content */}
                {!isLoading && !isStandardDownloading && !error && !standardDownloadError && standardImageUrl && (
                    <>
                        {isImage && (
                            <img
                                src={standardImageUrl}
                                alt={selectedMedia.media_name}
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '80vh',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                        {isVideo && (
                            <video
                                controls
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '80vh'
                                }}
                            >
                                <source src={standardImageUrl} type={selectedMedia.media_type} />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </>
                )}
            </Box>

            {showRightArrow && (
                <Box
                    onClick={handleNext}
                    sx={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        width: '120px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'fixed',
                            right: '50px',
                            color: 'white',
                            fontSize: '4rem'
                        }}
                    >
                        <ChevronRight
                            sx={{
                                width: 60,
                                height: 60
                            }}
                        />
                    </IconButton>
                </Box>
            )}

            {/* Media Info - remains the same */}
            {!isLoading && !error && (
                <Box
                    sx={{
                        marginTop: '20px',
                        color: 'white',
                        textAlign: 'center'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '18px',
                            fontWeight: '500'
                        }}
                    >
                        {selectedMedia?.media_name}
                    </Typography>
                    {selectedMedia?.media_description && (
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                marginTop: '8px',
                                opacity: 0.8
                            }}
                        >
                            {selectedMedia.media_description}
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default MediaViewer;