import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styles from './styles.module.css';
import { TEXT } from './constants';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthentication } from '../../../authentication/hooks/useAuthentication';

const NavigationLayout = () => {
    const { logout, isLoading } = useAuthentication();

    const handleLogout = () => {
        logout();
    };

    return (
        <div className={styles['navigation-bar-container']}>
            <nav>
                <div className={styles['nav-links']}>
                    <div className={styles['logo-container']}>
                        {TEXT.GROUPSHACK}
                    </div>
                    <NavLink
                        to="/home"
                        className={({ isActive }) => isActive ? styles.active : ''}
                    >
                        {TEXT.HOME}
                    </NavLink>
                    <NavLink
                        to="/groups"
                        className={({ isActive }) => isActive ? styles.active : ''}
                    >
                        {TEXT.MY_GROUPS}
                    </NavLink>
                    <NavLink
                        to="/events"
                        className={({ isActive }) => isActive ? styles.active : ''}
                    >
                        {TEXT.MY_EVENTS}
                    </NavLink>
                    <NavLink
                        to="/collections"
                        className={({ isActive }) => isActive ? styles.active : ''}
                    >
                        {TEXT.MY_COLLECTIONS}
                    </NavLink>
                </div>
                <div 
                    className={styles['logout-section']}
                    onClick={handleLogout}
                    role="button"
                    style={{ cursor: 'pointer' }}
                >
                    <span>{isLoading.logout ? 'Logging out...' : TEXT.LOGOUT}</span>
                    <LogoutIcon sx={{ fontSize: '16px' }} />
                </div>
            </nav>
            <main>
                <Outlet />
            </main>
        </div>
    );
};

export default NavigationLayout;