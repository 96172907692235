import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const usePersonStore = create(
    persist(
        (set) => ({
            people: [],
            person: null,
            setPeople: (newPeople) => set((state) => {
                const existingIds = new Set(state.people.map(p => p.person_id));
                const uniqueNewPeople = newPeople.filter(p => !existingIds.has(p.person_id));
                return {
                    people: [...state.people, ...uniqueNewPeople],
                    person: newPeople.find(p => p.person_id === localStorage.getItem('person_id')) || state.person
                };
            }),
            setPerson: (personData) => set({ person: personData }),
            clearPersons: () => set({ people: [], person: null })
        }),
        {
            name: 'person-storage'
        }
    )
);