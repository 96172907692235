export const TEXT = {
    SHAREABLE_UPLOAD_LINK: 'Shareable Upload Link',
    SHAREABLE_UPLOAD_LINK_DESCRIPTION: 'Provide a temporary 30 minute upload link to share with friends and family.',
    COPY_UPLOAD_URL: 'Copy Upload URL',
    URL_COPIED: 'URL copied',
    SEND_UPLOAD_LINK: 'Send Upload Link',
    SHARE_UPLOAD_LINK_BUTTON_TEXT: 'Share Upload Link'
};

export const URL_CONFIG = {
    PRODUCTION: {
        DOMAIN: 'https://www.groupshack.com',
        UPLOAD_PATH: '/upload',
        PUBLIC_PATH: '/public'
    },
    DEVELOPMENT: {
        // Development configuration will use API_URL from config
        UPLOAD_PATH: '/upload',
        PUBLIC_PATH: '/public'
    }
};