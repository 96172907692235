import { useState } from 'react';

/**
 * Hook to handle file upload validations and processing
 * 
 * @param {Object} options Configuration options
 * @param {string} options.acceptedTypes Comma-separated list of MIME types or file extensions
 * @param {Object} options.maxSizeConfig Size limits for different file types
 * @param {Function} options.onFileSelect Callback when files are selected and validated
 * @param {boolean} options.multiple Whether multiple file selection is allowed
 * @param {number} options.maxFiles Maximum number of files allowed in multiple mode
 * @returns {Object} Object containing error state and file handling functions
 */
export const useFileUpload = ({
    acceptedTypes,
    maxSizeConfig = {
        image: 20 * 1024 * 1024, // 20MB for images
        video: 100 * 1024 * 1024 // 100MB for videos
    },
    onFileSelect,
    multiple = false,
    maxFiles = 10
}) => {
    const [error, setError] = useState('');

    /**
     * Validates a single file against type and size constraints
     * 
     * @param {File} file The file object to validate
     * @returns {Object} Object with valid flag and error message if invalid
     */
    const validateFile = (file) => {
        if (!file) {
            return { valid: false, error: 'No file selected' };
        }

        // Determine file type category
        const isImage = file.type.startsWith('image/');
        const isVideo = file.type.startsWith('video/');
        
        // Get applicable max size
        const maxSize = isImage ? maxSizeConfig.image : 
                       isVideo ? maxSizeConfig.video : 
                       Math.max(maxSizeConfig.image, maxSizeConfig.video);

        // Validate file type
        if (acceptedTypes !== '*' && !file.type.match(new RegExp(acceptedTypes.split(',').join('|')))) {
            return {
                valid: false,
                error: `File type not accepted for ${file.name}`
            };
        }

        // Validate file size
        if (file.size > maxSize) {
            const sizeInMB = Math.floor(maxSize / 1024 / 1024);
            return {
                valid: false,
                error: `File ${file.name} exceeds ${sizeInMB}MB limit for ${isImage ? 'images' : 'videos'}`
            };
        }

        return { valid: true };
    };

    /**
     * Handler for a single file upload (backward compatibility)
     * 
     * @param {File} file The file to upload
     */
    const handleFile = (file) => {
        if (!file) {
            setError('No file selected');
            return;
        }

        const validationResult = validateFile(file);
        
        if (!validationResult.valid) {
            setError(validationResult.error);
            return;
        }

        setError('');
        onFileSelect?.([file]);
    };

    /**
     * Handler for multiple file uploads
     * 
     * @param {FileList|Array} files Collection of files to upload
     */
    const handleFiles = (files) => {
        if (!files || files.length === 0) {
            setError('No files selected');
            return;
        }

        // Convert FileList to array
        const filesArray = Array.from(files);
        
        // Check if too many files were selected
        if (filesArray.length > maxFiles) {
            setError(`Maximum ${maxFiles} files allowed. Only the first ${maxFiles} will be processed.`);
            // Continue with processing, but truncate the list
            filesArray.length = maxFiles;
        } else {
            setError('');
        }
        
        // Validate each file
        const invalidFiles = filesArray
            .map(file => {
                const result = validateFile(file);
                return { file, ...result };
            })
            .filter(result => !result.valid);
        
        if (invalidFiles.length > 0) {
            // Show the first error
            setError(invalidFiles[0].error);
            return;
        }

        setError('');
        onFileSelect?.(filesArray);
    };

    return {
        error,
        handleFile,   // For backward compatibility
        handleFiles,  // New method for multiple files
        validateFile  // Expose the validation function
    };
};