import _ from 'lodash';

export const sortCollectionsByYear = (groupInfo, allCollections) => {
    if (!groupInfo?.collections || !allCollections?.length) {
        return {};
    }

    // Get collection IDs from the group
    const groupCollectionIds = new Set(
        groupInfo.collections.map(collection => collection.collection_id)
    );

    // Filter collections that belong to this group
    const groupCollections = allCollections.filter(collection => 
        groupCollectionIds.has(collection.collection_id)
    );

    // Group collections by year
    const collectionsByYear = _.groupBy(groupCollections, (collection) => {
        const date = new Date(collection.collection_created_on_date);
        return date.getFullYear();
    });

    // Sort collections within each year by date (newest first)
    const sortedCollectionsByYear = _.mapValues(collectionsByYear, collections => 
        _.orderBy(collections, ['collection_created_on_date'], ['desc'])
    );

    // Sort years in descending order
    const sortedResult = {};
    Object.keys(sortedCollectionsByYear)
        .sort((a, b) => b - a) // Sort years in descending order
        .forEach(year => {
            sortedResult[year] = sortedCollectionsByYear[year];
        });

    return sortedResult;
};

// Helper function to get years in order
export const getSortedYears = (collectionsByYear) => {
    return Object.keys(collectionsByYear).sort((a, b) => b - a);
};