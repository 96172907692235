import styles from './styles.module.css';
import { TEXT } from './constants';

import { useNavigate } from 'react-router-dom';
import { Button, Box, InputAdornment, TextField } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const ResetPasswordComponent = () => {
    const navigate = useNavigate();

    return (
        <div className={styles['reset-password-container']}>
            <div className={styles['title-container']}>
                <div className={styles['title-text']}>
                    {TEXT.TITLE_NAME}
                </div>
                <div className={styles['title-description-text']}>
                    {TEXT.TITLE_DESCRIPTION}
                </div>
            </div>
            <div className={styles['reset-password-input-container']}>
                <Box
                    component="form"
                    sx={{ '& .MuiTextField-root': {
                            m: 1,
                            width: '444px',
                            height: '56px'
                        } 
                    }}
                    noValidate
                    autoComplete="off"
                >
                <div>
                    <TextField
                        id="outlined-basic"
                        label={TEXT.EMAIL_ADDRESS}
                        slotProps={{
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontSize: '17px',
                                    fontFamily: 'Wanted Sans'
                                }
                            },
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EmailIcon sx={{
                                            color: '#757575',
                                            fontSize: '19px',
                                            marginRight: '1.2px'
                                        }} />
                                    </InputAdornment>
                                ),
                            }
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="outlined-basic"
                        label={TEXT.VERIFICATION_CODE}
                        slotProps={{
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontSize: '17px',
                                    fontFamily: 'Wanted Sans'
                                }
                            }
                        }}
                    />
                </div>
                </Box>
                <div className={styles['reset-button-container']}>
                    <Button
                        variant="contained"
                        sx={{
                            width: '444px',
                            height: '42px',
                            margin: '8px',
                            textTransform: 'none',
                            backgroundColor: '#0357D7',
                            fontSize: '15px',
                            fontFamily: 'Wanted Sans',
                            '&:hover': {
                                backgroundColor: '#0357D7'
                            }
                        }}
                    >
                        {TEXT.RESET_PASSWORD}
                    </Button>
                    <Button
                        variant="text"
                        disableRipple
                        onClick={() => navigate('/auth/login')}
                        sx={{
                            width: '444px',
                            height: '42px',
                            margin: '4px 8px',
                            textTransform: 'none',
                            fontSize: '15px',
                            fontFamily: 'Wanted Sans',
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        {TEXT.BACK_TO_LOGIN}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordComponent;