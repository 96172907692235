export const formatFileSize = (bytes) => {
    if (!bytes || isNaN(bytes)) return '0 KB';
    
    const KB = 1024;
    const MB = KB * 1024;
    
    if (bytes < MB) {
        // For KB values, show one decimal place
        const sizeInKB = (bytes / KB).toFixed(1);
        return `${sizeInKB} KB`;
    } else {
        const mb = bytes / MB;
        // If less than 2 MB, show two decimal places for more precision
        if (mb < 2) {
            return `${mb.toFixed(2)} MB`;
        }
        // For larger files, show one decimal place
        return `${mb.toFixed(1)} MB`;
    }
};