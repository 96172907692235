import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { TEXT } from './constants';
import { DIALOG_TYPES, TYPES } from '../../../../types/types';
import { Box, Card, CardContent, Button, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import ListItem from '../list-item/ListItem';

const List = ({ 
    headerText, 
    contentType, 
    items, 
    showButtonOne, 
    showButtonTwo,
    buttonOneText, 
    buttonTwoText, 
    onButtonOneClick, 
    onButtonTwoClick,
    setActiveDialog
}) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const hasItems = items.length > 0;
    const atLeastThreeItems = items.length > 3;

    const handleItemClick = (itemId) => {
        if (contentType === TYPES.EVENT) {
            setActiveDialog({
                type: DIALOG_TYPES.VIEW_EVENT,
                data: { eventId: itemId }
            });
        }
        else if (contentType === TYPES.GROUP) {
            navigate(`/groups/${itemId}`);
        }
    };

    const renderActionButtons = () => {
        return (
            <div className={styles['actions']}>
                {showButtonOne && (
                    (headerText === 'Add Member') ? (
                        // Add Member button with special styling
                        <div className={styles['add-member-container']}>
                            <Button
                                disableRipple
                                onClick={() => onButtonOneClick()}
                                startIcon={
                                    <Box
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            backgroundColor: 'rgba(114, 187, 255, 0.20)',
                                            border: '1px solid #0357D7',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <AddIcon
                                            sx={{
                                                fontSize: 16,
                                                fill: '#0357D7',
                                                strokeWidth: '1px',
                                                stroke: '#0357D7'
                                            }}
                                        />
                                    </Box>
                                }
                                sx={{
                                    justifyContent: 'flex-start',
                                    padding: 0,
                                    paddingRight: '16px',
                                    marginLeft: 0, 
                                    textTransform: 'none',
                                    color: '#1976d2',
                                    height: '40px',
                                    '&:hover': {
                                        backgroundColor: 'transparent'
                                    },
                                    '& .MuiButton-startIcon': {
                                        marginLeft: 0,
                                        marginRight: '12px'
                                    }
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#0357D7',
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '24px'
                                    }}
                                >
                                    {buttonOneText}
                                </Typography>
                            </Button>
                        </div>
                    ) : (
                        // Standard button style for everything else including Upcoming Events
                        <Button
                            onClick={onButtonOneClick}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'stretch',
                                borderRadius: '4px',
                                border: '1px solid #0357D7',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                color: '#0357D7',
                                textTransform: 'none',
                                marginTop: '8px',
                                marginBottom: showButtonTwo ? '0px' : '10px',
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            {buttonOneText}
                        </Button>
                    )
                )}
                {showButtonTwo && (
                    <Button
                        disableRipple
                        onClick={onButtonTwoClick}
                        sx={{
                            display: 'flex',
                            padding: '6px 16px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '24px',
                            color: '#565656',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        {buttonTwoText}
                    </Button>
                )}
            </div>
        );
    };

    return (
        <Card
            elevation={0}
            sx={{
                display: 'flex',
                height: 'auto',
                width: '100%',
                padding: '16px',
                marginTop: '16px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
                boxSizing: 'border-box',
                maxWidth: '320px', // Adjusted to 320px
                overflow: 'visible'
            }}
        >
            <div className={styles['header']}>
                {headerText}
            </div>
            <CardContent
                className={styles['content']}
                sx={{
                    marginLeft: '-2px',
                    paddingTop: '8px',
                    width: '100%',
                    paddingBottom: '0 !important', // Override MUI's default padding
                    '&:last-child': {
                        paddingBottom: '0'
                    }
                }}
            >
                <div style={{ marginBottom: '4px' }}>
                {hasItems ? (
                    <>
                        <div className={`${styles['list']} ${expanded ? styles['list-expanded'] : styles['list-collapsed']}`}>
                            {items.map((item) => (
                                <ListItem 
                                    key={item.id}
                                    data={item}
                                    onClick={handleItemClick}
                                />
                            ))}
                        </div>

                        {atLeastThreeItems && (
                            <Button 
                                disableRipple
                                onClick={() => setExpanded(!expanded)}
                                sx={{
                                    alignSelf: 'flex-start',
                                    fontSize: '12px',
                                    color: '#565656',
                                    fontFamily: 'Wanted Sans',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    marginTop: '8px',
                                    marginLeft: '12px', // Aligned with list items
                                    marginBottom: '5px',
                                    textTransform: 'none',
                                    width: 'fit-content',
                                    padding: '0',
                                    '&:hover': {
                                        background: 'transparent'
                                    },
                                    minWidth: 'unset'
                                }}
                            >
                                {expanded ? TEXT.COLLAPSE : TEXT.EXPAND}
                            </Button>
                        )}
                    </>
                ) : (
                    <div className={styles['none-text']} style={{ marginBottom: '2px' }}>
                        {TEXT.NONE}
                    </div>
                )}
                </div>
                
                {/* Action buttons always rendered here with appropriate spacing */}
                <div>
                    {renderActionButtons()}
                </div>
            </CardContent>
        </Card>
    );
};

export default List;