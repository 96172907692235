export const TYPES = {
    ACCOUNT: 'account',
    PERSON: 'person',
    USER: 'user',
    GROUP: 'group',
    COLLECTION: 'collection',
    EVENT: 'event'
};

export const DIALOG_TYPES = {
    NONE: 'NONE',
    JOIN_GROUP: 'JOIN_GROUP',
    VIEW_EVENT: 'VIEW_EVENT',
    INVITE_MEMBERS: 'INVITE_MEMBERS',
    SHARE_UPLOAD_LINK: 'SHARE_UPLOAD_LINK'
};

export const MEDIA_DOWNLOAD_TYPES = {
    THUMBNAIL: 'thumbnail',
    STANDARD: 'standard',
    ORIGINAL: 'original'
};

export const EVENT_TYPES = {
    GROUP_CREATED: "group_created",
    GROUP_DELETED: "group_deleted",
    GROUP_UPDATED: "group_updated",
    USER_ADDED_TO_GROUP: "user_added_to_group",
    USER_REMOVED_FROM_GROUP: "user_removed_from_group",
    COLLECTION_CREATED: "collection_created",
    COLLECTION_DELETED: "collection_deleted",
    COLLECTION_UPDATED: "collection_updated",
    EVENT_CREATED: "event_created",
    EVENT_DELETED: "event_deleted",
    EVENT_UPDATED: "event_updated",
    USER_ADDED_TO_EVENT: "user_added_to_event",
    USER_REMOVED_FROM_EVENT: "user_removed_from_event"
};