import React, { useState } from 'react';
import styles from './styles.module.css';
import { TEXT } from './constants';
import { useAuthentication } from '../../../authentication/hooks/useAuthentication';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, TextField, Checkbox, FormControlLabel, Button, Divider } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const GoogleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 48 48">
      <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" />
      <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" />
      <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" />
      <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" />
    </svg>
);

const LoginComponent = () => {
    const navigate = useNavigate();
    const { login, isLoading, error } = useAuthentication();
    
    const [credentials, setCredentials] = useState({
        user_name: '',
        password: ''
    });

    const [rememberMe, setRememberMe] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleRememberMe = (e) => {
        setRememberMe(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('SUBMIT FOR LOGIN');
        console.log('CREDENTIALS:', credentials);
        await login(credentials);
    };

    return (
        <div className={styles['login-container']}>
            <div className={styles['title-container']}>
                <div className={styles['title-text']}>
                    {TEXT.TITLE_NAME}
                </div>
                <div className={styles['title-description-text']}>
                    {TEXT.TITLE_DESCRIPTION}
                </div>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div className={styles['login-input-container']}>

                        <TextField
                            id="user_name"
                            name="user_name"
                            value={credentials.user_name}
                            onChange={handleChange}
                            label={TEXT.USERNAME_LABEL}
                            slotProps={{
                                input: {
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '16px'
                                    }
                                },
                                inputLabel: {
                                    shrink: true,
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '17px'                                    }
                                }
                            }}
                            sx = {{
                                marginBottom: '16px',
                                width: '444px'
                            }}
                        />
                        <TextField
                            id="password"
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            label={TEXT.PASSWORD_LABEL}
                            type="password"
                            slotProps={{
                                input: {
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '16px'
                                    }
                                },
                                inputLabel: {
                                    shrink: true,
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '17px'                                    }
                                }
                            }}
                            sx = {{
                                width: '444px'
                            }}
                        />
                </div>
                <div className={styles['login-failed-container']}>
                    {error?.login && (
                        <Alert severity="error" sx={{
                            mt: 1,
                            width: '444px'
                            }}
                        >
                            {TEXT.LOGIN_FAILED}
                        </Alert>
                    )}
                </div>
                <div className={styles['login-assistance-input-container']}>
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={rememberMe}
                                onChange={handleRememberMe}
                            />
                        }
                        label={TEXT.REMEMBER_ME}
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '13px',
                                fontFamily: 'Wanted Sans'
                            }
                        }}
                    />
                    <a href="#" onClick={() => navigate('/auth/reset-password')}>
                        {TEXT.FORGOT_PASSWORD}
                    </a>
                </div>

                <div className={styles['login-button-container']}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoading?.login}
                        sx={{
                            width: '444px',
                            height: '42px',
                            margin: '5px',
                            textTransform: 'none',
                            backgroundColor: '#0357D7',
                            fontSize: '15px',
                            fontFamily: 'Wanted Sans',
                            '&:hover': {
                                backgroundColor: '#0357D7'
                            }
                        }}
                    >
                        {isLoading?.login ? TEXT.SIGNING_IN : TEXT.LOGIN}
                    </Button>
                </div>
                </Box>

                <div className={styles['divider-container']}>
                    <Divider sx={{ flexGrow: 1 }} />
                    <span className={styles['divider-text']}>{TEXT.OR}</span>
                    <Divider sx={{ flexGrow: 1 }} />
                </div>

                <div className={styles['signup-button-container']}>
                    <Button
                        variant="outlined"
                        startIcon={<EmailIcon />}
                        onClick={() => navigate('/auth/signup')}
                        sx={{
                            marginBottom: '16px',
                            textTransform: 'none !important',
                            fontFamily: 'Wanted Sans',
                            fontSize: '15px',
                            width: '444px',
                            gap: '4px',
                            color: 'black',
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            '&:hover': {
                                border: '1px solid rgba(0, 0, 0, 0.87)'
                            }
                        }} 
                    >
                        {TEXT.SIGNUP_EMAIL}
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<GoogleIcon />}
                        sx={{
                            textTransform: 'none !important',
                            width: '444px',
                            fontFamily: 'Wanted Sans',
                            gap: '4px',
                            color: 'black',
                            fontSize: '15px',
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            '&:hover': {
                                border: '1px solid rgba(0, 0, 0, 0.87)'
                            }
                        }} 
                    >
                        {TEXT.SIGNUP_GMAIL}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;