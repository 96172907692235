import { useState } from 'react';
import { 
    Box, 
    Typography, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    IconButton, 
    TextField, 
    Button,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TEXT } from './constants';

export const UserInviteDialog = ({
    open,
    onClose,
    onAddMembers,
    userId,
    groupId
}) => {
    const [email, setEmail] = useState('');
    const [emails, setEmails] = useState([]);
    const [error, setError] = useState('');

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError('');
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addEmail();
        }
    };

    const addEmail = () => {
        const trimmedEmail = email.trim();
        
        if (!trimmedEmail) {
            return;
        }

        if (!emailRegex.test(trimmedEmail)) {
            setError('Please enter a valid email address');
            return;
        }

        if (emails.includes(trimmedEmail)) {
            setError('This email has already been added');
            return;
        }

        setEmails([...emails, trimmedEmail]);
        setEmail('');
        setError('');
    };

    const removeEmail = (emailToRemove) => {
        setEmails(emails.filter(email => email !== emailToRemove));
    };

    const handleAddMembers = () => {
        if (onAddMembers && emails.length > 0) {
            onAddMembers(emails);
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    display: 'flex',
                    width: '100%',
                    padding: '32px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexShrink: '0',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    backgroundColor: '#FFF'
                }
            }}
        >
            <DialogTitle
                sx={{
                    alignSelf: 'stretch'
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '26px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        letterSpacing: '-1px',
                    }}
                >
                    {TEXT.INVITE_MEMBERS}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        zIndex: '1',
                        backgroundColor: '#565656 !important',
                        padding: '1px !important'
                    }}
                >
                    <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
                <Typography
                    sx={{
                        marginTop: '8px',
                        color: '#565656',
                        fontFamily: 'Wanted Sans',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.INVITE_MEMBERS_DESCRIPTION}
                </Typography>
            </DialogTitle>

            <DialogContent 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch'
                }}
            >
                <Box
                    sx={{
                        marginTop: '22px',
                        width: '100%'
                    }}
                >
                <TextField
                    label={ TEXT.ADD_MEMBERS }
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    onKeyPress={handleKeyPress}
                    placeholder="email@address.com"
                    sx={{
                        '& .MuiInputBase-input': {
                            fontFamily: 'Wanted Sans',
                            fontSize: '15px'
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: 'Wanted Sans',
                            fontWeight: '400',
                            fontStyle: 'normal',
                            '&.Mui-focused': {
                                color: '#000000'
                            }                
                        }
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontStyle: 'Wanted Sans',
                                fontSize: '17px',
                            }
                        }
                    }}
                />
                </Box>

                {/* Email Chips */}
                <Box sx={{ 
                    marginTop: '17px',
                    display: 'flex', 
                    gap: 1, 
                    flexWrap: 'wrap', 
                    width: '100%'
                }}>
                    {emails.map((email, index) => (
                        <Chip
                            key={index}
                            label={email}
                            onDelete={() => removeEmail(email)}
                            deleteIcon={<CloseIcon />}
                            sx={{
                                display: 'flex',
                                padding: '4px',
                                alignItems: 'center',
                                borderRadius: '100px',
                                fontSize: '15px'
                            }}
                        />
                    ))}
                </Box>

                <Button
                    fullWidth
                    onClick={handleAddMembers}
                    disabled={emails.length === 0}
                    sx={{
                        marginTop: '28px',
                        backgroundColor: '#0357D7',
                        borderRadius: '4px',
                        padding: '12px',
                        '&:hover': {
                            backgroundColor: '#0357D7'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            color: '#FFF',
                            fontFamily: 'Wanted Sans',
                            fontSize: '16px',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textTransform: 'none'
                        }}
                    >
                        { TEXT.ADD_MEMBERS }
                    </Typography>
                </Button>
            </DialogContent>
        </Dialog>
    );
};