import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useAuthenticationStore = create(
    persist(
        (set) => ({
            isAuthenticated: false,
            login: () => set({ isAuthenticated: true }),
            logout: () => {
                // Force a clean slate on logout
                set({ isAuthenticated: false }, true);
                
                // Additional cleanup to prevent stale state
                try {
                    localStorage.removeItem('authentication-storage');
                } catch (e) {
                    console.error('Error clearing authentication storage:', e);
                }
            }
        }),
        {
            name: 'authentication-storage',
            // Add version for better cache busting
            version: 1,
            // Use a custom storage that handles errors
            storage: createJSONStorage(() => ({
                getItem: (name) => {
                    try {
                        return localStorage.getItem(name);
                    } catch (e) {
                        console.error(`Error getting storage ${name}:`, e);
                        return null;
                    }
                },
                setItem: (name, value) => {
                    try {
                        localStorage.setItem(name, value);
                    } catch (e) {
                        console.error(`Error setting storage ${name}:`, e);
                    }
                },
                removeItem: (name) => {
                    try {
                        localStorage.removeItem(name);
                    } catch (e) {
                        console.error(`Error removing storage ${name}:`, e);
                    }
                }
            })),
            // Ensure only what we need is persisted
            partialize: (state) => ({
                isAuthenticated: state.isAuthenticated
            })
        }
    )
);