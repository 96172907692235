import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useShareableLinkStore } from '../../store/shareableLinkStore';
import { TEXT } from './constants';

export const PublicMediaFileUploadConfirmation = ({ uploadedMedia, onUploadMore }) => {
    const { shareableToken } = useShareableLinkStore();
    const collectionName = shareableToken.collectionName;

    return (
        <Box
            sx={{
                display: 'flex',
                width: {
                    xs: '95%', // Mobile
                    sm: '80%', // Tablet
                    md: '65%', // Small Desktop
                    lg: '47%'  // Large Desktop
                },
                padding: {
                    xs: '20px', // Mobile
                    sm: '30px', // Tablet
                    md: '55px'  // Desktop
                },
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.03)'
            }}
        >
            {/* Left arrow icon removed */}
            
            <Typography
                sx={{
                    color: '#000',
                    textAlign: 'center',
                    fontSize: {
                        xs: '22px', // Mobile
                        sm: '26px', // Tablet
                        md: '29px'  // Desktop
                    },
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '150%',
                    fontFamily: 'Wanted Sans'
                }}
            >
                {uploadedMedia.length > 0 
                    ? TEXT.UPLOAD_CONFIRMATION_DIALOG_HEADER 
                    : TEXT.EMPTY_STATE_HEADER}
            </Typography>

            <Typography
                sx={{
                    marginTop: '7px',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: {
                        xs: '16px', // Mobile
                        sm: '18px', // Tablet
                        md: '20px'  // Desktop
                    },
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '150%',
                    fontFamily: 'Wanted Sans'
                }}
            >
                {uploadedMedia.length > 0 
                    ? TEXT.UPLOAD_CONFIRMATION_DIALOG_DESCRIPTION 
                    : TEXT.EMPTY_STATE_DESCRIPTION}
            </Typography>

            <Box
                sx={{
                    marginTop: '8px',
                    width: '100%',
                    maxHeight: {
                        xs: uploadedMedia.length > 10 ? '400px' : 'auto', // Mobile
                        sm: uploadedMedia.length > 15 ? '450px' : 'auto', // Tablet
                        md: uploadedMedia.length > 15 ? '500px' : 'auto'  // Desktop
                    },
                    overflowY: uploadedMedia.length > 15 ? 'auto' : 'visible',
                    overflowX: 'hidden',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none'
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: 'repeat(2, 1fr)', // Mobile: 2 items per row
                            sm: 'repeat(3, 1fr)', // Tablet: 3 items per row
                            md: 'repeat(4, 1fr)', // Desktop: 4 items per row
                            lg: 'repeat(5, 1fr)'  // Large Desktop: 5 items per row
                        },
                        gap: {
                            xs: '10px', // Mobile: smaller gap
                            sm: '12px', // Tablet
                            md: '16px'  // Desktop
                        },
                        padding: '16px 0'
                    }}
                >
                    {uploadedMedia.map((mediaItem, index) => (
                        <Box
                            key={mediaItem.media_id || `preview-${index}`}
                            sx={{
                                width: '100%',
                                aspectRatio: '1',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                backgroundColor: '#f0f0f0'
                            }}
                        >
                            {mediaItem.thumbnailUrl ? (
                                <img
                                    src={mediaItem.thumbnailUrl}
                                    alt={`Upload ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '8px'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: '10px', // Mobile: smaller text
                                                sm: '12px'  // Tablet and Desktop
                                            },
                                            color: '#666',
                                            textAlign: 'center',
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {mediaItem.media_name || 'Uploaded file'}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
            </Box>

            <Box sx={{ 
                width: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: {
                    xs: '12px', // Mobile: smaller gap
                    sm: '16px'  // Tablet and Desktop
                }, 
                marginTop: {
                    xs: '10px', // Mobile
                    sm: '13px'  // Tablet and Desktop
                }
            }}>
                <Button
                    onClick={() => window.location.href = '/auth/signup'}
                    disableRipple
                    sx={{
                        height: {
                            xs: '45px', // Mobile
                            sm: '50px'  // Tablet and Desktop
                        },
                        padding: '6px 16px',
                        borderRadius: '4px',
                        background: '#0357D7',
                        textTransform: 'none',
                        '&:hover': {
                            background: '#0245AC'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            color: '#FFF',
                            fontSize: {
                                xs: '16px', // Mobile
                                sm: '18px'  // Tablet and Desktop
                            },
                            fontWeight: '500',
                            lineHeight: '24px',
                            fontFamily: 'Wanted Sans'
                        }}
                    >
                        {TEXT.SIGNUP_BUTTON_TEXT}
                    </Typography>
                </Button>

                <Button
                    onClick={onUploadMore}
                    disableRipple
                    sx={{
                        height: {
                            xs: '45px', // Mobile
                            sm: '50px'  // Tablet and Desktop
                        },
                        padding: '6px 16px',
                        borderRadius: '4px',
                        background: '#FFF',
                        border: '1px solid #0357D7',
                        textTransform: 'none',
                        '&:hover': {
                            background: '#F5F5F5'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            color: '#0357D7',
                            fontSize: {
                                xs: '16px', // Mobile
                                sm: '18px'  // Tablet and Desktop
                            },
                            fontWeight: '500',
                            lineHeight: '24px',
                            fontFamily: 'Wanted Sans'
                        }}
                    >
                        {TEXT.GO_BACK_BUTTON_TEXT}
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
};