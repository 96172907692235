import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';


const SwitchContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'backgroundColor'
})(({ theme, size = 'medium', backgroundColor }) => {
    const sizes = {
        small: { width: 110, height: 26 },
        medium: { width: 192, height: 40 },
        large: { width: 240, height: 48 }
    };

    return {
        position: 'relative',
        width: sizes[size].width,
        height: sizes[size].height,
        backgroundColor: backgroundColor || theme.palette.grey[100],
        borderRadius: sizes[size].height / 2,
        cursor: 'pointer',
        overflow: 'hidden',
        display: 'inline-flex',
        userSelect: 'none',
    };
});

const SliderBackground = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'activeColor'
})(({ theme, activeColor }) => ({
    position: 'absolute',
    width: '50%',
    height: '100%',
    backgroundColor: activeColor || theme.palette.primary.main,
    borderRadius: 'inherit',
    transition: 'transform 0.3s ease',
}));

const IconContainer = styled(Box)({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    zIndex: 1,
});

const IconWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'size'
})(({ theme, size = 'medium' }) => {
    const sizes = {
        small: { iconSize: 16, fontSize: 12 },
        medium: { iconSize: 24, fontSize: 14 },
        large: { iconSize: 28, fontSize: 16 }
    };

    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        height: '100%',
        transition: 'color 0.3s ease',
        gap: theme.spacing(1),
        '& svg': {
            fontSize: sizes[size].iconSize,
        },
        '& .MuiTypography-root': {
            fontSize: sizes[size].fontSize,
        }
    };
});

const PillSwitch = ({
    // State props
    onChange = () => { },
    defaultChecked = false,

    // Customization props
    size = 'medium',
    backgroundColor,
    activeColor,
    activeTextColor = '#fff',
    inactiveTextColor = '#666',

    // Content props
    leftIcon: LeftIcon = ReorderOutlinedIcon,
    rightIcon: RightIcon = AppsOutlinedIcon,
    leftLabel,
    rightLabel,

    // Accessibility props
    ariaLabel = 'toggle view',

    // Custom styles
    sx
}) => {
    const [isRightActive, setIsRightActive] = useState(defaultChecked);

    const handleClick = () => {
        const newValue = !isRightActive;
        setIsRightActive(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <SwitchContainer
            onClick={handleClick}
            size={size}
            backgroundColor={backgroundColor}
            sx={sx}
            role="switch"
            aria-checked={isRightActive}
            aria-label={ariaLabel}
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleClick();
                }
            }}
        >
            <SliderBackground
                activeColor={activeColor}
                sx={{
                    transform: isRightActive ? 'translateX(100%)' : 'translateX(0)',
                }}
            />
            <IconContainer>
                <IconWrapper
                    size={size}
                    sx={{
                        color: !isRightActive ? activeTextColor : inactiveTextColor,
                    }}
                >
                    <LeftIcon />
                    {leftLabel && (
                        <Typography variant="body2" component="span">
                            {leftLabel}
                        </Typography>
                    )}
                </IconWrapper>
                <IconWrapper
                    size={size}
                    sx={{
                        color: isRightActive ? activeTextColor : inactiveTextColor,
                    }}
                >
                    <RightIcon />
                    {rightLabel && (
                        <Typography variant="body2" component="span">
                            {rightLabel}
                        </Typography>
                    )}
                </IconWrapper>
            </IconContainer>
        </SwitchContainer>
    );
};

export default PillSwitch;