export const eventDateSorter = (events) => {
    // Handle invalid input
    if (!Array.isArray(events)) {
        throw new Error('Input must be an array of events');
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Initialize buckets
    const sortedEvents = {
        upcomingEvents: [],
        pastEvents: []
    };

    // Sort events into appropriate buckets
    events.forEach(event => {
        if (!event?.event_date) {
            console.warn('Event missing event_date property:', event);
            return;
        }

        const eventDate = new Date(event.event_date);
        eventDate.setHours(0, 0, 0, 0);

        if (eventDate >= today) {
            sortedEvents.upcomingEvents.push(event);
        } else {
            sortedEvents.pastEvents.push(event);
        }
    });

    // Sort upcoming events by nearest date first
    sortedEvents.upcomingEvents.sort((a, b) => 
        new Date(a.event_date) - new Date(b.event_date)
    );

    // Sort past events by most recent first
    sortedEvents.pastEvents.sort((a, b) => 
        new Date(b.event_date) - new Date(a.event_date)
    );

    return sortedEvents;
};