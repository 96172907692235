import { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, IconButton, CircularProgress } from '@mui/material';
import { TEXT, URL_CONFIG } from './constants';
import { useMedia } from '../../../media/hooks/useMedia';
import CloseIcon from '@mui/icons-material/Close';
import EmailInput from './EmailInput';
import { API_URL } from '../../../../services/api/config';

export const CollectionShareableLinkDialog = ({
    open,
    onClose,
    userId,
    groupId,
    collectionId
}) => {
    const [shareableLink, setShareableLink] = useState('');
    const [urlCopied, setUrlCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    // Ref to track if we've already fetched data in this dialog session
    const hasFetchedRef = useRef(false);
    // Track if component is currently mounted - initialize to true
    const isMounted = useRef(true);

    const { fetchShareableLink } = useMedia();
    
    // Reset when component unmounts
    useEffect(() => {
        isMounted.current = true;
        console.log('Component mounted');
        
        return () => {
            console.log('Component unmounting');
            isMounted.current = false;
        };
    }, []);

    // Reset state when dialog is opened
    useEffect(() => {
        if (open) {
            console.log('Dialog opened, resetting state');
            setIsLoading(false);
            setError(null);
            hasFetchedRef.current = false;
        }
    }, [open]);
    
    /**
     * Creates a shareable upload URL based on the environment
     */
    const createShareableUploadUrl = useCallback((proxyLink) => {
        console.log('Creating shareable URL with proxyLink:', proxyLink);
        if (!proxyLink) return '';
        
        // Remove leading/trailing slashes if present
        const cleanProxyLink = proxyLink.replace(/^\/+|\/+$/g, '');
        
        // Check if we're in production or development environment
        const isProduction = process.env.NODE_ENV === 'production';
        
        if (isProduction) {
            const url = `${URL_CONFIG.PRODUCTION.DOMAIN}${URL_CONFIG.PRODUCTION.PUBLIC_PATH}${URL_CONFIG.PRODUCTION.UPLOAD_PATH}/${cleanProxyLink}`;
            console.log('Created production URL:', url);
            return url;
        } else {
            const url = `${API_URL}${URL_CONFIG.DEVELOPMENT.PUBLIC_PATH}${URL_CONFIG.DEVELOPMENT.UPLOAD_PATH}/${cleanProxyLink}`;
            console.log('Created development URL:', url);
            return url;
        }
    }, []);

    // Fetch shareable link with better state tracking
    const fetchLink = useCallback(async () => {
        if (!open) {
            console.log('Not fetching - dialog is closed');
            return;
        }
        
        if (hasFetchedRef.current) {
            console.log('Not fetching - already fetched in this session');
            return;
        }
        
        console.log('Fetching shareable link started');
        setIsLoading(true);
        setError(null);
        hasFetchedRef.current = true;
        
        try {
            console.log('Making API call for shareable link');
            const shareableUrl = await fetchShareableLink(userId, groupId, collectionId);
            console.log('API response received:', shareableUrl);
            
            // Double-check we're still mounted and dialog is still open
            if (!isMounted.current || !open) {
                console.log('Not updating state - component unmounted or dialog closed');
                return;
            }
            
            if (shareableUrl && shareableUrl.proxy_link) {
                console.log('Generating URL from proxy_link:', shareableUrl.proxy_link);
                const link = createShareableUploadUrl(shareableUrl.proxy_link);
                console.log('Setting shareable link to:', link);
                setShareableLink(link);
            } else {
                console.error('Invalid response - missing proxy_link:', shareableUrl);
                throw new Error('Invalid response format');
            }
        } catch (err) {
            console.error('Error in fetch:', err);
            
            if (isMounted.current && open) {
                console.log('Setting error state');
                setError(err.message || 'Failed to generate link');
            }
        } finally {
            if (isMounted.current && open) {
                console.log('Setting loading state to false');
                setIsLoading(false);
            }
        }
    }, [open, userId, groupId, collectionId, fetchShareableLink, createShareableUploadUrl]);

    // Start fetch when dialog is opened
    useEffect(() => {
        console.log('Fetch effect triggered, open:', open, 'hasFetched:', hasFetchedRef.current);
        
        if (open && !hasFetchedRef.current && !isLoading) {
            console.log('Initiating fetch from effect');
            fetchLink();
        }
    }, [open, fetchLink, isLoading]);
    
    // Reset the copied state when dialog closes
    const handleClose = () => {
        console.log('Dialog close handler called');
        setUrlCopied(false);
        onClose();
    };

    // Allow users to retry if there was an error
    const handleRetry = () => {
        console.log('Retry button clicked');
        hasFetchedRef.current = false;
        fetchLink();
    };

    console.log('Rendering component, current states:', { 
        shareableLink, 
        isLoading, 
        hasError: !!error, 
        open 
    });

    return (
        <Dialog
            open={open} 
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    backgroundColor: '#FFF',
                    padding: '32px'
                }
            }}
        >
            <DialogTitle>
                <Typography
                    sx={{
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        letterSpacing: '-1px'
                    }}
                >
                    {TEXT.SHAREABLE_UPLOAD_LINK}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        zIndex: '1',
                        backgroundColor: '#565656 !important',
                        padding: '1px !important'
                    }}
                >
                    <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography
                        sx={{
                            color: '#565656',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal'
                        }}
                    >
                        {TEXT.SHAREABLE_UPLOAD_LINK_DESCRIPTION}
                    </Typography>
                    
                    {isLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                            <CircularProgress size={24} />
                        </Box>
                    ) : error ? (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography
                                sx={{
                                    color: '#ff5252',
                                    fontSize: '14px',
                                    mb: 2
                                }}
                            >
                                Unable to generate shareable link.
                            </Typography>
                            <Box 
                                component="button"
                                onClick={handleRetry}
                                sx={{
                                    backgroundColor: '#4a4a4a',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    padding: '8px 16px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#2a2a2a'
                                    }
                                }}
                            >
                                Try Again
                            </Box>
                        </Box>
                    ) : (
                        <EmailInput
                            groupUrl={shareableLink}
                            urlCopied={urlCopied}
                            setUrlCopied={setUrlCopied}
                            label="Shareable Link"
                            placeholder="https://..."
                            showCopy={!!shareableLink}
                            disabled={!shareableLink}
                        />
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};