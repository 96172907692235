export const TEXT = {
    ACTIVITY: 'Activity',
    MY_GROUPS: 'My Groups',
    MY_EVENTS: 'My Events',
    MY_COLLECTIONS: 'My Collection',
    UPCOMING_EVENTS: 'Upcoming Events',

    HOME: 'home',
    FEED: 'feed',
    GO_BACK: 'go back',

    // User Group List
    JOIN_GROUP: 'Join Group',
    CREATE_GROUP: '+ Create Group',
    
    // User Event List
    VIEW_EVENT: 'View Events',
    CREATE_EVENT: '+ Create Event',  // Changed to match List.js constants

    // Members
    ADD_MEMBER: 'Add Member',
    
    // General
    VIEW_MORE: 'view more',
    COLLAPSE: 'collapse', 
    NONE: 'None'
};

export const VIEW_STATES = {
    HOME: 'home',
    GROUPS: 'groups',
    LIST: 'list',
    DETAIL: 'detail',
    CREATE_GROUP: 'create_group',
    CREATE_COLLECTION: 'create_collection',
    CREATE_EVENT: 'create_event',
    EDIT: 'edit'
};