export const TEXT = {
    TITLE_NAME: 'Signup',
    TITLE_DESCRIPTION: 'Create your account',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    BIRTHDAY: 'Birthday',
    EMAIL_ADDRESS: 'Email Address',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    REPEAT_PASSWORD: 'Repeat Password',
    I_AGREE: 'I agree to the',
    TERMS_AND_CONDITIONS: 'Terms & Conditions',
    CREATE_ACCOUNT: 'Create Account',
    BACK_TO_LOGIN: 'Back to Login',
    CLOSE_TERMS_AND_CONDITIONS: 'Close',
    REQUIRE_FIELD_ANNOTATION: '* Required Fields',
    CITY: 'City',
    COUNTRY: 'Country',
    TERMS_REQUIRED: 'required',
    TERMS_TOOLTIP: 'You must accept the Terms and Conditions to create an account',
    ACCEPT_AND_CLOSE: 'Accept & Close',
    BACK_TO_ALL_SECTIONS: 'Back to All Sections',
    TABLE_OF_CONTENTS: 'Table of Contents',
    LAST_UPDATED: 'Last Updated',
    EFFECTIVE_DATE: 'Effective Date'
};