import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Clock } from 'lucide-react';
import { TEXT } from './constants';

import sample_image_1 from '../../../../assets/images/landing/sample_1.jpg';
import sample_image_2 from '../../../../assets/images/landing/sample_2.jpg';
import sample_image_3 from '../../../../assets/images/landing/sample_3.jpg';

const AppPreviewComponent = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const images = [
      {
        src: sample_image_1,
        title: "Amazing 10-day trip in Honolulu",
        subtitle: "48 images, 6 videos uploaded by Noah",
        uploadTime: "5 minutes ago"
      },
      {
        src: sample_image_2,
        title: "Day trip to Makaapu Lighthouse",
        subtitle: "20 images, 6 videos uploaded by Ryan",
        uploadTime: "50 minutes ago"
      },
      {
        src: sample_image_3,
        title: "Group hike to Pillbox",
        subtitle: "16 images, 1 videos uploaded by Christina",
        uploadTime: "3 hours ago"
      },
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentImageIndex((prevIndex) => 
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          );
    }, 10000);

       // Cleanup interval on component unmount
       return () => clearInterval(intervalId);
    }, [images.length]);

    return (
        <div className={styles['app-preview-container']}>
            <div className={styles['image-container']}>
                <img
                    src={images[currentImageIndex].src}
                    alt={`Preview ${currentImageIndex + 1}`}
                    className={styles['image']}
                />
                <div className={styles['overlay-container']}>
                    <div className={styles['upload-info']}>
                        <Clock size={16} />
                        {TEXT.UPLOADED} {images[currentImageIndex].uploadTime}
                    </div>
                    <div className={styles['title-text']}>
                        {images[currentImageIndex].title}
                    </div>
                    <div className={styles['subtitle-text']}>
                        {images[currentImageIndex].subtitle}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppPreviewComponent;