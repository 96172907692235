import { Box, Typography } from '@mui/material';

export const FeedItem = ({
    logo,
    description,
    lastUpdated
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                padding: '20px',
                alignItems: 'center',
                flexDirection: 'row',
                borderRadius: '16px',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                transition: 'all 0.3s ease',
            }}
        >
            <Box
                sx={{
                    width: 56,
                    height: 56,
                    borderRadius: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.07)',
                    transition: 'opacity 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                        opacity: 0.85,
                    }
                }}
            >
                {logo}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '18px'
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '19px',
                        fontWeight: '570'
                    }}
                >
                    {description}
                </Typography>
                <Typography
                    sx={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontSize: '12px',
                    }}
                >
                    {lastUpdated}
                </Typography>
            </Box>
        </Box>
    );
};