import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { signupApi } from '../services/signupApi';

export const useSignup = () => {
    const navigate = useNavigate();

    const signupMutation = useMutation({
        mutationFn: signupApi.signup,
        onSuccess: (response) => {
            console.log('Signup Response');
            console.log(response);
            navigate('/auth/login');
        }
    })

    return {
        signup: signupMutation.mutate
    };
}