import { useMutation } from "@tanstack/react-query";
import { personApi } from "../services/personApi";

export const usePerson = () => {
    const updatePersonMutation = useMutation({
        mutationFn: async(personFormData) => {
            const requestPayload = {
                person_id: personFormData.personId,
                first_name: personFormData.firstName,
                last_name: personFormData.lastName,
                email_address: personFormData.emailAddress,
                phone_number: personFormData.phoneNumber
            };

            // Only include non-empty fields in the request
            Object.keys(requestPayload).forEach(key => {
                if (!requestPayload[key]) {
                    delete requestPayload[key];
                }
            });

            const response = await personApi.updatePerson(requestPayload);
            return response.data;
        },
        onSuccess: () => {
            console.log('Successfully updated Person details');
        },
        onError: (error) => {
            console.error(`Failed to update Person details with error:`, error);
        }
    });

    return {
        updatePerson: updatePersonMutation.mutate,
        updatePersonAsync: updatePersonMutation.mutateAsync,
        isUpdatePersonLoading: updatePersonMutation.isPending,
        errorUpdatePerson: updatePersonMutation.error
    }
};