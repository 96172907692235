import styles from './styles.module.css';
import { Outlet } from 'react-router-dom';

import AppPreviewComponent from './components/app-preview/AppPreviewComponent';

const LandingPage = () => {
    return (
        <div className={styles['landing-page']}>
            <div className={styles['left-section']}>
                <AppPreviewComponent key='app-preview' />
            </div>
            <div className={styles['right-section']}>
                <Outlet />
            </div>
        </div>
    )
}

export default LandingPage;