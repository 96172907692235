import styles from './styles.module.css';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

const ListItem = ({ data, onClick }) => {
    return (
        <div 
            className={styles['list-item-container']}
            onClick={() => onClick(data.id)}
            style={{ cursor: 'pointer' }}
        >
            <Avatar
                src={data.profileImage}
                alt={data.name}
                sx={{ width: 40, height: 40 }}
            />
            <div className={styles['item-text-container']}>
                <span className={styles['item-name']}>
                    {data.name}
                </span>
                <div className={styles['item-description']}>
                {typeof data.description === 'object' && data.description !== null ? (
                    <div className={styles['description-stack']}>
                        <div>{data.description.date}</div>
                        <div>{data.description.group}</div>
                    </div>
                ) : (
                    data.description
                )}
                </div>
            </div>
        </div>
    );
};

ListItem.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        information: PropTypes.string
    }).isRequired,
    onClick: PropTypes.func.isRequired
};

export default ListItem;