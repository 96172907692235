const mimeMap = {
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'png': 'image/png',
    'mp4': 'video/mp4',
    'heic': 'image/heic',
    'heif': 'image/heif',
    'webp': 'image/webp',
    'mov': 'video/quicktime'
};

export const getMediaTypeCounts = (mediaArray) => {
    const counts = {
        image: 0,
        video: 0,
        unknown: 0
    };

    mediaArray.forEach(media => {
        const mediaType = media.media_type?.toLowerCase() || '';
        
        // First check if it starts with image/ or video/
        if (mediaType.startsWith('image/')) {
            counts.image++;
        } else if (mediaType.startsWith('video/')) {
            counts.video++;
        } else {
            // If not, check the file extension
            const fileName = media.file_name?.toLowerCase() || '';
            const fileExtension = fileName.split('.').pop();
            
            if (fileExtension in mimeMap) {
                const mimeType = mimeMap[fileExtension];
                if (mimeType.startsWith('image/')) {
                    counts.image++;
                } else if (mimeType.startsWith('video/')) {
                    counts.video++;
                } else {
                    counts.unknown++;
                }
            } else {
                counts.unknown++;
            }
        }
    });

    return counts;
};