import { useState } from 'react';
import EventList from './components/event-list/EventList';
import { Box, Typography } from '@mui/material';
import { TEXT } from './constants';
import styles from './styles.module.css';
import { ViewEventDialog } from './components/view-event-dialog/ViewEventDialog';
import { eventDateSorter } from './utils/EventDateSorter';
import { useEventStore } from './store/eventStore';

const EventPage = () => {
    const [showEventDialog, setShowEventDialog] = useState({
        show: false,
        eventId: null
    });

    const { events } = useEventStore();

    const sortedEvents = eventDateSorter(events);

    const upcomingEvents = sortedEvents.upcomingEvents;
    const pastEvents = sortedEvents.pastEvents;

    const numUpcomingEvents = upcomingEvents.length;
    const numPreviousEvents = pastEvents.length;

    const closeEventDialog = () => {
        setShowEventDialog({
            show: false,
            eventId: null
        });
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    padding: '32px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '32px',
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    backgroundColor: '#FFF'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        alignSelf: 'stretch'
                    }}
                >
                    <Typography
                        sx={{
                            alignSelf: 'stretch',
                            color: '#000',
                            fontFamily: 'Wanted Sans',
                            fontSize: '32px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal'
                        }}
                    >
                        { TEXT.MY_EVENTS }
                    </Typography>
                    <Typography
                        sx={{
                            alignSelf: 'stretch',
                            color: '#565656',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px'
                        }}
                    >
                        { `${TEXT.UPCOMING_EVENTS}: ` }
                        <span className={styles['value']}>
                            { numUpcomingEvents }
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            alignSelf: 'stretch',
                            color: '#565656',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px' 
                        }}
                    >
                        { `${TEXT.PAST_EVENTS}: ` }
                        <span className={styles['value']}>
                            { numPreviousEvents }
                        </span>
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    marginTop: '20px'
                }}
            >
                <EventList
                    headerTitle={TEXT.UPCOMING_EVENTS}
                    setActiveDialog={setShowEventDialog}
                    events={upcomingEvents}
                />
            </Box>
            <Box
                sx={{
                    marginTop: '20px'
                }}
            >
                <EventList
                    headerTitle={TEXT.PAST_EVENTS}
                    setActiveDialog={setShowEventDialog}
                    events={pastEvents}
                />
            </Box>
            { showEventDialog.show && (
                <ViewEventDialog
                    open={true}
                    onClose={closeEventDialog}
                    eventId={showEventDialog.eventId}
                />
            )}
        </Box>
    )
}

export default EventPage;