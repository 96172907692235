import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { TEXT } from './constants';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

export const CollectionCreatePreview = () => {
    const navigate = useNavigate();

    const createCollectionClicked = () => {
        navigate('/collections/new');
    };

    return (
        <Box
            onClick={createCollectionClicked}
            sx={{
                display: 'flex',
                width: '100%',
                minHeight: '120px',
                padding: '32px',
                borderRadius: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                border: '2px dashed rgba(33, 150, 243, 0.6)',
                backgroundColor: 'rgba(33, 150, 243, 0.04)',
                transition: 'all 0.2s ease-in-out',
                cursor: 'pointer',
                position: 'relative',
                overflow: 'hidden',
                
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(33, 150, 243, 0.08)',
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in-out',
                },

                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(33, 150, 243, 0.15)',
                    backgroundColor: 'rgba(33, 150, 243, 0.08)',
                    border: '2px dashed rgba(33, 150, 243, 0.8)',

                    '& .icon': {
                        transform: 'scale(1.1)',
                        color: 'rgba(33, 150, 243, 0.9)',
                    },

                    '& .text': {
                        color: 'rgba(0, 0, 0, 0.9)',
                    }
                },

                '&:active': {
                    transform: 'translateY(0)',
                    boxShadow: '0 2px 8px rgba(33, 150, 243, 0.1)',
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <AddCircleOutlineOutlinedIcon
                    className="icon"
                    sx={{
                        width: '24px',
                        height: '24px',
                        color: 'rgba(33, 150, 243, 0.8)',
                        transition: 'all 0.2s ease-in-out',
                    }}
                />
                <Typography
                    className="text"
                    sx={{
                        color: 'rgba(0, 0, 0, 0.8)',
                        fontFamily: 'Wanted Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
                        fontSize: '18px',
                        fontWeight: '600',
                        letterSpacing: '-0.01em',
                        transition: 'color 0.2s ease-in-out',
                    }}
                >
                    {TEXT.CREATE_COLLECTION_PREVIEW_HEADER_TEXT}
                </Typography>
            </Box>
        </Box>
    );
}