import React, { useState } from 'react';
import { TEXT } from './constants';
import { Card, CardContent, CardMedia, Typography, Box, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getGroupCollectionsWithMediaCounts } from '../../utils/GetGroupCollectionMediaCounts';
import { useCollectionStore } from '../../../collection/store/collectionStore';
import placeholderImage from '../../../../assets/images/placeholder/group_placeholder_2.jpg';

const GroupCard = ({ data }) => {
    const { collections } = useCollectionStore();
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageError, setImageError] = useState(false);
    
    const name = data.group_name;
    const members = data.users.map(user => user.user_name);
    const mediaCount = getGroupCollectionsWithMediaCounts(data.group_id, collections);
    const groupCardImage = data.profile_image ?? placeholderImage;
    const numImages = mediaCount.totalMediaCounts?.image || 0;
    const numVideos = mediaCount.totalMediaCounts?.video || 0;
    const numImagesText = `${numImages == 1 ? TEXT.IMAGE : TEXT.IMAGES}`;
    const numVideosText = `${numVideos == 1 ? TEXT.VIDEO : TEXT.VIDEOS}`;
    const totalMediaCountText = `${numImages} ${numImagesText}, ${numVideos} ${numVideosText}`;
    
    const groupCardClick = () => {
        navigate(`/groups/${data.group_id}`, { replace: false });
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const handleImageError = () => {
        setImageError(true);
        setImageLoaded(true); // Consider it "loaded" even if it errored, to hide the skeleton
    };

    return (
        <Card
            elevation={0}
            onClick={() => groupCardClick()}
            sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                padding: '16px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
                cursor: 'pointer',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
                background: '#FFFFFF',
                transition: 'all 0.2s ease-in-out', // Modified transition to affect all properties
                '&:hover': {
                    transform: 'translateY(-4px)', // Makes the card move up slightly
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow
                    borderColor: '#D0D2D3' // Optional: darkens the border color on hover
                }
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '250px',
                    borderRadius: '10px',
                    position: 'relative',
                    overflow: 'hidden',
                    border: '1px solid #E6E8E9',
                }}
            >
                {!imageLoaded && (
                    <Skeleton 
                        variant="rectangular" 
                        animation="wave"
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                            borderRadius: '10px',
                            transform: 'scale(1)', // Fix for animation issues
                        }}
                    />
                )}
                <CardMedia
                    component="img"
                    image={imageError ? placeholderImage : groupCardImage}
                    alt={name}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        objectFit: 'cover',
                        display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
                    }}
                />
            </Box>
            
            <CardContent
                sx={{
                    padding: '16px'
                }}
            >
                <Typography
                    sx={{
                        width: '100%',
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    { name }
                </Typography>
                
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '16px'
                    }}
                >
                    <Typography 
                        sx={{ 
                            color: '#565656',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '16px'
                        }}
                    >
                        {TEXT.MEMBERS}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignSelf: 'stretch',
                            marginBottom: '16px'
                        }}
                    >
                        {members.map((member, index) => (
                            <Typography 
                                key={index} 
                                sx={{
                                    color: '#565656',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '16px'
                                }}
                            >
                                {member}
                                {index < members.length - 1 && ', '}
                            </Typography>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography 
                            sx={{ 
                                color: '#565656',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '16px'
                            }}
                        >
                            {TEXT.MEDIA}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#565656',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '16px'
                            }}
                        >
                            {totalMediaCountText}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default GroupCard;