import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Typography, TextField, Switch, FormControlLabel, Snackbar, Alert } from '@mui/material';
import { TEXT } from './constants';
import { usePersonStore } from '../../../person/store/personStore';
import { useUser } from '../../hooks/useUser';
import { usePerson } from '../../../person/hooks/usePerson';
import { useMedia } from '../../../media/hooks/useMedia';

export const UserForm = ({ initialData, onSubmit }) => {
    const { person } = usePersonStore();
    const { updatePersonAsync, isUpdatePersonLoading, errorUpdatePerson } = usePerson();
    const { updateUserAsync, isUpdateUserLoading, errorUpdateUser } = useUser();
    const { updateThumbnailImage, isUpdateThumbnailImageLoading } = useMedia();

    // Toast notification state
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastSeverity, setToastSeverity] = useState('success');

    const [userData, setUserData] = useState({
        userId: initialData?.user_id || '',
        personId: person?.person_id || '',
        userName: initialData?.user_name || '',
        firstName: person?.first_name || '',
        lastName: person?.last_name || '',
        birthday: person?.birthday || '',
        phoneNumber: person?.phone_number || '',
        emailAddress: person?.email_address || '',
        photoUrl: initialData?.profile_image || null,
        photoFile: null,
        notifications: {
            activityNotification: initialData?.notifications?.activityNotification ?? true,
            groupNotification: initialData?.notifications?.groupNotification ?? true,
            marketingNotification: initialData?.notifications?.marketingNotification ?? false
        },
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    });

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handlePhotoUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setUserData(prev => ({
                    ...prev,
                    photoUrl: e.target.result,
                    photoFile: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemovePhoto = () => {
        setUserData(prev => ({
            ...prev,
            photoUrl: null,
            photoFile: null
        }));
    };

    const handleNotificationChange = (notificationType) => (event) => {
        setUserData(prev => ({
            ...prev,
            notifications: {
                ...prev.notifications,
                [notificationType]: event.target.checked
            }
        }));
    };

    const handleToastClose = () => {
        setToastOpen(false);
    };

    const showToast = (message, severity = 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setToastOpen(true);
    };

    const handleSubmit = async (e) => {
        if (e && e.preventDefault) e.preventDefault();
        
        // Track results for each update operation
        const updateResults = {
            profileImage: { success: true, attempted: false },
            personalInfo: { success: true, attempted: false },
            userPreferences: { success: true, attempted: false }
        };

        try {
            // Update profile photo if changed
            if (userData.photoFile) {
                updateResults.profileImage.attempted = true;
                try {                    
                    await updateThumbnailImage({
                        entityId: userData.userId,
                        entityType: 'user',
                        entityThumbnailFile: userData.photoFile
                    });
                    
                } catch (error) {
                    console.error('Failed to update profile image:', error);
                    updateResults.profileImage.success = false;
                }
            }

            // Update personal information
            const hasPersonalInfoChanges = 
                userData.firstName !== person?.first_name ||
                userData.lastName !== person?.last_name ||
                userData.phoneNumber !== person?.phone_number ||
                userData.emailAddress !== person?.email_address ||
                userData.birthday !== person?.birthday;

            if (hasPersonalInfoChanges) {
                updateResults.personalInfo.attempted = true;
                try {
                    await updatePersonAsync({
                        personId: userData.personId,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        phoneNumber: userData.phoneNumber,
                        emailAddress: userData.emailAddress,
                        birthday: userData.birthday
                    });
                } catch (error) {
                    console.error('Failed to update personal information:', error);
                    updateResults.personalInfo.success = false;
                }
            }

            // Update user preferences (notifications and password)
            const hasUserChanges = 
                userData.notifications.activityNotification !== initialData?.notifications?.activityNotification ||
                userData.notifications.groupNotification !== initialData?.notifications?.groupNotification ||
                userData.notifications.marketingNotification !== initialData?.notifications?.marketingNotification ||
                (userData.currentPassword && userData.newPassword && userData.repeatNewPassword);

            if (hasUserChanges) {
                updateResults.userPreferences.attempted = true;
                try {
                    await updateUserAsync({
                        userId: userData.userId,
                        currentPassword: userData.currentPassword,
                        newPassword: userData.newPassword,
                        repeatNewPassword: userData.repeatNewPassword,
                        notifications: userData.notifications
                    });
                } catch (error) {
                    console.error('Failed to update user preferences:', error);
                    updateResults.userPreferences.success = false;
                }
            }

            // Show appropriate toast messages
            const failures = [];
            if (updateResults.profileImage.attempted && !updateResults.profileImage.success) {
                failures.push("Failed to update Profile Image");
            }
            if (updateResults.personalInfo.attempted && !updateResults.personalInfo.success) {
                failures.push("Failed to update Personal Information");
            }
            if (updateResults.userPreferences.attempted && !updateResults.userPreferences.success) {
                failures.push("Failed to update User Preferences");
            }

            if (failures.length > 0) {
                showToast(failures.join('\n'), 'error');
            } else {
                showToast("Updates were successful!");
                
                // Wait a moment for the toast to be visible before navigating
                setTimeout(() => {
                    navigate('/');
                }, 1500);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            showToast('An unexpected error occurred. Please try again.', 'error');
        }
    };

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none'
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Wanted Sans',
                    fontSize: '32px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal'
                }}
            >
                {TEXT.EDIT_PROFILE}
            </Typography>

            {/* Profile Photo Section */}
            <Box
                sx={{
                    marginTop: '32px',
                    display: 'flex',
                    padding: '32px',
                    flexDirection: 'column',
                    gap: '16px',
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.PROFILE_PHOTO}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        alignSelf: 'stretch'
                    }}
                >
                    <Avatar 
                        src={userData.photoUrl || "/path/to/default/avatar.jpg"}
                        sx={{
                            width: 128,
                            height: 128
                        }}
                    />
                    <Box>
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="photo-upload"
                            onChange={handlePhotoUpload}
                        />
                        <label htmlFor="photo-upload">
                            <Button
                                component="span"
                                sx={{
                                    marginRight: '8px',
                                    padding: '8px 22px',
                                    borderRadius: '4px',
                                    border: '1px solid #0357D7',
                                    background: '#0357D7'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#FFF',
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '26px',
                                        textTransform: 'none'
                                    }}
                                >
                                    {TEXT.UPLOAD_IMAGE_BUTTON_TEXT}
                                </Typography>
                            </Button>
                        </label>
                        {userData.photoUrl && (
                            <Button
                                onClick={handleRemovePhoto}
                                sx={{
                                    padding: '8px 22px',
                                    borderRadius: '4px',
                                    border: '1px solid #0357D7'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#0357D7',
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '26px',
                                        textTransform: 'none'
                                    }}
                                >
                                    {TEXT.REMOVE_BUTTON_TEXT}
                                </Typography>
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* Personal Information Section */}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    marginTop: '16px',
                    padding: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.PERSONAL_INFORMATION}
                </Typography>

                <TextField
                    name="userName"
                    disabled={true}
                    label={TEXT.USER_NAME}
                    value={userData.userName}
                    onChange={handleInputChange}
                    fullWidth
                    slotProps={{
                        input: {
                            readOnly: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <Box sx={{ display: 'flex', gap: '16px' }}>
                    <TextField
                        name="firstName"
                        label={TEXT.FIRST_NAME}
                        value={userData.firstName}
                        onChange={handleInputChange}
                        fullWidth
                        slotProps={{
                            input: {
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '16px'
                                }
                            },
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '17px'
                                }
                            }
                        }}
                    />
                    <TextField
                        name="lastName"
                        label={TEXT.LAST_NAME}
                        value={userData.lastName}
                        onChange={handleInputChange}
                        fullWidth
                        slotProps={{
                            input: {
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '16px'
                                }
                            },
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '17px'
                                }
                            }
                        }}
                    />
                </Box>

                <TextField
                    name="birthday"
                    label={TEXT.BIRTHDAY}
                    type="date"
                    value={userData.birthday}
                    onChange={handleInputChange}
                    fullWidth
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    name="phoneNumber"
                    label={TEXT.PHONE_NUMBER}
                    value={userData.phoneNumber}
                    onChange={handleInputChange}
                    fullWidth
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    name="emailAddress"
                    label={TEXT.EMAIL_ADDRESS}
                    type="email"
                    value={userData.emailAddress}
                    onChange={handleInputChange}
                    fullWidth
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />
            </Box>

            {/* Notification Preferences Section */}
            <Box
                sx={{
                    marginTop: '16px',
                    padding: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.NOTIFICATION_PREFERENCES}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '14px',
                        color: '#666'
                    }}
                >
                    {TEXT.NOTIFICATION_PREFERNCES_INFO_TEXT}
                </Typography>

                <FormControlLabel
                    control={
                        <Switch
                            checked={userData.notifications.activityNotification}
                            onChange={handleNotificationChange('activityNotification')}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#0357D7'
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#0357D7'
                                }
                            }}
                        />
                    }
                    label={
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontWeight: '700'
                                }}
                            >
                                {TEXT.ACTIVITY_NOTIFICATION}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '12px',
                                    color: '#666'
                                }}
                            >
                                {TEXT.ACTIVITY_NOTIFICATION_INFO_TEXT}
                            </Typography>
                        </Box>
                    }
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={userData.notifications.groupNotification}
                            onChange={handleNotificationChange('groupNotification')}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#0357D7'
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#0357D7'
                                }
                            }}
                        />
                    }
                    label={
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontWeight: '700'
                                }}
                            >
                                {TEXT.GROUP_NOTIFICATION}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '12px',
                                    color: '#666'
                                }}
                            >
                                {TEXT.GROUP_NOTIFICATION_INFO_TEXT}
                            </Typography>
                        </Box>
                    }
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={userData.notifications.marketingNotification}
                            onChange={handleNotificationChange('marketingNotification')}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#0357D7'
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#0357D7'
                                }
                            }}
                        />
                    }
                    label={
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontWeight: '700'
                                }}
                            >
                                {TEXT.MARKETING_NOTIFICATION}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '12px',
                                    color: '#666'
                                }}
                            >
                                {TEXT.MARKETING_NOTIFICATION_INFO_TEXT}
                            </Typography>
                        </Box>
                    }
                />
            </Box>

            {/* Change Password Section */}
            <Box
                sx={{
                    marginTop: '16px',
                    padding: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.CHANGE_PASSWORD}
                </Typography>

                <TextField
                    name="currentPassword"
                    label={TEXT.CURRENT_PASSWORD}
                    type="password"
                    value={userData.currentPassword}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{
                        marginTop: '10px'
                    }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    name="newPassword"
                    label={TEXT.NEW_PASSWORD}
                    type="password"
                    value={userData.newPassword}
                    onChange={handleInputChange}
                    fullWidth
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    name="repeatNewPassword"
                    label={TEXT.REPEAT_NEW_PASSWORD}
                    type="password"
                    value={userData.repeatNewPassword}
                    onChange={handleInputChange}
                    fullWidth
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                {/* Action Buttons */}
                <Box
                    sx={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '8px',
                        width: '100%'
                    }}
                >
                    <Button
                        onClick={() => navigate(-1)}
                        sx={{
                            padding: '6px 16px',
                            borderRadius: '4px',
                            border: '1px solid #0357D7'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#0357D7',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            {TEXT.GO_BACK_BUTTON_TEXT}
                        </Typography>
                    </Button>
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        disabled={isUpdatePersonLoading || isUpdateUserLoading || isUpdateThumbnailImageLoading}
                        sx={{
                            padding: '6px 16px',
                            borderRadius: '4px',
                            border: '1px solid #0357D7',
                            background: '#0357D7',
                            '&:disabled': {
                                background: '#cccccc',
                                borderColor: '#cccccc'
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#FFF',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            {isUpdatePersonLoading || isUpdateUserLoading || isUpdateThumbnailImageLoading
                                ? 'Saving...'
                                : TEXT.SAVE_CHANGES_BUTTON_TEXT}
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    marginTop: 'calc(7% + 95px)',
                    bottom: '0'
                }}
            />

            {/* Toast notification */}
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleToastClose} 
                    severity={toastSeverity} 
                    sx={{ width: '100%', whiteSpace: 'pre-line' }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};