import { useMutation } from "@tanstack/react-query";
import { userApi } from "../services/userApi";
import { useUserStore } from "../store/userStore";

export const useUserFeedActivities = () => {
    const { setUserFeedActivities } = useUserStore();
    const userId = localStorage.getItem('user_id');

    const fetchUserFeedActivitiesMutation = useMutation({
        mutationFn: async () => {
            console.log('Fetching feed activities for user:', userId);

            const activitiesData = await userApi.getUserFeedActivities(userId);
            console.log('Feed activities received:', activitiesData);

            return activitiesData;
        },
        onSuccess: (data) => {
            console.log('Successfully fetched feed activities');
            setUserFeedActivities(data);
        },
        onError: (error) => {
            console.error('Error in feed activities mutation:', error);
        }
    });

    return {
        fetchUserFeedActivities: fetchUserFeedActivitiesMutation.mutate,
        isLoading: fetchUserFeedActivitiesMutation.isPending,
        error: fetchUserFeedActivitiesMutation.error
    };
};