import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { TEXT } from './constants';

export const ExpiredShareableLink = () => {
    const navigate = useNavigate();

    const clickGoToLoginPage = () => {
        navigate('/auth/login');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                width: {
                    xs: '90%', // Mobile
                    sm: '70%', // Tablet
                    md: '50%', // Small Desktop
                    lg: '40%'  // Large Desktop
                },
                padding: {
                    xs: '20px', // Mobile
                    sm: '24px', // Tablet
                    md: '32px'  // Desktop
                },
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '20px'
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Wanted Sans',
                        fontSize: {
                            xs: '18px', // Mobile
                            sm: '20px'  // Tablet and Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '150%'
                    }}
                >
                    { TEXT.LINK_HAS_EXPIRED }
                </Typography>
                <Typography
                    sx={{
                        marginTop: '8px',
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Wanted Sans',
                        fontSize: {
                            xs: '16px', // Mobile
                            sm: '18px', // Tablet
                            md: '20px'  // Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '150%'
                    }}
                >
                    { TEXT.LINK_NOT_AVAILABLE_TEXT_1 }
                </Typography>
                <Typography
                    sx={{
                        color: '#000',
                        textAlign: 'center',
                        fontFamily: 'Wanted Sans',
                        fontSize: {
                            xs: '16px', // Mobile
                            sm: '18px', // Tablet
                            md: '20px'  // Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '150%'
                    }}
                >
                    { TEXT.LINK_NOT_AVAILABLE_TEXT_2 }
                </Typography>
            </Box>
            <Button
                onClick={() => clickGoToLoginPage()}
                disableRipple
                sx={{
                    marginTop: {
                        xs: '24px', // Mobile
                        sm: '28px', // Tablet
                        md: '32px'  // Desktop
                    },
                    display: 'flex',
                    padding: '6px 16px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    borderRadius: '4px',
                    border: '1px solid #0357D7',
                    background: '#0357D7',
                    textTransform: 'none',
                    height: {
                        xs: '40px', // Mobile
                        sm: '42px', // Tablet
                        md: '44px'  // Desktop
                    },
                    '&:hover': {
                        background: '#0245AC'
                    }
                }}
            >
                <Typography
                    sx={{
                        color: '#FFF',
                        fontFamily: 'Wanted Sans',
                        fontSize: {
                            xs: '14px', // Mobile
                            sm: '14px', // Tablet
                            md: '16px'  // Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '24px'
                    }}
                >
                    { TEXT.GO_TO_LOGIN_BUTTON_TEXT }
                </Typography>
            </Button>
        </Box>
    )
}
