import { apiClient } from '../../../services/api/config';
import { ENDPOINTS } from '../../../services/api/endpoints';

export const signupApi = {
    signup: async(signupInformation) => {
        console.log('PAYLOAD: ', signupInformation);
        console.log('SIGN UP SENT');
        const response = await apiClient.post(ENDPOINTS.ACCOUNT.CREATE_ACCOUNT, signupInformation);
        return response.data;
    }
}