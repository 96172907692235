import { apiClient } from "../../../services/api/config";
import { ENDPOINTS } from "../../../services/api/endpoints";

export const groupApi = {
    getGroupDetails: async (groupId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.GROUP.RETRIEVE_GROUP_DETAILS,
                params: {
                    group_id: groupId
                }
            };

            const response = await apiClient(config);
            return response.data;
        } catch (error) {
            console.error('Error fetching group details:', error);
            throw error;
        }
    },

    getGroupProfileImage: async (groupId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.MEDIA.RETRIEVE_THUMBNAIL_IMAGE,
                params: {
                    id: groupId,
                    type: 'group'
                },
                responseType: 'blob'
            };

            const response = await apiClient(config);
            
            // Check if the response is empty or contains "null"
            if (!response.data || response.data.size === 0) {
                return null;
            }

            // Convert blob to base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Additional check for "null" content
                    if (reader.result === "data:application/json;base64,bnVsbA==") {
                        resolve(null);
                    } else {
                        resolve(reader.result);
                    }
                };
                reader.onerror = reject;
                reader.readAsDataURL(response.data);
            });
        } catch (error) {
            console.error('Error fetching group profile image', error);
            throw error;
        }
    },

    getAllGroups: async(showPrivateGroups) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.GROUP.RETRIEVE_ALL_GROUPS,
                params: {
                    show_private_groups:  showPrivateGroups
                }
            };

            const response = await apiClient(config);
            return response;
        } catch (error) {
            console.error('Error fetching all groups', error);
            throw error;
        }
    },

    joinGroup: async(groupId, userId) => {
        try {
            const config = {
                method: 'POST',
                url: ENDPOINTS.GROUP.USER_ADDED_TO_GROUP,
                data: {
                    user_id: userId,
                    group_id: groupId
                }
            };
            const response = await apiClient(config);
            return response;
        } catch (error) {
            console.error(`Error adding user ${userId} to group ${groupId}`);
            throw error;
        }
    },

    createGroup: async(groupInformation) => {
        try {
            const config = {
                method: 'POST',
                url: ENDPOINTS.GROUP.CREATE_GROUP,
                data: {
                    user_id: groupInformation.user_id,
                    group_name: groupInformation.group_name,
                    group_description: groupInformation.group_description,
                    group_url: groupInformation.group_url,
                    open_invitation: groupInformation.open_invitation,
                    is_private: groupInformation.is_private
                }
            };
            const response = await apiClient(config);
            return response;
        } catch (error) {
            console.error(`Error creating group ${groupInformation.group_name}`);
            throw error;
        }
    }
};