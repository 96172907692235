import { useMutation } from "@tanstack/react-query";
import { userApi } from "../services/userApi";

export const useUser = () => {
    const updateUserMutation = useMutation({
        mutationFn: async(userFormData) => {
            const { currentPassword, newPassword, repeatNewPassword, notifications } = userFormData;
            
            // Only include password fields if user is changing password
            const passwordUpdate = currentPassword && newPassword && repeatNewPassword && newPassword === repeatNewPassword
                ? { password: newPassword }
                : {};
            
            const requestPayload = {
                user_id: userFormData.userId,
                ...passwordUpdate,
                activity_notification_enabled: notifications.activityNotification,
                group_notification_enabled: notifications.groupNotification,
                marketing_notification_enabled: notifications.marketingNotification
            };

            const response = await userApi.updateUser(requestPayload);
            return response.data;
        },
        onSuccess: () => {
            console.log('Successfully updated User details');
        },
        onError: (error) => {
            console.error(`Failed to update User details with error:`, error);
        }
    });

    return {
        updateUser: updateUserMutation.mutate,
        updateUserAsync: updateUserMutation.mutateAsync,
        isUpdateUserLoading: updateUserMutation.isPending,
        errorUpdateUser: updateUserMutation.error
    }
};