export const TEXT = {
    CREATE_GROUP: 'Create Group',
    EDIT_GROUP: 'Edit Group',
    UPLOAD_THUMBNAIL: 'Upload Thumbnail',
    UPLOAD_IMAGE_BUTTON_TEXT: 'Upload Image',
    REMOVE_BUTTON_TEXT: 'Remove',
    GROUP_INFORMATION: 'Group Information',
    GROUP_NAME: 'Group Name',
    GROUP_DESCRIPTION: 'Description',
    ADD_MEMBERS: 'Add Members',
    GROUP_PRIVACY_SETTINGS: 'Group Privacy Settings',
    PRIVATE_GROUP: 'Private Group',
    GROUP_WILL_NOT_APPEAR_IN_SEARCH_RESULTS: 'Group will not appear in search results',
    OPEN_INVITATION: 'Open Invitation',
    ALLOW_ANYONE_TO_JOIN_GROUP: 'Allow anyone to join the group',
    GO_BACK_BUTTON_TEXT: 'go back',
    CREATE_GROUP_BUTTON_TEXT: 'create group',
    SAVE_CHANGES_BUTTON_TEXT: 'save changes'
};