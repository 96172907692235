import { apiClient } from '../../../../services/api/config';
import { ENDPOINTS } from '../../../../services/api/endpoints';
import { mimeTypeToExtension, isImageMimeType } from '../../../../utils/MimeTypeConverter';

// HEIC-optimized chunk sizes
const DEFAULT_CHUNK_SIZE = 512 * 1024; // 512KB for regular files
const HEIC_CHUNK_SIZE = 256 * 1024;    // 256KB for HEIC files
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds
const BACKOFF_MULTIPLIER = 1.5; // Exponential backoff
const STATUS_CHECK_INTERVAL = 1500; // 1.5 seconds

// Function to determine appropriate chunk size based on file type
const getChunkSize = (fileType) => {
    // Check if it's a HEIC file (case insensitive)
    if (fileType.toLowerCase().includes('heic') || 
        fileType.toLowerCase().includes('heif')) {
        console.log('HEIC/HEIF file detected, using smaller chunk size');
        return HEIC_CHUNK_SIZE;
    }
    
    // Use default size for all other file types
    return DEFAULT_CHUNK_SIZE;
};

const logBlobInfo = async (blob, stage) => {
    const sizeKB = blob.size / 1024;
    console.log(`[${stage}] Blob size: ${sizeKB.toFixed(2)} KiB`);
    console.log(`[${stage}] Blob type:`, blob.type);
    
    // If it's an image, log dimensions
    if (blob.type.startsWith('image/')) {
        const url = URL.createObjectURL(blob);
        const img = new Image();
        await new Promise((resolve) => {
            img.onload = () => {
                console.log(`[${stage}] Image dimensions: ${img.width}x${img.height}`);
                URL.revokeObjectURL(url);
                resolve();
            };
            img.src = url;
        });
    }
};

export const shareableLinkApi = {
    getShareableTokenDetails: async (shareableLink) => {
        try {
            console.log('Fetching shareable token details for link:', shareableLink);
            const config = {
                method: 'GET',
                url: ENDPOINTS.PUBLIC.SHAREABLE_LINK.GET_SHAREABLE_LINK_TOKEN,
                params: {
                    shareable_link: shareableLink
                }
            };
            const response = await apiClient(config);
            console.log('Shareable token details response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching shareable token details:', error);
            throw error;
        }
    },

    // Helper function to fetch original media file for HEIC files when thumbnails fail
    getOriginalMediaFile: async (mediaId, token) => {
        try {
            console.log('Fetching original media file for HEIC conversion, ID:', mediaId);
            const response = await apiClient({
                method: 'POST',
                url: ENDPOINTS.MEDIA.DOWNLOAD_ORIGINAL,
                data: {
                    media_id: mediaId,
                    token: token
                },
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            
            if (!response.data || response.data.size === 0) {
                console.warn('Empty media file response for mediaId:', mediaId);
                return null;
            }
            
            await logBlobInfo(response.data, 'Original HEIC file');
            return response.data;
        } catch (error) {
            console.error('Error fetching original media file:', error);
            return null;
        }
    },
    
    generateHEICThumbnail: async (blob) => {
        try {
            // If blob is null, generate a placeholder thumbnail
            if (!blob) {
                console.log('No blob provided, generating placeholder thumbnail');
                const canvas = document.createElement('canvas');
                canvas.width = 200;
                canvas.height = 200;
                const ctx = canvas.getContext('2d');
                
                // Fill with light blue background
                ctx.fillStyle = '#e3f2fd';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                
                // Add HEIC text
                ctx.fillStyle = '#0357D7';
                ctx.font = 'bold 24px Arial';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText('HEIC Image', canvas.width/2, canvas.height/2 - 15);
                
                // Add smaller text explaining format
                ctx.font = '14px Arial';
                ctx.fillText('Preview not available', canvas.width/2, canvas.height/2 + 15);
                
                // Convert to data URL
                return canvas.toDataURL('image/jpeg');
            }

            // First try to use the browser's built-in image creation
            // This works in Safari which has native HEIC support
            const url = URL.createObjectURL(blob);
            try {
                // Try native browser support first (works in Safari)
                const img = document.createElement('img');
                img.width = 300;
                img.height = 300;
                
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = url;
                    // Set a timeout to avoid hanging
                    setTimeout(reject, 1000);
                });
                
                // If we get here, the browser supports HEIC
                console.log('Browser has native HEIC support, using it for thumbnail');
                
                // Create a canvas to resize the image
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                
                // Calculate proportional dimensions
                const maxSize = 300;
                const ratio = Math.min(maxSize / img.width, maxSize / img.height);
                canvas.width = img.width * ratio;
                canvas.height = img.height * ratio;
                
                // Draw the image to the canvas, resizing it
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                
                // Convert to base64
                const thumbnailBase64 = canvas.toDataURL('image/jpeg', 0.8);
                URL.revokeObjectURL(url);
                return thumbnailBase64;
            } catch (e) {
                // Browser doesn't support HEIC, clean up and continue to fallback method
                URL.revokeObjectURL(url);
                console.log('Browser does not support HEIC natively, using fallback placeholder');
                
                // Generate a placeholder thumbnail
                const canvas = document.createElement('canvas');
                canvas.width = 200;
                canvas.height = 200;
                const ctx = canvas.getContext('2d');
                
                // Fill with light blue background
                ctx.fillStyle = '#e3f2fd';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                
                // Add HEIC text
                ctx.fillStyle = '#0357D7';
                ctx.font = 'bold 24px Arial';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText('HEIC Image', canvas.width/2, canvas.height/2 - 15);
                
                // Add smaller text explaining format
                ctx.font = '14px Arial';
                ctx.fillText('Preview not available', canvas.width/2, canvas.height/2 + 15);
                
                // Convert to data URL
                return canvas.toDataURL('image/jpeg');
            }
        } catch (error) {
            console.error('Error generating HEIC thumbnail:', error);
            return null;
        }
    },

    getMediaThumbnailImage: async (mediaId, token) => {
        try {
            console.log('Fetching thumbnail for media ID:', mediaId);
            const response = await apiClient({
                method: 'POST',
                url: ENDPOINTS.MEDIA.DOWNLOAD_THUMBNAIL_PUBLIC,
                data: {
                    media_id: mediaId,
                    token: token
                },
                responseType: 'blob',
                headers: {
                    'Accept': 'image/*',
                    'Authorization': `Bearer ${token}`  // Add the token to headers
                }
            });
            
            if (!response.data || response.data.size === 0) {
                console.warn('Empty thumbnail response for mediaId:', mediaId);
                return null;
            }
    
            await logBlobInfo(response.data, 'Initial download');
    
            // Convert to base64 and log size
            const base64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    console.log(`[Base64] String length: ${reader.result.length}`);
                    console.log(`[Base64] Approximate size: ${(reader.result.length / 1024).toFixed(2)} KiB`);
                    
                    if (reader.result === "data:application/json;base64,bnVsbA==") {
                        console.warn('Null thumbnail content for mediaId:', mediaId);
                        resolve(null);
                    } else {
                        resolve(reader.result);
                    }
                };
                reader.onerror = (error) => {
                    console.error('Error reading thumbnail blob:', error);
                    reject(error);
                };
                reader.readAsDataURL(response.data);
            });
            
            // If we got a valid thumbnail, return it
            if (base64) {
                return base64;
            }
            
            // If we get here, we need to check if the file name or type contains HEIC/HEIF
            // We don't need to make an API call since we can infer from the media ID
            console.log('Server did not provide a thumbnail, checking if this is a HEIC/HEIF file');
            
            // Check if the media ID contains information about the file type in the upload context
            // If not, we'll generate a generic placeholder
            const isHeicFile = mediaId && (
                // We can determine this from the file name/type that was uploaded
                // This information is available in the client context
                mediaId.toLowerCase().includes('heic') || 
                mediaId.toLowerCase().includes('heif')
            );
            
            if (isHeicFile) {
                console.log('HEIC/HEIF file detected, generating placeholder thumbnail');
                
                // Generate a placeholder thumbnail for HEIC files
                const placeholderThumbnail = await shareableLinkApi.generateHEICThumbnail(null);
                if (placeholderThumbnail) {
                    return placeholderThumbnail;
                }
            }
            
            return null;
        } catch (error) {
            console.error('Error fetching thumbnail:', error);
            // Return a placeholder instead of throwing an error
            try {
                console.log('Generating fallback placeholder after error');
                return await shareableLinkApi.generateHEICThumbnail(null);
            } catch (placeholderError) {
                console.error('Error generating placeholder:', placeholderError);
                return null;
            }
        }
    },

    uploadFile: async (file, collectionId, groupId, userId, token = null) => {
        try {
            console.log('Starting file upload process:', {
                fileName: file.name,
                fileSize: file.size,
                fileType: file.type,
                collectionId,
                groupId,
                userId,
                hasToken: !!token
            });
            
            // Determine appropriate chunk size based on file type
            const chunkSize = getChunkSize(file.type);
            console.log(`Using chunk size of ${chunkSize / 1024}KB for file type: ${file.type}`);
            
            const totalChunks = Math.ceil(file.size / chunkSize);
            const mediaType = mimeTypeToExtension(file.type);
            
            console.log(`File will be uploaded in ${totalChunks} chunks of ${chunkSize} bytes each`);
            
            // Initialize upload
            console.log('Initializing upload...');
            
            // Ensure token is in localStorage
            if (token) {
                localStorage.setItem('shareable_token', token);
            }
            
            const initResponse = await apiClient({
                method: 'POST',
                url: ENDPOINTS.PUBLIC.SHAREABLE_LINK.UPLOAD_TO_SHAREABLE_LINK,
                data: {
                    collection_id: collectionId,
                    group_id: groupId,
                    user_id: userId,
                    media_name: file.name,
                    media_type: mediaType,
                    size_in_bytes: file.size,
                    total_chunks: totalChunks,
                    ...(token && { token: token })
                }
            });

            const mediaId = initResponse.data.media_id;
            console.log('Upload initialized with media ID:', mediaId);
            
            // Track successful uploads
            const uploadedChunks = new Set();
            let failedChunks = new Set();
            let overallFailedAttempts = 0;
            let finalizationStarted = false;
            
            // Upload all chunks in sequential order
            const initialStatusResponse = await apiClient({
                method: 'GET',
                url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_STATUS,
                params: { media_ids: mediaId }
            });
            
            if (initialStatusResponse?.data?.[mediaId]?.uploaded_chunks) {
                for (let i = 1; i <= initialStatusResponse.data[mediaId].uploaded_chunks; i++) {
                    uploadedChunks.add(i);
                }
                console.log(`Server reports ${uploadedChunks.size}/${totalChunks} chunks already uploaded`);
            }
            
            // First attempt - upload all chunks in sequence
            while (uploadedChunks.size < totalChunks && overallFailedAttempts < MAX_RETRIES) {
                try {
                    console.log(`Starting upload attempt ${overallFailedAttempts + 1}`);
                    
                    for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
                        // Skip already uploaded chunks
                        if (uploadedChunks.has(chunkNumber)) {
                            console.log(`Chunk ${chunkNumber} already uploaded, skipping`);
                            continue;
                        }
                        
                        const start = (chunkNumber - 1) * chunkSize;
                        const end = Math.min(start + chunkSize, file.size);
                        const chunk = file.slice(start, end);
                        
                        console.log(`Uploading chunk ${chunkNumber}/${totalChunks} (${(end-start)/1024}KB, bytes ${start}-${end})`);
                        
                        let chunkSuccess = false;
                        let chunkRetries = 0;
                        
                        // Retry this specific chunk up to 3 times
                        while (!chunkSuccess && chunkRetries < 3) {
                            try {
                                // Create a new FormData for each attempt
                                const formData = new FormData();
                                formData.append('media_id', mediaId);
                                formData.append('chunk_number', chunkNumber);
                                formData.append('chunk', chunk);
                                
                                // Attempt to upload the chunk
                                const chunkResponse = await apiClient({
                                    method: 'POST',
                                    url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_CHUNK,
                                    data: formData,
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    },
                                    timeout: 30000, // 30 second timeout
                                    // Disable transformRequest to ensure FormData is sent properly
                                    transformRequest: []
                                });
                                
                                // Check if this is the last chunk and the server indicates finalization
                                if (chunkResponse?.data?.status?.includes('finalization initiated') || 
                                    (chunkResponse?.data?.progress === 100 && 
                                     chunkResponse?.data?.uploaded_chunks === totalChunks)) {
                                    console.log(`Server indicates finalization has started!`);
                                    finalizationStarted = true;
                                    uploadedChunks.add(chunkNumber);
                                    chunkSuccess = true;
                                }
                                // Regular success case
                                else if (chunkResponse?.data?.status === 'Chunk processed successfully') {
                                    console.log(`Chunk ${chunkNumber} uploaded successfully`);
                                    uploadedChunks.add(chunkNumber);
                                    chunkSuccess = true;
                                    
                                    // Remove from failed chunks if it was previously there
                                    if (failedChunks.has(chunkNumber)) {
                                        failedChunks.delete(chunkNumber);
                                    }
                                } 
                                else {
                                    console.log(`Chunk ${chunkNumber} upload response:`, chunkResponse?.data);
                                    
                                    // If the response shows progress, consider it successful
                                    if (chunkResponse?.data?.progress && chunkNumber === totalChunks) {
                                        console.log(`Last chunk shows upload progress: ${chunkResponse.data.progress}%`);
                                        uploadedChunks.add(chunkNumber);
                                        chunkSuccess = true;
                                    } else {
                                        console.warn(`Chunk ${chunkNumber} upload response unclear:`, chunkResponse?.data);
                                        chunkRetries++;
                                    }
                                }
                            } catch (chunkError) {
                                console.error(`Error uploading chunk ${chunkNumber} (attempt ${chunkRetries + 1}):`, chunkError);
                                
                                // Check if this is a 413 error (payload too large)
                                if (chunkError.response && chunkError.response.status === 413) {
                                    console.error(`Chunk ${chunkNumber} is too large (${chunk.size/1024}KB) for server to handle`);
                                    failedChunks.add(chunkNumber);
                                    
                                    // We'll try again with a smaller chunk size in the next overall attempt
                                    break;
                                } else {
                                    // For other errors, retry the same chunk
                                    chunkRetries++;
                                    
                                    if (chunkRetries < 3) {
                                        const retryDelay = RETRY_DELAY * Math.pow(BACKOFF_MULTIPLIER, chunkRetries);
                                        console.log(`Retrying chunk ${chunkNumber} in ${retryDelay}ms...`);
                                        await new Promise(resolve => setTimeout(resolve, retryDelay));
                                    } else {
                                        // Mark as failed after all retries
                                        failedChunks.add(chunkNumber);
                                    }
                                }
                            }
                        }
                        
                        // After each chunk, verify upload status (but not too frequently)
                        if (chunkNumber % 5 === 0 || chunkNumber === totalChunks || !chunkSuccess) {
                            await new Promise(resolve => setTimeout(resolve, STATUS_CHECK_INTERVAL));
                            try {
                                const statusResponse = await apiClient({
                                    method: 'GET',
                                    url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_STATUS,
                                    params: { media_ids: mediaId }
                                });
                                
                                if (statusResponse?.data?.[mediaId]) {
                                    const status = statusResponse.data[mediaId];
                                    console.log(`Status check: ${status.uploaded_chunks || 0}/${totalChunks} chunks, status: ${status.status}`);
                                    
                                    // If status shows "finalization initiated", mark as finalization started
                                    if (status.status?.includes('finalization') || 
                                        (status.progress === 100 && status.uploaded_chunks >= totalChunks)) {
                                        finalizationStarted = true;
                                    }
                                    
                                    // Update uploadedChunks based on server report
                                    if (status.uploaded_chunks) {
                                        const previousCount = uploadedChunks.size;
                                        
                                        // Update our local tracking with server's confirmation
                                        for (let i = 1; i <= status.uploaded_chunks; i++) {
                                            uploadedChunks.add(i);
                                        }
                                        
                                        if (uploadedChunks.size > previousCount) {
                                            console.log(`Server confirmed ${uploadedChunks.size - previousCount} additional chunks`);
                                        }
                                    }
                                    
                                    // If status is completed, we're done!
                                    if (status.status === 'completed') {
                                        console.log('Server reports upload is complete!');
                                        return mediaId;
                                    }
                                }
                            } catch (statusError) {
                                console.error('Error checking status:', statusError);
                            }
                        }
                    }
                    
                    // If we got here, we've tried all chunks once
                    // If finalization has started, wait longer for it to complete
                    if (finalizationStarted || uploadedChunks.size >= totalChunks) {
                        console.log('All chunks uploaded, waiting for finalization to complete...');
                        
                        // Wait longer for finalization (up to 60 seconds total with increasing delays)
                        for (let i = 0; i < 10; i++) {
                            const waitDelay = 2000 * Math.pow(1.3, i); // Exponential wait time
                            console.log(`Finalization check ${i+1}/10 (waiting ${Math.round(waitDelay/1000)}s)...`);
                            await new Promise(resolve => setTimeout(resolve, waitDelay));
                            
                            try {
                                const finalizeStatus = await apiClient({
                                    method: 'GET',
                                    url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_STATUS,
                                    params: { media_ids: mediaId }
                                });
                                
                                if (finalizeStatus?.data?.[mediaId]) {
                                    const status = finalizeStatus.data[mediaId];
                                    console.log(`Finalization check: status=${status.status}, progress=${status.progress}%`);
                                    
                                    if (status.status === 'completed') {
                                        console.log('Server confirmed upload completion during finalization!');
                                        return mediaId;
                                    }
                                    
                                    // If progress is 100%, we can also consider it done
                                    if (status.progress === 100 && status.uploaded_chunks >= totalChunks) {
                                        console.log('Upload at 100% progress with all chunks uploaded - considering successful');
                                        return mediaId;
                                    }
                                }
                            } catch (error) {
                                console.error('Error checking finalization status:', error);
                            }
                        }
                        
                        // If we waited for finalization but it's still not complete,
                        // let's return media ID anyway - the server will finish processing
                        if (uploadedChunks.size >= totalChunks) {
                            console.log('All chunks uploaded but finalization still in progress - media will be available shortly');
                            return mediaId;
                        }
                    }
                    
                    // Check if we have any failed chunks to retry with a different approach
                    if (failedChunks.size > 0) {
                        console.log(`${failedChunks.size} chunks failed in this attempt, will retry with smaller chunks`);
                        overallFailedAttempts++;
                        
                        if (overallFailedAttempts < MAX_RETRIES) {
                            // Reduce chunk size for next attempt
                            const smallerChunkSize = Math.floor(chunkSize / 2);
                            console.log(`Reducing chunk size to ${smallerChunkSize/1024}KB for retry`);
                            
                            // Try each failed chunk with a smaller size
                            for (const failedChunkNumber of failedChunks) {
                                const originalStart = (failedChunkNumber - 1) * chunkSize;
                                const originalEnd = Math.min(originalStart + chunkSize, file.size);
                                
                                // Calculate how many smaller chunks we need
                                const subChunkCount = Math.ceil((originalEnd - originalStart) / smallerChunkSize);
                                console.log(`Splitting failed chunk ${failedChunkNumber} into ${subChunkCount} smaller chunks`);
                                
                                let allSubChunksSuccess = true;
                                
                                for (let subIdx = 0; subIdx < subChunkCount; subIdx++) {
                                    const subStart = originalStart + (subIdx * smallerChunkSize);
                                    const subEnd = Math.min(subStart + smallerChunkSize, originalEnd);
                                    const subChunk = file.slice(subStart, subEnd);
                                    
                                    console.log(`Uploading smaller chunk for ${failedChunkNumber} part ${subIdx+1}/${subChunkCount} (${(subEnd-subStart)/1024}KB)`);
                                    
                                    // We'll try each smaller piece up to 2 times
                                    let subChunkSuccess = false;
                                    let subChunkRetries = 0;
                                    
                                    while (!subChunkSuccess && subChunkRetries < 2) {
                                        try {
                                            const formData = new FormData();
                                            formData.append('media_id', mediaId);
                                            formData.append('chunk_number', failedChunkNumber);  // Use original chunk number
                                            formData.append('chunk', subChunk);
                                            
                                            const subResponse = await apiClient({
                                                method: 'POST',
                                                url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_CHUNK,
                                                data: formData,
                                                headers: {
                                                    'Content-Type': 'multipart/form-data',
                                                },
                                                timeout: 30000,
                                                transformRequest: []
                                            });
                                            
                                            if (subResponse?.data?.status === 'Chunk processed successfully') {
                                                console.log(`Smaller chunk uploaded successfully`);
                                                subChunkSuccess = true;
                                            } else {
                                                console.warn(`Smaller chunk upload response unclear:`, subResponse?.data);
                                                subChunkRetries++;
                                                await new Promise(resolve => setTimeout(resolve, 1000));
                                            }
                                        } catch (subError) {
                                            console.error(`Error uploading smaller chunk (attempt ${subChunkRetries + 1}):`, subError);
                                            subChunkRetries++;
                                            if (subChunkRetries < 2) {
                                                await new Promise(resolve => setTimeout(resolve, 1000));
                                            }
                                        }
                                    }
                                    
                                    if (!subChunkSuccess) {
                                        allSubChunksSuccess = false;
                                        console.error(`Failed to upload a sub-chunk for chunk ${failedChunkNumber}`);
                                        break;
                                    }
                                }
                                
                                if (allSubChunksSuccess) {
                                    console.log(`All smaller chunks for chunk ${failedChunkNumber} uploaded successfully`);
                                    uploadedChunks.add(failedChunkNumber);
                                    failedChunks.delete(failedChunkNumber);
                                    
                                    // Check status after successfully retrying a chunk
                                    try {
                                        const statusResponse = await apiClient({
                                            method: 'GET',
                                            url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_STATUS,
                                            params: { media_ids: mediaId }
                                        });
                                        
                                        if (statusResponse?.data?.[mediaId]) {
                                            const status = statusResponse.data[mediaId];
                                            console.log(`Status after smaller chunks: ${status.uploaded_chunks || 0}/${totalChunks}, status: ${status.status}`);
                                            
                                            if (status.status === 'completed') {
                                                console.log('Server reports upload is complete after smaller chunks!');
                                                return mediaId;
                                            }
                                        }
                                    } catch (statusError) {
                                        console.error('Error checking status after smaller chunks:', statusError);
                                    }
                                }
                            }
                        }
                    } else if (uploadedChunks.size < totalChunks) {
                        // If we don't have failed chunks but we're still missing some
                        overallFailedAttempts++;
                        console.log(`Missing ${totalChunks - uploadedChunks.size} chunks, will retry`);
                        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
                    } else {
                        // All chunks uploaded according to our tracking
                        console.log('All chunks uploaded according to client-side tracking');
                        break;
                    }
                } catch (error) {
                    console.error(`Overall upload attempt ${overallFailedAttempts + 1} failed:`, error);
                    overallFailedAttempts++;
                    
                    if (overallFailedAttempts < MAX_RETRIES) {
                        const retryDelay = RETRY_DELAY * Math.pow(BACKOFF_MULTIPLIER, overallFailedAttempts);
                        console.log(`Retrying entire upload after ${retryDelay}ms...`);
                        await new Promise(resolve => setTimeout(resolve, retryDelay));
                    }
                }
            }

            // If all chunks have been uploaded, consider it successful even if the server doesn't report it completed
            if (uploadedChunks.size >= totalChunks) {
                console.log(`All ${totalChunks} chunks were uploaded - upload is successful`);
                return mediaId;
            }

            // If we get here, we've exhausted all options
            console.error('Upload did not complete successfully after all attempts');
            throw new Error('Upload did not complete successfully');

        } catch (error) {
            console.error('Error in uploadFile:', error);
            throw error;
        }
    },

    deleteFile: async (mediaId, collectionId) => {
        try {
            await apiClient({
                method: 'POST',
                url: ENDPOINTS.MEDIA.DELETE_FILE,
                data: {
                    media_id: mediaId,
                    collection_id: collectionId
                }
            });
            return true;
        } catch (error) {
            console.error('Error deleting file:', error);
            throw error;
        }
    }
}
