import React, { useState } from 'react';
import { useSignup } from '../../hooks/useSignup';
import styles from './styles.module.css';
import { termsAndConditions } from '../../../../legal/termsAndConditions';
import { TEXT } from './constants';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Checkbox, TextField, Typography, Link, Modal, Paper, FormControlLabel, Divider, Tooltip } from '@mui/material';
import { X } from 'lucide-react';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// Fixed formatContent function to avoid invalid DOM nesting
const formatContent = (content) => {
    // Handle section headers with numbering (e.g., "3.1 Account Creation")
    const sectionHeaderRegex = /^(\d+\.\d+)\s+(.+)$/gm;
    if (content.match(sectionHeaderRegex)) {
      // Split the content by section headers
      const parts = content.split(sectionHeaderRegex).filter(Boolean);
      
      // Group the parts into [number, title, content] triples
      const processedParts = [];
      for (let i = 0; i < parts.length; i += 3) {
        if (i + 2 < parts.length) {
          processedParts.push({
            number: parts[i],
            title: parts[i + 1],
            content: parts[i + 2].trim()
          });
        }
      }
      
      return (
        <>
          {processedParts.map((part, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography 
                sx={{ 
                  fontFamily: 'Wanted Sans', 
                  fontWeight: 600, 
                  fontSize: '1rem',
                  mb: 1.5 
                }}
              >
                {part.number} {part.title}
              </Typography>
              {formatSimpleContent(part.content)}
            </Box>
          ))}
        </>
      );
    }
    
    // Detect if content contains bold text (**text**)
    const containsBold = content.includes('**');
    
    // Handle bullets and lists
    const containsList = content.includes('\n- ');
    const containsNumberedList = content.includes('\n1. ');
    
    if (containsBold || containsList || containsNumberedList) {
      // Format bold text by replacing **text** with <strong>text</strong>
      let formattedText = content;
      if (containsBold) {
        formattedText = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      }
      
      // Format lists
      if (containsList) {
        const parts = formattedText.split('\n- ');
        return (
          <>
            {parts[0] && (
              <Typography sx={{ fontFamily: 'Wanted Sans', mb: 1, fontSize: '0.95rem' }}>
                {/* Fixed: Using dangerouslySetInnerHTML properly */}
                <span dangerouslySetInnerHTML={{ __html: parts[0] }} />
              </Typography>
            )}
            <Box component="ul" sx={{ pl: 2, mb: 2 }}>
              {parts.slice(1).map((item, i) => (
                <Box key={i} component="li" sx={{ fontFamily: 'Wanted Sans', mb: 0.5, fontSize: '0.95rem' }}>
                  {/* Fixed: Using dangerouslySetInnerHTML properly */}
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                </Box>
              ))}
            </Box>
          </>
        );
      }
      
      // Format numbered lists
      if (containsNumberedList) {
        const parts = formattedText.split(/\n\d+\. /);
        return (
          <>
            {parts[0] && (
              <Typography sx={{ fontFamily: 'Wanted Sans', mb: 1, fontSize: '0.95rem' }}>
                {/* Fixed: Using dangerouslySetInnerHTML properly */}
                <span dangerouslySetInnerHTML={{ __html: parts[0] }} />
              </Typography>
            )}
            <Box component="ol" sx={{ pl: 2, mb: 2 }}>
              {parts.slice(1).map((item, i) => (
                <Box key={i} component="li" sx={{ fontFamily: 'Wanted Sans', mb: 0.5, fontSize: '0.95rem' }}>
                  {/* Fixed: Using dangerouslySetInnerHTML properly */}
                  <span dangerouslySetInnerHTML={{ __html: item }} />
                </Box>
              ))}
            </Box>
          </>
        );
      }
      
      return (
        <Typography 
          sx={{ fontFamily: 'Wanted Sans', mb: 1.5, fontSize: '0.95rem', lineHeight: 1.6 }}
        >
          {/* Fixed: Using dangerouslySetInnerHTML properly */}
          <span dangerouslySetInnerHTML={{ __html: formattedText }} />
        </Typography>
      );
    }
    
    return formatSimpleContent(content);
  };
  
  // Helper function to format simple text content
  const formatSimpleContent = (content) => {
    return content.split('\n\n').map((paragraph, idx) => (
      <Typography 
        key={idx}
        sx={{ fontFamily: 'Wanted Sans', mb: 1.5, fontSize: '0.95rem', lineHeight: 1.6 }}
      >
        {paragraph}
      </Typography>
    ));
  };

const SignupComponent = () => {
    const navigate = useNavigate();
    const { signup } = useSignup();
    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
    const [currentSection, setCurrentSection] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [signupInformation, setSignupInformation] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        user_name: '',
        password: '',
        repeat_password: '',
        birthday: '',
        city: '',
        country: '',
        terms_accepted: false
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        const fieldValue = e.target.type === 'checkbox' ? checked : value;
        setSignupInformation(prev => ({
            ...prev,
            [name]: fieldValue
        }));
    };
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        setFormSubmitted(true);
        
        // Check if terms have been accepted before proceeding
        if (!signupInformation.terms_accepted) {
            return;
        }
        
        console.log('Submitting signup information:', signupInformation);
        await signup(signupInformation);
    };

    const handleOpenTermsAndConditions = (e) => {
        e.preventDefault();
        setOpenTermsAndConditions(true);
        setCurrentSection(null);
    };

    const handleCloseTermsAndConditions = () => {
        setOpenTermsAndConditions(false);
        setCurrentSection(null);
    };

    const handleSectionClick = (sectionIndex) => {
        setCurrentSection(currentSection === sectionIndex ? null : sectionIndex);
    };

    // Option to auto-accept terms after viewing
    const handleAcceptTerms = () => {
        setSignupInformation(prev => ({
            ...prev,
            terms_accepted: true
        }));
        handleCloseTermsAndConditions();
    };

    return (
        <div className={styles['signup-container']}>
            <div className={styles['title-container']}>
                <div className={styles['title-text']}>
                    {TEXT.TITLE_NAME}
                </div>
                <div className={styles['title-decsription-text']}>
                    {TEXT.TITLE_DESCRIPTION}
                </div>
            </div>
            <div className={styles['signup-input-container']}>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 4
                    }}
                >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            name="first_name"
                            value={signupInformation.first_name}
                            onChange={handleChange}
                            required
                            label={TEXT.FIRST_NAME}
                            fullWidth
                            slotProps={{
                                inputLabel: {
                                    shrink: true,
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '17px'
                                    }
                                },
                                input: {
                                    style: {
                                        fontFamily: 'Wanted Sans'
                                    }
                                }
                            }}
                        />
                        <TextField
                            name="last_name"
                            value={signupInformation.last_name}
                            onChange={handleChange}
                            label={TEXT.LAST_NAME}
                            fullWidth
                            slotProps={{
                                inputLabel: {
                                    shrink: true,
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '17px'
                                    }
                                },
                                input: {
                                    style: {
                                        fontFamily: 'Wanted Sans'
                                    }
                                }
                            }}
                        />
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={TEXT.BIRTHDAY}
                        value={dayjs(signupInformation.birthday)}
                        required={false}
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#CCCCCC',  // Default border color
                            },
                            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#CCCCCC !important',  // Override error state
                            },
                            '& .MuiInputLabel-root.Mui-error': {
                                color: 'rgba(0, 0, 0, 0.6)',  // Override error state label color
                            }
                        }}
                        onChange={(newValue) => {
                            handleChange({ target: { name: 'birthday', value: newValue } });
                        }}
                        slotProps={{
                            textField: {
                                required: false,
                                error: false,  // Explicitly set error to false
                                fullWidth: true,
                                inputProps: {
                                    placeholder: 'select a date',
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        textTransform: 'lowercase'
                                    }
                                }
                            }
                        }}
                    />
                    </LocalizationProvider>
                    <TextField
                        name="email_address"
                        value={signupInformation.email_address}
                        onChange={handleChange}
                        required
                        label={TEXT.EMAIL_ADDRESS}
                        type="email"
                        fullWidth
                        slotProps={{
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '17px'
                                }
                            },
                            input: {
                                style: {
                                    fontFamily: 'Wanted Sans'
                                }
                            }
                        }}
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            name="city"
                            value={signupInformation.city}
                            onChange={handleChange}
                            required
                            label={TEXT.CITY}
                            fullWidth
                            slotProps={{
                                inputLabel: {
                                    shrink: true,
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '17px'
                                    }
                                },
                                input: {
                                    style: {
                                        fontFamily: 'Wanted Sans'
                                    }
                                }
                            }}
                        />
                        <TextField
                            name="country"
                            value={signupInformation.country}
                            onChange={handleChange}
                            required
                            label={TEXT.COUNTRY}
                            fullWidth
                            slotProps={{
                                inputLabel: {
                                    shrink: true,
                                    style: {
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '17px'
                                    }
                                },
                                input: {
                                    style: {
                                        fontFamily: 'Wanted Sans'
                                    }
                                }
                            }}
                        />
                    </Box>
                    <TextField
                        name="user_name"
                        value={signupInformation.user_name}
                        onChange={handleChange}
                        required
                        label={TEXT.USERNAME}
                        fullWidth
                        slotProps={{
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '17px'
                                }
                            },
                            input: {
                                style: {
                                    fontFamily: 'Wanted Sans'
                                }
                            }
                        }}
                    />
                    <TextField
                        name="password"
                        value={signupInformation.password}
                        onChange={handleChange}
                        required
                        label={TEXT.PASSWORD}
                        type="password"
                        fullWidth
                        slotProps={{
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '17px'
                                }
                            },
                            input: {
                                style: {
                                    fontFamily: 'Wanted Sans'
                                }
                            }
                        }}
                    />
                    <TextField
                        name="repeat_password"
                        value={signupInformation.repeat_password}
                        onChange={handleChange}
                        required
                        label={TEXT.REPEAT_PASSWORD}
                        type="password"
                        fullWidth
                        slotProps={{
                            inputLabel: {
                                shrink: true,
                                style: {
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '17px'
                                }
                            },
                            input: {
                                style: {
                                    fontFamily: 'Wanted Sans'
                                }
                            }
                        }}
                    />
                </Box>
                <Typography sx={{ 
                    fontSize: '12px', 
                    fontFamily: 'Wanted Sans',
                    color: 'rgba(0, 0, 0, 0.6)',
                    width: '100%',
                    maxWidth: '444px',
                    mt: 1,
                    mb: -1,
                    textAlign: 'right'
                }}>
                    {TEXT.REQUIRE_FIELD_ANNOTATION}
                </Typography>
            </div>
            <div className={styles['terms-and-conditions-container']} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                    <Checkbox 
                    name="terms_accepted"
                    checked={signupInformation.terms_accepted}
                    onChange={handleChange}
                    required
                    color="primary"
                    size="small"
                    sx={{
                        padding: '0px',
                        marginRight: '8px',
                        '&.Mui-error': {
                        color: '#d32f2f'
                        }
                    }}
                    />
                    <Typography sx={{
                        fontSize: '13px',
                        fontFamily: 'Wanted Sans',
                        color: formSubmitted && !signupInformation.terms_accepted ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
                        paddingTop: '2px'
                    }}
                    >
                    {TEXT.I_AGREE}{' '}
                    <Link
                        component="button"
                        onClick={handleOpenTermsAndConditions}
                        sx={{ 
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        },
                        color: formSubmitted && !signupInformation.terms_accepted ? '#d32f2f' : '#0357D7'
                        }}
                    >
                        {TEXT.TERMS_AND_CONDITIONS}
                    </Link>
                    {formSubmitted && !signupInformation.terms_accepted && (
                        <Typography 
                        component="span" 
                        sx={{ 
                            color: '#d32f2f', 
                            ml: 1,
                            fontSize: '13px',
                            fontFamily: 'Wanted Sans'
                        }}
                        >
                        {TEXT.TERMS_REQUIRED}
                        </Typography>
                    )}
                    </Typography>
                </div>
                </div>
            <div className={styles['create-account-button-container']}>
                <Tooltip 
                    title={!signupInformation.terms_accepted ? TEXT.TERMS_TOOLTIP : ""}
                    arrow
                    placement="top"
                >
                    <span style={{ width: '450px', marginRight: '16px', marginTop: '5px' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!signupInformation.terms_accepted}
                            sx={{
                                width: '100%',
                                height: '42px',
                                margin: '8px',
                                textTransform: 'none',
                                backgroundColor: '#0357D7',
                                fontWeight: 500,
                                fontFamily: 'Wanted Sans',
                                fontSize: '15px',
                                '&:hover': {
                                    backgroundColor: '#0357D7'
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'rgba(3, 87, 215, 0.5)',
                                    color: 'white'
                                }
                            }}
                        >
                            {TEXT.CREATE_ACCOUNT}
                        </Button>
                    </span>
                </Tooltip>
                <Button
                    variant="text"
                    disableRipple
                    onClick={() => navigate('/auth/login')}
                    sx={{
                        width: '444px',
                        height: '42px',
                        margin: '4px 8px',
                        textTransform: 'none',
                        fontWeight: 500,
                        fontFamily: 'Wanted Sans',
                        fontSize: '15px',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    {TEXT.BACK_TO_LOGIN}
                </Button>
            </div>

            <Modal
                open={openTermsAndConditions}
                onClose={handleCloseTermsAndConditions}
                aria-labelledby="terms-modal-title"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Paper
                    sx={{
                        position: 'relative',
                        width: '90%',
                        maxWidth: '900px',
                        maxHeight: '90vh',
                        p: 4,
                        overflowY: 'auto',
                        bgcolor: 'background.paper',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)'
                    }}
                >
                    <Button
                        onClick={handleCloseTermsAndConditions}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            minWidth: 'auto',
                            p: 1
                        }}
                    >
                        <X size={24} />
                    </Button>

                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            mb: 1,
                            fontWeight: 700,
                            fontFamily: 'Wanted Sans',
                            color: '#0357D7'
                        }}
                    >
                        {termsAndConditions.title}
                    </Typography>
                    
                    <Typography
                        variant="subtitle1"
                        sx={{
                            mb: 3,
                            fontFamily: 'Wanted Sans',
                            color: '#555'
                        }}
                    >
                        {TEXT.EFFECTIVE_DATE}: {termsAndConditions.effectiveDate}
                    </Typography>

                    {/* Table of Contents */}
                    <Box 
                        sx={{ 
                            mb: 4, 
                            p: 2, 
                            backgroundColor: '#f8f9fa', 
                            borderRadius: '4px',
                            border: '1px solid #eaeaea' 
                        }}
                    >
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                mb: 2, 
                                fontWeight: 600, 
                                fontFamily: 'Wanted Sans',
                                color: '#333'
                            }}
                        >
                            {TEXT.TABLE_OF_CONTENTS}
                        </Typography>
                        {termsAndConditions.sections.map((section, index) => (
                            <Typography 
                                key={index} 
                                component="div"
                                sx={{ 
                                    cursor: 'pointer',
                                    mb: 1,
                                    fontFamily: 'Wanted Sans',
                                    color: '#0357D7',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                                onClick={() => handleSectionClick(index)}
                            >
                                {section.title}
                            </Typography>
                        ))}
                    </Box>

                    {/* Display all sections or just the selected one */}
                    {currentSection !== null ? (
                        <Box key={currentSection} sx={{ mb: 3 }}>
                            <Typography 
                                variant="h5" 
                                sx={{ 
                                    mb: 2, 
                                    fontWeight: 600, 
                                    fontFamily: 'Wanted Sans',
                                    color: '#0357D7',
                                    paddingBottom: '0.5rem',
                                    borderBottom: '1px solid #eaeaea'
                                }}
                            >
                                {termsAndConditions.sections[currentSection].title}
                            </Typography>
                            {formatContent(termsAndConditions.sections[currentSection].content)}
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setCurrentSection(null)}
                                    sx={{
                                        textTransform: 'none',
                                        fontFamily: 'Wanted Sans',
                                        borderColor: '#0357D7',
                                        color: '#0357D7',
                                        '&:hover': {
                                            borderColor: '#0357D7',
                                            backgroundColor: 'rgba(3, 87, 215, 0.04)'
                                        }
                                    }}
                                >
                                    {TEXT.BACK_TO_ALL_SECTIONS}
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        termsAndConditions.sections.map((section, index) => (
                            <Box key={index} sx={{ mb: 4 }}>
                                <Typography 
                                    variant="h5" 
                                    sx={{ 
                                        mb: 2, 
                                        fontWeight: 600, 
                                        fontFamily: 'Wanted Sans',
                                        color: '#0357D7',
                                        cursor: 'pointer',
                                        paddingBottom: '0.5rem',
                                        borderBottom: '1px solid #eaeaea',
                                        '&:hover': {
                                            color: '#0245AE'
                                        }
                                    }}
                                    onClick={() => handleSectionClick(index)}
                                >
                                    {section.title}
                                </Typography>
                                {formatContent(section.content)}
                            </Box>
                        ))
                    )}

                    <Divider sx={{ my: 3 }} />

                    <Box 
                        sx={{ 
                            mt: 4, 
                            display: 'flex', 
                            justifyContent: 'center',
                            gap: 2,
                            paddingTop: '1rem'
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={handleCloseTermsAndConditions}
                            sx={{
                                width: '200px',
                                height: '42px',
                                textTransform: 'none',
                                fontFamily: 'Wanted Sans',
                                borderColor: '#0357D7',
                                color: '#0357D7',
                                '&:hover': {
                                    borderColor: '#0357D7',
                                    backgroundColor: 'rgba(3, 87, 215, 0.04)'
                                }
                            }}
                        >
                            {TEXT.CLOSE_TERMS_AND_CONDITIONS}
                        </Button>
                        
                        <Button
                            variant="contained"
                            onClick={handleAcceptTerms}
                            sx={{
                                width: '200px',
                                height: '42px',
                                textTransform: 'none',
                                backgroundColor: '#0357D7',
                                fontWeight: 550,
                                fontFamily: 'Wanted Sans',
                                '&:hover': {
                                    backgroundColor: '#0245AE'
                                }
                            }}
                        >
                            {TEXT.ACCEPT_AND_CLOSE}
                        </Button>
                    </Box>
                    
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            display: 'block', 
                            textAlign: 'center', 
                            mt: 2,
                            fontFamily: 'Wanted Sans',
                            color: '#777'
                        }}
                    >
                        {TEXT.LAST_UPDATED}: {termsAndConditions.lastUpdated}
                    </Typography>
                </Paper>
            </Modal>
        </div>
    );
};

export default SignupComponent;