import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useEventStore = create(
    persist(
        (set) => ({
            events: [],
            setEvents: (eventsData) => {
                if (Array.isArray(eventsData)) {
                    set({ events: eventsData });
                }
            },
            clearEvents: () => set({ events: [] })
        }),
        {
            name: 'event-storage',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({ events: state.events })
        }
    )
);