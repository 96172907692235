import { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { useEventStore } from '../../store/eventStore';
import { dateDifference } from '../../../../utils/DateDifference';
import CloseIcon from '@mui/icons-material/Close';
import { eventApi } from '../../services/eventApi';

export const ViewEventDialog = ({
    open,
    onClose,
    eventId
}) => {
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const events = useEventStore((state) => state.events);

    // First try to find the event in the store
    useEffect(() => {
        const fetchEventData = async () => {
            setLoading(true);
            
            // First check if the event is already in the store
            const storeEvent = events.find(e => e && e.event_id === eventId);
            
            if (storeEvent) {
                console.log('Found event in store:', storeEvent.event_name);
                setEvent(storeEvent);
                setLoading(false);
                return;
            }
            
            // If not in store, fetch it directly
            try {
                console.log('Fetching event details for:', eventId);
                const eventDetails = await eventApi.getEventDetails(eventId);
                
                // Also fetch the profile image
                let profileImage = null;
                try {
                    profileImage = await eventApi.getEventProfileImage(eventId);
                } catch (imgError) {
                    console.error('Error fetching event image:', imgError);
                }
                
                // Combine event details with profile image
                const eventWithImage = {
                    ...eventDetails,
                    profile_image: profileImage
                };
                
                console.log('Fetched event:', eventWithImage.event_name);
                setEvent(eventWithImage);
            } catch (error) {
                console.error('Error fetching event details:', error);
            } finally {
                setLoading(false);
            }
        };
        
        if (open && eventId) {
            fetchEventData();
        }
    }, [open, eventId, events]);

    const eventTitle = event?.event_name || 'Loading event...';
    const eventDate = event?.event_date || 'None';
    var daysUntilEvent = 0;

    if (event?.event_date) {
        daysUntilEvent = dateDifference(event.event_date);
    }
    
    const daysUntilEventText = `in ${daysUntilEvent} days`;
    const eventDescription = event?.event_description || '';
    const eventImage = event?.profile_image || null;

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    backgroundColor: '#FFF',
                    padding: '32px'
                }
            }}
        >
            <DialogTitle>
                <Typography
                    sx={{
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        letterSpacing: '-1px'
                    }}
                >
                    {loading ? 'Loading...' : eventTitle}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        zIndex: '1',
                        backgroundColor: '#565656 !important',
                        padding: '1px !important'
                    }}
                >
                    <CloseIcon
                        sx={{ color: 'white' }}
                    />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {loading ? (
                    <Typography>Loading event details...</Typography>
                ) : (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start'
                            }}
                        >
                            <Typography
                                sx={{
                                    flexShrink: '0',
                                    color: '#565656',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal'
                                }}
                            >
                                {eventDate}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#565656',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal' 
                                }}
                            >
                                {daysUntilEventText}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '25px',
                                height: '400px',
                                alignSelf: 'stretch',
                                borderRadius: '10px',
                                backgroundColor: 'salmon',
                                backgroundImage: eventImage ? `url(${eventImage})` : 'none',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}
                        />
                        <Typography
                            sx={{
                                marginTop: '20px',
                                color: '#565656',
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal'
                            }}
                        >
                            {eventDescription}
                        </Typography>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};