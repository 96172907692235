export const assembleGroupListItems = (groupsInput, peopleInput) => {
    // Standardize inputs to arrays
    const groups = Array.isArray(groupsInput) ? groupsInput : [groupsInput];
    const people = Array.isArray(peopleInput) ? peopleInput : [peopleInput];
    var items = [];

    // Guard clause - check if inputs are valid
    if (!groupsInput || !peopleInput) {
        console.warn('Invalid input: groups or people is undefined');
        return items;
    }
    
    // Get the logged-in user's ID from the people array
    const loggedInUserId = people[0]?.data?.user_id;
    
    // Convert people array to a map for efficient lookup
    const peopleMap = people.reduce((acc, personWrapper) => {
        if (personWrapper.success && personWrapper.data && personWrapper.data.user_id) {
            acc[personWrapper.data.user_id] = personWrapper.data;
        }
        return acc;
    }, {});
    
    // Process each group
    groups.forEach(group => {
        if (group && group.users && Array.isArray(group.users)) {
            const userNames = group.users
                .filter(user => user.user_id !== loggedInUserId)
                .map(user => {
                    const person = user && user.user_id ? peopleMap[user.user_id] : null;
                    if (person) {
                        return `${person.first_name} ${person.last_name}`;
                    }
                    return 'Unknown User';
                })
                .filter(name => name !== 'Unknown User');
            items.push({
                id: group.group_id || '',
                name: group.group_name || '',
                profileImage: group.profile_image,
                description: userNames.length > 0 ? userNames.join(', ') : 'No other members'
            });
        }
    });
    
    return items;
};

export const assembleEventListItems = (groupsInput, eventsInput) => {
    // Standardize inputs to arrays
    const groups = Array.isArray(groupsInput) ? groupsInput : [groupsInput];
    const events = Array.isArray(eventsInput) ? eventsInput : [eventsInput];
    var items = [];
    
    // Guard clause
    if (!groupsInput || !eventsInput) {
        console.warn('Invalid input: groups or events is undefined');
        return items;
    }

    // Create a map of group_id to group_name for efficient lookup
    const groupMap = groups.reduce((acc, group) => {
        acc[group.group_id] = group.group_name;
        return acc;
    }, {});

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    };

    // Process each event
    events.forEach(event => {
        if (!event) return; // Skip undefined events
        
        const groupName = groupMap[event.group_id] || 'Unknown Group';
        const formattedDate = event.event_date ? formatDate(event.event_date) : 'Unknown Date';
        // Use profile_image directly from the event object
        // The useEventProfileImage hook will have already updated the events with profile images
        items.push({
            id: event.event_id,
            name: event.event_name,
            profileImage: event.profile_image, // This now comes directly from the events that have been updated with profile images
            description: {
                date: formattedDate,
                group: groupName
            }
        });
    });

    // Sort events by date using the original event_date for accurate sorting
    items.sort((a, b) => {
        const dateA = new Date(a.description.date);
        const dateB = new Date(b.description.date);
        return dateA - dateB;
    });
    
    return items;
};

export const assembleUserListItems = (groupsInput, peopleInput) => {    
    // Standardize inputs to arrays
    const groups = Array.isArray(groupsInput) ? groupsInput : [groupsInput];
    const people = Array.isArray(peopleInput) ? peopleInput : [peopleInput];
    var items = [];

    // Guard clause
    if (!groupsInput || !peopleInput) {
        console.warn('Invalid input: groups or people is undefined');
        return items;
    }

    // Create a map of person data with their associated user details
    const peopleMap = people.reduce((acc, personWrapper) => {
        if (personWrapper.success && personWrapper.data && personWrapper.data.user_id) {
            acc[personWrapper.data.user_id] = {
                ...personWrapper.data,
                profile_image: personWrapper.data.user?.profile_image
            };
        }
        return acc;
    }, {});

    // Get unique users from all groups
    const uniqueUsers = new Set();
    groups.forEach(group => {
        if (group && group.users && Array.isArray(group.users)) {
            group.users.forEach(user => {
                if (user && user.user_id) {
                    uniqueUsers.add(user.user_id);
                }
            });
        }
    });

    // Create items for each unique user
    Array.from(uniqueUsers).forEach(userId => {
        const person = peopleMap[userId];
        if (person) {
            items.push({
                id: userId,
                profileImage: person.profile_image,
                name: `${person.first_name} ${person.last_name}`,
                description: ''
            });
        }
    });

    // Sort by name alphabetically
    items.sort((a, b) => a.name.localeCompare(b.name));

    return items;
};

export const isEmpty = (value) => {
    if (value == null) return true;
    
    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length === 0;
    }
    
    if (typeof value === 'object') {
      return Object.keys(value).length === 0;
    }
    
    return false;
};