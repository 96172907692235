/**
 * Utility functions for handling MIME types and file extensions
 */

// Map of MIME types to their corresponding file extensions
export const MIME_TO_EXTENSION = {
    // Images
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/heic': 'heic',
    'image/heif': 'heif',
    'image/svg+xml': 'svg',
    'image/bmp': 'bmp',
    'image/tiff': 'tiff',
    
    // Videos
    'video/mp4': 'mp4',
    'video/quicktime': 'mov',
    'video/x-msvideo': 'avi',
    'video/x-ms-wmv': 'wmv',
    'video/x-matroska': 'mkv',
    'video/webm': 'webm',
    'video/x-m4v': 'm4v',
    'video/x-flv': 'flv',
    'video/3gpp': '3gp'
};

// Map of file extensions to their corresponding MIME types
export const EXTENSION_TO_MIME = {
    // Images
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'webp': 'image/webp',
    'heic': 'image/heic',
    'heif': 'image/heif',
    'svg': 'image/svg+xml',
    'bmp': 'image/bmp',
    'tiff': 'image/tiff',
    
    // Videos
    'mp4': 'video/mp4',
    'mov': 'video/quicktime',
    'avi': 'video/x-msvideo',
    'wmv': 'video/x-ms-wmv',
    'mkv': 'video/x-matroska',
    'webm': 'video/webm',
    'm4v': 'video/x-m4v',
    'flv': 'video/x-flv',
    '3gp': 'video/3gpp'
};

/**
 * Converts a MIME type to its corresponding file extension
 * @param {string} mimeType - The MIME type to convert (e.g., "image/jpeg")
 * @returns {string} The corresponding file extension (e.g., "jpeg")
 */
export const mimeTypeToExtension = (mimeType) => {
    if (!mimeType) return '';
    
    const normalizedMime = mimeType.toLowerCase();
    return MIME_TO_EXTENSION[normalizedMime] || normalizedMime.split('/')[1];
};

/**
 * Converts a file extension to its corresponding MIME type
 * @param {string} extension - The file extension to convert (e.g., "jpeg")
 * @returns {string} The corresponding MIME type (e.g., "image/jpeg")
 */
export const extensionToMimeType = (extension) => {
    if (!extension) return '';
    
    // Remove leading dot if present and convert to lowercase
    const normalizedExt = extension.replace(/^\./, '').toLowerCase();
    return EXTENSION_TO_MIME[normalizedExt] || `application/${normalizedExt}`;
};

/**
 * Checks if a MIME type represents an image
 * @param {string} mimeType - The MIME type to check
 * @returns {boolean} True if the MIME type represents an image
 */
export const isImageMimeType = (mimeType) => {
    if (!mimeType) return false;
    return mimeType.toLowerCase().startsWith('image/');
};

/**
 * Checks if a MIME type represents a video
 * @param {string} mimeType - The MIME type to check
 * @returns {boolean} True if the MIME type represents a video
 */
export const isVideoMimeType = (mimeType) => {
    if (!mimeType) return false;
    return mimeType.toLowerCase().startsWith('video/');
};

/**
 * Gets the file extension from a filename
 * @param {string} filename - The filename to process
 * @returns {string} The file extension without the dot
 */
export const getExtensionFromFilename = (filename) => {
    if (!filename) return '';
    return filename.split('.').pop().toLowerCase();
};