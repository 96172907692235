import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useCollectionStore = create(
    persist(
        (set) => ({
            collections: [],
            
            setCollections: (collectionsData) => set({ collections: collectionsData }),
            
            clearCollections: () => set({ collections: [] }),
            
            // Add new media to a collection
            addMediaToCollection: (collectionId, newMediaItem) => set((state) => ({
                collections: state.collections.map(collection => {
                    if (collection.collection_id === collectionId) {
                        return {
                            ...collection,
                            media: collection.media 
                                ? [newMediaItem, ...collection.media]  // Add new media at the start
                                : [newMediaItem]  // Create new media array if none exists
                        };
                    }
                    return collection;
                })
            })),

            // Remove media from a collection
            removeMediaFromCollection: (collectionId, mediaId) => set((state) => ({
                collections: state.collections.map(collection => {
                    if (collection.collection_id === collectionId) {
                        return {
                            ...collection,
                            media: collection.media
                                ? collection.media.filter(item => item.media_id !== mediaId)
                                : []
                        };
                    }
                    return collection;
                })
            })),

            // Update existing media in a collection
            updateMediaInCollection: (collectionId, mediaId, updatedData) => set((state) => ({
                collections: state.collections.map(collection => {
                    if (collection.collection_id === collectionId) {
                        return {
                            ...collection,
                            media: collection.media
                                ? collection.media.map(item => 
                                    item.media_id === mediaId 
                                        ? { ...item, ...updatedData }
                                        : item
                                  )
                                : []
                        };
                    }
                    return collection;
                })
            }))
        }),
        {
            name: 'collection-storage'
        }
    )
);