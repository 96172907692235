import { Box, Button, Typography, IconButton } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { TEXT } from './constants';
import { useMediaStore } from '../../store/mediaStore';
import { usePersonStore } from '../../../person/store/personStore';
import { useUserDetails } from '../../../user/hooks/useUserDetails';
import { formatFileSize } from '../../../../utils/formatFileSize';
import { useMedia } from '../../hooks/useMedia';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';

export const MediaFilePreview = ({ 
    selectedMedia, 
    collectionId, 
    thumbnailData, 
    onDoubleClick,
    viewMode = 'grid',
    selectedItems = {},
    allMediaItems = [],
    onBatchDownload,
    onBatchDelete,
    onSelectedItemsChange // Add the missing prop
}) => {
    const { 
        downloadRawFile, 
        deleteFile, 
        isRawDownloading, 
        rawDownloadError,
        isDeleting 
    } = useMedia();

    const { people } = usePersonStore();
    const { userData } = useUserDetails();
    
    // Add state for error message and operation status
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
    
    // Count selected items
    const selectedItemCount = useMemo(() => {
        return Object.values(selectedItems).filter(Boolean).length;
    }, [selectedItems]);

    // Get selected media items
    const selectedMediaItems = useMemo(() => {
        if (!allMediaItems || !allMediaItems.length) return [];
        
        return allMediaItems.filter(item => 
            item?.media_id && selectedItems[item.media_id]
        );
    }, [allMediaItems, selectedItems]);

    // Calculate total size of selected files
    const totalSelectedSize = useMemo(() => {
        return selectedMediaItems.reduce(
            (total, item) => total + (item.size_in_bytes || 0), 
            0
        );
    }, [selectedMediaItems]);
    
    // Error message timeout
    useEffect(() => {
        let timer;
        if (errorMessage) {
            timer = setTimeout(() => {
                setErrorMessage(null);
            }, 3000); // Hide message after 3 seconds
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [errorMessage]);
    
    // Check if we should show multi-selection view
    const showMultiSelectionView = viewMode === 'filter';
    
    // Handle batch operations
    const handleBatchDownload = () => {
        if (isDownloading) {
            setErrorMessage("Download already in progress");
            return;
        }
        
        if (selectedItemCount === 0) {
            setErrorMessage("No files selected for download");
            return;
        }
        
        setIsDownloading(true);
        
        if (onBatchDownload) {
            const selectedIds = Object.entries(selectedItems)
                .filter(([_, isSelected]) => isSelected)
                .map(([mediaId]) => mediaId);
            
            try {
                onBatchDownload(selectedIds);
                // Set a timeout to reset the downloading state since we don't have a promise
                setTimeout(() => {
                    setIsDownloading(false);
                }, 3000);
            } catch (error) {
                console.error('Error during batch download:', error);
                setIsDownloading(false);
            }
        }
    };

    const handleBatchDelete = () => {
        if (isDeleteInProgress) {
            setErrorMessage("Delete operation already in progress");
            return;
        }
        
        if (selectedItemCount === 0) {
            setErrorMessage("No files selected for deletion");
            return;
        }
        
        setIsDeleteInProgress(true);
        
        if (onBatchDelete) {
            const selectedIds = Object.entries(selectedItems)
                .filter(([_, isSelected]) => isSelected)
                .map(([mediaId]) => mediaId);
            
            try {
                onBatchDelete(selectedIds);
                // Set a timeout to reset the deleting state since we don't have a promise
                setTimeout(() => {
                    setIsDeleteInProgress(false);
                }, 3000);
            } catch (error) {
                console.error('Error during batch delete:', error);
                setIsDeleteInProgress(false);
            }
        }
    };
    
    // Add handlers for deselection
    const handleDeselectAll = () => {
        const newSelectedItems = {};
        
        // Notify parent component
        if (onSelectedItemsChange) {
            onSelectedItemsChange(newSelectedItems);
        }
    };
    
    const handleRemoveFile = (mediaId, event) => {
        if (event) {
            event.stopPropagation();
        }
        
        // Create a new object without the selected item
        const newSelectedItems = { ...selectedItems };
        delete newSelectedItems[mediaId];
        
        // Notify parent component
        if (onSelectedItemsChange) {
            onSelectedItemsChange(newSelectedItems);
        }
    };
    
    // Use memoization for thumbnail access to avoid re-renders
    const thumbnail = useMemo(() => {
        if (!selectedMedia?.media_id) return null;
        return thumbnailData?.[selectedMedia.media_id] || null;
    }, [selectedMedia, thumbnailData]);

    const handleDownload = async () => {
        if (selectedMedia?.media_id) {
            try {
                await downloadRawFile(selectedMedia.media_id);
            } catch (err) {
                console.error('Download failed:', err);
            }
        }
    };

    const handleDelete = () => {
        if (selectedMedia?.media_id && (collectionId !== undefined)) {
            deleteFile({
                mediaId: selectedMedia.media_id,
                collectionId: collectionId
            });
        }
    };

    const getUploaderName = () => {
        if (!selectedMedia?.uploaded_by_user_id) return 'Unknown';
        
        if (!userData?.person_id) return 'Unknown';
        var personId = userData.person_id;    
        
        const personDoc = people?.find(person => 
            person.data?.person_id === personId
        );
                
        if (!personDoc?.data) return 'Unknown';
        
        return `${personDoc.data.first_name} ${personDoc.data.last_name}`;
    };

    const uploaderName = getUploaderName();
    
    const fileCreatedOn = '2024-24-24'; // Maybe add this field to models
    const fileDimension = '200x200'; // Need to figure something out maybe add this field

    // Multi-selection view for list view mode
    if (showMultiSelectionView) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    padding: '32px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flex: '1 0 0',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '16px',
                        width: '100%'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '24px'
                        }}
                    >
                        Files Selected
                    </Typography>
                    
                    <Box sx={{ marginLeft: 'auto' }}>
                        {selectedItemCount > 0 && (
                            <IconButton
                                onClick={handleDeselectAll}
                                size="small"
                                title="Clear all selections"
                                sx={{
                                    padding: '4px',
                                    color: '#666',
                                    '&:hover': {
                                        color: '#FF3434',
                                        backgroundColor: 'rgba(255, 52, 52, 0.04)'
                                    }
                                }}
                            >
                                <DeleteOutlinedIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                </Box>
                
                {/* Scrollable list of selected files */}
                <Box
                    sx={{
                        width: '100%',
                        maxHeight: '230px',
                        overflowY: 'auto',
                        borderRadius: '4px',
                        padding: '8px',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#E0E0E0',
                            borderRadius: '6px',
                        }
                    }}
                >
                    {selectedMediaItems.map(item => (
                        <Box
                            key={item.media_id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 4px',
                                borderBottom: '1px solid #F5F5F5',
                                '&:hover .remove-icon': {
                                    opacity: 1
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    flex: 1
                                }}
                            >
                                {item.media_name || 'Untitled'}
                            </Typography>
                            <IconButton
                                className="remove-icon"
                                onClick={(e) => handleRemoveFile(item.media_id, e)}
                                size="small"
                                title="Remove from selection"
                                sx={{ 
                                    padding: '2px',
                                    opacity: 0,
                                    transition: 'opacity 0.2s ease',
                                    color: '#888',
                                    '&:hover': {
                                        color: '#FF3434',
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <CloseIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                        </Box>
                    ))}
                    {selectedMediaItems.length === 0 && (
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '22px',
                                padding: '12px 4px',
                                color: '#666',
                                textAlign: 'center'
                            }}
                        >
                            Choose files from the list to download or delete multiple files at once
                        </Typography>
                    )}
                </Box>
                
                {/* File Information section */}
                <Box
                    sx={{
                        marginTop: '32px',
                        width: '100%'
                    }}
                >
                    <Typography
                        sx={{
                            marginBottom: '16px',
                            fontFamily: 'Wanted Sans',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '16px',
                            letterSpacing: '-1px'  
                        }}
                    >
                        File Information
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderTop: '1px solid #E6E8E9',
                            borderBottom: '1px solid #E6E8E9'
                        }}
                    >
                        <Typography
                            sx={{
                                marginTop: '16px',
                                marginBottom: '16px',
                                fontFamily: 'Wanted Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '16px',
                                color: '#565656'
                            }}
                        >
                            {selectedItemCount > 0 ? 'Number of files selected' : 'No files selected'}
                        </Typography>
                        <Typography
                            sx={{
                                marginTop: '16px',
                                marginBottom: '16px',
                                fontFamily: 'Wanted Sans',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '16px',
                                color: '#000000'
                            }}
                        >
                            {selectedItemCount}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #E6E8E9'
                        }}
                    >
                        <Typography
                            sx={{
                                marginTop: '16px',
                                marginBottom: '16px',
                                fontFamily: 'Wanted Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '16px',
                                color: '#565656'
                            }}
                        >
                            Total File Size
                        </Typography>
                        <Typography
                            sx={{
                                marginTop: '16px',
                                marginBottom: '16px',
                                fontFamily: 'Wanted Sans',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '16px',
                                color: '#000000'
                            }}
                        >
                            {formatFileSize(totalSelectedSize)}
                        </Typography>
                    </Box>
                </Box>
                
                {/* Action buttons */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '32px'
                    }}
                >
                    {/* Error message display */}
                    {errorMessage && (
                        <Typography
                            sx={{
                                color: '#FF3434',
                                fontSize: '14px',
                                fontFamily: 'Wanted Sans',
                                marginBottom: '12px',
                                padding: '8px',
                                backgroundColor: 'rgba(255, 52, 52, 0.08)',
                                borderRadius: '4px',
                                textAlign: 'center',
                                animation: 'fadeIn 0.3s ease',
                                '@keyframes fadeIn': {
                                    '0%': {
                                        opacity: 0,
                                    },
                                    '100%': {
                                        opacity: 1,
                                    },
                                },
                            }}
                        >
                            {errorMessage}
                        </Typography>
                    )}
                    
                    <Button
                        onClick={handleBatchDownload}
                        disableElevation
                        disableRipple
                        sx={{
                            display: 'flex',
                            padding: '8px 22px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                            border: '1px solid #0357D7',
                            background: '#0357D7',
                            '&:hover': {
                                background: '#0249BC',
                                borderColor: '#0249BC'
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '26px',
                                color: '#FFF',
                                textTransform: 'none'
                            }}
                        >
                            {isDownloading ? TEXT.DOWNLOAD_IN_PROGRESS : TEXT.DOWNLOAD_SELECTED }
                        </Typography>
                    </Button>

                    <Button
                        onClick={handleBatchDelete}
                        sx={{
                            marginTop: '8px',
                            display: 'flex',
                            padding: '8px 22px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                            border: '1px solid #FF3434',
                            background: 'transparent',
                            '&:hover': {
                                background: 'rgba(255, 52, 52, 0.04)',
                                borderColor: '#FF3434'
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '26px',
                                color: '#FF3434',
                                textTransform: 'none'
                            }}
                        >
                            {isDeleteInProgress ? TEXT.DELETE_IN_PROGRESS : TEXT.DELETE_SELECTED }
                        </Typography>
                    </Button>
                </Box>
            </Box>
        );
    }

    // Show empty state if no media is selected
    if (!selectedMedia) {
        return (
            <Box sx={{
                display: 'flex',
                padding: '32px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '32px',
                flex: '1 0 0',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
                background: '#FFF'
            }}>
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans'
                    }}
                >Select a file to view details</Typography>
            </Box>
        );
    }

    // Original single item view (unchanged)
    return (
        <Box
            sx={{
                display: 'flex',
                padding: '32px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: '1 0 0',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
                background: '#FFF'
            }}
        >
            <Box
                sx={{
                    height: '230px',
                    width: '100%',
                    backgroundImage: thumbnail ? `url(${thumbnail})` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '4px',
                    backgroundColor: !thumbnail ? '#f0f0f0' : 'transparent',
                    cursor: 'pointer' // Add pointer cursor to indicate it's clickable
                }}
                onDoubleClick={() => {
                    if (selectedMedia && onDoubleClick) {
                        onDoubleClick(selectedMedia);
                    }
                }}
            />
            <Box
                sx={{
                    marginTop: '13px'
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '16px'
                    }}
                >
                    {selectedMedia.media_name}
                </Typography>
                <Typography
                    sx={{
                        marginTop: '10px',
                        fontFamily: 'Wanted Sans',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '16px'
                    }}
                >
                    {formatFileSize(selectedMedia.size_in_bytes)}
                </Typography>
            </Box>
            <Box
                sx={{
                    marginTop: '32px',
                    width: '100%'
                }}
            >
                <Typography
                    sx={{
                        marginBottom: '16px',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '16px',
                        letterSpacing: '-1px'  
                    }}
                >
                    {TEXT.FILE_INFORMATION}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #E6E8E9',
                        borderBottom: '1px solid #E6E8E9'
                    }}
                >
                    <Typography
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px',
                            color: '#565656'
                        }}
                    >
                        {TEXT.UPLOADED_BY}
                    </Typography>
                    <Typography
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '16px',
                            color: '#000000'
                        }}
                    >
                        {uploaderName}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #E6E8E9'
                    }}
                >
                    <Typography
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px',
                            color: '#565656'
                        }}
                    >
                        {TEXT.CREATED}
                    </Typography>
                    <Typography
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '16px',
                            color: '#000000'
                        }}
                    >
                        {fileCreatedOn}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #E6E8E9'
                    }}
                >
                    <Typography
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px',
                            color: '#565656'
                        }}
                    >
                        {TEXT.DIMENSION}
                    </Typography>
                    <Typography
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '16px',
                            color: '#000000'
                        }}
                    >
                        {fileDimension}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {rawDownloadError && (
                    <Typography
                        sx={{
                            color: '#FF3434',
                            marginBottom: '8px',
                            fontSize: '14px'
                        }}
                    >
                        Download failed: {rawDownloadError.message}
                    </Typography>
                )}

                <Button
                    onClick={handleDownload}
                    disabled={isRawDownloading}
                    sx={{
                        display: 'flex',
                        padding: '8px 22px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px',
                        border: '1px solid #0357D7',
                        background: '#0357D7',
                        '&:disabled': {
                            backgroundColor: '#0357D7',
                            opacity: 0.7
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '26px',
                            color: '#FFF',
                            textTransform: 'none'
                        }}
                    >
                        {isRawDownloading ? TEXT.DOWNLOAD_IN_PROGRESS : TEXT.DOWNLOAD}
                    </Typography>
                </Button>

                <Button
                    onClick={handleDelete}
                    disabled={isDeleting}
                    sx={{
                        marginTop: '8px',
                        display: 'flex',
                        padding: '8px 22px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px',
                        border: '1px solid #FF3434'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '26px',
                            color: '#FF3434',
                            textTransform: 'none'
                        }}
                    >
                        {isDeleting ? TEXT.DELETE_IN_PROGRESS : TEXT.DELETE}
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default MediaFilePreview;