export const TEXT = {
    JOIN_GROUP: 'Join Group',
    CREATE_GROUP: '+ Create Group',
    JOIN_EVENT: 'Join Event',
    CREATE_EVENT: '+ Create Event',
    EXPAND: 'expand',
    COLLAPSE: 'collapse',
    VIEW_EVENTS: 'View Events',
    NONE: 'None',

    // Header Text Types from Parent Component to determine rendering
    ADD_MEMBER: 'Add Member'
};
