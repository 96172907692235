import { useMutation } from "@tanstack/react-query";
import { groupApi } from "../services/groupApi";
import { useGroupStore } from "../store/groupStore";

export const useGroupDetails = () => {
    const { setGroups } = useGroupStore();

    const fetchGroupDetailsMutation = useMutation({
        mutationFn: async (groupIds) => {
            // If we receive an array of objects with group_id property
            if (Array.isArray(groupIds) && typeof groupIds[0] === 'object') {
                groupIds = groupIds.map(group => group.group_id);
            }
            
            // Handle single ID case
            if (!Array.isArray(groupIds)) {
                groupIds = [groupIds];
            }
            
            // Fetch all groups in parallel
            const responses = await Promise.all(
                groupIds.map(id => groupApi.getGroupDetails(id))
            );
            
            return responses;
        },
        onSuccess: (data) => {
            console.log('Fetched Groups Data:', data);
            setGroups(data);
        }
    });

    const fetchAllGroupsMutation = useMutation({
        mutationFn: async(showPrivateGroups = false) => {
            const response = await groupApi.getAllGroups(showPrivateGroups);
            return response.data.map(group => ({
                group_id: group.group_id,
                group_name: group.group_name
            }));
        }
    });

    return {
        fetchGroupDetails: fetchGroupDetailsMutation.mutate,
        isLoading: fetchGroupDetailsMutation.isPending,
        error: fetchGroupDetailsMutation.error,

        fetchAllGroups: fetchAllGroupsMutation.mutate,
        isFetchAllGroupsLoading: fetchAllGroupsMutation.isPending,
        isFetchAllGroupsError: fetchAllGroupsMutation.error,
        allGroupsData: fetchAllGroupsMutation.data 
    };
};