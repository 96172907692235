import { useMutation } from "@tanstack/react-query";
import { shareableLinkApi } from '../services/shareableLinkApi';
import { userApi } from '../../../user/services/userApi';
import { groupApi } from '../../../group/services/groupApi';
import { collectionApi } from '../../../collection/services/collectionApi';
import { personApi } from '../../../person/services/personApi';
import { useShareableLinkStore } from "../store/shareableLinkStore";
import { apiClient } from "../../../../services/api/config";

export const useShareableData = () => {
    const { setShareableToken, shareableToken } = useShareableLinkStore();

    const fetchShareableDataMutation = useMutation({
        mutationFn: async (shareableLink) => {
            try {
                console.log('Fetching shareable data for link:', shareableLink);
                
                // First get the initial response with token and IDs
                const response = await shareableLinkApi.getShareableTokenDetails(shareableLink);
                console.log('Initial API response:', response);
                
                // Validate the response
                if (!response || typeof response !== 'object') {
                    console.error('Invalid response format from server:', response);
                    throw new Error('Invalid response received from server');
                }
                
                const { token, user_id, collection_id, group_id } = response;

                if (!token) {
                    console.error('No token received from server');
                    throw new Error('No token received from server');
                }

                console.log('Received token and IDs:', { 
                    token: token.substring(0, 10) + '...', 
                    user_id, 
                    collection_id, 
                    group_id 
                });

                // Set up authorization header for subsequent requests
                apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                
                // Save token to localStorage immediately for persistence
                localStorage.setItem('shareable_token', token);

                // Initialize result object with IDs
                const result = {
                    token,
                    userId: user_id,
                    collectionId: collection_id,
                    groupId: group_id,
                    groupName: null,
                    collectionName: null,
                    personName: null,
                    personId: null
                };

                // Create an array of promises for parallel execution
                const promises = [];
                
                // Only add user/person promise if user_id exists
                if (user_id) {
                    console.log('Fetching user and person details for user_id:', user_id);
                    const userPromise = userApi.getUserDetailsByUserId(user_id)
                        .then(async (userData) => {
                            console.log('User details received:', userData);
                            if (userData && userData.person_id) {
                                return personApi.getPersonDetails(userData.person_id)
                                    .then(personData => {
                                        console.log('Person details received:', personData);
                                        if (personData && personData.data) {
                                            result.personName = `${personData.data.first_name} ${personData.data.last_name}`.trim();
                                            result.personId = personData.data.person_id;
                                            console.log('Person name set to:', result.personName);
                                        }
                                    });
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching user/person details:', error);
                            // Silently handle error, we'll still return partial data
                        });
                    
                    promises.push(userPromise);
                }

                // Only add group promise if group_id exists
                if (group_id) {
                    console.log('Fetching group details for group_id:', group_id);
                    const groupPromise = groupApi.getGroupDetails(group_id)
                        .then(groupData => {
                            console.log('Group details received:', groupData);
                            if (groupData && groupData.group_name) {
                                result.groupName = groupData.group_name;
                                console.log('Group name set to:', result.groupName);
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching group details:', error);
                            // Silently handle error, we'll still return partial data
                        });
                    
                    promises.push(groupPromise);
                }

                // Only add collection promise if collection_id exists
                if (collection_id) {
                    console.log('Fetching collection details for collection_id:', collection_id);
                    const collectionPromise = collectionApi.getCollectionDetails(collection_id)
                        .then(collectionData => {
                            console.log('Collection details received:', collectionData);
                            if (collectionData && collectionData.collection_name) {
                                result.collectionName = collectionData.collection_name;
                                console.log('Collection name set to:', result.collectionName);
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching collection details:', error);
                            // Silently handle error, we'll still return partial data
                        });
                    
                    promises.push(collectionPromise);
                }

                // Wait for all promises to complete
                try {
                    await Promise.all(promises);
                    console.log('All data fetched successfully:', result);
                } catch (promiseError) {
                    // If secondary data fetching fails, we can still return with the token
                    console.warn('Error fetching additional data, but continuing with token:', promiseError);
                }
                
                return result;
            } catch (error) {
                console.error('Error in fetchShareableDataMutation:', error);
                throw error;
            } finally {
                // Clean up authorization header
                delete apiClient.defaults.headers.common['Authorization'];
            }
        },
        onSuccess: (data) => {
            console.log('Saving shareable token to localStorage and store:', data);
            // Save the token to localStorage for persistence
            localStorage.setItem('shareable_token', data.token);
            // Update the Zustand store
            setShareableToken(data);
        },
        onError: (error) => {
            console.error('Error in fetchShareableDataMutation onError:', error);
            // Clean up authorization header in case of error
            delete apiClient.defaults.headers.common['Authorization'];
            throw error;
        }
    });

    const uploadMutation = useMutation({
        mutationFn: async ({ file, collectionId, groupId, userId }) => {          
            try {
                console.log('Starting media upload mutation with params:', {
                    fileName: file.name,
                    fileSize: file.size,
                    fileType: file.type,
                    collectionId,
                    groupId,
                    userId
                });
                
                if (!shareableToken || !shareableToken.token) {
                    console.error('No token available for upload');
                    throw new Error('No token available for upload');
                }
                
                console.log('Using token for upload:', shareableToken.token.substring(0, 10) + '...');
                
                const mediaId = await shareableLinkApi.uploadFile(
                    file, 
                    collectionId, 
                    groupId, 
                    userId, 
                    shareableToken.token
                );
                
                console.log('Upload completed successfully with mediaId:', mediaId);
                
                return {
                    mediaId,
                    media_id: mediaId,
                    media_name: file.name,
                    media_type: file.type,
                    size_in_bytes: file.size
                };
            } catch (error) {
                console.error('Upload mutation failed:', error);
                return {
                    success: false,
                    error: error.message || 'Upload failed'
                };
            }
        }
    });

    const getMediaThumbnail = async (mediaId) => {
        try {
            console.log('Getting media thumbnail for mediaId:', mediaId);
            
            if (!shareableToken || !shareableToken.token) {
                console.error('No token available for thumbnail fetch');
                return { 
                    success: false, 
                    error: 'No token available' 
                };
            }
            
            console.log('Using token for thumbnail fetch:', shareableToken.token.substring(0, 10) + '...');
            
            const thumbnailUrl = await shareableLinkApi.getMediaThumbnailImage(mediaId, shareableToken.token);
            
            if (thumbnailUrl) {
                console.log('Thumbnail fetched successfully');
                return { 
                    success: true, 
                    thumbnailUrl 
                };
            } else {
                console.warn('Failed to load thumbnail');
                return { 
                    success: false, 
                    error: 'Failed to load thumbnail' 
                };
            }
        } catch (error) {
            console.error('Error getting media thumbnail:', error);
            return { 
                success: false, 
                error: error.message || 'Error loading thumbnail'
            };
        }
    };

    return {
        fetchShareableData: fetchShareableDataMutation.mutate,
        fetchShareableDataAsync: fetchShareableDataMutation.mutateAsync,
        isLoading: fetchShareableDataMutation.isPending,
        error: fetchShareableDataMutation.error,
        data: fetchShareableDataMutation.data,

        uploadMediaFilePublic: uploadMutation.mutate,
        uploadMediaFilePublicAsync: uploadMutation.mutateAsync,
        isUploading: uploadMutation.isPending,
        uploadError: uploadMutation.error,

        getMediaThumbnail
    };
};
