import { apiClient } from "../../../services/api/config";
import { ENDPOINTS } from "../../../services/api/endpoints";
import { mimeTypeToExtension } from "../../../utils/MimeTypeConverter";

const CHUNK_SIZE = 256 * 1024; // 256KB instead of 1MB
const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds
const STATUS_CHECK_INTERVAL = 1000; // 1 second

const logBlobInfo = async (blob, stage) => {    
    // If it's an image, log dimensions
    if (blob.type.startsWith('image/')) {
        const url = URL.createObjectURL(blob);
        const img = new Image();
        await new Promise((resolve) => {
            img.onload = () => {
                URL.revokeObjectURL(url);
                resolve();
            };
            img.src = url;
        });
    }
};

export const mediaApi = {
    getMediaDetails: async (mediaId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.MEDIA.DOWNLOAD_STANDARD,
                params: {
                    media_id: mediaId
                },
                responseType: 'blob'
            };

            const response = await apiClient(config);
            
            // Convert blob to base64
            const base64Thumbnail = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(response.data);
            });

            return {
                mediaId,
                thumbnailUrl: base64Thumbnail
            };
        } catch (error) {
            console.error('Error fetching media details:', error);
            throw error;
        }
    },

    getMediaThumbnailImage: async (mediaId) => {
        try {
            const response = await apiClient({
                method: 'GET',
                url: ENDPOINTS.MEDIA.DOWNLOAD_THUMBNAIL,
                params: {
                    media_id: mediaId
                },
                responseType: 'blob',
                headers: {
                    'Accept': 'image/*'
                }
            });
            
            if (!response.data || response.data.size === 0) {
                console.warn('Empty thumbnail response for mediaId:', mediaId);
                return null;
            }

            await logBlobInfo(response.data, 'Initial download');

            // Convert to base64 and log size
            const base64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result === "data:application/json;base64,bnVsbA==") {
                        resolve(null);
                    } else {
                        resolve(reader.result);
                    }
                };
                reader.onerror = (error) => {
                    console.error('Error reading thumbnail blob:', error);
                    reject(error);
                };
                reader.readAsDataURL(response.data);
            });

            return base64;
        } catch (error) {
            console.error('Error fetching thumbnail:', error);
            throw error;
        }
    },

    getShareableLink: async (userId, groupId, collectionId) => {
        try {
            // Use the correct endpoint from ENDPOINTS object to ensure proper path construction
            const config = {
                method: 'POST',
                url: ENDPOINTS.PUBLIC.SHAREABLE_LINK.CREATE_SHAREABLE_MEDIA_UPLOAD_LINK,
                data: {
                    user_id: userId,
                    group_id: groupId,
                    collection_id: collectionId
                }
                // Don't override withCredentials - let the interceptor handle it based on auth state
            };

            const response = await apiClient(config);
            return response.data;
        }
        catch (error) {
            console.error('Error fetching shareable link:', error);
            throw error;
        }
    },

    uploadFile: async (file, collectionId, groupId, userId, token = null) => {
        try {
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
            const mediaType = mimeTypeToExtension(file.type);
                        
            // Initialize upload
            const initResponse = await apiClient({
                method: 'POST',
                url: ENDPOINTS.MEDIA.INITIALIZE_MEDIA_UPLOAD,
                data: {
                    collection_id: collectionId,
                    group_id: groupId,
                    user_id: userId,
                    media_name: file.name,
                    media_type: mediaType,
                    size_in_bytes: file.size,
                    total_chunks: totalChunks,
                    ...(token && { token: token })
                }
            });
    
            const mediaId = initResponse.data.media_id;
            const uploadedChunks = new Set();
            let failedAttempts = 0;
            let consecutiveFailures = 0;
    
            while (uploadedChunks.size < totalChunks && failedAttempts < MAX_RETRIES) {
                try {
                    // Check current upload status
                    const statusResponse = await apiClient({
                        method: 'GET',
                        url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_STATUS,
                        params: { media_ids: mediaId }
                    });
    
                    const uploadStatus = statusResponse.data[mediaId];
                    
                    // If upload is already completed, return success
                    if (uploadStatus.status === 'completed') {
                        return mediaId;
                    }
    
                    // Update our set of confirmed uploaded chunks
                    if (uploadStatus.uploaded_chunks) {
                        for (let i = 1; i <= uploadStatus.uploaded_chunks; i++) {
                            uploadedChunks.add(i);
                        }
                    }
    
                    // Reset consecutive failures counter if we got a successful status check
                    consecutiveFailures = 0;
    
                    // Upload remaining chunks
                    for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
                        if (uploadedChunks.has(chunkNumber)) {
                            continue;
                        }
    
                        const start = (chunkNumber - 1) * CHUNK_SIZE;
                        const end = Math.min(start + CHUNK_SIZE, file.size);
                        const chunk = file.slice(start, end);
    
                        const formData = new FormData();
                        formData.append('media_id', mediaId);
                        formData.append('chunk_number', chunkNumber);
                        formData.append('chunk', chunk);
    
                        try {
                            const chunkResponse = await apiClient({
                                method: 'POST',
                                url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_CHUNK,
                                data: formData,
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                                // Add a timeout to prevent hanging requests
                                timeout: 30000
                            });
    
                            if (chunkResponse.data.status === 'Chunk processed successfully') {
                                uploadedChunks.add(chunkNumber);
                                consecutiveFailures = 0;
                            }
                        } catch (chunkError) {
                            console.error(`Error uploading chunk ${chunkNumber} for ${file.name}:`, chunkError);
                            consecutiveFailures++;
                            
                            // If we get too many consecutive failures, take a break
                            if (consecutiveFailures >= 3) {
                                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * 2));
                                consecutiveFailures = 0;
                                break; // Exit the chunk loop and check status again
                            }
                            
                            // Skip this chunk for now and try again later
                            continue;
                        }
    
                        // Short pause between chunks to avoid overwhelming the server
                        await new Promise(resolve => setTimeout(resolve, 100));
                    }
    
                    // Verify upload progress with status check
                    await new Promise(resolve => setTimeout(resolve, STATUS_CHECK_INTERVAL));
                    const verifyStatus = await apiClient({
                        method: 'GET',
                        url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_STATUS,
                        params: { media_ids: mediaId }
                    });
    
                    if (verifyStatus.data[mediaId].status === 'completed') {
                        return mediaId;
                    }
    
                    const progress = (uploadedChunks.size / totalChunks * 100).toFixed(1);
    
                } catch (error) {
                    console.error(`Upload attempt ${failedAttempts + 1} failed:`, error);
                    failedAttempts++;
                    
                    if (failedAttempts < MAX_RETRIES) {
                        const backoffDelay = RETRY_DELAY * Math.pow(2, failedAttempts - 1);
                        await new Promise(resolve => setTimeout(resolve, backoffDelay));
                    } else {
                        throw new Error(`Upload failed after ${MAX_RETRIES} attempts`);
                    }
                }
            }
    
            // Final status check
            try {
                const finalStatus = await apiClient({
                    method: 'GET',
                    url: ENDPOINTS.MEDIA.UPLOAD_MEDIA_STATUS,
                    params: { media_ids: mediaId }
                });
    
                if (finalStatus.data[mediaId].status === 'completed') {
                    return mediaId;
                } else {
                    const progress = (uploadedChunks.size / totalChunks * 100).toFixed(1);
                    throw new Error(`Upload did not complete successfully. Progress: ${progress}%`);
                }
            } catch (error) {
                console.error('Final status check failed:', error);
                throw error;
            }
    
        } catch (error) {
            console.error('Error in uploadFile:', error);
            throw error;
        }
    },

    downloadStandardFile: async(mediaId) => {
        try {
            const response = await apiClient({
                method: 'GET',
                url: ENDPOINTS.MEDIA.DOWNLOAD_STANDARD,
                params: { media_id: mediaId },
                responseType: 'blob'
            });
            
            // Create an Object URL instead of base64 to save memory
            const url = URL.createObjectURL(response.data);
            
            return {
                mediaId,
                standardSizeUrl: url
            };
        } catch (error) {
            console.error('Error fetching standard media files:', error);
            throw error;
        }
    },

    downloadRawFile: async (mediaId) => {
        try {
            const response = await apiClient({
                method: 'GET',
                url: ENDPOINTS.MEDIA.DOWNLOAD_ORIGINAL,
                params: { media_id: mediaId },
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            return true;
        } catch (error) {
            console.error('Error downloading file:', error);
            throw error;
        }
    },

    deleteFile: async (mediaId, collectionId) => {
        try {
            await apiClient({
                method: 'POST',
                url: ENDPOINTS.MEDIA.DELETE_FILE,
                data: {
                    media_id: mediaId,
                    collection_id: collectionId
                }
            });
            return true;
        } catch (error) {
            console.error('Error deleting file:', error);
            throw error;
        }
    },

    updateEntityThumbnailImage: async (entityId, entityType, thumbnailFile) => {
        try {
            const formData = new FormData();
            formData.append('id', entityId);
            formData.append('type', entityType);
            formData.append('file', thumbnailFile);

            const response = await apiClient({
                method: 'POST',
                url: ENDPOINTS.MEDIA.UPLOAD_THUMBNAIL_IMAGE,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response;
        } catch (error) {
            console.error('Error updating Entity thumbnail image with error:', error);
            throw error;
        }
    }
};