import { useMutation } from "@tanstack/react-query";
import { eventApi } from "../services/eventApi";
import { useEventStore } from "../store/eventStore";

export const useEventDetails = () => {
    const { setEvents } = useEventStore();

    const fetchEventDetailsMutation = useMutation({
        mutationFn: async (eventIds) => {
            if (Array.isArray(eventIds) && typeof eventIds[0] === 'object') {
                eventIds = eventIds.map(event => event.event_id);
            }

            if (!Array.isArray(eventIds)) {
                eventIds = [eventIds];
            }

            const responses = await Promise.all(
                eventIds.map(id => eventApi.getEventDetails(id))
            );

            return responses;
        },
        onSuccess: (data) => {
            setEvents(data);
        }
    });

    return {
        fetchEventDetails: fetchEventDetailsMutation.mutate
    };
};