import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useShareableLinkStore = create(
    persist(
        (set, get) => ({
            shareableToken: {},
            setShareableToken: (shareableTokenData) => {
                // Log the current and new state
                console.log('Setting shareableToken:', {
                    current: get().shareableToken,
                    new: shareableTokenData
                });
                
                // Always ensure the token is set explicitly, and handle partial updates
                if (shareableTokenData) {
                    const currentToken = get().shareableToken || {};
                    
                    // Merge new data with existing data, prioritizing new values
                    const updatedToken = {
                        ...currentToken,
                        ...shareableTokenData,
                        // Ensure token property is always set correctly
                        token: shareableTokenData.token || currentToken.token
                    };
                    
                    console.log('Updated token state:', updatedToken);
                    
                    // Explicitly update localStorage as a backup
                    if (updatedToken.token) {
                        localStorage.setItem('shareable_token', updatedToken.token);
                    }
                    
                    set({ shareableToken: updatedToken });
                } else {
                    console.warn('Attempted to set shareableToken with falsy value:', shareableTokenData);
                }
            },
            clearShareableToken: () => {
                console.log('Clearing shareableToken');
                localStorage.removeItem('shareable_token');
                set({ shareableToken: {} });
            }
        }),
        {
            name: 'shareable-token-storage',
            // Add version for potential migrations
            version: 1
        }
    )  
);
