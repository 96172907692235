import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { TEXT } from './constants';
import { Box, Typography, Button } from '@mui/material';
import { useGroupStore } from '../../store/groupStore';
import { useCollectionStore } from '../../../collection/store/collectionStore';
import { sortCollectionsByYear, getSortedYears } from '../../utils/SortCollectionsByYear';
import GroupCollectionList from '../group-collection-list/GroupCollectionList';
import { dateConvert } from '../../../../utils/DateConverter';
import placeholderImage from '../../../../assets/images/placeholder/group_placeholder3.jpeg';

const GroupView = ({ groupId }) => {
    const navigate = useNavigate();
    const { groups } = useGroupStore();
    const { collections } = useCollectionStore();
    
    const groupInfo = groups.find(group => group.group_id === groupId);
    
    if (!groupInfo) {
        // If group not found, navigate back to groups list
        navigate('/groups', { replace: true });
        return null;
    }

    const handleCreateCollection = () => {
        navigate(`/groups/${groupId}/collections/new`);
    };

    const handleEditGroup = () => {
        navigate(`/groups/${groupId}/edit`);
    };


    const groupName = groupInfo.group_name;
    const groupDescription = groupInfo.group_description;
    const groupCreatedDate = groupInfo.group_created_on_date ? 
        dateConvert(groupInfo.group_created_on_date) : 'n/a';
    const numGroupMembers = groupInfo.users?.length || 0;

    // Sort collections by year
    const collectionsByYear = sortCollectionsByYear(groupInfo, collections);
    const years = getSortedYears(collectionsByYear);

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    height: 'auto',
                    width: 'auto',
                    padding: '32px',
                    marginBottom: '32px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '32px',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                }}
            >
                <Box
                    component="img"
                    src={groupId.image_url || placeholderImage}
                    sx={{
                            height: '240px',
                            width: '100%',  // ensure full width
                            alignSelf: 'stretch',
                            borderRadius: '10px',
                            border: '1px solid #E6E8E9',
                            objectFit: 'cover'
                    }}
                />

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Typography
                        sx={{
                            color: '#000',
                            fontFamily: 'Wanted Sans',
                            fontSize: '32px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal'
                        }}
                    >
                        { groupName }
                    </Typography>
                    
                    <Typography
                        sx={{
                            color: '#000',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal'
                        }}
                    >
                        { groupDescription }
                    </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Typography
                        sx={{
                            color: '#565656',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px'
                        }}
                    >
                        { TEXT.CREATED }
                        <span className={styles['value']}>
                            { groupCreatedDate }
                        </span>
                    </Typography>
                    
                    <Typography
                        sx={{
                            color: '#565656',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '16px',
                        }}
                    >
                        { TEXT.MEMBERS } 
                        <span className={styles['value']}>
                            { numGroupMembers }
                        </span>
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: '16px', alignSelf: 'stretch' }}>
                    <Button
                    onClick={handleCreateCollection}
                    variant="contained"
                        sx={{
                            display: 'flex',
                            width: 'auto',
                            height: '42px',
                            padding: '8px 22px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                            border: '1px solid #0345D7',
                            background: '#0357D7',
                            textTransform: 'none',
                            fontFamily: 'Wanted Sans',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '26px'
                        }}
                    >
                        { TEXT.CREATE_COLLECTION }
                    </Button>
                    <Button
                        onClick={handleEditGroup}
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            color: '#0357D7',
                            padding: '8px 22px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            border: '1px solid #0357D7',
                            textTransform: 'none',
                            fontFamily: 'Wanted Sans',
                            fontSize: '15px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '26px'
                        }}
                    >
                        { TEXT.EDIT_INFO }
                    </Button>
                </Box>
            </Box>
                {years.map(year => (
                    <GroupCollectionList
                        key={year}
                        year={year}
                        collections={collectionsByYear[year]}
                    />
                ))}
            <Box
                sx={{
                    marginTop: 'calc(5% + 100px)',
                    bottom: '0'
                }}
            />
    </Box>
);
};

export default GroupView;