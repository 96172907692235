// useMedia.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mediaApi } from "../services/mediaApi";
import { useMediaStore } from "../store/mediaStore";
import { useCollectionStore } from "../../collection/store/collectionStore";
import { isEmpty } from "../../../utils/Utils";

export const useMedia = () => {
    const queryClient = useQueryClient();
    const { setMedia, setUploadStatus } = useMediaStore();
    const { addMediaToCollection } = useCollectionStore();

    const getMediaThumbnail = async (mediaId) => {
        try {
            const response = await mediaApi.getMediaThumbnailImage(mediaId);
            return { success: true, thumbnailUrl: response };
        } catch (error) {
            console.error('Error fetching thumbnail:', error);
            return { success: false, error };
        }
    };

    const fetchMediaInfoMutation = useMutation({
        mutationFn: async ({ mediaIds, options = { batch: false } }) => {
            if (!Array.isArray(mediaIds)) {
                mediaIds = [mediaIds];
            }

            const fetchDetails = async (id) => {
                const details = await mediaApi.getMediaDetails(id);
                return { ...details, mediaId: id };
            };

            if (options.batch) {
                const responses = await Promise.all(
                    mediaIds.map(fetchDetails)
                );
                return { responses, batch: true };
            } else {
                const details = await fetchDetails(mediaIds[0]);
                return { responses: [details], batch: false };
            }
        },
        onSuccess: (data) => {
            const { responses } = data;
            
            responses.forEach(({ mediaId, ...mediaDetails }) => {
                try {
                    setMedia(prev => {
                        const existingIndex = prev.findIndex(item => item.media_id === mediaId);
                        if (existingIndex >= 0) {
                            const updatedMedia = [...prev];
                            updatedMedia[existingIndex] = { 
                                ...updatedMedia[existingIndex], 
                                ...mediaDetails 
                            };
                            return updatedMedia;
                        }
                        return [...prev, { media_id: mediaId, ...mediaDetails }];
                    });
                } catch (error) {
                    console.error('Error updating media store:', error);
                }
            });
        }
    });

    const uploadMutation = useMutation({
        mutationFn: async ({ file, collectionId, groupId, userId }) => {
            const tempId = `temp-${Date.now()}`;
            setUploadStatus(tempId, 'uploading');
            
            try {
                const mediaId = await mediaApi.uploadFile(
                    file, 
                    collectionId, 
                    groupId, 
                    userId
                );
                
                await new Promise(resolve => setTimeout(resolve, 1000));
                const mediaDetails = await mediaApi.getMediaDetails(mediaId);
                
                const newMediaItem = {
                    media_id: mediaId,
                    media_name: file.name,
                    media_type: file.type,
                    size_in_bytes: file.size,
                    collection_id: collectionId,
                    ...mediaDetails
                };

                setMedia(prev => [...prev, newMediaItem]);
                setUploadStatus(mediaId, 'complete');
                addMediaToCollection(collectionId, newMediaItem);

                return { mediaId, newMediaItem };
            } catch (error) {
                setUploadStatus(tempId, 'error');
                throw error;
            }
        },
        onSuccess: (_, { collectionId }) => {
            queryClient.invalidateQueries(['media']);
            queryClient.invalidateQueries(['collection', collectionId]);
        }
    });

    const standardSizeMutation = useMutation({
        mutationFn: async (mediaId) => {
            const response = await mediaApi.downloadStandardFile(mediaId);
            return response;
        }
    });

    const downloadRawMutation = useMutation({
        mutationFn: (mediaId) => mediaApi.downloadRawFile(mediaId)
    });

    const deleteMutation = useMutation({
        mutationFn: ({ mediaId, collectionId }) => 
            mediaApi.deleteFile(mediaId, collectionId),
        onSuccess: (_, { mediaId }) => {
            try {
                setMedia(prev => prev.filter(item => item.media_id !== mediaId));
                queryClient.invalidateQueries(['media']);
            } catch (error) {
                console.error('Error updating media store after delete:', error);
            }
        }
    });

    const fetchShareableLinkMutation = useMutation({
        mutationFn: async ({ userId, groupId, collectionId }) => {
            return await mediaApi.getShareableLink(userId, groupId, collectionId);
        },
        onSuccess: (data) => {
            return data;
        },
        onError: (error) => {
            console.error('Error in shareable link mutation:', error);
            throw error;
        }
    });

    const updateEntityThumbnailImageMutation = useMutation({
        mutationFn: async(variables) => {
            const { entityId, entityType, entityThumbnailFile } = variables;
            
            if (!isEmpty(entityId) && !isEmpty(entityType) && entityThumbnailFile) {
                const response = await mediaApi.updateEntityThumbnailImage(entityId, entityType, entityThumbnailFile);
                return response;
            } else {
                return;
            }
        },
        onSuccess: () => {
            console.log('Entity thumbnail image was updated')
        },
        onError: (error) => {
            console.error(`Error in updating Entity thumbnail image with error:`, error);
            throw error;
        }
    });

    return {
        uploadFile: uploadMutation.mutate,
        uploadFileAsync: uploadMutation.mutateAsync,
        isUploading: uploadMutation.isPending,
        uploadError: uploadMutation.error,

        fetchMediaInfo: (mediaIds, options) => fetchMediaInfoMutation.mutate({ mediaIds, options }),
        fetchMediaInfoAsync: (mediaIds, options) => fetchMediaInfoMutation.mutateAsync({ mediaIds, options }),
        isFetchingMediaInfo: fetchMediaInfoMutation.isPending,
        mediaInfoError: fetchMediaInfoMutation.error,

        downloadStandardFile: standardSizeMutation.mutateAsync,
        isStandardDownloading: standardSizeMutation.isPending,
        standardDownloadError: standardSizeMutation.error,

        downloadRawFile: downloadRawMutation.mutate,
        isRawDownloading: downloadRawMutation.isPending,
        rawDownloadError: downloadRawMutation.error,

        deleteFile: deleteMutation.mutate,
        isDeleting: deleteMutation.isPending,
        deleteError: deleteMutation.error,

        fetchShareableLink: (userId, groupId, collectionId) => 
            fetchShareableLinkMutation.mutateAsync({ userId, groupId, collectionId }),
        isFetchingShareableLink: fetchShareableLinkMutation.isPending,
        fetchShareableLinkError: fetchShareableLinkMutation.error,

        getMediaThumbnail,

        updateThumbnailImage: updateEntityThumbnailImageMutation.mutateAsync,
        isUpdateThumbnailImageLoading: updateEntityThumbnailImageMutation.isPending,
        updateThumbnailImageError: updateEntityThumbnailImageMutation.error
    };
};