import React, { useRef, useState } from 'react';
import { Button, Typography, Box, Paper } from '@mui/material';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import { useShareableData } from '../../hooks/useShareableData';
import { useFileUpload } from '../../hooks/usePublicFileUpload';
import { useShareableLinkStore } from '../../store/shareableLinkStore';
import { TEXT } from './constants.js';

const PublicMediaFileUpload = ({
    variant = 'button',
    width,
    height,
    acceptedTypes = '*',
    maxSizeConfig = {
        image: 5 * 1024 * 1024,  // 5MB for images (increased from 3MB)
        video: 100 * 1024 * 1024  // 100MB for videos (increased from 3MB)
    },
    onFileSelect,
    onUploadSuccess,
    buttonText = 'Upload File',
    disabled = false,
    className,
    ariaLabel,
    collectionId,
    groupId,
    userId 
}) => {
    const inputRef = useRef(null);
    const { uploadMediaFilePublicAsync, getMediaThumbnail, isUploading } = useShareableData();
    const [isDragging, setIsDragging] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    const maxVideoSizeUpload = Math.floor(maxSizeConfig.video / 1024 / 1024);
    const maxImageSizeUpload = Math.floor(maxSizeConfig.image / 1024 / 1024);
    
    const getFileRequirementsText = () => {
        if (acceptedTypes.includes('image')) {
            return `Image files (max. ${maxImageSizeUpload}MB)`;
        } else if (acceptedTypes.includes('video')) {
            return `Video files (max. ${maxVideoSizeUpload}MB)`;
        }
        return `Image and video files (max. ${maxImageSizeUpload}MB for images, ${maxVideoSizeUpload}MB for videos)`;
    };

    const handleFileSelection = async (file) => {
        if (!file) return;
        
        // Reset any previous errors
        setUploadError(null);
        
        // Notify parent component about file selection
        onFileSelect?.(file);
    
        try {
            console.log('Starting file upload:', file.name, 'Size:', file.size, 'Type:', file.type);
            
            // Ensure token is in localStorage before upload
            const token = localStorage.getItem('shareable_token');
            if (!token) {
                console.warn('No token found in localStorage before upload, checking store');
                // Get the current state of the store
                const store = useShareableLinkStore.getState();
                const storeToken = store?.shareableToken?.token;
                
                if (storeToken) {
                    console.log('Token found in store, saving to localStorage');
                    localStorage.setItem('shareable_token', storeToken);
                } else {
                    throw new Error('No authentication token available for upload');
                }
            } else {
                console.log('Token verified before upload:', token.substring(0, 10) + '...');
            }
            
            // Upload the file
            const result = await uploadMediaFilePublicAsync({
                file,
                collectionId,
                groupId,
                userId
            });
            
            console.log('Upload result:', result);
            
            if (!result || !result.mediaId) {
                console.error('Upload failed: No media ID returned');
                setUploadError('Upload failed. Please try again.');
                return;
            }
            
            // Re-verify token is still in localStorage after upload
            const postUploadToken = localStorage.getItem('shareable_token');
            if (!postUploadToken) {
                console.warn('Token missing after upload, restoring from pre-upload value');
                localStorage.setItem('shareable_token', token);
            }
            
            // Add a small delay to ensure the server has processed the upload
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Try to fetch thumbnail up to 3 times with delays
            let thumbnailResult = null;
            let isHeicFile = file.type.toLowerCase().includes('heic') || file.type.toLowerCase().includes('heif');
            
            // For HEIC files, give the server more time since they need transcoding
            const maxAttempts = isHeicFile ? 5 : 3;
            const delayBetweenAttempts = isHeicFile ? 2000 : 1000;
            
            for (let i = 0; i < maxAttempts; i++) {
                console.log(`Attempting to fetch thumbnail (attempt ${i + 1}/${maxAttempts})`);
                try {
                    thumbnailResult = await getMediaThumbnail(result.mediaId);
                    console.log('Thumbnail result:', thumbnailResult);
                    if (thumbnailResult?.success && thumbnailResult.thumbnailUrl) {
                        break;
                    }
                } catch (thumbnailError) {
                    console.error('Error fetching thumbnail:', thumbnailError);
                }
                await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
            }
            
            const newMediaItem = {
                media_id: result.mediaId,
                media_name: file.name,
                media_type: file.type,
                size_in_bytes: file.size,
                collection_id: collectionId,
                thumbnailUrl: thumbnailResult?.success ? thumbnailResult.thumbnailUrl : null
            };

            console.log('Upload completed successfully:', newMediaItem);
            onUploadSuccess?.(newMediaItem);
        } catch (error) {
            console.error('Upload failed:', error);
            setUploadError(`Upload failed: ${error.message || 'Unknown error'}`);
        }
    };

    const { error, handleFile } = useFileUpload({
        acceptedTypes,
        maxSizeConfig,
        onFileSelect: handleFileSelection,
    });

    const handleButtonClick = () => {
        inputRef.current?.click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const ButtonVariant = () => (
        <Box>
            <input
                ref={inputRef}
                type="file"
                accept={acceptedTypes}
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    handleFile(file);
                    e.target.value = '';
                }}
                style={{ display: 'none' }}
                aria-label={ariaLabel || buttonText}
            />
            <Button
                onClick={handleButtonClick}
                disabled={disabled || isUploading}
                component="span"
                className={className}
                sx={{
                    display: 'flex',
                    width: width,
                    height: height,
                    padding: '8px 22px',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #0357D7',
                    backgroundColor: '#0357D7',
                    '&:hover': {
                        backgroundColor: '#0243ac',
                    },
                    '&:disabled': {
                        backgroundColor: '#cccccc',
                        borderColor: '#cccccc',
                    }
                }}
            >
                <Typography
                    sx={{
                        fontSize: '15px',
                        fontWeight: '500',
                        lineHeight: '26px',
                        color: '#FFF',
                        textTransform: 'none'
                    }}
                >
                    {isUploading ? TEXT.UPLOADING : buttonText}
                </Typography>
            </Button>
            {uploadError && (
                <Typography
                    color="error"
                    variant="caption"
                    sx={{ mt: 1, display: 'block' }}
                >
                    {uploadError}
                </Typography>
            )}
        </Box>
    );

    const DropzoneVariant = () => (
        <Box>
            <input
                ref={inputRef}
                type="file"
                accept={acceptedTypes}
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    handleFile(file);
                    e.target.value = '';
                }}
                style={{ display: 'none' }}
                aria-label={ariaLabel || "Drop files here or click to upload"}
            />
            <Paper
                variant="outlined"
                onClick={handleButtonClick}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsDragging(false);

                    const file = e.dataTransfer.files?.[0];
                    if (file) {
                        handleFile(file);
                    }
                }}
                sx={{
                    border: theme => `2px dashed ${isDragging ? theme.palette.primary.main : '#2196F3'}`,
                    bgcolor: theme => isDragging ? '#2196F314' : 'rgba(33, 150, 243, 0.08)',
                    width: width,
                    height: height,
                    borderRadius: '8px',
                    p: 4,
                    textAlign: 'center',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    minHeight: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                        bgcolor: 'rgba(3, 87, 215, 0.04)',
                        borderColor: '#0357D7',
                    },
                    opacity: isUploading ? 0.7 : 1,
                    pointerEvents: isUploading ? 'none' : 'auto'
                }}
            >
                <UploadFileIcon 
                    sx={{ 
                        width: '24px',
                        height: '24px',
                        color: isDragging ? '#0357D7' : 'text.secondary',
                        mb: 2 
                    }} 
                />
                <Box sx={{ textAlign: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '175%',
                            letterSpacing: '.15px',
                            fontFamily: 'Wanted Sans',
                            color: '#2196F3',
                            textDecoration: 'underline',
                            display: 'inline'
                        }}
                    >
                        { TEXT.CLICK_TO_UPLOAD }
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '17px',
                            fontFamily: 'Wanted Sans',
                            fontWeight: '400',
                            lineHeight: '175%',
                            letterSpacing: '.15px',
                            display: 'inline',
                            ml: 1
                        }}
                    >
                        { TEXT.OR_DRAG_AND_DROP }
                    </Typography>
                </Box>

                <Typography 
                    sx={{
                        color: 'rgba(0, 0, 0, 0.60)',
                        fontFamily: 'Wanted Sans',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        textAlign: 'center',
                        height: '143%',
                        mt: 1
                    }}
                >
                    {isUploading ? TEXT.UPLOADING : getFileRequirementsText()}
                </Typography>
            </Paper>
            {(error || uploadError) && (
                <Typography
                    color="error"
                    variant="caption"
                    sx={{ mt: 1, display: 'block' }}
                >
                    {error || uploadError}
                </Typography>
            )}
        </Box>
    );

    return (
        <Box sx={{ width: '100%' }}>
            {variant === 'button' ? <ButtonVariant /> : <DropzoneVariant />}
        </Box>
    );
};

export default PublicMediaFileUpload;
