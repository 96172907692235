import { getMediaTypeCounts } from "../../../utils/GetMediaTypeCounts";

export const getGroupCollectionsWithMediaCounts = (groupId, collections) => {
    // Filter collections by group ID
    const groupCollections = collections.filter(collection => 
        collection.group_id === groupId
    );

    // Calculate total media counts across all collections
    const totalMediaCounts = {
        image: 0,
        video: 0,
        unknown: 0
    };

    // Add media counts for each collection
    groupCollections.forEach(collection => {
        // Skip if collection has no media array or empty media array
        if (!collection.media || collection.media.length === 0) return;
        
        const collectionCounts = getMediaTypeCounts(collection.media);
        
        // Add to total counts
        totalMediaCounts.image += collectionCounts.image;
        totalMediaCounts.video += collectionCounts.video;
        totalMediaCounts.unknown += collectionCounts.unknown;
        
        // Add the counts to the collection object itself
        collection.mediaCounts = collectionCounts;
    });

    return {
        totalMediaCounts
    };
};