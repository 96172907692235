import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useUserStore = create(
    persist(
        (set) => ({
            user: null,
            userFeedActivites: [],
            setUser: (userData) => set({ user: userData }),
            setUserFeedActivities: (userFeedActivitiesData) => set({ userFeedActivites: userFeedActivitiesData }),
            clearUser: () => {
                // Force a clean slate on clear
                set({ user: null, userFeedActivites: [] }, true);
                
                // Additional cleanup to prevent stale state
                try {
                    localStorage.removeItem('user-storage');
                } catch (e) {
                    console.error('Error clearing user storage:', e);
                }
            },
            clearUserFeedActivities: () => set({ userFeedActivites: [] })
        }),
        {
            name: 'user-storage',
            // Add version for better cache busting
            version: 1,
            // Use a custom storage that handles errors
            storage: createJSONStorage(() => ({
                getItem: (name) => {
                    try {
                        return localStorage.getItem(name);
                    } catch (e) {
                        console.error(`Error getting storage ${name}:`, e);
                        return null;
                    }
                },
                setItem: (name, value) => {
                    try {
                        localStorage.setItem(name, value);
                    } catch (e) {
                        console.error(`Error setting storage ${name}:`, e);
                    }
                },
                removeItem: (name) => {
                    try {
                        localStorage.removeItem(name);
                    } catch (e) {
                        console.error(`Error removing storage ${name}:`, e);
                    }
                }
            }))
        }
    )
);