import { Box } from '@mui/material';
import { CollectionListItem } from '../collection-list-item/CollectionListItem';
import { useCollectionStore } from '../../store/collectionStore';

export const CollectionList = ({ sortBy }) => {
    const { collections } = useCollectionStore();
    
    // Sort collections based on the date
    const sortedCollections = [...collections].sort((a, b) => {
        const dateA = new Date(a.collection_created_on_date);
        const dateB = new Date(b.collection_created_on_date);
        
        // For 'latest', we want newest first (descending order)
        if (sortBy === 'latest') {
            return dateB - dateA;
        }
        // For 'oldest', we want oldest first (ascending order)
        else if (sortBy === 'oldest') {
            return dateA - dateB;
        }
        
        // Default to latest if sortBy is not recognized
        return dateB - dateA;
    });

    return (
        <Box
        sx={{
            display: 'flex',
            height: 'auto',
            marginTop: '20px',
            padding: '32px',
            gap: '32px',
            alignSelf: 'stretch',
            borderRadius: '10px',
            border: '1px solid #E6E8E9',
            background: '#FFF',
            flexWrap: 'wrap',
            justifyContent: 'flex-start'
        }}
    >
        {sortedCollections.map((collection) => (
            <CollectionListItem
                key={collection.collection_id}
                collection={collection}
            />
        ))}
    </Box>
    );
};