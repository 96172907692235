// mediaStore.js - Quick fix for quota issues
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useMediaStore = create(
    persist(
        (set, get) => ({
            media: [],
            uploadStatuses: {},

            setMedia: (mediaData) => {
                const state = get();
                let processedMedia;
                
                try {
                    // Determine if this is a function or direct data
                    processedMedia = typeof mediaData === 'function' 
                        ? mediaData(state.media)
                        : mediaData;
                    
                    // Strip large data from items to save space
                    const optimizedMedia = Array.isArray(processedMedia) 
                        ? processedMedia.map(item => ({
                            ...item,
                            // Keep thumbnail but remove full-size data
                            standardSizeUrl: undefined,
                            last_accessed: Date.now()
                          }))
                        : processedMedia;
                    
                    // Keep only the 20 most recent items to prevent quota issues
                    let finalMedia = Array.isArray(optimizedMedia) ? optimizedMedia : [optimizedMedia];
                    
                    // If we have too many items in total, prune the oldest
                    const allMedia = [...state.media];
                    const newItemsMap = new Map(finalMedia.map(item => 
                        [item.media_id || item.tempId || item.id, item]
                    ));
                    
                    // Merge existing items with new ones
                    for (const item of finalMedia) {
                        const id = item.media_id || item.tempId || item.id;
                        const existingIndex = allMedia.findIndex(m => 
                            (m.media_id || m.tempId || m.id) === id
                        );
                        
                        if (existingIndex >= 0) {
                            allMedia[existingIndex] = item;
                        } else {
                            allMedia.push(item);
                        }
                    }
                    
                    // Sort by last_accessed and limit to 20 items max
                    const sortedMedia = allMedia.sort((a, b) => 
                        (b.last_accessed || 0) - (a.last_accessed || 0)
                    ).slice(0, 20);
                    
                    set({ media: sortedMedia });
                } catch (error) {
                    // Handle quota error gracefully
                    console.error('Error in setMedia:', error);
                    
                    // If we hit quota error, clear half of the oldest items
                    if (error.name === 'QuotaExceededError') {
                        try {
                            // Keep only 10 most recent items
                            const reducedMedia = state.media
                                .sort((a, b) => (b.last_accessed || 0) - (a.last_accessed || 0))
                                .slice(0, 10);
                            
                            set({ media: reducedMedia });
                            
                            // Try to add the new item(s) again, but only most important fields
                            const criticalData = Array.isArray(processedMedia)
                                ? processedMedia.map(item => ({
                                    media_id: item.media_id,
                                    media_name: item.media_name,
                                    media_type: item.media_type,
                                    collection_id: item.collection_id,
                                    last_accessed: Date.now()
                                  }))
                                : {
                                    media_id: processedMedia.media_id,
                                    media_name: processedMedia.media_name,
                                    media_type: processedMedia.media_type,
                                    collection_id: processedMedia.collection_id,
                                    last_accessed: Date.now()
                                  };
                            
                            set({ media: [...reducedMedia, ...Array.isArray(criticalData) ? criticalData : [criticalData]] });
                        } catch (innerError) {
                            // Last resort: clear everything
                            console.error('Failed to recover from quota error:', innerError);
                            set({ media: [] });
                        }
                    }
                    
                    throw error;
                }
            },

            setUploadStatus: (mediaId, status) => set(state => ({
                uploadStatuses: {
                    ...state.uploadStatuses,
                    [mediaId]: status
                }
            })),

            // Get media item, updating last_accessed
            getMedia: (mediaId) => {
                const state = get();
                const mediaItem = state.media.find(item => item.media_id === mediaId);
                if (!mediaItem) return null;

                // Update last_accessed time when media is accessed
                set(state => ({
                    media: state.media.map(item =>
                        item.media_id === mediaId
                            ? { ...item, last_accessed: Date.now() }
                            : item
                    )
                }));
                
                return mediaItem;
            },

            updateLastAccessed: (mediaId) => {
                set(state => ({
                    media: state.media.map(item =>
                        item.media_id === mediaId
                            ? { ...item, last_accessed: Date.now() }
                            : item
                    )
                }));
            },

            clearMedia: () => set({ 
                media: [], 
                uploadStatuses: {} 
            }),
            
            // Emergency function to clear storage when needed
            emergencyClearStorage: () => {
                try {
                    localStorage.removeItem('media-storage');
                    set({ media: [], uploadStatuses: {} });
                    return true;
                } catch (error) {
                    console.error('Failed to clear storage:', error);
                    return false;
                }
            }
        }),
        {
            name: 'media-storage',
            partialize: (state) => ({
                // Only store essential data to reduce storage size
                media: state.media.map(item => ({
                    media_id: item.media_id,
                    media_name: item.media_name,
                    media_type: item.media_type,
                    thumbnailUrl: item.thumbnailUrl,
                    collection_id: item.collection_id,
                    last_accessed: item.last_accessed,
                    // Explicitly remove large data
                    standardSizeUrl: undefined
                })),
                uploadStatuses: state.uploadStatuses
            })
        }
    )
);

// Add an emergency clear function to the window for use in case of errors
window.clearMediaStorage = () => {
    try {
        localStorage.removeItem('media-storage');
        alert('Media storage cleared. Please refresh the page.');
        return true;
    } catch (error) {
        console.error('Failed to clear storage:', error);
        return false;
    }
};