import { apiClient } from "../../../services/api/config";
import { ENDPOINTS } from "../../../services/api/endpoints";

export const userApi = {
    getUserDetails: async () => {
        try {
            const user_id = localStorage.getItem('user_id');
            
            if (!user_id) {
                throw new Error('No user ID found');
            }
            
            const config = {
                method: 'GET',
                url: ENDPOINTS.USER.RETRIEVE_USER_DETAILS,
                params: {
                    user_id: user_id
                }
            };
            
            const response = await apiClient(config);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getUserDetailsByUsername: async (username) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.USER.RETRIEVE_USER_DETAILS,
                params: {
                    user_name: username
                }
            };
            
            const response = await apiClient(config);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getUserDetailsByUserId: async (userId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.USER.RETRIEVE_USER_DETAILS,
                params: {
                    user_id: userId
                }
            };
            
            const response = await apiClient(config);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getUserProfileImage: async (userId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.MEDIA.RETRIEVE_THUMBNAIL_IMAGE,
                params: {
                    id: userId,
                    type: 'user'
                },
                responseType: 'blob'
            };

            const response = await apiClient(config);
            
            // Check if the response is empty
            if (!response.data || response.data.size === 0) {
                return null;
            }

            // Convert blob to base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Additional check for "null" content
                    if (reader.result === "data:application/json;base64,bnVsbA==") {
                        resolve(null);
                    } else {
                        resolve(reader.result);
                    }
                };
                reader.onerror = reject;
                reader.readAsDataURL(response.data);
            });
        } catch (error) {
            console.error('Error fetching User profile image:', error);
            throw error;
        }
    },

    updateUser: async(requestPayload) => {
        try {
            const config = {
                method: 'PATCH',
                url: ENDPOINTS.USER.UPDATE_USER,
                data: requestPayload
            };
            
            const response = await apiClient(config);
            return response;
        } catch (error) {
            console.error('Error updating User details:', error);
            throw error;
        }
    },

    getUserFeedActivities: async(userId, cursor = null, limit = 20) => {
        try {
            const config = {
                method: 'POST',
                url: ENDPOINTS.ACTIVITY.RETRIEVE_USER_FEED_ACTIVITIES,
                data: {
                    user_id: userId,
                    cursor: cursor,
                    limit: limit
                }
            };

            const response = await apiClient(config);
            return response.data;

        } catch (error) {
            console.error('Error fetching User feed activities:', error);
            throw error;
        }
    },
};