export const TEXT = {
    CREATE_COLLECTION: 'Create Collection',
    EDIT_COLLECTION: 'Edit Collection',
    UPLOAD_THUMBNAIL: 'Upload Thumbnail',
    UPLOAD_IMAGE: 'Upload Image',
    REMOVE: 'Remove',
    COLLECTION_INFORMATION: 'Collection Information',
    COLLECTION_NAME: 'Collection Name',
    COLLECTION_DESCRIPTION: 'Description',
    COLLECTION_LOCATION: 'Location',
    GROUP_NAME: 'Group Name',
    GROUPS_NOT_AVAILABLE: 'No groups available',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    GO_BACK_BUTTON_TEXT: 'go back',
    CREATE_COLLECTION_BUTTON_TEXT: 'create collection',
    SAVE_CHANGES_BUTTON_TEXT: 'save changes'
}