import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, TextField, Chip } from '@mui/material';
import { TEXT } from './constants';
import styles from './styles.module.css';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGroupDetails } from '../../hooks/useGroupDetails';
import { isEmpty } from '../../../../utils/Utils';
import { useGroup } from '../../hooks/useGroup';

const JoinGroupDialog = ({ open, onClose }) => {
    const { fetchAllGroups, allGroupsData } = useGroupDetails();
    const { addUserToGroups } = useGroup();
    const [allGroups, setAllGroups] = useState([]);
    const [groupNameInput, setGroupNameInput] = useState('');
    const [selectedGroups, setSelectedGroups] = useState([]); // For display (group names)
    const [selectedGroupIds, setSelectedGroupIds] = useState([]); // For processing (group IDs)
    const [matchedGroup, setMatchedGroup] = useState(null);
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        fetchAllGroups();
    }, []);
    
    useEffect(() => {
        if (!isEmpty(allGroupsData)) {
            setAllGroups(allGroupsData);
        }
    }, [allGroupsData]);

    const handleGroupNameChange = (event) => {
        const input = event.target.value;
        setGroupNameInput(input);
        
        if (!input || !allGroups) {
            setMatchedGroup(null);
            return;
        }
        
        // Check for match in allGroups
        const match = allGroups.find(group => 
            group?.group_name?.toLowerCase() === input.toLowerCase()
        );

        setMatchedGroup(match || null);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && matchedGroup && 
            !selectedGroupIds.includes(matchedGroup.group_id)) {
            // Add to both arrays
            setSelectedGroups([...selectedGroups, matchedGroup.group_name]);
            setSelectedGroupIds([...selectedGroupIds, matchedGroup.group_id]);
            // Clear input and matched group
            setGroupNameInput('');
            setMatchedGroup(null);
        }
    };

    const handleDeleteChip = (groupName) => {
        const index = selectedGroups.indexOf(groupName);
        if (index !== -1) {
            const newSelectedGroups = selectedGroups.filter((_, i) => i !== index);
            const newSelectedGroupIds = selectedGroupIds.filter((_, i) => i !== index);
            setSelectedGroups(newSelectedGroups);
            setSelectedGroupIds(newSelectedGroupIds);
        }
    };

    const handleJoinGroups = () => {
        if (selectedGroupIds.length > 0) {
            addUserToGroups({ groupIds: selectedGroupIds, userId });
            onClose();
        }
    };

    const textFieldCommonSx = {
        '& .MuiInputLabel-root': {
            fontFamily: 'Wanted Sans',
            fontWeight: '400',
            fontStyle: 'normal',
            '&.Mui-focused': {
                color: '#000000'
            }                
        },
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
                fontFamily: 'Wanted Sans'  // Add this to style the input text
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000000'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000000'
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="join-group-dialog"
            className={styles['dialog-container']}
            PaperProps={{
                className: styles['dialog-paper']
            }}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                className={styles['close-button']}
            >
                <CloseIcon className={styles['close-button-icon']} />
            </IconButton>
            <div className={styles['header-container']}>
                <DialogTitle
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '28px',
                        fontWeight: 700,
                        letterSpacing: '-1px',
                        marginBottom: '8px',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.JOIN_GROUP}
                </DialogTitle>
                <div className={styles['dialog-description']}>
                    {TEXT.JOIN_GROUP_DESCRIPTION}
                </div>
            </div>
            <DialogContent>
                <TextField
                    fullWidth
                    label="Search Group Name"
                    variant="outlined"
                    value={groupNameInput}
                    onChange={handleGroupNameChange}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                        endAdornment: matchedGroup ? (
                            <CheckCircleIcon sx={{ 
                                color: '#4CAF50', 
                                marginRight: '2px',
                            }} />
                        ) : null
                    }}
                    helperText={matchedGroup ? TEXT.GROUP_SEARCH_MATCH : ""}
                    FormHelperTextProps={{
                        sx: {
                            marginLeft: '4px',
                            fontFamily: 'Wanted Sans',
                            fontSize: '12px',
                            color: '#4CAF50'
                        }
                    }}
                    sx={{
                        marginBottom: '8px',
                        ...textFieldCommonSx
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontSize: '17px',
                                fontFamily: 'Wanted Sans'
                            }
                        }
                    }}
                />
                <div className={styles['chips-container']}>
                    {selectedGroups.map((groupName, index) => (
                        <Chip
                            key={index}
                            label={groupName}
                            onDelete={() => handleDeleteChip(groupName)}
                            deleteIcon={<CancelIcon />}
                            sx={{
                                marginTop: '8px',
                                marginBottom: '8px',
                                marginRight: '8px',
                                fontFamily: 'Wanted Sans'
                            }}
                        />
                    ))}
                </div>
                <TextField
                    fullWidth
                    label="Search Group URL"
                    variant="outlined"
                    sx={{
                        marginTop: '24px',
                        ...textFieldCommonSx
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontSize: '17px',
                                fontFamily: 'Wanted Sans'
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions
                sx={{
                    padding: '0 22.5px 10px 22.5px !important',
                    marginTop: '9px'
                }}
            >
                <Button
                    onClick={handleJoinGroups}
                    variant="contained"
                    sx={{
                        height: '36px',
                        width: '100%',
                        borderRadius: '4px',
                        border: '1px solid #0357D7',
                        background: '#0357D7',
                        textTransform: 'none',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '24px',
                        fontFamily: 'Wanted Sans',
                    }}
                >
                    {TEXT.JOIN_GROUP}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JoinGroupDialog;