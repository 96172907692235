export const compressImage = async (
    file,
    options = {
      maxWidth: 1200,
      maxHeight: 1200,
      quality: 0.85,
      maxSizeMB: 1
    }
  ) => {
    if (!file || !file.type.startsWith('image/')) {
      return file;
    }
  
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        
        img.onload = () => {
          // Calculate dimensions while maintaining aspect ratio
          let width = img.width;
          let height = img.height;
          
          if (width > options.maxWidth) {
            height = Math.round(height * options.maxWidth / width);
            width = options.maxWidth;
          }
          
          if (height > options.maxHeight) {
            width = Math.round(width * options.maxHeight / height);
            height = options.maxHeight;
          }
          
          // Create canvas for resizing
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          
          // Draw and export
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convert to blob with quality setting
          canvas.toBlob(
            blob => {
              // Create new file from blob
              const compressedFile = new File(
                [blob],
                file.name,
                { type: 'image/jpeg', lastModified: Date.now() }
              );
              
              console.log(`Image compressed: ${(file.size / 1024).toFixed(2)}KB → ${(compressedFile.size / 1024).toFixed(2)}KB (${Math.round(compressedFile.size / file.size * 100)}%)`);
              
              resolve(compressedFile);
            },
            'image/jpeg',
            options.quality
          );
        };
        
        img.onerror = () => {
          reject(new Error('Failed to load image'));
        };
      };
      
      reader.onerror = () => {
        reject(new Error('Failed to read file'));
      };
    });
  };