import styles from './styles.module.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TEXT } from './constants';
import GroupCard from '../group-card/GroupCard';
import { Button, Box } from '@mui/material';
import JoinGroupDialog from '../join-group-dialog/JoinGroupDialog';

const GroupList = ({ groups }) => {
    const navigate = useNavigate();
    const [showJoinGroupDialog, setShowJoinGroupDialog] = useState(false);

    const handleCloseDialog = () => {
        setShowJoinGroupDialog(false);
    };

    const handleCreateGroup = () => {
        navigate('/groups/new');
    };

    const handleGroupClick = (groupId) => {
        navigate(`/groups/${groupId}`);
    };

    return (
        <div className={styles['group-list-container']}>
            <div className={styles['header-container']}>
                <div className={styles['header']}>
                    {TEXT.MY_GROUPS}
                </div>
                <div className={styles['description']}>
                    {TEXT.TOTAL_MEDIA}
                </div>
                <div className={styles['button-container']}>
                    <Button
                        onClick={() => setShowJoinGroupDialog(true)}
                        disableRipple
                        sx={{
                            display: 'flex',
                            width: 'auto',
                            height: 'auto',
                            padding: '8px 22px',
                            justfyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid',
                            background: '#0357D7',
                            color: '#FFF',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '26px',
                            textTransform: 'none'
                        }}
                    >
                        {TEXT.JOIN_GROUP}
                    </Button>
                    <Button
                        onClick={handleCreateGroup}
                        disableRipple
                        sx={{
                            display: 'flex',
                            width: 'auto',
                            height: 'auto',
                            padding: '8px 22px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            border: '1px solid #0357D7',
                            color: '#0357D7',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '26px',
                            textTransform: 'none'
                        }}
                    >
                        {TEXT.CREATE_GROUP}
                    </Button>
                </div>
            </div>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(2, 1fr)',  // 0px and up (mobile)
                        md: 'repeat(3, 1fr)'   // 1001px and up
                    },
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    alignSelf: 'stretch',
                    gap: '20px',
                    flexShrink: 0
                }}
            >
                {groups.map((group) => (
                    <div 
                        key={group.group_id}
                        onClick={() => handleGroupClick(group.group_id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <GroupCard
                            data={group}
                        />
                    </div>
                ))}
            </Box>
            <JoinGroupDialog
                open={showJoinGroupDialog}
                onClose={handleCloseDialog}
            />
            <Box sx={{ marginTop: 'calc(5% + 100px)' }} />
        </div>
    );
};

export default GroupList;