import { Box, Typography, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useState, useRef, useEffect, useCallback } from 'react';
import EventListItem from '../event-list-item/EventListItem';

const EventList = ({ headerTitle, setActiveDialog: setShowEventDialog, events = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [dragOffset, setDragOffset] = useState(0);
    
    const containerRef = useRef(null);
    const animationRef = useRef(null);
    const velocityRef = useRef(0);
    const lastTimeRef = useRef(null);
    const lastDragXRef = useRef(null);
    const clickTimeRef = useRef(0);
    const clickedItemRef = useRef(null);
    const dragDistanceRef = useRef(0);
    
    const itemWidth = 330;
    const itemGap = 30;
    const itemsPerView = 3;
    const totalItems = events?.length || 0;
    const maxIndex = Math.max(0, totalItems - itemsPerView);
    const navigationDisabled = totalItems <= itemsPerView;

    // Get current position based on index and drag offset - identical to CollectionCarouselPreview
    const getCurrentPosition = useCallback(() => {
        return currentIndex * (itemWidth + itemGap) + dragOffset;
    }, [currentIndex, dragOffset]);

    // Smooth animation with easing function - identical to CollectionCarouselPreview
    const animateToPosition = useCallback((targetPosition) => {
        const startPosition = getCurrentPosition();
        const startTime = performance.now();
        const duration = 500;

        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            // Using cubic ease-out function for smooth deceleration - identical to CollectionCarouselPreview
            const easeOut = 1 - Math.pow(1 - progress, 3);
            const currentPosition = startPosition + (targetPosition - startPosition) * easeOut;
            
            const newIndex = Math.round(currentPosition / (itemWidth + itemGap));
            setCurrentIndex(Math.max(0, Math.min(newIndex, maxIndex)));
            setDragOffset(currentPosition - (newIndex * (itemWidth + itemGap)));

            if (progress < 1) {
                animationRef.current = requestAnimationFrame(animate);
            } else {
                // Clean up when animation is complete
                setDragOffset(0);
                setCurrentIndex(Math.round(targetPosition / (itemWidth + itemGap)));
            }
        };

        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        
        animationRef.current = requestAnimationFrame(animate);
    }, [getCurrentPosition, maxIndex]);

    // Navigation handlers using the smooth animation - identical to CollectionCarouselPreview
    const handleNext = useCallback(() => {
        const nextIndex = Math.min(currentIndex + 1, maxIndex);
        animateToPosition(nextIndex * (itemWidth + itemGap));
    }, [currentIndex, maxIndex, animateToPosition]);

    const handlePrev = useCallback(() => {
        const prevIndex = Math.max(currentIndex - 1, 0);
        animateToPosition(prevIndex * (itemWidth + itemGap));
    }, [currentIndex, animateToPosition]);

    // Restore pointer events after drag
    const restorePointerEvents = useCallback(() => {
        const eventItems = document.querySelectorAll('.event-item-container > *');
        eventItems.forEach(item => {
            item.style.pointerEvents = 'auto';
        });
    }, []);

    // Mouse/touch event handlers - closely matching CollectionCarouselPreview
    const handleMouseDown = useCallback((e) => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
        }
        
        const eventItemContainer = e.target.closest('.event-item-container');
        if (!eventItemContainer && !e.target.closest('.carousel-container')) {
            return;
        }
        
        // Handle click detection for showing event dialog
        const now = performance.now();
        const timeDiff = now - clickTimeRef.current;
        
        if (timeDiff < 300 && clickedItemRef.current === eventItemContainer) {
            const eventId = eventItemContainer?.getAttribute('data-event-id');
            if (eventId) {
                const event = events.find(e => e.event_id === eventId);
                if (event) {
                    setShowEventDialog({show: true, eventId: eventId});
                    return;
                }
            }
        }
        
        clickTimeRef.current = now;
        clickedItemRef.current = eventItemContainer;
        
        // Disable pointer events during drag
        const eventItems = document.querySelectorAll('.event-item-container > *');
        eventItems.forEach(item => {
            item.style.pointerEvents = 'none';
        });
        
        setIsDragging(true);
        setStartX(e.pageX);
        lastTimeRef.current = now;
        lastDragXRef.current = e.pageX;
        velocityRef.current = 0;
        dragDistanceRef.current = 0;
    }, [events, setShowEventDialog]);

    const handleMouseMove = useCallback((e) => {
        if (!isDragging) return;
        e.preventDefault();

        const currentX = e.pageX;
        const deltaX = startX - currentX;
        
        // Track velocity for momentum effect - identical to CollectionCarouselPreview
        const currentTime = performance.now();
        const timeDelta = currentTime - lastTimeRef.current;
        const dragDelta = lastDragXRef.current - currentX;
        
        dragDistanceRef.current += Math.abs(dragDelta);
        
        if (timeDelta > 0) {
            velocityRef.current = dragDelta / timeDelta;
        }
        
        lastTimeRef.current = currentTime;
        lastDragXRef.current = currentX;

        // Update position with clamping - identical to CollectionCarouselPreview
        const newPosition = getCurrentPosition() + deltaX;
        const maxPosition = maxIndex * (itemWidth + itemGap);
        const clampedPosition = Math.max(0, Math.min(newPosition, maxPosition));
        
        const newIndex = Math.floor(clampedPosition / (itemWidth + itemGap));
        const newOffset = clampedPosition - (newIndex * (itemWidth + itemGap));
        
        setCurrentIndex(newIndex);
        setDragOffset(newOffset);
        setStartX(currentX);
    }, [isDragging, startX, getCurrentPosition, maxIndex]);

    const handleMouseUp = useCallback(() => {
        if (!isDragging) return;
        setIsDragging(false);

        // If barely moved, treat as a potential click
        if (dragDistanceRef.current < 5) {
            restorePointerEvents();
            return;
        }

        // Apply momentum based on final velocity - identical to CollectionCarouselPreview
        const velocity = velocityRef.current;
        const momentum = velocity * 100; // Apply momentum for natural feel
        const targetPosition = getCurrentPosition() + momentum;
        
        // Snap to the nearest item position - identical to CollectionCarouselPreview
        const nearestIndex = Math.round(targetPosition / (itemWidth + itemGap));
        const clampedIndex = Math.max(0, Math.min(nearestIndex, maxIndex));
        
        // Animate to the final position
        animateToPosition(clampedIndex * (itemWidth + itemGap));
        
        setTimeout(restorePointerEvents, 0);
    }, [isDragging, getCurrentPosition, animateToPosition, maxIndex, restorePointerEvents]);

    const handleMouseLeave = useCallback(() => {
        if (isDragging) {
            handleMouseUp();
        }
    }, [isDragging, handleMouseUp]);

    // Clean up animations on unmount
    useEffect(() => {
        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, []);

    return (
        <Box
            sx={{
                paddingTop: '24px',
                paddingBottom: '24px',
                paddingLeft: '32px',
                paddingRight: '32px',
                borderRadius: '10px',
                border: '1px solid #E6E8E9',
                background: '#FFF',
                width: '100%'
            }}
        >
            {/* Header with navigation */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px'
            }}>
                <Typography
                    sx={{
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        letterSpacing: '-1px'
                    }}
                >
                    {headerTitle}
                </Typography>
                <Box>
                    <IconButton
                        onClick={handlePrev}
                        disabled={navigationDisabled || currentIndex <= 0}
                        sx={{
                            color: (navigationDisabled || currentIndex <= 0) ? '#E6E8E9' : '#000',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <IconButton
                        onClick={handleNext}
                        disabled={navigationDisabled || currentIndex >= maxIndex}
                        sx={{
                            color: (navigationDisabled || currentIndex >= maxIndex) ? '#E6E8E9' : '#000',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        <ChevronRight />
                    </IconButton>
                </Box>
            </Box>

            {/* Carousel container */}
            <Box 
                ref={containerRef}
                className="carousel-container"
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',
                    cursor: isDragging ? 'grabbing' : 'grab',
                    touchAction: 'pan-y'
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onTouchStart={(e) => {
                    // Touch support for mobile
                    const touch = e.touches[0];
                    handleMouseDown({...e, pageX: touch.pageX, target: touch.target});
                }}
                onTouchMove={(e) => {
                    const touch = e.touches[0];
                    handleMouseMove({...e, pageX: touch.pageX, preventDefault: () => e.preventDefault()});
                }}
                onTouchEnd={(e) => {
                    handleMouseUp(e);
                }}
            >
                <Box sx={{
                    display: 'flex',
                    transform: `translateX(-${getCurrentPosition()}px)`,
                    transition: isDragging ? 'none' : undefined,
                    gap: `${itemGap}px`,
                    willChange: 'transform',
                    userSelect: 'none'
                }}>
                    {events.map((event) => (
                        <Box
                            key={event.event_id}
                            className="event-item-container"
                            data-event-id={event.event_id}
                            sx={{
                                flex: `0 0 ${itemWidth}px`,
                                width: `${itemWidth}px`,
                                cursor: isDragging ? 'grabbing' : 'pointer',
                                '& > *': {
                                    pointerEvents: isDragging ? 'none' : 'auto'
                                }
                            }}
                        >
                            <EventListItem
                                event={event}
                                setShowEventDialog={setShowEventDialog}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default EventList;