import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { useShareableLinkStore } from '../../store/shareableLinkStore';
import { useShareableData } from '../../hooks/useShareableData';
import { TEXT } from './constants';
import PublicMediaFileUpload from '../public-media-file-upload/PublicMediaFileUpload';
import { PublicMediaFileItem } from '../public-media-file-item/PublicMediaFileItem';
import { PublicMediaFileUploadConfirmation } from '../public-media-file-upload-confirmation/PublicMediaFileUploadConfirmation';

// Responsive grid configuration based on screen size
const getItemsPerRow = () => {
    // Use window.innerWidth to determine screen size
    if (typeof window !== 'undefined') {
        if (window.innerWidth < 600) return 2; // Mobile
        if (window.innerWidth < 960) return 3; // Tablet
        return 4; // Desktop
    }
    return 4; // Default for SSR
};

export const PublicMediaUpload = () => {
    const { shareableToken } = useShareableLinkStore();
    const [uploadingMedia, setUploadingMedia] = useState([]);
    const [uploadedMedia, setUploadedMedia] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isDataReady, setIsDataReady] = useState(false);
    const [itemsPerRow, setItemsPerRow] = useState(getItemsPerRow());
    const [uploadError, setUploadError] = useState(null);
    
    // Get the shareableData hook
    const { 
        fetchShareableData, 
        isLoading,
        error, 
        getMediaThumbnail 
    } = useShareableData();
    
    // Update items per row when window resizes
    useEffect(() => {
        const handleResize = () => {
            setItemsPerRow(getItemsPerRow());
        };
        
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);
    
    // Mark data as ready as soon as we have the essential fields for uploading
    useEffect(() => {
        console.log('PublicMediaUpload component received token:', shareableToken);
        
        // We just need the token itself and the IDs to enable uploads
        if (
            shareableToken &&
            shareableToken.token &&
            shareableToken.userId &&
            shareableToken.collectionId
        ) {
            console.log('Essential upload data is ready');
            setIsDataReady(true);
            
            // Use default values for missing fields to prevent UI issues
            if (!shareableToken.personName) {
                console.log('Using default person name');
                shareableToken.personName = 'User';
            }
            
            if (!shareableToken.collectionName) {
                console.log('Using default collection name');
                shareableToken.collectionName = 'Media Collection';
            }
            
            // Store token in localStorage as an additional backup
            if (shareableToken.token) {
                localStorage.setItem('shareable_token', shareableToken.token);
            }
        } else {
            console.warn('Missing essential data for uploads:', {
                hasToken: !!shareableToken?.token,
                hasUserId: !!shareableToken?.userId,
                hasCollectionId: !!shareableToken?.collectionId
            });
        }
    }, [shareableToken]);

    const handleFileSelect = (file) => {
        const tempMediaItem = {
            media_id: `temp-${Date.now()}`,
            media_name: file.name,
            media_type: file.type,
            size_in_bytes: file.size,
            isUploading: true
        };
        setUploadingMedia(prev => [...prev, tempMediaItem]);
    };

    const handleUploadSuccess = async (mediaItem) => {
        try {
            // Fetch thumbnail after successful upload
            const thumbnailResult = await getMediaThumbnail(mediaItem.media_id);
            
            // Remove the temporary uploading item
            setUploadingMedia(prev => 
                prev.filter(item => item.media_name !== mediaItem.media_name)
            );
            
            // Add the media item with the thumbnail URL
            setUploadedMedia(prev => [...prev, {
                ...mediaItem,
                thumbnailUrl: thumbnailResult.success ? thumbnailResult.thumbnailUrl : null
            }]);
        } catch (error) {
            console.error('Error handling upload success:', error);
            // Still add the media item even if thumbnail fetch fails
            setUploadedMedia(prev => [...prev, mediaItem]);
        }
    };

    const handleDelete = (mediaToDelete) => {
        setUploadingMedia(prev => 
            prev.filter(item => item.media_id !== mediaToDelete.media_id)
        );
        setUploadedMedia(prev => 
            prev.filter(item => item.media_id !== mediaToDelete.media_id)
        );
    };

    const handleComplete = () => {
        if (uploadedMedia.length === 0) {
            setShowConfirmDialog(true);
            return;
        }
        proceedToNextStep();
    };

    const proceedToNextStep = () => {
        setShowConfirmDialog(false);
        setShowConfirmation(true);
    };

    const handleUploadMore = () => {
        setShowConfirmation(false);
    };

    const getAllMediaItems = () => {
        return [...uploadingMedia, ...uploadedMedia];
    };

    if (showConfirmation) {
        return (
            <PublicMediaFileUploadConfirmation 
                uploadedMedia={uploadedMedia}
                onUploadMore={handleUploadMore}
            />
        );
    }

    // Show loading state while data is being fetched
    if (isLoading || !isDataReady) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '400px',
                width: {
                    xs: '95%', // Mobile
                    sm: '80%', // Tablet
                    md: '65%', // Small Desktop
                    lg: '50%'  // Large Desktop
                },
                margin: '0 auto',
                backgroundColor: '#FFFFFF',
                borderRadius: '20px',
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.03)'
            }}>
                <CircularProgress />
                <Typography sx={{ ml: 2 }}>Loading media upload page...</Typography>
            </Box>
        );
    }

    // Show error state if there was a problem fetching data
    if (error) {
        return (
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '400px',
                width: {
                    xs: '95%', // Mobile
                    sm: '80%', // Tablet
                    md: '65%', // Small Desktop
                    lg: '50%'  // Large Desktop
                },
                margin: '0 auto',
                backgroundColor: '#FFFFFF',
                borderRadius: '20px',
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.03)',
                padding: '20px'
            }}>
                <Typography variant="h6" color="error" sx={{ mb: 2 }}>
                    Error loading upload page
                </Typography>
                <Typography>
                    There was a problem loading the upload page. Please check the URL and try again.
                </Typography>
            </Box>
        );
    }

    // Only proceed if we have the required data
    return (
        <Box
            sx={{
                display: 'flex',
                width: {
                    xs: '95%', // Mobile
                    sm: '80%', // Tablet
                    md: '65%', // Small Desktop
                    lg: '50%'  // Large Desktop
                },
                padding: {
                    xs: '20px', // Mobile
                    sm: '30px', // Tablet
                    md: '55px'  // Desktop
                },
                flexDirection: 'column',
                alignItems: 'center',
                gap: {
                    xs: '20px', // Mobile
                    sm: '24px', // Tablet
                    md: '32px'  // Desktop
                },
                borderRadius: '20px',
                position: 'relative',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.03)'
            }}
        >
            {/* Right arrow in top corner removed */}

            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    flexDirection: {
                        xs: 'column', // Stack vertically on mobile
                        sm: 'row'     // Side by side on larger screens
                    },
                    alignItems: 'center'
                }}>
                    <Typography
                        component="span"
                        sx={{
                            color: '#000',
                            textAlign: 'center',
                            fontFamily: 'Wanted Sans',
                            fontSize: {
                                xs: '20px', // Mobile
                                sm: '22px', // Tablet
                                md: '24px'  // Desktop
                            },
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '150%'
                        }}
                    >
                        {shareableToken.personName}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{
                            color: '#000',
                            marginLeft: {
                                xs: '0', // No margin on mobile (stacked)
                                sm: '7px' // Margin on larger screens (side by side)
                            },
                            fontFamily: 'Wanted Sans',
                            fontSize: {
                                xs: '18px', // Mobile
                                sm: '20px', // Tablet
                                md: '24px'  // Desktop
                            },
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '150%'
                        }}
                    >
                        {TEXT.INVITED_TO_UPLOAD_IMAGES_OR_VIDEOS}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        color: '#0357D7',
                        marginTop: '8px',
                        textAlign: 'center',
                        fontFamily: 'Wanted Sans',
                        fontSize: {
                            xs: '20px', // Mobile
                            sm: '22px', // Tablet
                            md: '24px'  // Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '150%'
                    }}
                >
                    {shareableToken.collectionName}
                </Typography>
            </Box>

            <Box sx={{ width: '100%' }}>
                <PublicMediaFileUpload 
                    variant="dropzone"
                    width="100%"
                    height={{
                        xs: '120px', // Mobile
                        sm: '140px', // Tablet
                        md: '152px'  // Desktop
                    }}
                    acceptedTypes="image/*, video/*"
                    maxSizeConfig={{
                        image: 5 * 1024 * 1024,  // 5MB for images (increased from 3MB)
                        video: 100 * 1024 * 1024  // 100MB for videos (increased from 3MB)
                    }}
                    onFileSelect={handleFileSelect}
                    onUploadSuccess={handleUploadSuccess}
                    collectionId={shareableToken.collectionId}
                    groupId={shareableToken.groupId}
                    userId={shareableToken.userId}
                />
            </Box>
            
            {uploadError && (
                <Typography color="error" sx={{ width: '100%', textAlign: 'center' }}>
                    {uploadError}
                </Typography>
            )}
            
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: {
                        xs: getAllMediaItems().length > 4 ? '400px' : 'auto', // Mobile
                        sm: getAllMediaItems().length > 6 ? '500px' : 'auto', // Tablet
                        md: getAllMediaItems().length > 6 ? '580px' : 'auto'  // Desktop
                    },
                    overflowY: getAllMediaItems().length > 6 ? 'auto' : 'visible',
                    overflowX: 'hidden',
                    scrollbarWidth: 'none',  // Firefox
                    '&::-webkit-scrollbar': {  // Chrome, Safari
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none'
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: 'repeat(2, 1fr)', // Mobile: 2 items per row
                            sm: 'repeat(3, 1fr)', // Tablet: 3 items per row
                            md: 'repeat(4, 1fr)'  // Desktop: 4 items per row
                        },
                        rowGap: '16px',
                        columnGap: {
                            xs: '8px', // Mobile: smaller gap
                            sm: '12px', // Tablet
                            md: '16px'  // Desktop
                        },
                        transition: 'opacity 0.3s ease-in-out',
                        flex: 1,
                        width: '100%'
                    }}
                >
                    {getAllMediaItems().map((mediaItem) => (
                        <PublicMediaFileItem
                            key={mediaItem.media_id}
                            mediaItem={mediaItem}
                            thumbnailUrl={mediaItem.thumbnailUrl}
                            onDelete={handleDelete}
                            isUploading={mediaItem.isUploading}
                        />
                    ))}
                </Box>
            </Box>
            
            <Button
                onClick={handleComplete}
                disableRipple
                sx={{
                    display: 'flex',
                    height: {
                        xs: '38px', // Mobile
                        sm: '40px', // Tablet
                        md: '42px'  // Desktop
                    },
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    borderRadius: '4px',
                    border: '1px solid #0357D7',
                    background: '#0357D7',
                    textTransform: 'none',
                    transition: 'background-color 0.2s',
                    '&:hover': {
                        background: '#0245AC'
                    }
                }}
            >
                <Typography
                    sx={{
                        color: '#FFF',
                        fontFamily: 'Wanted Sans',
                        fontSize: {
                            xs: '16px', // Mobile
                            sm: '17px', // Tablet
                            md: '18px'  // Desktop
                        },
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '24px'
                    }}
                >
                    {TEXT.IM_DONE_UPLOADING}
                </Typography>
            </Button>

            <Dialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '20px',
                        padding: {
                            xs: '15px', // Mobile
                            sm: '20px'  // Tablet and Desktop
                        },
                        width: {
                            xs: '90%', // Mobile
                            sm: 'auto'  // Tablet and Desktop
                        },
                        maxWidth: {
                            xs: '90%', // Mobile
                            sm: '500px' // Tablet and Desktop
                        }
                    }
                }}
            >
                <DialogTitle sx={{
                    color: '#000',
                    fontFamily: 'Wanted Sans',
                    fontSize: {
                        xs: '20px', // Mobile
                        sm: '24px'  // Tablet and Desktop
                    },
                    fontWeight: '700',
                    lineHeight: '150%'
                }}>
                    {TEXT.CONFIRMATION_DIALOG_HEADER}
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ 
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontStyle: 'normal',
                        fontSize: {
                            xs: '14px', // Mobile
                            sm: '16px'  // Tablet and Desktop
                        },
                        fontWeight: '400',
                        lineHeight: '150%'
                    }}>
                        {TEXT.CONFIRMATION_DIALOG_TEXT}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ mt: -1 }}>
                    <Button
                        disableRipple
                        onClick={() => setShowConfirmDialog(false)}
                        sx={{
                            color: '#000',
                            textTransform: 'none',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px'
                        }}
                    >
                        {TEXT.CANCEL_BUTTON_TEXT}
                    </Button>
                    <Button
                        disableRipple
                        onClick={proceedToNextStep}
                        variant="contained"
                        sx={{
                            background: '#0357D7',
                            textTransform: 'none',
                            fontFamily: 'Wanted Sans',
                            fontSize: '14px',
                            '&:hover': {
                                background: '#0245AC'
                            }
                        }}
                    >
                        {TEXT.CONTINUE_BUTTON_TEXT}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};