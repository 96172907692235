import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { InsertLink as InsertLinkIcon } from '@mui/icons-material';
import { TEXT } from './constants';
import CollectionFileView from './components/collection-file-view/CollectionFileView';
import { CollectionShareableLinkDialog } from './components/collection-shareable-link-dialog/CollectionShareableLinkDialog';
import MediaFileUpload from '../media/components/media-file-upload/MediaFileUpload';
import { MediaFilePreview } from '../media/components/media-file-preview/MediaFilePreview';
import { MediaViewer } from '../media/components/media-viewer/MediaViewer';
import { useCollectionStore } from './store/collectionStore';
import { useMedia } from '../media/hooks/useMedia';

const CollectionViewPage = () => {
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [showShareableLinkDialog, setShowShareableLinkDialog] = useState(false);
    const [showMediaViewer, setShowMediaViewer] = useState(false);
    const [thumbnailData, setThumbnailData] = useState({});
    const [viewMode, setViewMode] = useState('grid');
    const [selectedItems, setSelectedItems] = useState({});

    const navigate = useNavigate();
    const { collectionId } = useParams();

    const collections = useCollectionStore(state => state.collections);
    const { 
        isFetchingMediaInfo: isMediaLoading,
        downloadRawFile,
        deleteFile
    } = useMedia();

    const currentCollection = collections.find(
        collection => collection.collection_id === collectionId
    );

    const groupId = currentCollection?.group_id;
    const userId = localStorage.getItem('user_id');

    // Define frequently used functions first to avoid reference errors
    const closeMediaViewer = () => {
        setShowMediaViewer(false);
    };

    const handleMediaDoubleClick = (media) => {
        setSelectedMedia(media);
        setShowMediaViewer(true);
    };

    const openShareableLinkDialog = () => {
        setShowShareableLinkDialog(true);
    };

    const closeShareableLinkDialog = () => {
        setShowShareableLinkDialog(false);
    };

    const handleViewModeChange = useCallback((newViewMode) => {
        setViewMode(newViewMode);
    }, []);
    
    const handleSelectedItemsChange = useCallback((newSelectedItems) => {
        setSelectedItems(newSelectedItems);
    }, []);

    // Set initial selected media
    useEffect(() => {
        if (currentCollection?.media?.length > 0 && !selectedMedia) {
            setSelectedMedia(currentCollection.media[0]);
        }
    }, [currentCollection?.media, selectedMedia]);

    // Handle thumbnail updates from CollectionFileView
    const handleThumbnailUpdate = useCallback((newThumbnailData) => {
        if (!newThumbnailData || typeof newThumbnailData !== 'object') return;
        
        // Merge new thumbnail data with existing data
        setThumbnailData(prevData => {
            // Check if any of the new thumbnails are different from what we have
            const hasNewData = Object.entries(newThumbnailData).some(
                ([mediaId, url]) => prevData[mediaId] !== url
            );
            
            if (hasNewData) {
                return { ...prevData, ...newThumbnailData };
            }
            
            return prevData;
        });
    }, []);

    const handleBatchDelete = useCallback(async (mediaIds) => {
        if (!mediaIds.length || !collectionId) return;
        
        try {
            // For each selected media, call the deleteFile function
            for (const mediaId of mediaIds) {
                await deleteFile({
                    mediaId,
                    collectionId
                });
                // Add a small delay between operations
                await new Promise(resolve => setTimeout(resolve, 300));
            }
            
            // Clear selections after deletion
            setSelectedItems({});
            
        } catch (error) {
            console.error('Batch delete error:', error);
        }
    }, [collectionId, deleteFile]);

    // Keyboard navigation effect for MediaViewer
    useEffect(() => {
        const handleKeyDown = (event) => {
            // Only handle keyboard events when MediaViewer is open
            if (showMediaViewer) {
                // Handle escape key to close the media viewer
                if (event.key === 'Escape') {
                    closeMediaViewer();
                    event.preventDefault();
                    return;
                }
                
                // Handle left/right arrow keys ONLY for navigating media items when viewer is open
                if (currentCollection?.media && selectedMedia) {
                    const media = currentCollection.media;
                    const currentIndex = media.findIndex(
                        item => item.media_id === selectedMedia.media_id
                    );
                    
                    if (currentIndex !== -1) {
                        let newIndex;
                        
                        // Only handle left and right arrows, never up and down
                        if (event.key === 'ArrowLeft') {
                            // Move to previous item, loop to end if at beginning
                            newIndex = currentIndex === 0 ? media.length - 1 : currentIndex - 1;
                            setSelectedMedia(media[newIndex]);
                            event.preventDefault();
                        } else if (event.key === 'ArrowRight') {
                            // Move to next item, loop to beginning if at end
                            newIndex = currentIndex === media.length - 1 ? 0 : currentIndex + 1;
                            setSelectedMedia(media[newIndex]);
                            event.preventDefault();
                        }
                    }
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showMediaViewer, currentCollection, selectedMedia, closeMediaViewer]);

    const handleUploadSuccess = async (uploadedMediaId) => {
        console.log('Upload success handler called with ID:', uploadedMediaId);
        try {
            if (currentCollection?.media) {
                const newMediaItem = currentCollection.media.find(
                    media => media.media_id === uploadedMediaId
                );
                
                if (newMediaItem) {
                    setSelectedMedia(newMediaItem);
                }
            }
        } catch (error) {
            console.error('Error in upload success handler:', error);
        }
    };

    return (
        <>
            {/* Media Viewer Overlay */}
            {showMediaViewer && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        zIndex: 9999,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <IconButton
                        onClick={closeMediaViewer}
                        sx={{
                            position: 'absolute',
                            top: '35px',
                            right: '54px',
                            color: '#fff',
                            zIndex: '1'
                        }}
                    >
                        <CloseIcon
                            sx={{
                                width: 41,
                                height: 41
                            }}
                        />
                    </IconButton>
                    <MediaViewer 
                        selectedMedia={selectedMedia}
                        onClose={closeMediaViewer}
                        onNavigate={setSelectedMedia}
                        collection={currentCollection?.media || []}
                    />
                </Box>
            )}

            <Box
                sx={{
                    marginLeft: '210px',
                    marginRight: '210px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px'
                    }
                }}
            >
                {/* Back Button */}
                <Box sx={{ marginBottom: '14px' }}>
                    <Button
                        disableRipple
                        onClick={() => navigate(-1)}
                        sx={{
                            color: '#000',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            width: 'fit-content',
                            padding: '6px 16px',
                            borderRadius: '20px', // Makes the button oval-shaped
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.04)' // Matching the View All hover color
                            },
                            '&:active': {
                                background: 'rgba(0, 0, 0, 0.08)' // Slightly darker for active state
                            }
                        }}
                    >
                        {TEXT.GO_BACK}
                    </Button>
                </Box>

                {/* Collection Info Section */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '32px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        rowGap: '8px',
                        alignSelf: 'stretch',
                        borderRadius: '10px',
                        border: '1px solid #E6E8E9',
                        background: '#FFF'
                    }}
                >
                    {/* Collection Details */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '16px',
                            alignSelf: 'stretch'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '32px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                color: '#000'
                            }}
                        >
                            {currentCollection?.collection_name || 'Unnamed Collection'}
                        </Typography>
                        <Typography
                            sx={{
                                marginTop: '16px',
                                alignSelf: 'stretch',
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal'
                            }}
                        >
                            {currentCollection?.collection_description || 'None'}
                        </Typography>
                    </Box>

                    {/* Collection Metadata */}
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '16px',
                                color: '#000'
                            }}
                        >
                            {TEXT.CREATED}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Wanted Sans',
                                marginLeft: '4px',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '16px',
                                color: '#000'
                            }}
                        >
                            {currentCollection?.collection_created_on_date || 'Unknown'}
                        </Typography>
                    </Box>

                    {/* Upload Buttons */}
                    <Box
                        sx={{
                            marginTop: '32px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: '4px' }}>
                            <MediaFileUpload
                                collectionId={collectionId}
                                groupId={groupId}
                                userId={userId}
                                variant="button"
                                width="163px"
                                height="43px"
                                acceptedTypes="image/*"
                                maxSize={2097152}
                                onFileSelect={(file) => console.log('File selected:', file)}
                                onUploadSuccess={handleUploadSuccess}
                                buttonText={TEXT.UPLOAD_IMAGE}
                            />
                            <MediaFileUpload
                                collectionId={collectionId}
                                groupId={groupId}
                                userId={userId}
                                variant="button"
                                width="165px"
                                height="42px"
                                acceptedTypes="video/*"
                                maxSize={2097152}
                                onFileSelect={(file) => console.log('File selected:', file)}
                                onUploadSuccess={handleUploadSuccess}
                                buttonText={TEXT.UPLOAD_VIDEO}
                            />
                        </Box>

                        <Button
                            onClick={openShareableLinkDialog}
                            sx={{
                                display: 'flex',
                                height: 'auto',
                                width: 'auto',
                                minWidth: 'fit-content',
                                padding: '8px 22px',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '4px',
                                border: '1px solid #0357D7'
                            }}
                        >
                            <InsertLinkIcon
                                sx={{
                                    marginTop: '1px',
                                    marginRight: '8px'
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '26px',
                                    textTransform: 'none'
                                }}
                            >
                                {TEXT.SHARE_UPLOAD_LINK}
                            </Typography>
                        </Button>
                    </Box>

                    {/* Dropzone */}
                    <Box sx={{ width: '100%', marginTop: '32px' }}>
                        <MediaFileUpload
                            collectionId={collectionId}
                            groupId={groupId}
                            userId={userId}
                            variant="dropzone"
                            width="100%"
                            height="152px"
                            acceptedTypes="image/*, video/*"
                            maxSize={2097152}
                            onFileSelect={(file) => console.log('File selected:', file)}
                            onUploadSuccess={handleUploadSuccess}
                            buttonText="+ Upload Media"
                        />
                    </Box>
                </Box>

                {/* Main Content Area */}
                <Box
                    sx={{
                        marginTop: '32px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px'
                    }}
                >
                    {/* File View */}
                    <Box sx={{ width: '75%' }}>
                        <CollectionFileView 
                            selectedMedia={selectedMedia}
                            onMediaSelect={setSelectedMedia}
                            onMediaDoubleClick={handleMediaDoubleClick}
                            onThumbnailUpdate={handleThumbnailUpdate}
                            isLoading={isMediaLoading}
                            showMediaViewer={showMediaViewer}
                            externalThumbnailData={thumbnailData}
                            externalSelectedItems={selectedItems}
                            onSelectedItemsChange={handleSelectedItemsChange}
                            onViewModeChange={handleViewModeChange}
                        />
                    </Box>
                    {/* Preview Panel */}
                    <Box 
                        sx={{ 
                            width: '25%', 
                            position: 'sticky', 
                            top: '32px', 
                            height: 'fit-content' 
                        }}
                    >
                        <MediaFilePreview
                            selectedMedia={selectedMedia}
                            collectionId={collectionId}
                            thumbnailData={thumbnailData}
                            onDoubleClick={handleMediaDoubleClick}
                            viewMode={viewMode}
                            selectedItems={selectedItems}
                            onSelectedItemsChange={handleSelectedItemsChange}
                            allMediaItems={currentCollection?.media || []}
                            onBatchDownload={(mediaIds) => {
                                mediaIds.forEach(mediaId => {
                                    downloadRawFile(mediaId);
                                });
                            }}
                            onBatchDelete={handleBatchDelete}
                        />
                    </Box>
                </Box>

                {/* Bottom Spacing */}
                <Box sx={{ marginTop: 'calc(6% + 100px)' }} />

                {/* Share Dialog */}
                {showShareableLinkDialog && (
                    <CollectionShareableLinkDialog
                        open={true}
                        onClose={closeShareableLinkDialog}
                        userId={userId}
                        groupId={groupId}
                        collectionId={collectionId}
                    />
                )}
            </Box>
        </>
    );
};

export default CollectionViewPage;