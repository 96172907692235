import { Box, Typography, Skeleton } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useMedia } from '../../hooks/useMedia';
import { formatFileSize } from '../../../../utils/formatFileSize';

export const MediaFileItem = ({ 
    mediaItem, 
    isSelected, 
    onClick, 
    onDoubleClick,
    onThumbnailLoad,
    thumbnailUrl: existingThumbnailUrl,
    hideDetails = false
}) => {
    const { getMediaThumbnail } = useMedia();
    const [thumbnailUrl, setThumbnailUrl] = useState(existingThumbnailUrl);
    const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(!existingThumbnailUrl);
    const mountedRef = useRef(true); // Track if component is mounted

    // Effect to update from props when they change
    useEffect(() => {
        // Update from props if thumbnail URL is provided
        if (existingThumbnailUrl) {
            setThumbnailUrl(existingThumbnailUrl);
            setHasAttemptedLoad(true);
            setIsLoading(false);
        }
    }, [existingThumbnailUrl]);

    // Effect to handle loading thumbnails when needed
    useEffect(() => {
        // Return early if any condition prevents loading
        if (!mediaItem?.media_id || hasAttemptedLoad || thumbnailUrl) {
            return;
        }

        // Set that we've attempted to load to prevent multiple attempts
        setHasAttemptedLoad(true);
        // Set loading state to true
        setIsLoading(true);
        
        // Define an async function to load the thumbnail
        const loadThumbnail = async () => {
            try {
                const result = await getMediaThumbnail(mediaItem.media_id);
                
                // Check if component is still mounted before updating state
                if (!mountedRef.current) return;
                
                if (result.success && result.thumbnailUrl) {
                    setThumbnailUrl(result.thumbnailUrl);
                    setIsLoading(false);
                    
                    // Use setTimeout to break potential render cycles
                    if (onThumbnailLoad) {
                        setTimeout(() => {
                            if (mountedRef.current) {
                                onThumbnailLoad(mediaItem.media_id, true, result.thumbnailUrl);
                            }
                        }, 0);
                    }
                } else {
                    setIsLoading(false);
                    
                    if (onThumbnailLoad) {
                        setTimeout(() => {
                            if (mountedRef.current) {
                                onThumbnailLoad(mediaItem.media_id, false);
                            }
                        }, 0);
                    }
                }
            } catch (error) {
                // Check if component is still mounted before callbacks
                if (!mountedRef.current) return;
                
                console.error('Error loading thumbnail:', error);
                setIsLoading(false);
                
                if (onThumbnailLoad) {
                    setTimeout(() => {
                        if (mountedRef.current) {
                            onThumbnailLoad(mediaItem.media_id, false);
                        }
                    }, 0);
                }
            }
        };
        
        // Execute the async function
        loadThumbnail();
        
    }, [mediaItem?.media_id, hasAttemptedLoad, thumbnailUrl, getMediaThumbnail, onThumbnailLoad]);

    // Lifecycle effect for mounting/unmounting
    useEffect(() => {
        // Mark component as mounted
        mountedRef.current = true;
        
        // Cleanup function for unmounting
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const handleClick = () => {
        onClick?.(mediaItem);
    };

    const handleDoubleClick = () => {
        onDoubleClick?.(mediaItem);
    };

    return (
        <Box
            onClick={handleClick}
            onDoubleClick={handleDoubleClick}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px',
                cursor: 'pointer',
                // Make it responsive but larger to ensure 4 per row
                width: '100%',
                // Increased minimum width for larger default size
                minWidth: { sm: '200px', md: '210px' },
                // Increased maximum width to better fill the space
                maxWidth: { sm: '100%', md: '100%' },
                margin: '0 auto',
                boxSizing: 'border-box'
            }}
        >
            {/* Thumbnail with improved aspect ratio */}
            <Box
                sx={{
                    width: '100%',
                    position: 'relative',
                    paddingTop: '75%', // 4:3 aspect ratio
                    borderRadius: '10px',
                    border: '2px solid', // CHANGE: Consistent 2px border
                    borderColor: isSelected ? '#0357D7' : 'transparent', // CHANGE: Using borderColor
                    boxShadow: isSelected ? 'none' : '0 0 0 1px #E6E8E9', // CHANGE: Using boxShadow for non-selected
                    overflow: 'hidden',
                    boxSizing: 'border-box',
                }}
            >
                {isLoading ? (
                    // Skeleton loader when thumbnail is loading
                    <Skeleton 
                        variant="rectangular" 
                        animation="wave"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: '8px',
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                        }}
                    />
                ) : (
                    // Actual thumbnail or fallback background
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : 'none',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundColor: !thumbnailUrl ? '#f0f0f0' : 'transparent',
                        }}
                    />
                )}
            </Box>
            
            {!hideDetails && (
                <Box sx={{ width: '100%' }}>
                    {isLoading ? (
                        // Skeleton loaders for text content
                        <>
                            <Skeleton 
                                variant="text" 
                                width="70%" 
                                height={16} 
                                animation="wave"
                                sx={{ marginBottom: '8px' }} 
                            />
                            <Skeleton 
                                variant="text" 
                                width="40%" 
                                height={16} 
                                animation="wave" 
                            />
                        </>
                    ) : (
                        // Actual text content
                        <>
                            <Typography
                                sx={{
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                    color: 'text.primary',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    maxWidth: '100%'
                                }}
                            >
                                {mediaItem?.media_name || 'Untitled'}
                            </Typography>
                            <Typography
                                sx={{
                                    marginTop: '4px',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '16px',
                                    color: 'text.secondary'
                                }}
                            >
                                {formatFileSize(mediaItem?.size_in_bytes) || '0 MB'}
                            </Typography>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default MediaFileItem;