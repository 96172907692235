import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { TEXT, VIEW_STATES } from './constants';
import { DIALOG_TYPES } from '../../types/types';
import { TYPES } from '../../types/types';
import { ROUTES } from '../../routes/constants';
import TimelineFeed from './components/timeline-feed/TimelineFeed';
import UserProfileCard from '../user/components/user-profile-card/UserProfileCard';
import List from './components/list/List';
import { useUserDetails } from '../user/hooks/useUserDetails';
import GroupPage from '../group/GroupPage';
import EventPage from '../event/EventPage';
import CollectionListPage from '../collection/CollectionListPage';
import JoinGroupDialog from '../group/components/join-group-dialog/JoinGroupDialog';
import { ViewEventDialog } from '../event/components/view-event-dialog/ViewEventDialog';
import { GroupForm } from '../group/components/group-form/GroupForm';
import { EventForm } from '../event/components/event-form/EventForm';
import { CollectionForm } from '../collection/components/collection-form/CollectionForm';
import { UserForm } from '../user/components/user-form/UserForm';
import { assembleGroupListItems, assembleEventListItems, assembleUserListItems } from '../../utils/Utils';
import { Button, Box } from '@mui/material';
import { UserInviteDialog } from '../user/components/user-invite-dialog/UserInviteDialog';
import { usePersonStore } from '../person/store/personStore';
import { useCollection } from '../collection/hooks/useCollection';
import { useGroup } from '../group/hooks/useGroup';
import { useEvent } from '../event/hooks/useEvent';
import { useEventDetails } from '../event/hooks/useEventDetails';
import { useEventProfileImage } from '../event/hooks/useEventProfileImage';
import { eventApi } from '../event/services/eventApi';

// Custom hooks
const useViewState = (location) => {
    const [viewState, setViewState] = useState({
        type: 'home',
        mode: 'view'
    });

    useEffect(() => {
        const currentPath = location.pathname.split('/')[1];
        setViewState(prev => ({
            ...prev,
            type: currentPath || 'home'
        }));
    }, [location.pathname]);

    return [viewState, setViewState];
};

const useCurrentGroup = (path, id, groups) => {
    const [currentGroup, setCurrentGroup] = useState(null);

    useEffect(() => {
        if (path === ROUTES.GROUPS && id && groups) {
            const group = groups.find(group => group.group_id === id);
            setCurrentGroup(group);
        }
    }, [path, id, groups]);

    return currentGroup;
};

const HomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split('/')[1];
    const id = location.pathname.split('/')[2];
    
    // Ref to track the current group ID for comparison
    const currentGroupIdRef = useRef(null);
    
    const { createCollection, isCreateCollectionLoading, errorCreateCollection } = useCollection();
    const { createGroup, isCreateGroupLoading, errorCreateGroup } = useGroup();
    const { createEvent, isCreateEventLoading, errorCreateEvent } = useEvent();
    const { fetchEventDetails } = useEventDetails();
    const { fetchEventProfileImages } = useEventProfileImage();
    
    const people = usePersonStore(state => state.people);
    const [activeDialog, setActiveDialog] = useState({
        type: DIALOG_TYPES.NONE,
        data: null
    });

    const [viewState, setViewState] = useViewState(location);
    const { 
        userData, 
        groups, 
        events,
        refetchUser 
    } = useUserDetails();

    const currentGroup = useCurrentGroup(path, id, groups);
    const [groupEventDetails, setGroupEventDetails] = useState([]);
    const [groupEventsWithImages, setGroupEventsWithImages] = useState([]);
    
    // Fetch event details and profile images for the current group's events
    useEffect(() => {
        // Clear the states immediately when currentGroup changes
        // This ensures we don't show events from the previous group while loading
        setGroupEventDetails([]);
        setGroupEventsWithImages([]);
        
        const fetchGroupEventData = async () => {
            // Only proceed if we're on a group page and have a valid currentGroup
            if (path === ROUTES.GROUPS && id && currentGroup) {
                console.log('Current group changed or refreshed:', currentGroup.group_name);
                
                // Get the events from the current group
                const groupEvents = currentGroup.events || [];
                
                if (groupEvents.length > 0) {
                    console.log('Fetching details for group events:', groupEvents.length);
                    
                    try {
                        // Step 1: Fetch detailed event data for all events in the group
                        const detailedEventsPromises = groupEvents.map(event => 
                            eventApi.getEventDetails(event.event_id, { fresh: true }) // Add a flag to bypass cache
                        );
                        const detailedEvents = await Promise.all(detailedEventsPromises);
                        
                        // Step 2: Fetch profile images for each event
                        const eventsWithImages = await Promise.all(
                            detailedEvents.map(async (event) => {
                                if (!event || !event.event_id) return event;
                                
                                try {
                                    const profileImage = await eventApi.getEventProfileImage(event.event_id, { fresh: true });
                                    return {
                                        ...event,
                                        profile_image: profileImage
                                    };
                                } catch (error) {
                                    console.error(`Error fetching profile image for event ${event.event_id}:`, error);
                                    return event;
                                }
                            })
                        );
                        
                        // Filter for events that belong to the current group
                        const currentGroupEvents = eventsWithImages.filter(
                            event => event && event.group_id === currentGroup.group_id
                        );
                        
                        setGroupEventDetails(currentGroupEvents);
                        setGroupEventsWithImages(currentGroupEvents);
                        
                        console.log('Updated events with images:', currentGroupEvents.length);
                    } catch (error) {
                        console.error('Error fetching group event data:', error);
                        // Also clear the states on error
                        setGroupEventDetails([]);
                        setGroupEventsWithImages([]);
                    }
                }
                // If no events, the states are already cleared at the beginning
            }
        };
        
        fetchGroupEventData();
        
        // Cleanup function to clear states when component unmounts or dependencies change
        return () => {
            setGroupEventDetails([]);
            setGroupEventsWithImages([]);
        };
    }, [path, id, currentGroup]);
    
    // Go Back
    const handleGoBack = useCallback(() => {
        if (viewState.mode === 'create') {
            // If in create mode, just switch back to view mode
            setViewState(prev => ({ ...prev, mode: 'view' }));
            // Use replace to avoid stacking history
            navigate(-1, { replace: true });
        } else {
            // For other cases, navigate to parent route
            const segments = location.pathname.split('/');
            if (segments.length > 2) {
                // If we're in a nested route, go to parent
                const parentPath = `/${segments[1]}`;
                navigate(parentPath, { replace: true });
            } else {
                // If we're at root level of a section, go home
                navigate('/', { replace: true });
            }
        }
    }, [viewState.mode, navigate, location.pathname]);

    // Dialog handlers
    const handleCloseDialog = useCallback(() => {
        setActiveDialog({
            type: DIALOG_TYPES.NONE,
            data: null
        });
    }, []);

    const handleDialogOpen = useCallback((type, data = null) => {
        setActiveDialog({
            type,
            data
        });
    }, []);

    // Action handlers
    const handleCreateGroup = useCallback(async (groupData) => {
        try {
            console.log('Creating group:', groupData);
            
            // Call the createGroup method from your hook with the proper structure
            await createGroup(groupData);
            
            await refetchUser();
            setViewState(prev => ({
                ...prev,
                mode: 'view'
            }));
            
            // Navigate back to groups list
            navigate('/groups');
        } catch (error) {
            console.error('Error creating group:', error);
        }
    }, [createGroup, refetchUser, setViewState, navigate]);

    const handleCreateEvent = useCallback(async (eventData) => {
        try {
            console.log('Creating event:', eventData);
            await createEvent(eventData);
            await refetchUser();
            navigate(`/groups/${eventData.groupId}`);
            setViewState(prev => ({
                ...prev,
                mode: 'view'
            }));
        } catch (error) {
            console.error('Error creating event:', error);
        }
    }, [createEvent, refetchUser, setViewState, navigate]);

    const handleCreateCollection = useCallback(async (collectionData) => {
        try {
            console.log('Creating collection:', collectionData);
            await createCollection(collectionData);
            await refetchUser();
            setViewState(prev => ({
                ...prev,
                mode: 'view'
            }));
        } catch (error) {
            console.error('Error creating collection:', error);
        }
    }, [createCollection, refetchUser, setViewState]);

    const handleUpdateProfile = useCallback(async (updatedData) => {
        try {
            console.log('Updating user profile:', updatedData);
            await refetchUser();
            navigate('/');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    }, [refetchUser, navigate]);

    // Render helpers
    const renderSideLists = useCallback(() => {
        if (!groups || !people) return null;

        if (path === ROUTES.GROUPS && id && currentGroup) {
            // IMPORTANT: Only show events if they belong to the CURRENT group
            // This ensures we never show events from a previously selected group
            const currentGroupId = currentGroup.group_id;
            
            // Double check that event items only include events for this specific group
            const filteredEvents = groupEventsWithImages.filter(event => 
                event && event.group_id === currentGroupId
            );
            
            console.log(`Rendering ${filteredEvents.length} events for group ${currentGroup.group_name}`);
            
            const eventItems = assembleEventListItems(currentGroup, filteredEvents);
            const userItems = assembleUserListItems(currentGroup, people);

            return (
                <>
                    <List
                        headerText={TEXT.UPCOMING_EVENTS}
                        contentType={TYPES.EVENT}
                        items={eventItems}
                        showButtonOne
                        buttonOneText={TEXT.CREATE_EVENT}
                        onButtonOneClick={() => setViewState(prev => ({ ...prev, type: VIEW_STATES.CREATE_EVENT, mode: 'create' }))}
                        setActiveDialog={setActiveDialog}
                    />
                    <List
                        headerText={TEXT.ADD_MEMBER}
                        contentType={TYPES.USER}
                        items={userItems}
                        showButtonOne
                        buttonOneText={TEXT.ADD_MEMBER}
                        onButtonOneClick={() => handleDialogOpen(DIALOG_TYPES.INVITE_MEMBERS, { group: currentGroup })}
                        setActiveDialog={setActiveDialog}
                    />
                </>
            );
        }

        return (
            <>
                <List
                    headerText={TEXT.MY_GROUPS}
                    contentType={TYPES.GROUP}
                    items={assembleGroupListItems(groups, people)}
                    showButtonOne
                    showButtonTwo
                    buttonOneText={TEXT.JOIN_GROUP}
                    buttonTwoText={TEXT.CREATE_GROUP}
                    onButtonOneClick={() => handleDialogOpen(DIALOG_TYPES.JOIN_GROUP)}
                    onButtonTwoClick={() => setViewState(prev => ({ ...prev, type: VIEW_STATES.CREATE_GROUP, mode: 'create' }))}
                    setActiveDialog={setActiveDialog}
                />
                <List
                    headerText={TEXT.UPCOMING_EVENTS}
                    contentType={TYPES.EVENT}
                    items={assembleEventListItems(groups, events || [])}
                    showButtonOne
                    buttonOneText={TEXT.VIEW_EVENT}
                    onButtonOneClick={() => navigate('/events')}
                    setActiveDialog={setActiveDialog}
                />
            </>
        );
    // Add additional dependency to ensure the callback is updated when state changes
    }, [groups, people, path, id, currentGroup, groupEventsWithImages, handleDialogOpen, navigate, setViewState, events]);

    const renderMainContent = useCallback(() => {
        if (!userData) return null;
    
        const currentId = location.pathname.split('/')[2];
        
        if (path === ROUTES.GROUPS) {
            if (viewState.mode === 'view') {
                if (location.pathname.includes('/collections/new')) {
                    return (
                        <CollectionForm 
                            groupId={currentId}
                            onSubmit={handleCreateCollection}
                            onCancel={() => navigate(`/groups/${currentId}`)}
                        />
                    );
                }
                if (location.pathname === '/groups/new') {
                    return (
                        <GroupForm
                            onSubmit={handleCreateGroup}
                            onCancel={() => navigate('/groups')}
                        />
                    );
                }
                return (
                    <GroupPage 
                        key={currentId} 
                        groupId={currentId} 
                        groups={groups} 
                        onCreateGroup={handleCreateGroup}
                    />
                );
            }
            if (viewState.mode === 'create') {
                if (viewState.type === VIEW_STATES.CREATE_GROUP) {
                    return <GroupForm
                        onSubmit={handleCreateGroup}
                        onCancel={() => setViewState(prev => ({ ...prev, mode: 'view' }))}
                    />;
                }
                if (viewState.type === VIEW_STATES.CREATE_EVENT) {
                    return <EventForm
                        groupId={currentId}
                        onSubmit={handleCreateEvent}
                        onCancel={() => setViewState(prev => ({ ...prev, mode: 'view'}))}
                    />;
                }
                if (viewState.type === VIEW_STATES.CREATE_COLLECTION) {
                    return (
                        <CollectionForm />
                    );
                }
            }
        }
        else if (path === ROUTES.PROFILE) {
            if (viewState.mode === 'view') {
                return (
                    <UserForm 
                        initialData={userData}
                        onSubmit={handleUpdateProfile}
                    />
                );
            }
        }
        else if (path === ROUTES.EVENTS) {
            if (viewState.mode === 'view') {
                return (
                    <EventPage
                        eventId={currentId}
                    />
                );
            }
            if (viewState.mode === 'create') {
                if (viewState.type === VIEW_STATES.CREATE_GROUP) {
                    return <GroupForm
                        onSubmit={handleCreateGroup}
                        onCancel={() => setViewState(prev => ({ ...prev, mode: 'view' }))}
                    />;
                }
            }
        }
        else if (path === ROUTES.COLLECTIONS) {
            if (viewState.mode === 'view') {
                if (currentId == 'new') {
                    return (
                        <CollectionForm
                            onSubmit={handleCreateCollection}
                            onCancel={() => setViewState(prev => ({ ...prev, mode: 'view' }))}
                        />
                    )
                }
                return (
                    <CollectionListPage
                        collectionId={currentId}
                    />
                );
            }
        }
        else if (path === ROUTES.HOME) {
            if (viewState.mode === 'view') {
                if (viewState.type === VIEW_STATES.HOME) {
                    return (
                        <TimelineFeed />
                    );
                }
                if (viewState.type === VIEW_STATES.GROUPS) {
                    return (
                        <GroupPage 
                            key={currentId} 
                            groupId={currentId} 
                            groups={groups}
                            onCreateGroup={handleCreateGroup}
                        />
                    );
                }
            }
            if (viewState.mode === 'create') {
                if (viewState.type === VIEW_STATES.CREATE_GROUP) {
                    return (
                        <GroupForm
                            onSubmit={handleCreateGroup}
                            onCancel={() => setViewState(prev => ({ ...prev, type: VIEW_STATES.HOME, mode: 'view' }))}
                        />
                    );
                }
            }
        }
        else {
            return <TimelineFeed />;
        }
    }, [
        userData, 
        viewState, 
        path, 
        location.pathname, 
        groups, 
        handleCreateEvent, 
        handleCreateGroup, 
        handleCreateCollection,
        handleUpdateProfile,
        setViewState,
        navigate
    ]);

    const renderDialog = useCallback(() => {
        const dialogProps = {
            open: true,
            onClose: handleCloseDialog
        };

        switch (activeDialog.type) {
            case DIALOG_TYPES.JOIN_GROUP:
                return <JoinGroupDialog {...dialogProps} />;
            case DIALOG_TYPES.VIEW_EVENT:
                return <ViewEventDialog {...dialogProps} eventId={activeDialog.data.eventId} />;
            case DIALOG_TYPES.INVITE_MEMBERS:
                return <UserInviteDialog {...dialogProps} group={activeDialog.data.group} />;
            default:
                return null;
        }
    }, [activeDialog, handleCloseDialog]);

    return (
        <div className={styles['home-page-container']} style={{ overflow: 'auto', height: 'auto', minHeight: '100vh' }}>
            <div className={styles['user-container']}>
                <div className={styles['user-profile-card-container']}>
                    <UserProfileCard
                        user={userData}
                    />
                </div>
                <div className={styles['side-list-container']}>
                    {renderSideLists()}
                </div>
            </div>
            <div className={styles['user-content-container']}>
            {(path !== ROUTES.HOME || viewState.mode === 'create') && (
                <Box sx={{ marginBottom: '14px' }}>
                    <Button
                        disableRipple
                        onClick={handleGoBack}
                        sx={{
                            color: '#000',
                            fontFamily: 'Wanted Sans',
                            fontStyle: 'normal',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            width: 'fit-content',
                            padding: '6px 16px',
                            borderRadius: '20px', // Makes the button oval-shaped
                            '&:hover': {
                                background: 'rgba(0, 0, 0, 0.04)' // Matching the View All hover color
                            },
                            '&:active': {
                                background: 'rgba(0, 0, 0, 0.08)' // Slightly darker for active state
                            }
                        }}
                    >
                        {TEXT.GO_BACK}
                    </Button>
                </Box>
            )}
            <div className={styles['content']} style={{ height: 'auto' }}>
                {renderMainContent()}
            </div>
            </div>
            {renderDialog()}
        </div>
    );
};

export default HomePage;