import { apiClient } from "../../../services/api/config";
import { ENDPOINTS } from "../../../services/api/endpoints";

export const eventApi = {
    getEventDetails: async (eventId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.EVENT.RETRIEVE_EVENT_DETAILS,
                params: {
                    event_id: eventId
                }
            };

            const response = await apiClient(config);
            return response.data;
        } catch(error) {
            console.error('Error fetching event details:', error);
            throw error;
        }
    },
    
    getEventProfileImage: async (eventId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.MEDIA.RETRIEVE_THUMBNAIL_IMAGE,
                params: {
                    id: eventId,
                    type: 'event'
                },
                responseType: 'blob'
            };

            const response = await apiClient(config);
            
            // Check if the response is empty or contains "null"
            if (!response.data || response.data.size === 0) {
                return null;
            }

            // Convert blob to base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Additional check for "null" content
                    if (reader.result === "data:application/json;base64,bnVsbA==") {
                        resolve(null);
                    } else {
                        resolve(reader.result);
                    }
                };
                reader.onerror = reject;
                reader.readAsDataURL(response.data);
            });
        } catch (error) {
            console.error('Error fetching event profile image', error);
            throw error;
        }
    },

    createEvent: async(eventInformation) => {
        try {
            const config = {
                method: 'POST',
                url: ENDPOINTS.EVENT.CREATE_EVENT,
                data: {
                    group_id: eventInformation.group_id,
                    user_id: eventInformation.user_id,
                    event_name: eventInformation.event_name,
                    event_location: eventInformation.event_location,
                    event_description: eventInformation.event_description,
                    event_date: eventInformation.event_date
                }
            };
            const response = await apiClient(config);
            return response;
        } catch (error) {
            console.error(`Error creating event ${eventInformation.event_name}`, error);
            throw error;
        }
    },
    
    joinEvent: async(eventId, userId) => {
        try {
            const config = {
                method: 'POST',
                url: ENDPOINTS.EVENT.USER_ADDED_TO_EVENT,
                data: {
                    user_id: userId,
                    event_id: eventId
                }
            };
            const response = await apiClient(config);
            return response;
        } catch (error) {
            console.error(`Error adding user ${userId} to event ${eventId}`, error);
            throw error;
        }
    }
};