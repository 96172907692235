export const TEXT = {
    TITLE_NAME: 'GroupShack',
    TITLE_DESCRIPTION: 'Photo-sharing made easy',
    USERNAME_LABEL: 'Username',
    PASSWORD_LABEL: 'Password',
    REMEMBER_ME: 'Remember me',
    FORGOT_PASSWORD: 'Forgot password?',
    LOGIN: 'Login',
    OR: 'or',
    SIGNUP_EMAIL: 'Signup with Email',
    SIGNUP_GMAIL: 'Signup with Gmail',
    SIGNING_IN: 'Signing in...',
    LOGIN_FAILED: 'Login failed. Please try again.'
};