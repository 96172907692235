import React, { useRef, useState } from 'react';
import { TEXT } from './constants';
import { Button, Typography, Box, Paper, CircularProgress, List, ListItem, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import { UploadFile as UploadFileIcon, CheckCircle, Error as ErrorIcon } from '@mui/icons-material';
import { useFileUpload } from '../../hooks/useFileUpload';
import { useMedia } from '../../hooks/useMedia';

// Utility function to sanitize filenames
const sanitizeFileName = (fileName, preserveSpaces = false) => {
    // Extract file extension
    const lastDotIndex = fileName.lastIndexOf('.');
    const extension = lastDotIndex !== -1 ? fileName.slice(lastDotIndex) : '';
    const nameWithoutExt = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
    
    // Handle special characters but preserve more of the original structure
    let sanitizedName;
    
    if (preserveSpaces) {
        // Just remove problematic characters but keep spaces
        sanitizedName = nameWithoutExt
            .replace(/[<>:"/\\|?*\x00-\x1F]/g, '') // Remove truly invalid chars
            .trim();
    } else {
        // Replace spaces with underscores and remove special characters
        sanitizedName = nameWithoutExt
            .replace(/\s+/g, '_')
            .replace(/[^a-zA-Z0-9_\-\.]/g, '') // Allow dots within name
            .trim();
    }
    
    // Return sanitized name with extension
    return sanitizedName + extension;
};

const MediaFileUpload = ({
    variant = 'button',
    width,
    height,
    acceptedTypes = '*',
    maxSizeConfig = {
        image: 20 * 1024 * 1024, // 20MB for images
        video: 100 * 1024 * 1024 // 100MB for videos
    },
    onFileSelect,
    onUploadSuccess,
    buttonText = 'Upload Files',
    disabled = false,
    className,
    ariaLabel,
    collectionId,
    groupId,
    userId,
    multiple = true, // Multiple file upload enabled by default
    maxFiles = 10    // Maximum number of files allowed
}) => {
    const inputRef = useRef(null);
    const { uploadFileAsync, isUploading } = useMedia();
    const [isDragging, setIsDragging] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [completedUploads, setCompletedUploads] = useState([]);
    const [failedUploads, setFailedUploads] = useState([]);
    const [uploadWarning, setUploadWarning] = useState('');
    const [processedMediaIds, setProcessedMediaIds] = useState(new Set());

    // Import mediaStore directly
    const { useMediaStore } = require('../../store/mediaStore');

    const maxVideoSizeUpload = Math.floor(maxSizeConfig.video / 1024 / 1024);
    const maxImageSizeUpload = Math.floor(maxSizeConfig.image / 1024 / 1024);
    
    // Helper function to generate a truly unique ID for React keys
    const generateUniqueId = (base) => {
        return `upload-temp-${base}-${Math.random().toString(36).substring(2, 9)}-${Date.now()}`;
    };
    
    // Define the requirements text based on accepted types
    const getFileRequirementsText = () => {
        if (acceptedTypes.includes('image')) {
            return `Image files (max. ${maxImageSizeUpload}MB)`;
        } else if (acceptedTypes.includes('video')) {
            return `Video files (max. ${maxVideoSizeUpload}MB)`;
        } else {
            return `Image and video files (max. ${maxImageSizeUpload}MB for images, ${maxVideoSizeUpload}MB for videos)`;
        }
    };

    const handleFileSelection = async (files) => {
        if (!files || files.length === 0) return;
        
        // Convert FileList to Array if needed
        let filesArray = Array.isArray(files) ? files : Array.from(files);
        
        // Limit to maximum number of files
        if (filesArray.length > maxFiles) {
            const warningMessage = `Only the first ${maxFiles} files will be uploaded (${filesArray.length} selected)`;
            console.log(warningMessage);
            setUploadWarning(warningMessage);
            filesArray = filesArray.slice(0, maxFiles);
        } else {
            setUploadWarning('');
        }
        
        // Check if this is a group thumbnail upload (single file, specific context)
        const isGroupThumbnail = !multiple && 
                               filesArray.length === 1 && 
                               (buttonText.toLowerCase().includes('thumbnail') || 
                                buttonText.toLowerCase().includes('profile'));
        
        // Process files - create new File objects with sanitized names
        const processedFiles = filesArray.map((file, index) => {
            const originalName = file.name;
            // Use different sanitization for group thumbnails to maintain compatibility
            const sanitizedName = sanitizeFileName(originalName, isGroupThumbnail);
            
            // If name didn't change, use original file
            if (sanitizedName === originalName) {
                return {
                    file,
                    originalName,
                    sanitizedName,
                    renamed: false
                };
            }
            
            // Create a new File object with the sanitized name
            const newFile = new File(
                [file], 
                sanitizedName, 
                { type: file.type, lastModified: file.lastModified }
            );
            
            return {
                file: newFile,
                originalName,
                sanitizedName,
                renamed: true
            };
        });
        
        // Special handling for group thumbnails - call onFileSelect immediately with minimal processing
        if (isGroupThumbnail) {
            console.log("Detected group thumbnail upload - using simplified processing");
            if (onFileSelect) {
                const processedFileObjects = processedFiles.map(item => item.file);
                onFileSelect(processedFileObjects);
                return; // Skip the rest of the upload process, let the parent handle it
            }
        }
        
        // Call onFileSelect with all processed files if provided
        if (onFileSelect) {
            const processedFileObjects = processedFiles.map(item => item.file);
            onFileSelect(processedFileObjects);
        }
        
        // Create tracking entries for each file with truly unique IDs
        const newUploadingFiles = processedFiles.map(({ file, originalName, sanitizedName, renamed }, index) => ({
            id: generateUniqueId(index),
            file,
            originalName,
            sanitizedName,
            renamed,
            progress: 0,
            status: 'uploading'
        }));
        
        setUploadingFiles(prev => [...prev, ...newUploadingFiles]);
        
        // Process each file in parallel
        const uploadPromises = processedFiles.map(async ({ file, originalName, sanitizedName, renamed }, index) => {
            const fileId = newUploadingFiles[index].id;
            
            try {
                // Upload the file and get response
                const { mediaId, newMediaItem } = await uploadFileAsync({
                    file,
                    collectionId,
                    groupId,
                    userId
                });
                
                // Check if we've already processed this mediaId
                if (processedMediaIds.has(mediaId)) {
                    console.log(`File with mediaId ${mediaId} already processed, skipping UI update`);
                    
                    // Just remove from uploading
                    setUploadingFiles(prev => prev.filter(item => item.id !== fileId));
                    return { success: true, mediaId, newMediaItem, file, originalName, sanitizedName };
                }
                
                // Add to processed media IDs to prevent duplicates
                setProcessedMediaIds(prev => new Set([...prev, mediaId]));
                
                // First, remove from uploading state
                setUploadingFiles(prev => prev.filter(item => item.id !== fileId));
                
                // Call onUploadSuccess immediately
                if (onUploadSuccess) {
                    onUploadSuccess(mediaId, newMediaItem, file, { 
                        originalName, 
                        sanitizedName,
                        renamed 
                    });
                }
                
                // Clear storage to avoid quota issues
                try {
                    const clearMedia = useMediaStore.getState().clearMedia;
                    if (typeof clearMedia === 'function') {
                        clearMedia();
                        console.log('Media storage cleared after successful upload');
                    }
                } catch (storageError) {
                    console.warn('Could not clear media storage:', storageError);
                }
                
                // Add to completed uploads with a delay to avoid duplicate rendering
                // This gives the parent component time to update its state first
                setTimeout(() => {
                    setCompletedUploads(prev => [
                        ...prev, 
                        {
                            id: `completed-${mediaId}-${Date.now()}`, // Ensure unique ID
                            file,
                            originalName,
                            sanitizedName,
                            renamed,
                            mediaId,
                            mediaItem: newMediaItem
                        }
                    ]);
                    
                    // Remove from completed after a short display period
                    setTimeout(() => {
                        setCompletedUploads(current => 
                            current.filter(item => 
                                !(item.mediaId === mediaId && item.file.name === file.name)
                            )
                        );
                        console.log(`Cleaned up file ${originalName} from completed list`);
                    }, 2000);
                }, 500);
                
                return { success: true, mediaId, newMediaItem, file, originalName, sanitizedName };
            } catch (error) {
                console.error(`Upload failed for ${originalName}:`, error);
                
                // Update failed uploads
                setFailedUploads(prev => [...prev, {
                    id: fileId,
                    file,
                    originalName,
                    sanitizedName,
                    renamed,
                    error
                }]);
                
                // Remove from uploading
                setUploadingFiles(prev => prev.filter(item => item.id !== fileId));
                
                return { success: false, file, originalName, error };
            }
        });
        
        await Promise.allSettled(uploadPromises);
    };
    
    const { error, handleFiles } = useFileUpload({
        acceptedTypes,
        maxSizeConfig,
        onFileSelect: handleFileSelection,
        multiple,
        maxFiles
    });

    const handleButtonClick = () => {
        inputRef.current?.click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const renderFileList = () => {
        // Create a map with truly unique keys to prevent duplicates
        const fileMap = new Map();
        
        // First add uploading files
        uploadingFiles.forEach(file => {
            const uniqueKey = `uploading-${file.id}`;
            fileMap.set(uniqueKey, { ...file, listKey: uniqueKey, status: 'uploading' });
        });
        
        // Then add failed uploads
        failedUploads.forEach(file => {
            const uniqueKey = `failed-${file.id}`;
            if (!fileMap.has(uniqueKey)) {
                fileMap.set(uniqueKey, { ...file, listKey: uniqueKey, status: 'failed' });
            }
        });
        
        // Finally add completed uploads (only if they don't appear in CollectionFileView)
        completedUploads.forEach(file => {
            // Use a predictable and unique key for completed uploads
            const uniqueKey = `completed-${file.id}`;
            if (!fileMap.has(uniqueKey)) {
                fileMap.set(uniqueKey, { ...file, listKey: uniqueKey, status: 'completed' });
            }
        });
        
        // Convert map to array
        const allFiles = Array.from(fileMap.values());
        
        if (allFiles.length === 0) return null;
        
        return (
            <List sx={{ mt: 2, maxHeight: '200px', overflow: 'auto', width: '100%' }}>
                {allFiles.map((fileItem) => {
                    // Use status property directly
                    const isUploading = fileItem.status === 'uploading';
                    const isComplete = fileItem.status === 'completed';
                    const isFailed = fileItem.status === 'failed';
                    
                    // Determine what name to display
                    const displayName = fileItem.originalName || fileItem.file.name;
                    const wasRenamed = fileItem.renamed;
                    const sanitizedName = fileItem.sanitizedName || fileItem.file.name;
                    
                    return (
                        <ListItem key={fileItem.listKey} sx={{ py: 0.5 }}>
                            <ListItemIcon sx={{ minWidth: '32px' }}>
                                {isUploading && <CircularProgress size={20} />}
                                {isComplete && <CheckCircle sx={{ color: 'success.main' }} />}
                                {isFailed && <ErrorIcon sx={{ color: 'error.main' }} />}
                            </ListItemIcon>
                            <Tooltip 
                                title={wasRenamed ? `Uploaded as: ${sanitizedName}` : ''} 
                                placement="top"
                                arrow
                            >
                                <ListItemText 
                                    primary={displayName}
                                    secondary={
                                        isUploading ? 'Uploading...' : 
                                        isComplete ? wasRenamed ? 'Completed (renamed)' : 'Completed' :
                                        isFailed ? 'Failed' : ''
                                    }
                                    primaryTypographyProps={{
                                        noWrap: true,
                                        sx: { 
                                            maxWidth: '200px',
                                            fontFamily: 'Wanted Sans' 
                                        }
                                    }}
                                    secondaryTypographyProps={{
                                        sx: { fontFamily: 'Wanted Sans' }
                                    }}
                                />
                            </Tooltip>
                        </ListItem>
                    );
                })}
            </List>
        );
    };

    const ButtonVariant = () => (
        <Box>
            <input
                ref={inputRef}
                type="file"
                accept={acceptedTypes}
                multiple={multiple}
                onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                        handleFiles(files);
                    }
                    e.target.value = '';
                }}
                style={{ display: 'none' }}
                aria-label={ariaLabel || buttonText}
            />
            <Button
                onClick={handleButtonClick}
                disabled={disabled || isUploading}
                component="span"
                className={className}
                sx={{
                    display: 'flex',
                    width: width,
                    height: height,
                    padding: '8px 22px',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #0357D7',
                    backgroundColor: '#0357D7',
                    '&:hover': {
                        backgroundColor: '#0243ac',
                    },
                    '&:disabled': {
                        backgroundColor: '#cccccc',
                        borderColor: '#cccccc',
                    }
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Wanted Sans',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '26px',
                        color: '#FFF',
                        textTransform: 'none'
                    }}
                >
                    {isUploading ? 'Uploading...' : buttonText}
                </Typography>
            </Button>
            {renderFileList()}
        </Box>
    );

    const DropzoneVariant = () => (
        <Box>
            <input
                ref={inputRef}
                type="file"
                accept={acceptedTypes}
                multiple={multiple}
                onChange={(e) => {
                    const files = e.target.files;
                    if (files && files.length > 0) {
                        handleFiles(files);
                    }
                    e.target.value = '';
                }}
                style={{ display: 'none' }}
                aria-label={ariaLabel || "Drop files here or click to upload"}
            />
            <Paper
                variant="outlined"
                onClick={handleButtonClick}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsDragging(false);

                    const files = e.dataTransfer.files;
                    if (files && files.length > 0) {
                        handleFiles(files);
                    }
                }}
                sx={{
                    border: theme => `2px dashed ${isDragging ? theme.palette.primary.main : '#2196F3'}`,
                    bgcolor: theme => isDragging ? '#2196F314':'var(--primary-selected, rgba(33, 150, 243, 0.08))',
                    width: width,
                    height: height,
                    borderRadius: '8px',
                    p: 4,
                    textAlign: 'center',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    minHeight: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                        bgcolor: 'rgba(3, 87, 215, 0.04)',
                        borderColor: '#0357D7',
                    },
                    opacity: isUploading ? 0.7 : 1,
                    pointerEvents: isUploading ? 'none' : 'auto'
                }}
            >
                <UploadFileIcon 
                    sx={{ 
                        width: '24px',
                        height: '24px',
                        color: isDragging ? '#0357D7' : 'text.secondary',
                        mb: 2 
                    }} 
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography
                        sx={{
                            textDecoration: 'underline',
                            fontFamily: 'Wanted Sans',
                            fontSize: '17px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '175%',
                            letterSpacing: '.15px',
                            color: '#2196F3'
                        }}
                    >
                        {TEXT.CLICK_TO_UPLOAD}
                    </Typography>
                    <Typography
                        sx={{
                            marginLeft: '6px',
                            fontFamily: 'Wanted Sans',
                            fontSize: '17px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '175%',
                            letterSpacing: '.15px'
                        }}
                    >
                        {multiple ? TEXT.DRAG_AND_DROP_MULTIPLE || 'or drag and drop multiple files' : TEXT.DRAG_AND_DROP}
                    </Typography>
                </Box>

                <Typography 
                    sx={{
                        textAlign: 'center',
                        fontFamily: 'Wanted Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '143%',
                        letterSpacing: '.17px'
                    }}
                >
                    {isUploading ? 'Uploading...' : getFileRequirementsText()}
                </Typography>
            </Paper>
            {renderFileList()}
        </Box>
    );

    return (
        <Box sx={{ width: '100%' }}>
            {variant === 'button' ? <ButtonVariant /> : <DropzoneVariant />}
            {error && (
                <Typography
                    color="error"
                    variant="caption"
                    sx={{ 
                        mt: 1, 
                        display: 'block',
                        fontFamily: 'Wanted Sans'
                    }}
                >
                    {error}
                </Typography>
            )}
            {uploadWarning && (
                <Typography
                    color="warning.main"
                    variant="caption"
                    sx={{ 
                        mt: 1, 
                        display: 'block',
                        fontFamily: 'Wanted Sans'
                    }}
                >
                    {uploadWarning}
                </Typography>
            )}
        </Box>
    );
};

export default MediaFileUpload;