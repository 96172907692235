import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useGroupStore = create(
    persist(
        (set) => ({
            groups: [],
            setGroups: (groupsData) => set({ groups: groupsData }),
            clearGroups: () => set({ groups: [] })
        }),
        {
            name: 'group-storage'
        }
    )
);