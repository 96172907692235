import { useMutation } from "@tanstack/react-query";
import { groupApi } from "../services/groupApi";
import { useMedia } from "../../media/hooks/useMedia";
import { isEmpty } from "../../../utils/Utils";
import { compressImage } from "../../../utils/ImageCompression";

export const useGroup = () => {
    // Get the media hooks for handling thumbnail upload
    const { 
        updateThumbnailImage, 
        isUpdateThumbnailImageLoading, 
        updateThumbnailImageError 
    } = useMedia();

    const addUserToGroupMutation = useMutation({
        mutationFn: async({ groupIds, userId }) => {
            const joinPromises = groupIds.map(groupId => 
                groupApi.joinGroup(groupId, userId)
            );
            return Promise.all(joinPromises);
        },
        onSuccess: () => {
            console.log('Successfully joined all groups');
        },
        onError: (error) => {
            console.error('Error joining groups:', error);
        }
    });

    const createGroupMutation = useMutation({
        mutationFn: async(groupFormData) => {
            const requestPayload = {
                user_id: groupFormData.userId,
                group_name: groupFormData.name,
                group_description: groupFormData.description,
                group_url: groupFormData.url,
                open_invitation: groupFormData.openInvitation,
                is_private: groupFormData.isPrivate
            };
    
            const response = await groupApi.createGroup(requestPayload);
            const groupId = response.data;
            console.log('Group created successfully, ID:', groupId);

            // Use the media service for thumbnail upload
            if (groupFormData.thumbnailFile && !isEmpty(groupId)) {
                console.log('Uploading group thumbnail image for group ID:', groupId);
                try {
                    // Pre-process the image before uploading
                    const originalSize = groupFormData.thumbnailFile.size;
                    
                    // Compress the image with industry-standard settings
                    const compressedThumbnail = await compressImage(
                        groupFormData.thumbnailFile,
                        {
                            maxWidth: 1200,
                            maxHeight: 1200,
                            quality: 0.85,
                            maxSizeMB: 1
                        }
                    );
                    
                    console.log(`Thumbnail prepared for upload: ${(originalSize/1024).toFixed(2)}KB → ${(compressedThumbnail.size/1024).toFixed(2)}KB (${Math.round(compressedThumbnail.size/originalSize*100)}%)`);
                    
                    const thumbnailResponse = await updateThumbnailImage({
                        entityId: groupId,
                        entityType: 'group',
                        entityThumbnailFile: compressedThumbnail
                    });
                    console.log('Group thumbnail uploaded successfully:', thumbnailResponse);
                } catch (thumbnailError) {
                    console.error('Failed to upload group thumbnail:', thumbnailError);
                    // We don't throw here to avoid failing the entire operation
                }
            } else {
                console.log('No thumbnail to upload or missing group ID');
            }
            
            return {
                groupId: groupId,
                formData: groupFormData
            };
        },
        onSuccess: (result) => {
            console.log(`Successfully created group: ${result.formData.name}`);
        },
        onError: (error, variables) => {
            console.error(`Failed to create group: ${variables.name}`, error);
        }
    });
    
    return {
        addUserToGroups: addUserToGroupMutation.mutate,

        createGroup: createGroupMutation.mutate,
        isCreateGroupLoading: createGroupMutation.isPending || isUpdateThumbnailImageLoading,
        errorCreateGroup: createGroupMutation.error || updateThumbnailImageError
    }
};