export const TEXT = {
    INVITED_TO_UPLOAD_IMAGES_OR_VIDEOS: 'has invited you to upload images or videos to',
    CLICK_TO_UPLOAD: 'Click to upload',
    OR_DRAG_AND_DROP: 'or drag and drop',
    SUPPORTED_FILE_TYPES_TEXT: 'SVG, PNG, JPG, or GIF',
    IM_DONE_UPLOADING: 'I\'m done uploading!',
    CONFIRMATION_DIALOG_HEADER: 'Before you head out...',
    CONFIRMATION_DIALOG_TEXT: 'Every photo tells part of the story. Add yours now or come back later to share.',
    CANCEL_BUTTON_TEXT: 'Cancel',
    CONTINUE_BUTTON_TEXT: 'Continue'
}