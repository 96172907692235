export const dateFormat = (dateStr) => {
    if (!dateStr) {
        throw new Error('Date string is required');
    }

    try {
        // Handle different date separators by replacing them with '-'
        const normalizedDate = dateStr.replace(/[./\\]/g, '-');
        
        // Create a Date object (this will handle various input formats)
        const date = new Date(normalizedDate);
        
        // Check if date is valid
        if (isNaN(date.getTime())) {
            // Try parsing DD-MM-YYYY format explicitly
            const [day, month, year] = normalizedDate.split('-');
            date = new Date(year, month - 1, day);
            
            if (isNaN(date.getTime())) {
                throw new Error('Invalid date format');
            }
        }

        // Format the date
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                       'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        
        const month = months[date.getMonth()];
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month} ${day}, ${year}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return dateStr; // Return original string if formatting fails
    }
};