import { useState } from 'react';
import { 
    Box, 
    TextField, 
    Chip,
    Typography,
    Button,
    IconButton,
    InputAdornment
} from '@mui/material';
import { TEXT } from './constants';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const EmailInput = ({ groupUrl, urlCopied, setUrlCopied }) => {
    const [email, setEmail] = useState('');
    const [emails, setEmails] = useState([]);
    const [error, setError] = useState('');

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setError('');
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addEmail();
        }
    };

    const addEmail = () => {
        const trimmedEmail = email.trim();
        
        if (!trimmedEmail) {
            return;
        }

        if (!emailRegex.test(trimmedEmail)) {
            setError('Please enter a valid email address');
            return;
        }

        if (emails.includes(trimmedEmail)) {
            setError('This email has already been added');
            return;
        }

        setEmails([...emails, trimmedEmail]);
        setEmail('');
        setError('');
    };

    const removeEmail = (emailToRemove) => {
        setEmails(emails.filter(email => email !== emailToRemove));
    };

    const handleCopyUrl = async () => {
        try {
            await navigator.clipboard.writeText(groupUrl);
            setUrlCopied(true);
        } catch (err) {
            console.error('Failed to copy URL:', err);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/* URL Copy Section */}
            <TextField
                fullWidth
                label={ TEXT.COPY_UPLOAD_URL }
                variant="outlined"
                value={groupUrl}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton 
                                onClick={handleCopyUrl}
                                edge="end"
                            >
                                <ContentCopyIcon
                                    sx={{
                                        fontSize: '20px'
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                    readOnly: true
                }}
                sx={{
                    marginTop: '20px',
                    '& .MuiInputBase-input': {
                        fontFamily: 'Wanted Sans'
                    },
                    '& .MuiInputLabel-root': {
                        fontFamily: 'Wanted Sans',
                        fontWeight: '400',
                        fontStyle: 'normal',
                        '&.Mui-focused': {
                            color: '#000000'
                        }                
                    },
                    '& .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23) !important',  // Added !important
                            pointerEvents: 'none'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23) !important'  // Added !important
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23) !important',  // Added !important
                            borderWidth: '1px'  // Prevents the border from getting thicker
                        }
                    }
                }}
                slotProps={{
                    inputLabel: {
                        shrink: true,
                        style: {
                            fontStyle: 'Wanted Sans',
                            fontSize: '17px',
                        }
                    }
                }}
            />
            {urlCopied && (
                <Typography 
                    sx={{ 
                        marginTop: '4px',
                        marginLeft: '15px',
                        color: '#46AB17',
                        fontFamily: 'Wanted Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '166%'
                    }}
                >
                    { TEXT.URL_COPIED }
                </Typography>
            )}

            {/* Email Input */}
            <TextField
                label={TEXT.SEND_UPLOAD_LINK}
                fullWidth
                placeholder="email@address.com"
                value={email}
                onChange={handleEmailChange}
                onKeyPress={handleKeyPress}
                error={!!error}
                helperText={error}
                sx={{
                    marginTop: '24px',
                    '& .MuiInputBase-input': {
                        fontFamily: 'Wanted Sans'
                    },
                    '& .MuiInputLabel-root': {
                        fontFamily: 'Wanted Sans',
                        fontWeight: '400',
                        fontStyle: 'normal',
                        '&.Mui-focused': {
                            color: '#000000'
                        }                
                    }
                }}
                slotProps={{
                    inputLabel: {
                        shrink: true,
                        style: {
                            fontStyle: 'Wanted Sans',
                            fontSize: '17px',
                        }
                    }
                }}
            />

            {/* Email Chips */}
            <Box sx={{ 
                marginTop: '10px',
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1,
                mb: 2
            }}>
                {emails.map((email, index) => (
                    <Chip
                        key={index}
                        label={email}
                        onDelete={() => removeEmail(email)}
                        deleteIcon={<CloseIcon />}
                        sx={{
                            backgroundColor: '#F5F5F5',
                            borderRadius: '100px',
                            display: 'flex',
                            padding: '4px',
                            alignItems: 'center',
                            '& .MuiChip-deleteIcon': {
                                color: '#565656'
                            }
                        }}
                    />
                ))}
            </Box>

            {/* Share Button */}
            <Button
                fullWidth
                onClick={addEmail}
                disabled={emails.length === 0}
                sx={{
                    marginTop: '32px',
                    display: 'flex',
                    padding: '6px 16px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: '1 0 0',
                    borderRadius: '4px',
                    border: '1px solid #0357D7',
                    background: '#0357D7'
                }}
            >
                <Typography
                    sx={{
                        color: '#FFF',
                        fontFamily: 'Wanted Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '24px',
                        textTransform: 'none'
                    }}
                >
                    { TEXT.SHARE_UPLOAD_LINK_BUTTON_TEXT }
                </Typography>
            </Button>
        </Box>
    );
};

export default EmailInput;