import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { dateFormat } from '../../../../utils/DateFormat';
import { useGroupStore } from '../../../group/store/groupStore';
import { TEXT } from './constants';

const EventListItem = ({ event, setShowEventDialog }) => {
    const { groups } = useGroupStore();

    const currentGroup = groups.find(group => group.group_id === event.group_id);
    const groupName = currentGroup?.group_name || '';
    const eventName = event?.event_name || TEXT.NOT_AVAILABLE;
    const date = event?.event_date ? dateFormat(event.event_date) : '';
    const eventId = event?.event_id;
    const profileImage = event?.profile_image || null;

    // For debugging
    if (profileImage) {
        console.log(`Event ${eventName} has profile image`);
    }

    return (
        <Box 
            onClick={() => setShowEventDialog({show: true, eventId: eventId})}
            sx={{ 
                cursor: 'pointer',
                '&:hover': {
                    opacity: 0.9
                }
            }}
        >
            <Box
                sx={{
                    width: '330px',
                    height: '200px',
                    borderRadius: '10px',
                    backgroundColor: 'antiquewhite',
                    backgroundImage: profileImage ? `url(${profileImage})` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {!profileImage && (
                    <Typography 
                        sx={{ 
                            color: '#565656',
                            fontFamily: 'Wanted Sans'
                        }}
                    >
                        No Image Available
                    </Typography>
                )}
            </Box>
            <Typography
                sx={{
                    marginTop: '8px',
                    alignSelf: 'stretch',
                    color: '#000',
                    fontFamily: 'Wanted Sans',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal'
                }}
            >
                {eventName}
            </Typography>
            <Typography
                sx={{
                    marginTop: '6px',
                    color: '#565656',
                    fontFamily: 'Wanted Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '16px',
                    letterSpacing: '-1px' 
                }}
            >
                {date}
            </Typography>
            <Typography
                sx={{
                    marginTop: '5px',
                    alignSelf: 'stretch',
                    color: '#565656',
                    fontFamily: 'Wanted Sans',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '16px',
                    letterSpacing: '-1px'
                }}
            >
                {groupName}
            </Typography>
        </Box>
    );
};

EventListItem.propTypes = {
    event: PropTypes.shape({
        event_id: PropTypes.string,
        event_name: PropTypes.string,
        event_date: PropTypes.string,
        group_id: PropTypes.string,
        profile_image: PropTypes.string
    }),
    setShowEventDialog: PropTypes.func.isRequired
};

export default EventListItem;