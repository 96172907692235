import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, TextField, Snackbar, Alert } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Avatar } from '@mui/material';
import { TEXT } from './constants';
import { useEvent } from '../../hooks/useEvent';

export const EventForm = ({
    groupId,
    onCancel
}) => {
    // State for form fields
    const userId = localStorage.getItem('user_id');
    const navigate = useNavigate();
    
    // Get event management functions from the hook
    const {
        createEvent,
        addUserToEvent,
        isCreateEventLoading,
        errorCreateEvent
    } = useEvent();

    // Toast notification state
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastSeverity, setToastSeverity] = useState('success');

    // Add validation state
    const [errors, setErrors] = useState({
        name: false,
        location: false
    });

    const [eventData, setEventData] = useState({
        groupId: groupId,
        userId: userId,
        name: '',
        description: '',
        location: '',
        date: dayjs(),
        thumbnailUrl: null,
        thumbnailFile: null
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventData(prev => ({
            ...prev,
            [name]: value
        }));

        // Clear error when user types
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: false
            }));
        }
    };

    const handleThumbnailUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Basic file validation
            if (!file.type.startsWith('image/')) {
                showToast('Please select an image file', 'error');
                return;
            }
            
            // Check file size (limit to 5MB for example)
            if (file.size > 5 * 1024 * 1024) {
                showToast('File size should not exceed 5MB', 'error');
                return;
            }
            
            const reader = new FileReader();
            reader.onload = (e) => {
                setEventData(prev => ({
                    ...prev,
                    thumbnailUrl: e.target.result,
                    thumbnailFile: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveThumbnail = () => {
        setEventData(prev => ({
            ...prev,
            thumbnailUrl: null,
            thumbnailFile: null
        }));
    };

    const handleToastClose = () => {
        setToastOpen(false);
    };

    const showToast = (message, severity = 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setToastOpen(true);
    };

    const validateForm = () => {
        const newErrors = {
            name: !eventData.name.trim(),
            location: !eventData.location.trim()
        };
        
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (validateForm()) {
            try {
                // Prevent multiple submissions
                if (isCreateEventLoading) {
                    return;
                }
                
                // Create a copy of the event data to format the date
                const formattedEventData = {
                    ...eventData,
                    // Format the date as YYYY-MM-DD
                    date: eventData.date.format('YYYY-MM-DD')
                };
                
                // Use the createEvent function from the hook
                createEvent(formattedEventData, {
                    onSuccess: (result) => {
                        const { eventId } = result;
                        
                        // Try to add user to event (non-blocking)
                        if (eventId) {
                            addUserToEvent({
                                eventId: eventId,
                                userId: userId
                            });
                        }
                        
                        // Show success toast
                        showToast("Event created successfully!");
                        
                        // Wait a moment for the toast to be visible before navigating
                        setTimeout(() => {
                            // Navigate based on context (from group or general events page)
                            if (groupId) {
                                navigate(`/groups/${groupId}`, { 
                                    state: { 
                                        toast: { 
                                            message: "Event created successfully!", 
                                            severity: "success" 
                                        } 
                                    } 
                                });
                            } else {
                                navigate('/events', { 
                                    state: { 
                                        toast: { 
                                            message: "Event created successfully!", 
                                            severity: "success" 
                                        } 
                                    } 
                                });
                            }
                        }, 1500);
                    },
                    onError: (error) => {
                        console.error('Error creating event:', error);
                        showToast('Failed to create event. Please try again.', 'error');
                    }
                });
            } catch (error) {
                console.error('Error in form submission:', error);
                showToast('An unexpected error occurred. Please try again.', 'error');
            }
        } else {
            showToast('Please fill in all required fields', 'error');
        }
    };

    return (
        <Box>
            <Typography
                sx={{
                    color: '#000',
                    fontFamily: 'Wanted Sans',
                    fontSize: '32px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal'
                }}
            >
                {TEXT.CREATE_EVENT}
            </Typography>

            {/* Thumbnail Upload Section */}
            <Box
                sx={{
                    marginTop: '32px',
                    display: 'flex',
                    padding: '32px',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.UPLOAD_THUMBNAIL}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch'
                    }}
                >
                    <Avatar 
                        src={eventData.thumbnailUrl || "/path/to/default/image.jpg"}
                        sx={{
                            width: 128,
                            height: 128
                        }}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="thumbnail-upload"
                        onChange={handleThumbnailUpload}
                    />
                    <label htmlFor="thumbnail-upload">
                        <Button
                            component="span"
                            sx={{
                                marginLeft: '16px',
                                display: 'flex',
                                padding: '8px 22px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '4px',
                                border: '1px solid #0357D7',
                                background: '#0357D7'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#FFF',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '26px',
                                    textTransform: 'none'
                                }}
                            >
                                {TEXT.UPLOAD_IMAGE}
                            </Typography>
                        </Button>
                    </label>
                    {eventData.thumbnailUrl && (
                        <Button
                            onClick={handleRemoveThumbnail}
                            sx={{
                                display: 'flex',
                                padding: '8px 22px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '8px',
                                borderRadius: '4px',
                                border: '1px solid #0357D7'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#0357D7',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '26px',
                                    textTransform: 'none'
                                }}
                            >
                                {TEXT.REMOVE}
                            </Typography>
                        </Button>
                    )}
                </Box>
            </Box>

            {/* Event Information Form */}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    marginTop: '16px',
                    padding: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.EVENT_INFORMATION}
                </Typography>

                <TextField
                    required
                    id="event_name"
                    name="name"
                    label="Event Name"
                    value={eventData.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name ? "Event name is required" : ""}
                    fullWidth
                    sx={{
                        marginTop: '18px'
                    }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    id="description"
                    name="description"
                    label="Description"
                    value={eventData.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                        marginTop: '8px'
                    }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    id="location"
                    name="location"
                    label="Location"
                    value={eventData.location}
                    onChange={handleInputChange}
                    required
                    error={errors.location}
                    helperText={errors.location ? "Location is required" : ""}
                    fullWidth
                    sx={{
                        marginTop: '8px'
                    }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ 
                            marginTop: '8px',
                            width: '50%'
                        }}
                    >
                        <DatePicker
                            label="Event Date"
                            value={eventData.date}
                            onChange={(newValue) => {
                                setEventData(prev => ({
                                    ...prev,
                                    date: newValue
                                }));
                            }}
                            format="MMMM D, YYYY"
                            fullWidth
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    inputProps: {
                                        style: { 
                                            fontFamily: 'Wanted Sans',
                                            fontSize: '16px'
                                        }
                                    },
                                    InputLabelProps: {
                                        style: { 
                                            fontFamily: 'Wanted Sans',
                                            fontSize: '17px'
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                </LocalizationProvider>

                <Box
                    sx={{
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '8px'
                    }}
                >
                    <Button
                        onClick={onCancel}
                        sx={{
                            color: '#0357D7',
                            borderRadius: '4px',
                            border: '1px solid #0357D7'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#0357D7',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            {TEXT.GO_BACK_BUTTON_TEXT}
                        </Typography>
                    </Button>
                    <Button
                        type="submit"
                        disabled={isCreateEventLoading}
                        sx={{
                            backgroundColor: '#0357D7',
                            '&:hover': {
                                backgroundColor: '#0243aa'
                            },
                            '&:disabled': {
                                opacity: 0.7,
                                backgroundColor: '#ccc',
                                borderColor: '#ccc'
                            },
                            textTransform: 'none'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#FFF',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            {isCreateEventLoading ? 'Creating...' : TEXT.CREATE_EVENT_BUTTON_TEXT}
                        </Typography>
                    </Button>
                </Box>
            </Box>

            {/* Toast notification */}
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleToastClose} 
                    severity={toastSeverity} 
                    sx={{ width: '100%', whiteSpace: 'pre-line' }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};