import { apiClient } from "../../../services/api/config";
import { ENDPOINTS } from "../../../services/api/endpoints";

export const personApi = {
    getPersonDetails: async (personId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.PERSON.RETRIEVE_PERSON_DETAILS,
                params: {
                    person_id: personId
                }
            };
            const response = await apiClient(config);
            return response.data;
        } catch (error) {
            console.error('Error fetching Person details:', error);
            throw error;
        }
    },
    
    updatePerson: async(requestPayload) => {
        try {
            const config = {
                method: 'PATCH',
                url: ENDPOINTS.PERSON.UPDATE_PERSON,
                data: requestPayload
            };
            
            const response = await apiClient(config);
            return response;
        } catch (error) {
            console.error('Error updating Person details:', error);
            throw error;
        }
    },
};