import { apiClient } from "../../../services/api/config";
import { ENDPOINTS } from "../../../services/api/endpoints";

export const collectionApi = {
    getCollectionDetails: async(collectionId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.COLLECTION.RETRIEVE_COLLECTION_DETAILS,
                params: {
                    collection_id: collectionId
                }
            };

            const response = await apiClient(config);
            return response.data;
        }
        catch (error) {
            console.error('Error fetching collection details:', error);
            throw error;
        }
    },

    getCollectionProfileImage: async (collectionId) => {
        try {
            const config = {
                method: 'GET',
                url: ENDPOINTS.MEDIA.RETRIEVE_THUMBNAIL_IMAGE,
                params: {
                    id: collectionId,
                    type: 'collection'
                },
                responseType: 'blob'
            };

            const response = await apiClient(config);
            
            // Check if the response is empty
            if (!response.data || response.data.size === 0) {
                return null;
            }

            // Convert blob to base64
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Additional check for "null" content
                    if (reader.result === "data:application/json;base64,bnVsbA==") {
                        resolve(null);
                    } else {
                        resolve(reader.result);
                    }
                };
                reader.onerror = reject;
                reader.readAsDataURL(response.data);
            });
        } catch (error) {
            console.error('Error fetching collection profile image', error);
            throw error;
        }
    },

    createCollection: async(collectionInformation) => {
        try {
            const config = {
                method: 'POST',
                url: ENDPOINTS.COLLECTION.CREATE_COLLECTION,
                data: collectionInformation
            };

            const response = await apiClient(config);

            return response.data;
        }
        catch (error) {
            console.error('Error creating collection', error);
            throw error;
        }
    } 
};