import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Select, MenuItem, FormControl, InputLabel, Typography, TextField, Snackbar, Alert } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TEXT } from './constants';
import { useGroupStore } from '../../../group/store/groupStore';
import { isEmpty } from '../../../../utils/Utils';

export const CollectionForm = ({
    groupId,
    onSubmit,
    onCancel
}) => {
    // Group Name that owns Collection
    const [groupName, setGroupName] = useState('');
    const navigate = useNavigate();

    // Toast notification state
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastSeverity, setToastSeverity] = useState('success');

    const userId = localStorage.getItem('user_id');

    // State for form fields
    const [collectionData, setCollectionData] = useState({
        name: '',
        description: '',
        location: '',
        userId: userId,
        groupId: groupId || '',
        groupName: '',
        startDate: dayjs(),
        endDate: dayjs(),
        thumbnailUrl: null,
        thumbnailFile: null
    });

    // Add form validation state
    const [errors, setErrors] = useState({
        name: false,
        groupId: false
    });

    const groups = useGroupStore(state => state.groups);

    useEffect(() => {
        if (!isEmpty(groupId)) {
            const currentGroup = groups.find(group => group.group_id == groupId);
            if (!isEmpty(currentGroup)) {
                setGroupName(currentGroup.group_name);
                setCollectionData(prev => ({
                    ...prev,
                    groupId: currentGroup.group_id,
                    groupName: currentGroup.group_name
                }));
            }
        }
    }, [groupId, groups]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCollectionData(prev => ({
            ...prev,
            [name]: value
        }));
        
        // Clear validation error when user types
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: false
            }));
        }
    };

    const handleThumbnailUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Basic file validation
            if (!file.type.startsWith('image/')) {
                showToast('Please select an image file', 'error');
                return;
            }
            
            // Check file size (limit to 5MB for example)
            if (file.size > 5 * 1024 * 1024) {
                showToast('File size should not exceed 5MB', 'error');
                return;
            }
            
            const reader = new FileReader();
            reader.onload = (e) => {
                setCollectionData(prev => ({
                    ...prev,
                    thumbnailUrl: e.target.result,
                    thumbnailFile: file
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveThumbnail = () => {
        setCollectionData(prev => ({
            ...prev,
            thumbnailUrl: null,
            thumbnailFile: null
        }));
    };

    const handleGroupChange = (event) => {
        const selectedGroup = groups.find(group => group.group_id === event.target.value);
        if (selectedGroup) {
            setCollectionData(prev => ({
                ...prev,
                groupId: selectedGroup.group_id,
                groupName: selectedGroup.group_name
            }));
            
            // Clear validation error
            setErrors(prev => ({
                ...prev,
                groupId: false
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {
            name: !collectionData.name.trim(),
            groupId: !collectionData.groupId
        };
        
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleToastClose = () => {
        setToastOpen(false);
    };

    const showToast = (message, severity = 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setToastOpen(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (validateForm()) {
            // Submit the form data
            try {
                onSubmit(collectionData);
                
                // Show success toast
                showToast("Collection created successfully!");
                
                // Wait a moment for the toast to be visible before navigating
                setTimeout(() => {
                    // Navigate back to the collections page or relevant destination
                    navigate('/collections');
                }, 1500);
            } catch (error) {
                console.error('Error creating collection:', error);
                showToast('Failed to create collection. Please try again.', 'error');
            }
        } else {
            showToast('Please fill in all required fields', 'error');
        }
    };

    return (
        <Box>
            <Typography
                sx={{
                    color: '#000',
                    fontFamily: 'Wanted Sans',
                    fontSize: '32px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal'
                }}
            >
                { TEXT.CREATE_COLLECTION }
            </Typography>

            {/* Thumbnail Upload Section */}
            <Box
                sx={{
                    marginTop: '32px',
                    display: 'flex',
                    padding: '32px',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        alignSelf: 'stretch',
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    { TEXT.UPLOAD_THUMBNAIL }
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'stretch'
                    }}
                >
                    <Avatar 
                        src={collectionData.thumbnailUrl || "/path/to/default/image.jpg"}
                        sx={{
                            width: 128,
                            height: 128
                        }}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="thumbnail-upload"
                        onChange={handleThumbnailUpload}
                    />
                    <label htmlFor="thumbnail-upload">
                        <Button
                            component="span"
                            sx={{
                                marginLeft: '16px',
                                display: 'flex',
                                padding: '8px 22px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '4px',
                                border: '1px solid #0357D7',
                                background: '#0357D7'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#FFF',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '26px',
                                    textTransform: 'none'
                                }}
                            >
                                { TEXT.UPLOAD_IMAGE }
                            </Typography>
                        </Button>
                    </label>
                    {collectionData.thumbnailUrl && (
                        <Button
                            onClick={handleRemoveThumbnail}
                            sx={{
                                display: 'flex',
                                padding: '8px 22px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '8px',
                                borderRadius: '4px',
                                border: '1px solid #0357D7'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#0357D7',
                                    fontFamily: 'Wanted Sans',
                                    fontSize: '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '26px',
                                    textTransform: 'none'
                                }}
                            >
                                { TEXT.REMOVE }
                            </Typography>
                        </Button>
                    )}
                </Box>
            </Box>

            {/* Collection Information Form */}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    height: '100%',
                    marginTop: '16px',
                    padding: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    borderRadius: '10px',
                    border: '1px solid #E6E8E9',
                    background: '#FFF'
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    { TEXT.COLLECTION_INFORMATION }
                </Typography>

                <TextField
                    required
                    id="collection_name"
                    name="name"
                    label={TEXT.COLLECTION_NAME}
                    value={collectionData.name}
                    onChange={handleInputChange}
                    error={errors.name}
                    helperText={errors.name ? "Collection name is required" : ""}
                    fullWidth
                    sx={{
                        marginTop: '18px'
                    }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />
                <FormControl 
                    fullWidth 
                    required
                    error={errors.groupId}
                    sx={{ 
                        marginTop: '8px',
                        '& .MuiSelect-select': {
                            maxHeight: '160px',  // Set your desired fixed height here
                            overflowY: 'auto'    // Enable vertical scrolling
                        }}
                    }
                >
                    <InputLabel
                        shrink
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '17px',
                            backgroundColor: 'white',
                            paddingLeft: '4px',
                            paddingRight: '4px'
                        }}
                    >
                        { TEXT.GROUP_NAME }
                    </InputLabel>
                    <Select
                        value={collectionData.groupId}
                        onChange={handleGroupChange}
                        disabled={!isEmpty(groupId)}
                        displayEmpty
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: '160px'  // Set the same height for dropdown menu
                                }
                            }
                        }}
                        sx={{
                            fontFamily: 'Wanted Sans',
                            fontSize: '16px'
                        }}
                    >
                        {isEmpty(groups) ? (
                            <MenuItem value="" disabled>{ TEXT.GROUPS_NOT_AVAILABLE }</MenuItem>
                        ) : (
                            groups.map((group) => (
                                <MenuItem 
                                    key={group.group_id} 
                                    value={group.group_id}
                                    sx={{
                                        fontFamily: 'Wanted Sans',
                                        fontSize: '16px'
                                    }}
                                >
                                    {group.group_name}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                    {errors.groupId && (
                        <Typography 
                            color="error" 
                            sx={{ 
                                fontSize: '0.75rem', 
                                marginLeft: '14px', 
                                marginTop: '3px' 
                            }}
                        >
                            Group selection is required
                        </Typography>
                    )}
                </FormControl>
                <TextField
                    id="collection_description"
                    name="description"
                    label={TEXT.COLLECTION_DESCRIPTION}
                    value={collectionData.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                        marginTop: '8px'
                    }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <TextField
                    id="collection_location"
                    name="location"
                    label={TEXT.COLLECTION_LOCATION}
                    value={collectionData.location}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{
                        marginTop: '8px'
                    }}
                    slotProps={{
                        input: {
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '16px'
                            }
                        },
                        inputLabel: {
                            shrink: true,
                            style: {
                                fontFamily: 'Wanted Sans',
                                fontSize: '17px'
                            }
                        }
                    }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ 
                            display: 'flex', 
                            gap: '16px',
                            width: '65%'
                        }}
                    >
                        <DatePicker
                            label="Start Date"
                            value={collectionData.startDate}
                            onChange={(newValue) => {
                                setCollectionData(prev => ({
                                    ...prev,
                                    startDate: newValue
                                }));
                            }}
                            format="MMMM D, YYYY"
                            sx={{
                                marginTop: '8px',
                                width: '50%'
                            }}
                            slotProps={{
                                textField: {
                                    inputProps: {
                                        style: { 
                                            fontFamily: 'Wanted Sans',
                                            fontSize: '16px'
                                        }
                                    },
                                    InputLabelProps: {
                                        style: { 
                                            fontFamily: 'Wanted Sans',
                                            fontSize: '17px'
                                        }
                                    }
                                }
                            }}
                        />
                        <DatePicker
                            label="End Date"
                            value={collectionData.endDate}
                            onChange={(newValue) => {
                                setCollectionData(prev => ({
                                    ...prev,
                                    endDate: newValue
                                }));
                            }}
                            format="MMMM D, YYYY"
                            sx={{
                                marginTop: '8px',
                                width: '50%'
                            }}
                            slotProps={{
                                textField: {
                                    inputProps: {
                                        style: { 
                                            fontFamily: 'Wanted Sans',
                                            fontSize: '16px'
                                        }
                                    },
                                    InputLabelProps: {
                                        style: { 
                                            fontFamily: 'Wanted Sans',
                                            fontSize: '17px'
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                </LocalizationProvider>

                <Box
                    sx={{
                        marginTop: '8px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '8px'
                    }}
                >
                    <Button
                        type="button"
                        onClick={onCancel}
                        sx={{
                            color: '#0357D7',
                            borderRadius: '4px',
                            border: '1px solid #0357D7'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#0357D7',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            { TEXT.GO_BACK_BUTTON_TEXT }
                        </Typography>
                    </Button>
                    <Button
                        type="submit"
                        sx={{
                            backgroundColor: '#0357D7',
                            '&:hover': {
                                backgroundColor: '#0243aa'
                            },
                            textTransform: 'none'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#FFF',
                                fontFamily: 'Wanted Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textTransform: 'none'
                            }}
                        >
                            { TEXT.CREATE_COLLECTION_BUTTON_TEXT }
                        </Typography>
                    </Button>
                </Box>
            </Box>

            {/* Toast notification */}
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleToastClose} 
                    severity={toastSeverity} 
                    sx={{ width: '100%', whiteSpace: 'pre-line' }}
                >
                    {toastMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};