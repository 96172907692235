export const TEXT = {
    FILE_INFORMATION: 'File Information',
    UPLOADED_BY: 'uploaded by',
    CREATED: 'created',
    DIMENSION: 'dimension',
    DOWNLOAD: 'Download',
    DOWNLOAD_IN_PROGRESS: 'Downloading...',
    DOWNLOAD_SELECTED: 'Download Selected',
    DELETE: 'Delete',
    DELETE_SELECTED: 'Delete Selected',
    DELETE_IN_PROGRESS: 'Deleting...'
};