import React, { useState, useEffect } from 'react';
import { Box, Typography} from '@mui/material';
import { TEXT } from './constants';
import { UserTimelineFeed } from '../../../user/components/user-timeline-feed/UserTimelineFeed';
import { CollectionCreatePreview } from '../../../collection/components/collection-create-preview/CollectionCreatePreview';
import { CollectionCarouselPreview } from '../../../collection/components/collection-carousel-preview/CollectionCarouselPreview';
import { useCollectionStore } from '../../../collection/store/collectionStore';
import { useGroupStore } from '../../../group/store/groupStore';
import PillSwitch from '../../../common/pill-switch/PillSwitch';
import PersonIcon from '@mui/icons-material/Person';
import CollectionsIcon from '@mui/icons-material/Collections';

const MAX_NUMBER_ITEMS_IN_CAROUSEL = 10;

const TimelineFeed = () => {
    const [isTimelineView, setIsTimelineView] = useState(false);
    const collections = useCollectionStore(state => state.collections);
    const groups = useGroupStore(state => state.groups);

    const handleViewChange = (event) => {
        setIsTimelineView(event);
    };

    // Create a map of group IDs to group names
    const groupNameMap = groups.reduce((acc, group) => {
        acc[group.group_id] = group.group_name;
        return acc;
    }, {});

    // Process collections to include group names and media counts
    const processedCollections = collections.map(collection => {
        const groupId = collection.collection_directory?.split('/')[0];
        const groupName = groupNameMap[groupId] || '';

        const mediaItems = collection.media || [];
        const numImages = mediaItems.filter(item => 
            item.media_type?.toLowerCase().includes('image')).length;
        const numVideos = mediaItems.filter(item => 
            item.media_type?.toLowerCase().includes('video')).length;

        return {
            ...collection,
            groupName,
            numImages,
            numVideos
        };
    });

    const renderContent = () => {
        if (isTimelineView) {
            return (
                <Box>
                    <UserTimelineFeed />
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '17px',
                    width: '100%' // Ensure it uses full width
                }}
            >
                <CollectionCreatePreview />
                {processedCollections.map((collection) => (
                    <CollectionCarouselPreview
                        key={collection.collection_id}
                        collectionId={collection.collection_id}
                        groupName={collection.groupName}
                        collectionName={collection.collection_name}
                        collectionThumbnailImage={collection.thumbnail}
                        mediaItems={collection.media?.slice(0, MAX_NUMBER_ITEMS_IN_CAROUSEL)}
                        totalCount={collection.media?.length}
                        numImages={collection.numImages}
                        numVideos={collection.numVideos}
                        style={{ width: '100%' }} // Add full width styling if needed
                    />
                ))}
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%', // Use full width of the container
                maxWidth: 'none', // Remove the maxWidth constraint
                height: '100vh',
                maxHeight: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                paddingRight: '16px' // Add right padding to match the container's spacing
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: '22px'
                }}
            >
                <Typography
                    sx={{
                        color: '#000',
                        fontFamily: 'Wanted Sans',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal'
                    }}
                >
                    {TEXT.TIMELINE_FEED_HEADER}
                </Typography>
                <PillSwitch 
                    onChange={handleViewChange}
                    size='small'
                    leftIcon={CollectionsIcon}
                    rightIcon={PersonIcon}
                />
            </Box>

            {renderContent()}
            
            <Box sx={{ marginTop: 'calc(6% + 100px)' }} />
        </Box>
    );
};

export default TimelineFeed;