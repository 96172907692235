export const TEXT = {
    CREATE_EVENT: 'Create Event',
    EDIT_EVENT: 'Edit Event',
    UPLOAD_THUMBNAIL: 'Upload Thumbnail',
    UPLOAD_IMAGE: 'Upload Image',
    REMOVE: 'Remove',
    EVENT_INFORMATION: 'Event Information',
    EVENT_NAME: 'Event Name',
    EVENT_DESCRIPTION: 'Description',
    EVENT_LOCATION: 'Location',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    GO_BACK_BUTTON_TEXT: 'go back',
    CREATE_EVENT_BUTTON_TEXT: 'create event',
    SAVE_CHANGES_BUTTON_TEXT: 'save changes'
}